
import { decodeText } from '../utils/decodeText';
import constant from '../../constant';
import { defineComponent, watchEffect, reactive, toRefs, ref, nextTick } from 'vue';
import { onClickOutside, onLongPress, useElementBounding } from '@vueuse/core';
import { JSONToObject } from '../utils/utils';
import { handleErrorPrompts } from '../../utils';
import TUIChat from '../index.vue';

const messageBubble = defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    messagesList: {
      type: Object,
      default: () => ({}),
    },
    isH5: {
      type: Boolean,
      default: false,
    },
    needGroupReceipt: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['jumpID', 'resendMessage', 'showReadReceiptDialog', 'dropDownOpen'],
  setup(props: any, ctx: any) {
    const { t } = (window as any).TUIKitTUICore.config.i18n.useI18n();
    const { TUIServer } = TUIChat;
    const data = reactive({
      message: {},
      messagesList: {},
      show: false,
      type: {},
      referenceMessage: {},
      referenceForShow: {},
      allMessageID: '',
      needGroupReceipt: false,
      face: [],
      url: '',
    });

    watchEffect(() => {
      data.type = constant;
      data.message = props.data;
      data.messagesList = props.messagesList;
      data.needGroupReceipt = props.needGroupReceipt;
      if ((data.message as any).cloudCustomData) {
        const messageIDList: any[] = [];
        const cloudCustomData = JSONToObject((data.message as any).cloudCustomData);
        data.referenceMessage = cloudCustomData.messageReply ? cloudCustomData.messageReply : '';
        for (let index = 0; index < (data.messagesList as any).length; index++) {
          // To determine whether the referenced message is still in the message list, the corresponding field of the referenced message is displayed if it is in the message list. Otherwise, messageabstract/messagesender is displayed
          messageIDList.push((data.messagesList as any)[index].ID);
          (data as any).allMessageID = JSON.stringify(messageIDList);
          if ((data.messagesList as any)[index].ID === (data.referenceMessage as any)?.messageID) {
            data.referenceForShow = (data.messagesList as any)[index];
            if ((data.referenceMessage as any).messageType === constant.typeText) {
              (data as any).face = decodeText((data.referenceForShow as any).payload);
            }
            if ((data.referenceMessage as any).messageType === constant.typeFace) {
              (data as any).url = `https://web.sdk.qcloud.com/im/assets/face-elem/${
                (data.referenceForShow as any).payload.data
              }@2x.png`;
            }
          }
        }
      }
    });

    const htmlRefHook = ref<HTMLElement | null>(null);
    const dropdown = ref(false);
    const dropdownRef = ref(null);

    const toggleDialog = (e: any) => {
      dropdown.value = !dropdown.value;
      if (dropdown.value) {
        ctx.emit('dropDownOpen', dropdownRef);
        nextTick(() => {
          const dialogDom = (dropdownRef as any)?.value?.children[0];
          const parentDom = (dropdownRef as any)?.value?.offsetParent;
          const parentBound = useElementBounding(parentDom);
          const messageListDom = document.getElementById('messageEle');
          const messageListBound = useElementBounding(messageListDom);
          const leftRange = messageListBound?.left?.value;
          const rightRange =
            messageListBound?.left?.value + (messageListDom as any).clientWidth - dialogDom.clientWidth;
          const topRange = messageListBound?.top?.value;
          const bottomRange =
            messageListBound?.top?.value + (messageListDom as any).clientHeight - dialogDom.clientHeight;
          const { clientX, clientY } = e;
          switch (true) {
            case clientX > leftRange && clientX < rightRange:
              dialogDom.style.left = `${e.clientX - parentBound?.left?.value}px`;
              break;
            case clientX <= leftRange:
              dialogDom.style.left = '20px';
              break;
            case clientX >= rightRange:
              dialogDom.style.right = `${parentBound?.left?.value + parentDom?.clientWidth - e.clientX}px`;
              break;
          }
          switch (true) {
            case clientY > topRange && clientY < bottomRange:
              dialogDom.style.top = `${e.clientY - parentBound?.top?.value}px`;
              break;
            case clientY <= topRange:
              dialogDom.style.top = '0px';
              break;
            case clientY >= bottomRange:
              dialogDom.style.bottom = `${parentBound?.top?.value + parentDom?.clientHeight - e.clientY}px`;
              break;
          }
        });
      }
    };

    const jumpToAim = (message: any) => {
      if (
        (data.referenceMessage as any)?.messageID &&
        data.allMessageID.includes((data.referenceMessage as any)?.messageID)
      ) {
        ctx.emit('jumpID', (data.referenceMessage as any).messageID);
      } else {
        const message = t('TUIChat.无法定位到原消息');
        handleErrorPrompts(message, props);
      }
    };

    onClickOutside(dropdownRef, () => {
      dropdown.value = false;
    });

    onLongPress(htmlRefHook, toggleDialog);

    const resendMessage = (message: any) => {
      ctx.emit('resendMessage', message);
    };

    const showReadReceiptTag = (message: any) => {
      if (data.needGroupReceipt && message.flow === 'out' && message.status === 'success' && message.needReadReceipt) {
        return true;
      }
      return false;
    };

    const readReceiptStyle = (message: any) => {
      if (message.isPeerRead || message.readReceiptInfo.unreadCount === 0) {
        return '';
      }
      return 'unRead';
    };

    const readReceiptCont = (message: any) => {
      switch (message.conversationType) {
        case TUIServer.TUICore.TIM.TYPES.CONV_C2C:
          if (message.isPeerRead) {
            return t('TUIChat.已读');
          }
          return t('TUIChat.未读');
        case TUIServer.TUICore.TIM.TYPES.CONV_GROUP:
          if (message.readReceiptInfo.unreadCount === 0) {
            return t('TUIChat.全部已读');
          }
          if (
            message.readReceiptInfo.readCount === 0 ||
            (message.readReceiptInfo.unreadCount === undefined && message.readReceiptInfo.readCount === undefined)
          ) {
            return t('TUIChat.未读');
          }
          return `${message.readReceiptInfo.readCount + t('TUIChat.人已读')}`;
        default:
          return '';
      }
    };

    const showReadReceiptDialog = (message: any) => {
      ctx.emit('showReadReceiptDialog', message);
    };

    return {
      ...toRefs(data),
      toggleDialog,
      htmlRefHook,
      jumpToAim,
      dropdown,
      dropdownRef,
      resendMessage,
      showReadReceiptTag,
      readReceiptStyle,
      readReceiptCont,
      showReadReceiptDialog,
    };
  },
});
export default messageBubble;
