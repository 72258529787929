// vue3中创建store实例对象的方法createStore()按需引入
import { createStore } from 'vuex'

export default createStore({
  state: {
    showlogin:false,
    company_status:0,
    userInfo:{
      nickname:'',
      avatar:''
    }
  },
  mutations: {
    updateInfo (state, payload) {
        state.showlogin = payload
    },
    // 企业认证
    updateCompanyStatus(state, payload){
      state.company_status = payload
    },
    // 修改头像昵称信息
    updateUserInfo(state, payload){
      state.userInfo.nickname = payload.nickname
      state.userInfo.avatar = payload.avatar
      let userInfoData = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
      if(userInfoData!=null){
        userInfoData.nickname=state.userInfo.nickname
        userInfoData.avatar= state.userInfo.avatar
        localStorage.setItem('userInfo',JSON.stringify(userInfoData))
      }
    }
  }, 
  actions: {
  },
  getters: {
  },
  modules: {
  }
})

