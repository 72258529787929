import {createRouter,createWebHashHistory} from "vue-router";
// import Home from "../views/Home/index/index.vue";
// import Brandspecial from "../views/Home/Brandspecial/index.vue"
import TUIKit from "../TUIKit/index.vue";
import BrandDetails from "../views/Home/BrandDetails/index.vue"
import Dailysaledetails from "../views/Home/Dailysaledetails/index.vue"
import Findnew from "../views/Home/Findnew/index.vue"
import Findnewproducts from "../views/Home/Findnewproducts/index.vue"
import OverseasArea from "../views/Home/OverseasArea/index.vue"
import Findthefactory from "../views/Home/Findthefactory/index.vue"
import AllGoods from "../views/search/AllGoods.vue";
import Set from "../views/set/index.vue";
import Option from "../views/set/option.vue";
import SureOrder from "../views/set/sureOrder.vue";
import Pay from "../views/set/pay.vue";
import goodsCar from "../views/set/goodsCar.vue";
import enterpriseReg from "../views/set/enterpriseReg.vue";
import GoodsDetail from "../views/goodsDetail/goodsDetail.vue";
import Store from "../views/store/store.vue";
import Factory from "../views/factory/factory.vue";
import Message from "../views/message.vue";
import OrderHome from "../views/order/orderHome.vue";
import OrderDetail from "../views/order/orderDetail.vue";
import RefundAndSales from "../views/order/refundAndSales.vue";
import RedundInput from "../views/order/redundInput.vue";
import Refund from "../views/order/refund.vue";
import Comment from "../views/order/comment.vue";
import CommentSuccess from "../views/order/commentSuccess.vue";
import BrowsingHistory from "../views/userInfo/browsingHistory.vue";
import UserInfo from "../views/userInfo/wallet.vue";
import Cart from "../views/userInfo/cart.vue";
import Collect from "../views/userInfo/collect.vue";
import Attention from "../views/userInfo/attention.vue";
import RefundOrderDetail from "../views/order/refundOrderDetail.vue";
import Logistics from "../views/order/logistics.vue";
import Coupone from "../views/coupone.vue";
import FindSimilarity from "../views/findSimilarity.vue";
import MyCoupone from "../views/userInfo/myCoupone.vue";
import GetFuhe from "../views/set/getFuhe.vue";  
// import ceshi from "../views/newHome/ceshi.vue";  
import certificationQualification from "../views/order/certificationQualification.vue";  
import Home from "../views/newHome/index.vue";  

const routes=[
{path:"/",name:"Home",component:Home},
// {path:"/ceshi",name:"txtes",component:ceshi,meta: { keepAlive: true,  }},//品牌特卖
{path:"/index/BrandDetails",name:"BrandDetails",component:BrandDetails,meta: { keepAlive: true,  }},//品牌特卖
{path:"/certificationQualification",name:"certificationQualification",component:certificationQualification,meta: { keepAlive: true,  }},//测试
{path:"/index/Dailysaledetails",name:"Dailysaledetails",component:Dailysaledetails,meta: { keepAlive: false,  }},//天天特卖查看更多
{path:"/index/Findnew",name:"Findnew",component:Findnew,meta: { keepAlive: true,  }},//找新品
{path:"/tUIKit",name:"TUIKit",component:TUIKit,meta: { keepAlive: true, }}, //客服
{path:"/index/Findnewproducts",name:"Findnewproducts",component:Findnewproducts,meta: { keepAlive: true,  }},//奈喜严选
{path:"/index/OverseasArea",name:"OverseasArea",component:OverseasArea,meta: { keepAlive: true,  }},//海外专区
{path:"/index/Findthefactory",name:"Findthefactory",component:Findthefactory,meta: { keepAlive: true,  }},//找品牌
{path:"/allGoods",name:"AllGoods",component:AllGoods,meta: { keepAlive: false,  }},//所有商品
{path:"/goodsDetail",name:"GoodsDetail",component:GoodsDetail,meta: { keepAlive:false,  }},//商品详情
{path:"/Set",name:"Set",component:Set,meta: { keepAlive: false,  }},//个人设置
{path:"/Option",name:"Option",component:Option,meta: { keepAlive: true,  }},  //意见反馈 已完成
{path:"/SureOrder",name:"SureOrder",component:SureOrder,meta: { keepAlive: false }},//确认订单
{path:"/Pay",name:"Pay",component:Pay,meta: { keepAlive: false  }},//订单支付
{path:"/goodsCar",name:"goodsCar",component:goodsCar,meta: { keepAlive: true,  }},//作废
{path:"/enterpriseReg",name:"enterpriseReg",component:enterpriseReg,meta: { keepAlive: true}},//企业认证
{path:"/store",name:"Store",component:Store,meta: { keepAlive:false,  }},//商家门店
{path:"/factory",name:"Factory",component:Factory,meta: { keepAlive: false,  }},//工厂详情 作废
{path:"/message",name:"Message",component:Message,meta: { keepAlive: false,  }},// 作废
{path:"/order/orderHome",name:"OrderHome",component:OrderHome,meta: { keepAlive: false,  }},//个人中心
{path:"/order/orderDetail",name:"OrderDetail",component:OrderDetail,meta: { keepAlive: false,  }},//订单详情
{path:"/order/refundAndSales",name:"refundAndSales",component:RefundAndSales,meta: { keepAlive: false,  }},//作废
{path:"/order/redundInput",name:"RedundInput",component:RedundInput,meta: { keepAlive: false,  }},//作废 退货退款
{path:"/order/refund",name:"Refund",component:Refund,meta: { keepAlive: false,  }},//作废 退货退款
{path:"/comment",name:"Comment",component:Comment,meta: { keepAlive: true,  }},//评价商品
{path:"/order/commentSuccess",name:"CommentSuccess",component:CommentSuccess,meta: { keepAlive: false,  }},//评价商品列表
{path:"/order/refundOrderDetail",name:"RefundOrderDetail",component:RefundOrderDetail,meta: { keepAlive: false,  }},//已买到的东西
{path:"/browsingHistory",name:"BrowsingHistory",component:BrowsingHistory,meta: { keepAlive: false,  }},//浏览记录
{path:"/userInfo",name:"UserInfo",component:UserInfo,meta: { keepAlive: false,  }},// 钱包
{path:"/cart",name:"Cart",component:Cart, meta: { keepAlive:false}},//购物车
{path:"/collect",name:"Collect",component:Collect,meta: { keepAlive: false,  }},//我的收藏
{path:"/attention",name:"Attention",component:Attention,meta: { keepAlive: false,  }},//关注门店
{path:"/logistics",name:"Logistics",component:Logistics,meta: { keepAlive: false,  }}, //物流
{path:"/coupone",name:"Coupone",component:Coupone,meta: { keepAlive: false,  }}, //优惠券
{path:"/findSimilarity",name:"FindSimilarity",component:FindSimilarity,meta: { keepAlive: false,  }}, //找相似
{path:"/myCoupone",name:"MyCoupone",component:MyCoupone,meta: { keepAlive: false,  }}, //优惠券
{path:"/getFuhe",name:"getFuhe",component:GetFuhe,meta: { keepAlive: false}},//待复核
    
];
const router=createRouter({
    history:createWebHashHistory(),
    routes
})
export default router; 