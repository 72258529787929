<script setup>
import { ref, reactive, onBeforeMount } from 'vue'
import { get_history } from '@/utils/api'
import { useRouter } from "vue-router";
const router = useRouter();
const data = reactive({
    page: 1,
    last_page: 3,
    list: [], 
    show:false
});
const active = ref(2)
onBeforeMount(() => {
    getList()
})
const loading = ref(true)
const getList = (val) => {
    if (val) {
        loading.value=true
        active.value = val;
        data.page = 1;
    }
    get_history({ page: data.page, limit: 5, type: active.value }).then(res => {
        loading.value=false
        if (res.code == 200) {
            data.show=true;
            data.last_page=res.data.last_page
            if (data.page == 1) {
                data.list = res.data.data
            } else {
                data.list =data.list.concat(res.data.data) 
            }
            console.log(data.list)
        }
    })
}
const load=()=>{
    if(data.last_page>data.page){
        data.page++;
        getList()
    }
}


</script>

<template>
    <div class="topHomeBox cof">
        <div class="wrapCenter flex">
            <img src="../../assets/logo1.png" alt="" class="logo" style="object-fit:cover">
            <div class="homeText fontS20 cur">浏览记录</div>

        </div>
    </div>
    <div class="wrapCenter" style="margin-top: 20px;">
        <div class="">
            <div v-if="data.show">
                <div class="navTag co6 fontS20 flex">
                    <span @click="getList(2);" :class="active ==2 ? 'activetag cur' : 'cur'">所有商品</span>
                    <span @click="getList(1);" :class="active == 1 ? 'activetag cur' : 'cur'">旗舰店</span>
                    <span @click="getList(3);" :class="active == 3 ? 'activetag cur' : 'cur'">批发商</span>
                </div>
                <div class="printLogFather" infinite-scroll-distance="30px" v-infinite-scroll="load"
                    style="height:75vh;overflow: auto;" v-if="active == 2" v-loading="loading">
                    <el-empty description="没有数据" v-if="data.list.length==0"/>
                    <div class="list cur" > 
                        <div v-for="(item, index) in data.list" :key="index">
                            <div class="dateBox flexJBC" style="margin:16px 0 20px">
                                <span class="fontS20 co6">{{ item.date }}</span>
                                <div style="border-top: 1px solid #CCCCCC;flex:1;height:1px;margin-left: 20px;"></div>
                            </div>
                            <el-row :gutter="16">
                                <el-col :span="6" v-for="(item1, index) in item.data" :key="index" @click="router.push({path:'/goodsDetail',query:{id:item1.id}})">
                                    <div class="li">
                                        <img :src="item1.image" alt="" style="object-fit:cover">
                                        <div class="title elipes2 co3 fonrS22">{{ item1.product_name }}</div>
                                        <div class="flex">
                                            <span class="priceColor fontS12">￥</span>
                                            <span class="priceColor fontS28">{{ item1.price }}</span>
                                            <span class="fontS18 co9" style="margin-left:14px">{{ item1.sales }}人付款</span>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </div>
                    <!-- 店铺 -->
                    <div class="printLogFather" infinite-scroll-distance="30px" v-infinite-scroll="load"
                    style="height:75vh;overflow: auto;" v-if="active == 1" v-loading="loading">
                    <div >
                        <el-empty description="没有数据" v-if="data.list.length==0"/>
                        <div v-for="(item, index) in data.list" :key="index">
                            <div class="dateBox flexJBC" style="margin:40px 0 20px">
                                <span class="fontS20 co6" style="padding-left:40px">{{ item.date }}</span>
                                <div style="border-top: 1px solid #CCCCCC;flex:1;height:1px;margin-left: 20px;"></div>
                            </div>
                            <div class="store flexJBC" v-for="(item1, index1) in item.data" :key="index1" >
                                <div class="left cur" style="width:30%" @click="router.push({path:'/store',query:{id:item1.id}})">
                                    <div class="flex">
                                        <img :src="item1.store_image" alt="" class="storeImg" style="object-fit:cover">
                                        <div class="co6 fontS22">
                                            <div class="elipes1" style="width:378px">{{ item1.stroe_name }}</div>
                                            <div class="flex" style="margin:13px 0px 20px ;">
                                                <el-rate v-model="item1.postage_score" disabled />
                                                <span class="fontS20"
                                                    style="color:#FFC047">{{ item1.postage_score }}分</span>
                                            </div>
                                            <div class="co9 fontS16">已成交{{ item1.turnovers }}</div>
                                        </div>
                                    </div>
                                    <div class="co9 fontS18" style="margin-top:10px ;">
                                        主营：
                                        <el-space :size="5" spacer="|">
                                            <span v-for="(text, index) in item1.main_business"
                                                :key="index">{{ text }}</span>
                                        </el-space>
                                    </div>
                                </div>
                                <div class="right flex">
                                    <div v-for="(item, index) in item1.product" class="" :key="item.id" @click="router.push({path:'/goodsDetail',query:{id:item.id}})"> 
                                        <div v-if="index <= 3" class="right_li cur">
                                            <img :src="item.image" alt="" style="object-fit:cover">
                                            <div class="priceBOx fontS20 cof ">￥{{ item.price }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                    <!-- 工厂 -->
                    <div class="printLogFather" infinite-scroll-distance="30px" v-infinite-scroll="load"
                    style="height:75vh;overflow: auto;" v-if="active == 3" v-loading="loading">
                    <el-empty description="没有数据" v-if="data.list.length==0"/>
                    <div v-if="active == 3">
                        <div v-for="(item, index) in data.list" :key="index">
                            <div class="dateBox flexJBC" style="margin:40px 0 20px">
                                <span class="fontS20 co6" style="padding-left:40px">{{ item.date }}</span>
                                <div style="border-top: 1px solid #CCCCCC;flex:1;height:1px;margin-left: 20px;"></div>
                            </div>
                            <div class="store flexJBC factory" v-for="(item1, index) in item.data" :key="index">
                                <div class="left cur" style="width:30%" @click="router.push({path:'/store',query:{id:item1.id}})">
                                    <div class="flex" style="align-items:flex-start">
                                        <img :src="item1.store_image" alt="" class="storeImg" style="object-fit:cover;width: 120px;height: 120px;"/>
                                        <div class="co6 fontS22">
                                            <div class="elipes1 co6 fontS22" style="width:378px">{{ item1.stroe_name }}
                                            </div>
                                            <div class="co9 fontS18" style="margin:10px 0 ;">
                                                <el-space :size="5" spacer="|">
                                                    <span v-for="(text, index) in item1.main_business"
                                                        :key="index">{{ text }}</span>
                                                </el-space>
                                            </div>
                                            <div class="flex co6 fontS16">
                                                <span :style="{ background: index1 % 2 == 0 ? '#FFF2D0' : '#FFDED3' }"
                                                    style="padding:6px 18px;margin-right: 10px;"
                                                    v-for="(item1, index1) in item1.tags" :key="index1">{{ item1
}}</span>
                                            </div>
                                            <div class="co9 fontS18 elipes2"
                                                style="margin:10px 0;line-height: 25px;width: 378px;">
                                                {{ item.store_info ? item.store_info : '暂无门店简介' }}</div>
                                            <div class="co9 fontS18">已成交{{ item1.turnovers }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="right flex">
                                    <div v-for="(item, index) in item1.product" class="" :key="item.id" @click="router.push({path:'/goodsDetail',query:{id:item.id}})">
                                        <div v-if="index <= 3" class="right_li cur">
                                            <img :src="item.image" alt="" style="object-fit:cover">
                                            <div class="priceBOx fontS20 cof">￥{{ item.price }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.topHomeBox {
    height: 100px;
    background: #25459C;
    display: flex;
    justify-content: center;
    align-items: center;

    .homeText {
        margin-left: 37px;
        margin-right: 80px;
        margin-top: 32px;
    }

    .active {
        line-height: 50px;
        border-bottom: 4px solid #fff;
    }

    .logo {
        // width: 140px;
        height: 62px;
        // margin-right: 32px;
        // margin: 19px 0;
    }
}

.store {
    .right {
        .right_li {
            margin-left: 20px;

            .priceBOx {
                position: absolute;
                // width: 80px;
                height: 32px;
                line-height: 32px;
                background: rgba(0, 0, 0, 0.6);
                bottom: 0;
                right: 0;
            }

            position: relative;

            img {
                width: 200px;
                height: 200px;
                background: #FAFAFA;
                opacity: 1;
                border: 1px solid #CCCCCC;
            }
        }
    }

    .left {
        .storeImg {
            width: 120px;
            height: 120px;
            // background: #F2F2F2;
            opacity: 1;
            margin-right: 20px;
        }
    }

    align-items: flex-start;
    width: 1472px;
    padding: 40px 0;
    // box-sizing: border-box;
    border-bottom: 1px solid #CCCCCC;
}

.list {

    flex-wrap: wrap;
    padding: 0 40px;
    box-sizing: border-box;
    justify-content: flex-start;

    .li {
        width: 336px;
        position: relative;

        .title {
            margin: 20px 0 16px;
            line-height: 28px;
        }

        margin-bottom: 40px;
        margin-right: 16px;

        img {
            width: 336px;
            height: 339px;
            display: block;
        }
    }
}

.paixuBox {
    .flitetBox {
        width: 390px;
        height: 352px;
        background: #FFFFFF;
        box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.12);
        position: absolute;
        padding: 20px 10px;
        box-sizing: border-box;
        top: 60px;
        left: 0;
        z-index: 9;

        .li {
            width: 110px;
            text-align: center;
            line-height: 40px;
            height: 40px;
            background: #F5F5F5;
            font-size: 16px;
            color: #666;
            margin-top: 14px;
            ;

        }
    }

    .priceBox {
        margin-left: 79px;

        /deep/.el-input-group__append,
        /deep/.el-input-group__prepend,
        /deep/.el-input__wrapper {
            background-color: #fff;
            box-shadow: none;
        }

        /deep/.el-input-group__prepend {
            padding: 0;
        }

    }

    width: 1472px;
    height: 58px;
    // line-height: 58px;
    background: #F5F5F5;
    border: 1px solid #CCCCCC;
    margin: 20px 0;

    .active {
        color: #25459C;
        background: #FFFFFF;
        border: 1px solid #CCCCCC;
    }

    .li {
        width: 140px;
        align-items: center;
        height: 58px;
        position: relative;

    }
}

.navTag {
    margin-top: 20px;
    // width: 100%;
    width: 1472px;
    border-bottom: 2px solid #25459C;

    .activetag {
        background: #25459C;
        color: #fff;
    }

    span {
        display: block;
        width: 140px;
        height: 48px;
        line-height: 48px;
        text-align: center;
    }
}
</style>
