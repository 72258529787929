<script setup>
import { selectUserCoupon } from '@/utils/api'
import { ref, onBeforeMount, nextTick } from 'vue'

const activeName = ref('1')
const list = ref([])
const page = ref('1')
const last_page = ref('1')
onBeforeMount(() => {
    getList()
})
const getList = (val) => {
    console.log(val)
    nextTick(() => {
        if (val) {
            page.value = 1;
            loading.value = true;
        }
        selectUserCoupon({ page: page.value, limit: 10, status: activeName.value }).then(res => {
            if (res.code == 200) {
                loading.value = false;
                last_page.value = res.data.last_page
                if (page.value == 1) {
                    list.value = res.data.data
                } else {
                    list.value = list.value.concat(res.data.data)
                }
            }
        })
    })

}

const loading = ref(true)
const load = () => {
    if (last_page.value > page.value) {
        page.value++;
        getList()
    }
}
</script>
<template>
    <div style="width:1296px">
        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="getList(true)">
            <el-tab-pane label="已使用" name="2"></el-tab-pane>
            <el-tab-pane label="已过期" name="3"> </el-tab-pane>
            <el-tab-pane label="未使用" name="1"></el-tab-pane>
        </el-tabs>
        <div v-loading="loading">

            <div v-if="list.length > 0 && activeName == 1" class="coupon printLogFather" infinite-scroll-distance="30px"
                v-infinite-scroll="load" style="height:75vh;overflow: auto;">
                <div class="" v-for="(item, index) in list" :key="index">
                    <div class="liss">
                        <div class="li_left">
                            <div class="li_left_inner">
                                <div class="top">
                                    {{
                                        item.coupon_type == 1 ? ('￥' + item.coupon_price) : (item.discount + '折')
                                    }}
                                </div>
                                <div>{{ item.coupon_type == 1 ? '满减券' : '折扣券' }}</div>
                            </div>
                        </div>
                        <div class="li_right">
                            <div class="li_right_inner">
                                <div class="co3 fontS18">{{ item.coupon_title }}</div>
                                <div class="co9 fontS14">有效期{{ item.end_time }}</div>
                            </div>
                            <div class="li_right_inner_r co9">
                                待
                                使
                                用
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="list.length > 0 && activeName == 2" class="coupon printLogFather" infinite-scroll-distance="30px"
                v-infinite-scroll="load" style="height:75vh;overflow: auto;">
                <div class="" v-for="(item, index) in list" :key="index">
                    <div class="liss">
                        <div class="li_left grayscale">
                            <div class="li_left_inner">
                                <div class="top">
                                    {{
                                        item.coupon_type == 1 ? ('￥' + item.coupon_price) : (item.discount + '折')
                                    }}
                                </div>
                                <div>{{ item.coupon_type == 1 ? '满减券' : '折扣券' }}</div>
                            </div>
                        </div>
                        <div class="li_right">
                            <div class="li_right_inner">
                                <div class="co3 fontS18">{{ item.coupon_title }}</div>
                                <div class="co9 fontS14">有效期{{ item.end_time }}</div>
                            </div>
                            <div class="li_right_inner_r co9">
                                已
                                使
                                用
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="list.length > 0 && activeName == 3" class="coupon printLogFather" infinite-scroll-distance="30px"
                v-infinite-scroll="load" style="height:75vh;overflow: auto;">
                <div class="" v-for="(item, index) in list" :key="index">
                    <div class="liss">
                        <div class="li_left grayscale">
                            <div class="li_left_inner">
                                <div class="top">
                                    {{
                                        item.coupon_type == 1 ? ('￥' + item.coupon_price) : (item.discount + '折')
                                    }}
                                </div>
                                <div>{{ item.coupon_type == 1 ? '满减券' : '折扣券' }}</div>
                            </div>
                        </div>
                        <div class="li_right">
                            <div class="li_right_inner">
                                <div class="co3 fontS18">{{ item.coupon_title }}</div>
                                <div class="co9 fontS14">有效期{{ item.end_time }}</div>
                            </div>
                            <div class="li_right_inner_r co9">
                                已
                                过
                                期
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <view class="noCoupon center" :style="style" v-if="list.length == 0 && !loading">
            <img src="../../assets/no_coupon.png" style="width:326px;height:252px;margin: 0 auto;object-fit:cover" mode="" />
            <div class="co9 fontS28">暂无优惠券~</div>
        </view>
    </div>
</template>
<style lang="less" scoped>
.noCoupon {
    padding-top: 220px;
}

.grayscale {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
    -webkit-filter: grayscale(1);
}

.coupon {
    // padding-left: 32px;
    // padding-right: 32px;
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;

    .liss {
        width: 476px;
        height: 122px;
        display: flex;
        box-shadow: 0 0 5px #ccc;
        margin-right: 40px;
        margin-top: 40px;

        .li_left {
            width: 33%;
            height: 122px;
            text-align: center;
            background-image: linear-gradient(to right, #FCE6D5, #FEF2E4);

            .li_left_inner {
                // width: 100%;
                height: 100%;
                color: #AC5301;
                background-image: url('../../assets/Mask group.png');
                background-size: 100%;

                view {
                    line-height: 30px;
                    font-size: 18px;
                }

                .top {
                    line-height: 90px;
                    font-size: 40px;
                }
            }
        }

        .li_right {
            width: 67%;
            height: 122px;
            background-color: #fff;
            display: flex;

            .li_right_inner {
                padding-left: 40px;
                padding-top: 42px;
                width: 88%;
                border-right: 2px dashed #f5f5f5;

                .co9 {
                    margin-top: 10px;
                }
            }

            .li_right_inner_r {
                padding: 20px 0;
                width: 10%;
                background-color: white;
                text-align: center;
                line-height: 30px;
                box-sizing: border-box;
            }
        }
    }

}

/deep/.el-tabs__item.is-active {
    color: #25459C !important;
}

/deep/.el-tabs__item:hover {
    color: #25459C !important;
}

/deep/.el-tabs__active-bar {
    background-color: #25459C !important;
}
</style>