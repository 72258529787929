<script setup>
import { reactive, ref, onBeforeUnmount,onMounted, onBeforeMount,onUnmounted} from 'vue'
import { useRouter } from "vue-router";
import { orderCreate, orderPay, getOrderPayStatus } from '@/utils/api'
import QrcodeVue from 'qrcode.vue'
import { ElMessage } from 'element-plus'
const router = useRouter();
const data = reactive({
    qrCode: '', //二维码链接
    query: router.currentRoute.value.query, //购物车订单预览商品的id   cart_id
    infoData:null,
    times:'',

})
const tyIndex = ref(2) //支付宝支付
const timer = ref(null) //支付宝支付
const timerss=ref(null)
onBeforeUnmount(() => {
    clearInterval(timer.value)
    timer.value=null
    clearInterval(timerss.value);
    timerss.value=null
})
onBeforeMount(() => {
    // 购物车创建订单
    if (router.currentRoute.value.query.sn) {
        console.log('进入',router.currentRoute.value.query.sn)
        // 订单主页待支付进入此页面
        // 订单支付状态
        timer.value = setInterval(() => {
            getOrderPayStatus({ order_id: data.query.order_id }).then(res => {
                console.log(res,'res')
                if (res.code == 200) {
                    if (res.data.status == 1) {
                        ElMessage.success('支付成功')
                        router.push({ path: '/order/OrderHome' })
                        localStorage.setItem('leftactive', 1)
                        clearInterval(timer.value)
                    }
                }
            })
        }, 2000)
        orderPay({ sn: data.query.sn, pay_type:2, money: data.query.real_price }).then(res => {
            if (res.code == 200) {
                data.qrCode = res.data.param.prePayTn
            }
        })
    } else {
        orderCreate(Object.assign(data.query)).then(res => {
            if (res.code == 200) {
                data.infoData=res.data
                console.log( data.infoData,'data.infoData')
                if(tyIndex.value==2){
                    orderPay({ sn:res.data.sn, pay_type:2, money: res.data.total_price }).then(res => {
                        if (res.code == 200) {
                            data.qrCode = res.data.param.prePayTn
                        }
                    })
                    timer.value = setInterval(() => {
                        getOrderPayStatus({ order_id: data.infoData.order_id }).then(res => {
                            if (res.code == 200) {
                                if (res.data.status == 1) {
                                    ElMessage.success('支付成功')
                                    router.push({ path: '/order/OrderHome' })
                                    localStorage.setItem('leftactive', 1)
                                    clearInterval(timer.value)
                                }
                            }
                        })
                    }, 2000)
                }
               
            }
        })
    }
})
const nextStep=()=>{
    clearInterval(timer.value)
    // 跳银行卡支付
    if(data.query.sn){
        // 订单页面进来
        orderPay({ sn:data.query.sn, pay_type:8,money:data.query.real_price }).then(res => {
         if (res.code == 200) {
            window.open(res.data.param.url)
         }else{
            ElMessage.warning(res.message)
         }
    })
    }else{
        orderPay({ sn:data.infoData.sn, pay_type:8,money:data.query.totalPrice }).then(res => {
         if (res.code == 200) {
            window.open(res.data.param.url)
         }else{
            ElMessage.warning(res.message)
         }
    })
    }
    
}
const ischeck=(e)=>{
    tyIndex.value=e
}
// let endText='订单已自动取消'
 const countdowm=(timestamp)=>{
    console.log('进入这里了吗？')
		timerss.value = setInterval(function(){
			let nowTime = new Date();
			let endTime = new Date(timestamp * 1000);
			let t = parseInt(endTime.getTime() - nowTime.getTime())
			if(t>0){
				let day = Math.floor(t/86400000);
				let hour=Math.floor((t/3600000)%24);
				let min=Math.floor((t/60000)%60);
				let sec=Math.floor((t/1000)%60);
				hour = hour < 10 ? "0" + hour : hour;
				min = min < 10 ? "0" + min : min;
				sec = sec < 10 ? "0" + sec : sec;
				let format = '';
				if(day > 0){
					format = `${day}天${hour}小时${min}分${sec}秒`;
				}
				if(day <= 0 && hour > 0 ){
					format = `${hour}小时${min}分${sec}秒`;
				}
				if(day <= 0 && hour <= 0){
					format =`${min}分${sec}秒`;
				}
                data.times = format
			}
            // else{
			// 	clearInterval(timerss.value);
            //     data.times = endText;
            //     // router.replace({path:'/order/OrderHome'})
			// }
            
		},1000);
                return  data.times
		}

</script>

<template>
    <div class="wrapCenter">
        <div class="top flex">
            <img src="../../assets/logo.png" style="width: 150px;height: 60px;object-fit:covers" alt="">
            <span class="fontS20 co3" style="margin:30px 0 0 10px;">收银台</span>
        </div>
        <div class="goods  fontS16 co3 flex flexJBC" >
            <div class="g_left " style="display: flex;font-weight: 500;">
                <div >倒计时 : </div>
                <div style="margin-left:10px;" v-if="data.query.auto_cancel_start_time">
                    {{countdowm(data.query.auto_cancel_start_time)}}
                </div>
                <div style="margin-left:10px;" v-else>
                    {{countdowm(data.infoData.auto_cancel_start_time)}}
                </div>
            </div>
            <div style="text-align: right;">
                <p class="fontS16 co3" style="font-weight: 500;">订单金额：
                    <span class="fontS20 homeRed">¥</span>
                    <span class="fontS24 homeRed" style="font-weight: 600;">{{
                        data.query.sn ? data.query.real_price :
                            data.query.totalPrice
                    }}</span>
                </p>
            </div>
        </div>
        <div class="pay_type">
            <el-radio-group v-model="tyIndex" @change="ischeck">
                <div class="p_t_li" :class="tyIndex == 2 ? 'active' : ''">
                    <el-radio :label="2" size="large">
                        <div class="flexJBC">
                            <div class="flex">
                                <img src="../../assets/Image/zfbpay.png" alt="" style="margin-left: 40px;object-fit:cover">
                                <span class="fontS16 co3" style="margin-left: 20px;">支付宝支付</span>
                            </div>
                            <div>
                                <span v-if="tyIndex == 2">
                                    <span class="co3 fontS16">支付：</span>
                                    <span class="fontS20 priceColor">￥</span>
                                    <span class="fontS22 priceColor" style="font-weight: 600;">{{
                                        data.query.sn ? data.query.real_price : data.query.totalPrice
                                    }}</span>
                                </span>
                            </div>
                        </div>
                    </el-radio>
                </div>
                <div class="p_t_li"  :class="tyIndex == 8 ? 'active' : ''">
                    <el-radio :label="8" size="large">
                        <div class="flexJBC">
                            <div class="flex">
                                <img src="../../assets/card.png" alt="" style="margin-left: 40px;object-fit:cover">
                                <span class="fontS16 co3" style="margin-left: 20px;">银行卡支付</span>
                            </div>
                            <div>
                                <span v-if="tyIndex == 8">
                                    <span class="co3 fontS16">支付：</span>
                                    <span class="fontS20 priceColor">￥</span>
                                    <span class="fontS22 priceColor" style="font-weight: 600;">{{
                                        data.query.sn ? data.query.real_price : data.query.totalPrice
                                    }}</span>
                                </span>
                            </div>
                        </div>
                    </el-radio>
                </div>
            </el-radio-group>
            <el-button type="primary" @click="nextStep" style="margin-top:20px" size="large" color="#25459C" v-if="tyIndex== 8">下一步</el-button>
        </div>
        <div class="center" style="margin-top:100px" v-if="data.qrCode&&tyIndex== 2">
            <qrcode-vue :value="data.qrCode" style="width:200px;height:200px"></qrcode-vue>
        </div>
    </div>
</template>


<style lang="less" scoped>
.btn {
    width: 250px;
    font-size: 20px;
    color: white;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background-color: #25459C;
    margin: 100px auto;
}

:deep(.el-radio-group) {
    display: block;
}

:deep(.el-radio--large) {
    width: 100%;
}

:deep(.el-radio__label) {
    width: 100%;
}

.active {
    box-sizing: border-box;
    border: 2px solid #25459C !important;
}

.pay_type {
    padding: 40px;
    box-sizing: border-box;

    .p_t_li {
        padding: 20px;
        box-sizing: border-box;
        border: 2px solid transparent;
    }
}
</style>
