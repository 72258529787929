<script setup>
import { ref, reactive, onMounted } from "vue";
import { useRouter } from 'vue-router';
//
import {
  CaretTop,
  CaretBottom,
  Filter,

} from "@element-plus/icons-vue";
import { getGoods, getShops, getFactorys } from "@/utils/api";
const router = useRouter()
const data = reactive({
  category_id:router.currentRoute.value.query.category_id,
  // showFi工产ltet:false, //是否显示筛选框
  year: '',
  year1: '', //工厂
  page: 1,
  last_page: 3,
  number: '',
  number1: '', //工厂
  maxPrice: "",
  minPrice: "",
  showSearchHistory: false,
  activeNav: 0, //shaixunList的当前项
  shaixunList: ["综合排序", "销量", "价格"],
  shaixunList1: ["综合排序", "销量", "筛选"],
  shaixunList2: ["综合排序", "成交量", "筛选"],
  typeFact1: [
    {
      //工厂筛选条件年份
      name: "小于1年",
      check: false,
      number: "0,1",
    },
    {
      name: "1~2年",
      check: false,
      number: "1,2",
    },
    {
      name: "2~3年",
      check: false,
      number: "2,3",
    },
    {
      name: "3~4年",
      check: false,
      number: "3,4",
    },
    {
      name: "4年以上",
      check: false,
      number: "4",
    },
  ],
  typeFact2: [
    {
      //工厂筛选条件销量
      name: "0~5k",
      check: false,
      number: "0,5000",
    },
    {
      name: "5k~1w",
      check: false,
      number: "5000,10000",
    },
    {
      name: "1~2w",
      check: false,
      number: "10000,20000",
    },
    {
      name: "2~4w",
      check: false,
      number: "20000,40000",
    },
    {
      name: "4w+",
      check: false,
      number: "40000",
    },
  ],
});
const isPriceDesc = ref('');
const active = ref(0);
const searchText = ref(router.currentRoute.value.query.searchText);
const goodList = ref([]);
const shopList = ref([]);
const factoryList = ref([]);
const filterShow1 = ref(false);
const filterShow2 = ref(false);
const $router = useRouter();
const loading=ref(true)
const go = ((type, item) => {
  // 0商品 1 店铺 2工厂
  console.log(item);
  if (type == 0) {
    $router.push({ path: '/GoodsDetail', query: { id: item.id } })
  } else if (type == 1) {
    $router.push({ path: '/store', query: { id: item.id } })
  } else if (type == 2) {
    $router.push({ path: '/store', query: { id: item.id } })
  }
})
//商品搜索
const searchGooDs = (price, sales) => {
  let custom_price = "";
  let min = data.minPrice ? Number(data.minPrice) : 0
  let max = data.maxPrice ? Number(data.maxPrice) : 0
  if (min == 0 && max == 0) {
    custom_price = ''
  } else if (min > max) {
    custom_price = max + "," + min;
  } else {
    custom_price = min + "," + max;
  }
  loading.value=true
  getGoods({
    page: data.page,
    limit: 10,
    search_key: searchText.value,
    custom_price: custom_price,
    price: price ? "" : !isPriceDesc.value ? "asc" : "desc",
    sales: sales ? "desc" : "",
    category_id:data.category_id
  }).then((res) => {
    loading.value=false
    console.log(res, "///////////");
    if (res.code == 200) {
      data.last_page = res.data.last_page
      if (data.page == 1) {
        goodList.value = res.data.data;
      } else {
        goodList.value = goodList.value.concat(res.data.data)
      }
    }
  });
};
//店铺搜索
const searchShops = (sales) => {
  loading.value=true
  getShops({
    search_key: searchText.value,
    limit: 10,
    year: data.year,
    number: data.number,
    sales: sales ? "desc" : "",
    page: data.page,
    category_id:'',
  }).then((res) => {
    if (res.code == 200) {
      loading.value=false
      data.last_page = res.data.last_page
      if (data.page == 1) {
        shopList.value = res.data.data;
      } else {
        shopList.value = shopList.value.concat(res.data.data)
      }
      console.log(shopList.value, 'shopList.value')
    }
  });
};
//工厂搜索
const searchFactorys = (sales, filter) => {
  loading.value=true
  getFactorys({
    search_key: searchText.value,
    limit: 10,
    sales: sales ? "desc" : "",
    year: filter ? data.year1 : "",
    number: filter ? data.number1 : "",
    page: data.page,
    category_id:'',
  }).then((res) => {
    if (res.code == 200) {
      loading.value=false
      data.last_page = res.data.last_page
      if (data.page == 1) {
        factoryList.value = res.data.data;
      } else {
        factoryList.value = factoryList.value.concat(res.data.data)
      }
    }
  });
};
//全部商品，店铺，工厂切换
const changeFirst = (val) => {
  filterShow1.value = false
  filterShow2.value = false
  active.value = val;
  data.activeNav = 0;
  data.page = 1;
  data.typeFact2.forEach(item => {
    item.check = false;
  })
  data.typeFact1.forEach(item => {
    item.check = false;
  })
  data.number = ''
  data.number1 = ''
  data.year = ''
  data.year1 = ''


  if (val == 0) {
    searchGooDs();
  } else if (val == 1) {
    searchShops();
  } else if (val == 2) {
    searchFactorys();
  }
};
//商品的综合，销量，价格切换
const typeChangeGoods = (val) => {
  data.activeNav = val;
  data.page = 1;
  if (val == 0) {
    searchGooDs(false, false);
  } else if (val == 1) {
    searchGooDs(false, true);
  } else if (val == 2) {
    isPriceDesc.value = !isPriceDesc.value;
    searchGooDs(false, false);
  }
};
//店铺的综合，成交量，价格切换
const typeChangeShop = (val) => {
  data.activeNav = val;
  filterShow1.value = false;
  data.page = 1;
  data.number = ''
  data.number1 = ''
  data.year = ''
  data.year1 = ''
  console.log(val);
  if (val == 0) {
    searchShops();
  } else if (val == 1) {

    searchShops(true);
  } else if (val == 2) {
    filterShow1.value = true;
    // searchShops(false, true, true);
  }
};
//工厂的综合，成交量，价格切换
const factoryChange = (val) => {
  data.activeNav = val;
  filterShow2.value = false;
  data.page = 1;
  console.log(val);
  if (val == 0) {
    searchFactorys();
  } else if (val == 1) {
    searchFactorys(true);
  } else if (val == 2) {
    filterShow2.value = true;
    // searchFactorys(false,true);
  }
};
const filter1 = (index) => {
  data.typeFact1.forEach((item) => {
    item.check = false;
  });
  data.typeFact1[index].check = true
  data.year = data.typeFact1[index].number
  data.page = 1;
  searchShops(false, true)
};
const filter2 = (index) => {
  data.typeFact2.forEach((item) => {
    item.check = false;
  });
  data.typeFact2[index].check = true
  data.number = data.typeFact2[index].number
  data.page = 1;
  // console.log( data.typeFact1=2[index].check);
  searchShops(false, true)
};
const filter3 = (index) => {
  data.typeFact1.forEach((item) => {
    item.check = false;
  });
  data.typeFact1[index].check = true
  data.year1 = data.typeFact1[index].number
  data.page = 1;
  searchFactorys(false, true)
};
const filter4 = (index) => {
  data.typeFact2.forEach((item) => {
    item.check = false;
  });
  data.typeFact2[index].check = true
  data.number1 = data.typeFact2[index].number
  data.page = 1;
  searchFactorys(false, true)
};
const search = () => {
  data.page = 1;
  if (active.value == 0) {
    searchGooDs()
  } else if (active.value == 1) {
    searchShops()
  } else if (active.value == 2) {
    searchFactorys()
  }
}

let load = () => {
  if (data.page < data.last_page) {
    data.page++;
    if (active.value == 0) {
      searchGooDs();
    } else if (active.value == 1) {
      searchShops();
    } else if (active.value == 2) {
      searchFactorys();
    }
  }
}
const searchPrcie = () => {
  data.page = 1;
  searchGooDs()
}

onMounted(() => {
  searchGooDs();
  console.log(router.currentRoute.value.query.category_id,'85288888888888888888888888')
});
</script>

<template>
  <div class="wrapCenter" style="margin-top: 20px">
    <div class="">
      <div>
        <div class="topsearch">
          <div class="flex">
            <div  class="logoWH" style="margin-right: 90px;">
              <img  src="../../assets/logo.png" alt="" style="width: 100%;margin-top: -12px;"/>
            </div>
            <div class="right flex">
              <div>
                <div class="inputBox flex">
                  <input v-model="searchText" type="text" @change="search()" :placeholder="active == 0 ?'请输入商品名称':active ==1?'请输入旗舰店店名':'请输入要查询的批发商'" />
                  <div @click="search()" class="seacrhIcon cur">
                    <img src="../../assets/search.png" style="object-fit:cover" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="navTag co6 fontS20 flex">
          <span @click="changeFirst(0)" :class="active == 0 ? 'activetag cur' : 'cur'">商品</span>
          <span @click="changeFirst(1)" :class="active == 1 ? 'activetag cur' : 'cur'">旗舰店</span>
          <span @click="changeFirst(2)" :class="active == 2 ? 'activetag cur' : 'cur'">批发商</span>
        </div>
        <div class="paixuBox flex fontS16 co6">
          <!-- 全部商品 -->
          <div v-if="active == 0" class="flex">
            <div v-for="(item, index) in data.shaixunList" :key="index" @click="typeChangeGoods(index)"
              class="cur flexCenter li center" :class="data.activeNav == index ? 'active' : ''">
              <span> {{ item }}</span>
              <div v-if="index == 2">
                <div style="margin-bottom: -8px">
                  <div>
                    <el-icon size="10" v-if="isPriceDesc === ''" color="#666">
                      <CaretTop />
                    </el-icon>
                    <el-icon size="10" v-else :color="isPriceDesc == 0 ? '#25459C' : '#666'">
                      <CaretTop />
                    </el-icon>
                  </div>
                </div>
                <el-icon size="8" :color="isPriceDesc ? '#25459C' : '#666'">
                  <CaretBottom />
                </el-icon>
              </div>
            </div>
          </div>

          <div class="priceBox co6 flex" v-if="active == 0">
            <span>价格区间：</span>
            <el-input @change="searchPrcie" type="number" min="0" v-model="data.minPrice"
              style="box-shadow: none;width: 90px; background: #f5f5f5;border: 1px solid #cccccc;">
              <template #prepend style="background: none">￥</template>
            </el-input>
            <div style="padding: 0 10px">-</div>
            <el-input @change="searchPrcie" min="0" type="number" v-model="data.maxPrice"
              style=" box-shadow: none;  width: 90px;background: #f5f5f5;border: 1px solid #cccccc;">
              <template #prepend style="background: none">￥</template>
            </el-input>
          </div>

          <!-- 店铺 -->
          <div v-if="active == 1" class="flex" style="position: relative;">
            <div v-for="(item, index) in data.shaixunList1" :key="index" @click="typeChangeShop(index)"
              class="cur flexCenter li center" :class="data.activeNav == index ? 'active' : ''">
              <span> {{ item }}</span>
              <div v-if="index == 2">
                <el-icon size="13" :color="index == 2 && data.activeNav == index ? '#25459C' : '#666'">
                  <Filter />
                </el-icon>
              </div>
            </div>
            <div style="position: absolute;left: 280px;" v-if="filterShow1" @mouseleave="filterShow1 = false">
              <div class="flitetBox co3 fontS16">
                <div style="text-align: left">开店时间</div>
                <div class="flex" style="flex-wrap: wrap">
                  <div v-for="(item, index) in data.typeFact1" :key="index" class="li cur"
                    :class="item.check ? 'activeFac' : ''" @click="filter1(index)" style="margin-left: 10p">
                    {{ item.name }}
                  </div>
                </div>
                <div style="text-align: left; margin-top: 40px">成交量</div>
                <div class="flex" style="flex-wrap: wrap">
                  <div v-for="(item, index) in data.typeFact2" :class="item.check ? 'activeFac' : ''"
                    @click="filter2(index)" :key="index" class="li flex cur" style="margin-left: 10px">
                    <span>{{ item.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 工厂 -->
          <div class="flex" style="position: relative" v-if="active == 2">
            <div v-for="(item, index) in data.shaixunList2" :key="index" @click="factoryChange(index)"
              class="cur flexCenter li center " :class="data.activeNav == index ? 'active' : ''">
              <span> {{ item }}</span>
              <div v-if="index == 2">
                <el-icon size="13" :color="index == 2 && data.activeNav == index ? '#25459C' : '#666'">
                  <Filter />
                </el-icon>
              </div>
            </div>

            <div class="flitetBox co3 fontS16" style="position: absolute; left: 280px" v-if="filterShow2"
              @mouseleave="filterShow2 = false">
              <div style="text-align: left">开店时间</div>
              <div class="flex" style="flex-wrap: wrap">
                <div v-for="(item, index) in data.typeFact1" :key="index" class="li cur"
                  :class="item.check ? 'activeFac' : ''" @click="filter3(index)" style="margin-left: 10px">
                  {{ item.name }}
                </div>
              </div>
              <div style="text-align: left; margin-top: 40px">成交量</div>
              <div class="flex" style="flex-wrap: wrap">
                <div v-for="(item, index) in data.typeFact2" :key="index" class="li cur"
                  :class="item.check ? 'activeFac' : ''" @click="filter4(index)" style="margin-left: 10px">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 商品列表 -->
        <div class="list" v-if="active == 0">
          <el-row :gutter="16" class="printLogFather" infinite-scroll-distance="30px" v-infinite-scroll="load"
            style="height:90vh;overflow: auto;" :infinite-scroll-immediate="false" :infinite-scroll-disabled="false" v-loading="loading" :style="{display:goodList.length==0?'block':'flex'}">
            <el-empty description="暂无商品" v-if="goodList.length == 0" />
            <el-col :span="6" v-for="item in goodList" :key="item" @click="go(0, item)">
              <div class="li cur">
                <img :src="item.image" alt="" style="object-fit:cover"/>
                <div class="title elipes2 co3 fonrS22 cur" style="height:56px">
                  {{ item.product_name }}
                </div>
                <div class="flex">
                  <span class="priceColor fontS12 cur">￥</span>
                  <span class="priceColor fontS28 cur">{{ item.price }}</span>
                  <span class="fontS18 co9 cur" style="margin-left: 14px">{{ item.month_number }}人付款</span>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <!-- 店铺列表 -->
        <div v-if="active == 1" class="printLogFather" infinite-scroll-distance="30px" v-infinite-scroll="load"
          style="height:90vh;overflow: auto;" :infinite-scroll-immediate="false" :infinite-scroll-disabled="false" v-loading="loading" >
          <el-empty description="暂无商品" v-if="shopList.length == 0" />
          <div class="store flexJBC" v-for="item in shopList" :key="item">
            <div class="left" @click="go(1, item)">
              <div class="flex">
                <img :src="item.store_image" alt="" style="object-fit:cover" class="storeImg" />
                <div class="co6 fontS22">
                  <div style="width:441px" class="elipes1">{{ item.store_name }}</div>
                  <div class="flex" style="margin: 13px 0px 20px">
                    <el-rate v-model="item.postage_score" allow-half disabled />
                    <span class="fontS20" style="color: #ffc047">{{ item.postage_score }}分</span>
                  </div>
                  <div class="co9 fontS16">已成交{{ item.pay_number }}W+</div>
                </div>
              </div>
              <div class="co9 fontS18" style="margin-top: 10px">
                主营：
                <span v-for="(item2, index2) in item.main_business" :key="index2">{{ item2 }}
                  <span v-if="index2 != item.main_business.length - 1" style="margin-right:10px">|</span>
                </span>
              </div>
            </div>
            <div class="right flex">
              <div v-for="(item2, index2) in item.product" class="right_li" :key="index2" @click="go(0, item2)">
                <img :src="item2.image" alt="" style="object-fit:cover"/>
                <div class="priceBOx fontS20 cof">￥{{ item2.price }}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- 工厂 列表-->
        <div v-if="active == 2" class="printLogFather" infinite-scroll-distance="30px" v-infinite-scroll="load"
          style="height:90vh;overflow: auto;" :infinite-scroll-immediate="false" :infinite-scroll-disabled="false" v-loading="loading">
          <el-empty description="暂无商品" v-if="factoryList.length == 0" />
          <div class="store flexJBC factory" v-for="item in factoryList" :key="item">
            <div class="left" @click="go(2, item)">
              <div class="flex">
                <img :src="item.store_image" alt="" style="object-fit:cover" class="storeImg" />
                <div class="co6 fontS22">
                  <div style="width:441px" class="elipes1">{{ item.store_name }}</div>
                  <div class="co9 fontS18" style="margin: 10px 0">
                    <span v-for="(item2, index2) in item.main_business" :key="index2">{{ item2 }}
                      <span v-if="index2 != item.main_business.length - 1" style="margin-right:10px">|</span>
                    </span>
                  </div>
                  <div class="flex co6 fontS16">
                    <span style="padding: 6px 18px;: ; margin-right: 20px;" v-for="(item2, index) in item.tags"
                      :key="index" :style="{ background: index % 2 == 0 ? '#fff2d0' : '#FFDED3' }">{{ item2 }}</span>
                  </div>
                  <div class="co9 fontS18 elipes2" style="margin: 10px 0;line-height: 25px;">
                    {{ item.store_info }}
                  </div>
                  <div class="co9 fontS18">已成交{{ item.pay_number }}</div>
                </div>
              </div>
            </div>
            <div class="right flex">
              <div v-for="item2 in item.product" class="right_li" :key="item2" @click.stop="go(0, item2)">
                <img :src="item2.image" alt="" style="object-fit:cover"/>
                <div class="priceBOx fontS20 cof">￥{{ item2.price }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.activeFac {
  color: #25459C !important;
  border: 1rpx solid #25459C !important;
}

.store {
  .right {
    .right_li {
      margin-left: 20px;

      .priceBOx {
        position: absolute;
        // width: 80px;
        height: 32px;
        line-height: 32px;
        background: rgba(0, 0, 0, 0.6);
        bottom: 0;
        right: 0;
      }

      position: relative;

      img {
        width: 200px;
        height: 200px;
        background: #fafafa;
        opacity: 1;
        border: 1px solid #cccccc;
      }
    }
  }

  .left {
    width: 30%;

    .storeImg {
      width: 120px;
      height: 120px;
      margin-right: 20px;
    }
  }

  align-items: flex-start;
  width: 1472px;
  padding: 40px 0;
  // box-sizing: border-box;
  border-bottom: 1px solid #cccccc;
}

.list {
  flex-wrap: wrap;
  padding: 0 40px;
  box-sizing: border-box;
  justify-content: flex-start;

  .li {
    width: 336px;
    position: relative;

    .title {
      margin: 20px 0 16px;
      line-height: 28px;
    }

    margin-bottom: 40px;
    margin-right: 16px;

    img {
      width: 336px;
      height: 339px;
      display: block;
    }
  }
}

.paixuBox {
  .flitetBox {
    width: 390px;
    height: 352px;
    background: #ffffff;
    box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.12);
    position: absolute;
    padding: 20px 10px;
    box-sizing: border-box;
    top: 60px;
    left: 0;
    z-index: 9;

    .li {
      width: 110px;
      text-align: center;
      line-height: 40px;
      height: 40px;
      background: #f5f5f5;
      font-size: 16px;
      color: #666;
      margin-top: 14px;
      margin-left: 10px;
    }
  }

  .priceBox {
    margin-left: 79px;

    /deep/.el-input-group__append,
    /deep/.el-input-group__prepend,
    /deep/.el-input__wrapper {
      background-color: #fff;
      box-shadow: none;
    }

    /deep/.el-input-group__prepend {
      padding: 0;
    }
  }

  width: 1472px;
  height: 58px;
  // line-height: 58px;
  background: #f5f5f5;
  border: 1px solid #cccccc;
  margin: 20px 0;

  .active {
    color: #25459C;
    background: #ffffff;
    border: 1px solid #cccccc;
  }

  .li {
    width: 140px;
    align-items: center;
    height: 58px;
    position: relative;
    // margin-left: 10px;
  }
}

.navTag {
  margin-top: 20px;
  // width: 100%;
  width: 1472px;
  border-bottom: 2px solid #25459C;

  .activetag {
    background: #25459C;
    color: #fff;
  }

  span {
    display: block;
    width: 140px;
    height: 48px;
    line-height: 48px;
    text-align: center;
  }
}

.topsearch {
  .left {
    margin-right: 90px;
  }

  .right {
    .inputBox {
      width: 683px;
      height: 48px;
      border: 2px solid #25459C;
      padding-left: 20px;
      box-sizing: border-box;

      input {
        height: 41px;
        flex: 1;
        border: none;
        outline: none;
      }

      .seacrhIcon {
        width: 75px;
        height: 48px;
        background: #25459C;
        display: flex;
        align-items: center;
        position: relative;
        right: -1px;

        img {
          width: 29.97px;
          height: 29.97px;
          margin: 0 auto;
        }
      }
    }
  }
}
</style>
