<script setup>
// import { Picture } from "@element-plus/icons-vue"; // icon 按需引入
import { onBeforeMount, reactive} from 'vue'
import { findToEvaluateList,addComment } from '@/utils/api'
import { useRouter ,  } from "vue-router";
import upImg from '@/components/upImg.vue'
import { ElMessage } from 'element-plus'
const router = useRouter()
// const value1 = ref('')
// const radio = ref('')
const data=reactive({
    query: router.currentRoute.value.query,
    info:'',
    datapa:[],//发表评价需要的参数
    fileList: [], //上传图片列表
    image: [], //图片列表
})
onBeforeMount(()=>{
    findToEvaluateList({sub_order_id:data.query.sub_order_id}).then(res=>{
        if(res.code==200){
            data.info=res.data
            data.info.orderProduct.forEach((item,index)=>{
                let obj={
                order_product_id:item.order_product_id,
                start:0, 
                comment_text:'',
                status:1,
                product_id:item.product_id,
              } 
              Reflect.set(data.datapa,index,obj)
              data.fileList.push([])
              data.image.push([])
            }) 
           
            // console.log( data.datapa)
        } 
    })
})
// 删除图片
const handleRemove = (file,index1) => {
    console.log('shanchu', file,index1)
    let fileJS = JSON.parse(JSON.stringify(file))
    const filePath = fileJS.response ? fileJS.response.data.file_path : fileJS.url;
    const index = data.image.findIndex((x) => x === filePath);
    data.image[index1].splice(index, 1);

}
// 图片上传成功
const handleSuccessed = (img, limit,index) => {
    console.log(index,'index1')
    // 如果limit==1
    if (limit) {
        data.image[0] = img
    } else { 
        data.image[index].push(img)
    }

}
const submit=()=>{
   data.datapa.forEach((item,index)=>{
        item.image=data.image[index].join(',')
   }) 
    addComment({
        data:JSON.stringify(data.datapa),
        sub_order_id:data.info.sub_order_id,
        store_id:data.info.store_id
    }).then(res=>{
        if(res.code==200){
            ElMessage.success('评价成功')
            router.replace({
                path:'/order/commentSuccess'
            })
            // setTimeout(()=>{ 
            //     router.go('-1')
            // },1000)
           
        }
    })
}
</script>
<template >
    <div class="wrapCenter" v-if="data.info">
        <img src="../../assets/logo.png" alt="" class="logoimg" style="object-fit:cover">
        <div class="flex topBox">
            <img src="../../assets/set.png" alt="" class="img" style="object-fit:cover">
            <div class="fontS24 co6 ">评价商品</div>
        </div> 
        <div class="goodsBox" v-for="(item,index) in data.info.orderProduct" :key="index" >
            <div class="flex goods" style="align-items:flex-start" @click="router.push({path:'/goodsDetail',query:{id:item.product_id}})">
                <img :src="item.image" alt="" style="object-fit:cover">
                <div>
                    <div class="fontS20 co3">{{ item.goods_name }}</div>
                    <div class="co9 fontS16" style="margin: 10px 0">
                        <span style="margin-right: 40px" v-if="item.details">规格：{{item.details}}</span>
                        <span>X{{ item.product_num }}</span>
                    </div> 
                    <div class="priceColor fontS20">￥{{ item.product_price }}</div>
                </div>
            </div>
            <div class="flex">
                <div class="fontS20 co3">相符描述：</div>
                <div class="demo-rate-block">
                    <el-rate v-model="data.datapa[index].start" size="small" />
                </div>
            </div>
            <div class="marT2">
                <el-input v-model="data.datapa[index].comment_text" placeholder="从多个角度评价宝贝，可以帮助更多想买的人" style="width: 696px;" :rows="8"
                    maxlength="200" type="textarea" show-word-limit />
            </div>
            <div class="marT2">
              <upImg :fileList="data.fileList[index]" :limit="5" :index="index" @handleSuccessed="handleSuccessed"  @handleRemove="handleRemove"  />
            </div>
                               
            <!-- <div class="co9 fontS18 center ImgUpbox marT2">
                <el-icon size="28px" color="#999">
                    <Picture />
                </el-icon>
                <div class="fontS16 co9" style="margin-top:10px">图片/视频</div>
            </div> -->
            <div class="flex redioBox" @click="data.datapa[index].status=data.datapa[index].status==0?1:0">
                <div class="redio" :class="!data.datapa[index].status?'active':''" ></div>
                <div><span class="co3 fontS20">匿名</span><span class="co9 fontS20">（隐藏头像昵称）</span></div>
            </div>
        </div>   
        <div class="btn cof center fontS20" @click="submit">发表评论</div>
    </div> 
</template>
<style lang="less" scoped> 
.redioBox {

    margin-top: 30px;
    .active{
        border: 6px solid #25459C !important;
    }
    .redio {
        width: 8px;
        height: 8px;
        background: #FFFFFF;
        opacity: 1;
        border: 6px solid #999;
        border-radius: 50%;
        margin-right: 10px;
    }
}

.ImgUpbox {
    width: 100px;
    height: 100px;
    background: #FFFFFF;
    border: 2px solid #CCCCCC;
    padding-top: 20px;
    box-sizing: border-box;
}

.topBox {
    width: 1080px;
    height: 72px;
    line-height: 72px;
    background: #F6F7F9;
    padding-left: 20px;
    box-sizing: border-box;
    border: 1px solid #CCCCCC;

    .img {
        width: 40px;
        height: 40px;
        margin-right: 10px;
    }
}
.btn {
        width: 244px;
        height: 48px;
        line-height: 48px;
        background: #25459C;
        margin-top: 40px;
        margin-bottom: 100px;
    }
.goodsBox {
   

    .goods {
        padding-bottom: 40px;
        margin-bottom: 40px;
    }

    background: #FFFFFF;
    margin-top: 20px;

    img {
        width: 120px;
        height: 120px;
        margin-right: 20px;
    }
}

.logoimg {
    width: 180px;
    height: 62px;
    margin: 30px 0 60px;
}
</style>