<template>
    <div class="wrapCenter">
        <img src="../../assets/logo.png" alt="" style="object-fit:cover" class="logoimg">
        <div class="co9 fontS16">首页 > 个人中心 > 已买到的商品>退货/退款 </div>
        <div class="goodsBox">
            <div class="flex goods" style="align-items:flex-start">
                <img src="../../assets/Image/5494c0fa4c8d21450ef7357d0929a5d8.jpeg" style="object-fit:cover" alt="">
                <div>
                    <div class="fontS20 co3">冬季保暖高帮棉拖鞋女包跟厚底室内居家用</div>
                    <div class="co9 fontS16" style='margin: 10px 0;'>
                        <span>尺码：38-39</span>
                        <span style="margin:0 40px">颜色：泡芙粉</span>
                        <span>X1</span>
                    </div>
                    <div class="homeRed fontS20">68.00</div>
                </div>
            </div>
            <div class="co3 fontS20">填写退货信息</div>
           <div class="flex co3 marT2">
              <span class="fontS16">快递单号：</span>
              <el-input  placeholder="请填写快递单号" style="width: 337px;height: 44px;"/> 
           </div>
           <div class="flex co3 marT2">
              <span class="fontS16">物流公司：</span>
              <el-input  placeholder="请填写物流公司" style="width: 337px;height: 44px;"/> 
           </div>
           <div class="btn cof center fontS20">确定</div>
        </div>
    </div>
</template>
<style lang="less" scoped>
.goodsBox {
   .btn{
    width: 244px;
height: 48px;
line-height: 48px;
background: #25459C;
margin-top: 40px;
   }
    .goods {
        padding-bottom: 40px;
        margin-bottom: 40px;
        border-bottom: 1px solid #CCCCCC;
    }

    height: 667px;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    margin-top: 40px;
    padding: 40px;
    box-sizing: border-box;

    img {
        width: 120px;
        height: 120px;
        margin-right: 20px;
    }
}

.logoimg {
    width: 160px;
    height: 62px;
    margin: 30px 0 60px;
}
</style>