

<template>
    <div class="wrapCenterpx">
        <div class="typeBox">
            <div class="typesonBox cur" :style="{ color: typeID == 0 ? '#25459C' : '' }" @click="clickType(0)">商品分类</div>
            <div @click="clickType(item.store_category_id)" class="typesonBox cur"
                :style="{ color: typeID == item.store_category_id ? '#25459C' : '' }" v-for="item in typeList"
                :key="item.store_category_id">{{ item.cate_name }}</div>
        </div>
        <div class="typeBox" style="border:none">
            <div class="sortText cur" @click="sortClick(0)"  :style="{ color: sortID == 0 ? '#25459C' : '' }">综合排序</div>
            <div class="shuline"><el-divider direction="vertical" /></div>
            <div class="sortText cur" @click="sortClick(1)" :style="{ color: sortID == 1 ? '#25459C' : '' }">销量</div>
            <div class="shuline"><el-divider direction="vertical" /></div>
            <div class="sortText cur" @click="sortClick(2)" :style="{ color: sortID == 2 ? '#25459C' : '' }">
                <div>价格</div>
                <img :src="require(`../../../assets/Image/${sortIcon}`)" style="margin-left: 4px;object-fit:cover" alt="">
            </div>
        </div>
    </div>
</template>
       
<script setup>
import { ref, defineProps, defineEmits } from "vue";
// import { Edit } from "@element-plus/icons-vue"; // icon 按需引入
// import { el } from "element-plus/es/locale";
const props = defineProps({
    typeList: Array,
    typeID: Number
});

// // -- 动态引入本地图片
// const getAssetURL = (image) => {
//     // 参数一: 相对路径
//     // 参数二: 当前路径的URL
//     return 
// }

// 向父组件返回类型值
console.log(props.typeID, 'typeID');
const emit = defineEmits(["clickType"]);
const clickType = (id) => {
    emit('clickType', { id, order_sales: sortID.value == 2 ? sortIcon.value == 'sortTop.png' ? 'desc' : 'sort' : 'desc', order_price: 'desc' })
}
// 排序
const sortID = ref(0)
const sortIcon = ref('sortNo.png')
const sortClick = (id) => {
    if (sortID.value == 2 && id == 2) {
        sortIcon.value = sortIcon.value == 'sortTop.png' ? 'sortBottom.png' : 'sortTop.png'
    } else if (id == 2) {
        sortIcon.value = 'sortTop.png'
    }
    sortID.value = id;
    if (sortID.value !== 2) {
        sortIcon.value = 'sortNo.png'
    }
    emit('clickType', { id: props.typeID, order_sales: 'desc', order_price: sortID.value == 2 ? sortIcon.value == 'sortTop.png' ? 'desc' : 'sort' : 'desc', })
}

</script>
       
<style scoped lang="less">
.wrapCenterpx {
    width: 1472px;
    height: 179px;
    background: #FFFFFF;
    margin: 0 auto;
    // margin-top: 40px;
    padding: 0 40px;
    box-sizing: border-box;
    // align-items: center;

    .typeBox {
        height: 50%;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        // line-height: calc(179px / 2);
        border-bottom: 1px dotted #CCCCCC;

        .typesonBox {
            margin-right: 80px;
            font-size: 20px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
        }

        .shuline {
            margin: 0 32px;
        }

        .sortText {
            display: flex;
            align-items: center;

            img {
                display: inline-block;
            }
        }
    }
}
</style>
       