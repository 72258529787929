<template>
    <div style="background: #f5f5f5;">
        <searchTopNew :lisac="navActive.num"></searchTopNew>
        <div class="topBox flex">
            <div class="topNavLeft">
                <div @mouseleave="activecategry = null; showcategry = false">
                    <div class="flexJBC cateBox" >
                        <div class="categrouBox printLogFather">
                            <div class="li" 
                                v-for="(item, index) in cateList" :key="index" @mouseenter="activecategry = index">
                                <div class="lineBottom" style="padding-bottom: 17px">
                                    <div class="fontS16 cur name textColor3">
                                        {{ item.cate_name }}</div>
                                    <el-space :size="14" spacer="|" style="display:flex;flex-wrap:wrap">
                                        <div v-for="(i, j) in item.children" :key="j" style="line-height:30px" class="cur">
                                            {{ i.cate_name }}
                                        </div>
                                    </el-space>
                                </div>
                            </div>
                        </div>
                        <!--  -->
                        <div class="rightbox" v-if="activecategry || activecategry === 0">
                            <div class="activeText fontS24 m-b32 fontW">{{cateList[activecategry].cate_name}}</div>
                            <div v-for="(item, index) in cateList[activecategry].children" :key="index">
                                <div>
                                    <div class="lisbox">
                                        <div class="leftbox co3 fontS20 textColor3 fontW">
                                            {{ item.cate_name }}
                                        </div>
                                        <div class="lisliright  fontS18 textColor6" :style="{ borderBottom: item.children ? '1px solid #CCCCCC':''}">
                                            <el-space :size="14" spacer="|" style="display:flex;flex-wrap:wrap">
                                                <div v-for="(item1, index1) in item.children" :key="index1" class="cur"  @click.stop="router.push({ path: '/allGoods', query: { category_id: item1.store_category_id } })">
                                                    {{ item1.cate_name }}
                                                </div>
                                            </el-space>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                 </div>
            </div>
            <div class="topcenter">
                <div class="bannerBox">
                    <el-carousel height="372px">
                        <el-carousel-item v-for="item in imgList" :key="item" @click="getUrl(item.jump_url)" >
                           <img :src="item.image" alt="" class="bannerImg" >
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="navBottom">
                    <div v-for="(item,index) in navBottom" :key="index" class="flexCj cur"  @click="gotoRouter(index,item)">
                        <div class="navImg"><img :src="item.image" alt=""></div>
                        <div class="textColor6 fontS18">{{ item.name }}</div>
                    </div>
                </div>
            </div>
            <div class="topRight">
                <div class="uesrOrder">
                    <div class="flexCj">
                        <div class="userInfo" >
                            <img :src="userData.avatar" alt="" v-if="userData">
                        </div>
                        <div class="fontS16 textColor3 m-t14 activeText fontW" v-if="userData">{{userData.nickname}}</div>
                        <div class="fontS16 textColor3 m-t14">您好~欢迎来到奈特喜!</div>
                        <div class="loginBtn cur" @click="loginInfo" v-if="!userData">一键登录</div>
                    </div>
                    <div class="orderTab flex">
                        <div class="m-l18 cur"  @click.stop="router.push({ path: '/order/orderHome', query: {orderIndex:2} })">
                            <div class="textColor3 fontS20">{{userData?userData.order.waiting_pay:0}}</div>
                            <div class="textColor9 fontS16 m-t4">待付款</div>
                        </div>
                        <div @click.stop="router.push({ path: '/order/orderHome', query: {orderIndex:3} })" class="cur">
                            <div class="textColor3 fontS20">{{userData?userData.order.waiting_delivery:0}}</div>
                            <div class="textColor9 fontS16 m-t4">待发货</div>
                        </div>
                        <div @click.stop="router.push({ path: '/order/orderHome', query: {orderIndex:4} })" class="cur">
                            <div class="textColor3 fontS20">{{userData?userData.order.waiting_receipt:0}}</div>
                            <div class="textColor9 fontS16 m-t4">待收货</div>
                        </div>
                        <div class="m-R18 cur" @click.stop="router.push({ path: '/order/orderHome', query: {orderIndex:5} })" >
                            <div class="textColor3 fontS20">{{userData?userData.order.waiting_evaluation:0}}</div>
                            <div class="textColor9 fontS16 m-t4">待评价</div>
                        </div>
                    </div>
                </div>
                <div class="pingtai">
                    <div class="tabBox">
                        <div class="tabItem cur" @click="getTab(1)" :class="tabIndex==1?'activeTab':''" ><div>平台公告</div><div class="tabLine" v-show="tabIndex==1"></div></div>
                        <div class="tabItem cur" @click="getTab(2)"  :class="tabIndex==2?'activeTab':''"><div>最新规则</div><div class="tabLine"  v-show="tabIndex==2"></div></div>
                    </div>
                    <!-- v-infinite-scroll="tabLoad" -->
                    <div class="tabCenter"  style="overflow: auto" v-infinite-scroll="tabLoad" :infinite-scroll-immediate="false"  :infinite-scroll-disabled="false">
                      <div class="tabC textClamp cur" v-for="(item,index) in selectNoticeList" :key="index" @click="getTabItem(item.id)">{{item.title}}</div>
                    </div>
                </div>
            </div>
             <!-- 侧边栏 -->
            <div class="sidebarBox">
                <div class="sidebarItem cur" @click="toTUIKit">
                    <img src="../../assets/kefu.png" alt="" style="width:40px;height:40px;margin-bottom:3px;">
                    <div class="fontS16">在线客服</div>
                </div>
                <div class="sidebarItem cur" @click="getDow">
                    <img src="../../assets/dow.png" alt="" style="width:40px;height:40px;margin-bottom:3px;">
                    <div class="fontS16">APP下载</div>
                </div>
                <div class="sidebarItem">
                     <topNav></topNav>
                </div>
            </div>
        </div>
        <TiantianSpecial></TiantianSpecial>
        <hotGoodsOrRanking></hotGoodsOrRanking>
        <userLike></userLike>
        <div style="height:50px;"></div>
        <couponeCom v-model:centerDialogVisible="couponeShow"></couponeCom>

        <!-- 公告 -->
        <el-dialog v-model="dialogRules" :title="modelTitle" width="1000px">
            <!-- <div v-html="showRulesinfo.text" style="line-height: 30px"></div> -->
            <div class="dialogRulesBox">
                <div class="title" style="overflow: auto" v-infinite-scroll="tabLoad" :infinite-scroll-immediate="false"  :infinite-scroll-disabled="false">
                    <div class="titleItem fontS18 fw cur" v-for="(item,index) in selectNoticeList"  :key="index" @click="getTabItem(item.id)">
                        <div class="ridus "></div><div class="textClamp">{{item.title}}</div>
                    </div>                
                </div>
                <div class="tabcenter">
                    <div class="centerTitle "><div class="fontS24 fw">{{findNoticeData.title}}</div> <div class="fontS14">{{findNoticeData.create_time}}</div></div>
                    <div class="centerC fontS16" v-html="findNoticeData.text"></div>
                </div>
             </div>
         </el-dialog>
         <!-- 二维码 -->
         <el-dialog v-model="dialogCode" title="" width="440px">
            <div class="dialogCodeBox">
              <div class="iosBox" v-for="(item,index) in codeImg" :key="index"> <div class="iosImg"><img :src="item.qrcode_image" alt=""/></div><div>{{item.type==2?'iOS':'Android'}}下载</div> </div>
             </div>
         </el-dialog>
    </div>
</template>

<script setup>
import {ref,reactive,onMounted} from 'vue'

import {Indexget_type,getCategory,comgetAd,userget_user_info,selectNotice,selectRule,findNotice,findRule,selectQrcode} from  "@/utils/api";
import topNav from "@/components/topNav.vue"; //回到顶部
import searchTopNew from "@/components/searchTopNew.vue"; //搜索组件
import TiantianSpecial from './TiantianSpecial.vue'
import hotGoodsOrRanking from './hotGoodsOrRanking.vue'
import couponeCom from "../Home/couponeCom.vue";
import userLike from './userLike.vue'
import { useRouter } from "vue-router";
import { useStore } from "vuex";
const store=useStore()
const router=useRouter()
const cateList = ref([])
const navActive=reactive({
  num:0
})
const tabIndex=ref(1)//tab切换
const showcategry = ref(false); //是否显示分类框
const activecategry = ref(null); //当前选中的分类
const dialogRules=ref(false)
const dialogCode=ref(false)
onMounted(()=>{
    getNavBottom()
    getCate()
    getBanner()
    userInfo()
    getSelectNotice()
})
// 获取主导航
const typeIstop=ref([])
const navBottom=ref([])
const getNavBottom=()=>{
    Indexget_type({}).then(res=>{
        if(res.code==200){
            // typeIstop.value=res.data.filter((item)=>item.is_top==0)
            navBottom.value=res.data.filter((item)=>item.is_top==1)
            console.log(typeIstop.value,'typeIstop')
            console.log(navBottom.value,'navBottom')
        }
    })
}
// 左边导航
const getCate=()=>{
     getCategory({ is_tree: 1 }).then(res => {
        if (res.code == 200) {
            cateList.value = res.data
        }
    })
}
const imgList=ref([])
const getBanner=()=>{
    comgetAd({ type: 1 }).then((res) => {
        if (res.code == 200) {
            imgList.value = res.data;
        }
    });
}
// banner 跳转
const getUrl=(val)=>{
    console.log(val,'val')
    router.push({ path:val})
}
// 获取用户的订单数据
const userData=ref(null)
const userInfo=()=>{
    userget_user_info().then(res => {
        if(res.code==200){
            userData.value = res.data
        console.log(userData.value ,'res')
        }
      })
}
// 登录
const loginInfo = () => {
  store.commit("updateInfo", true);
}
// 联系客服
const toTUIKit = () => {
  let token=localStorage.getItem('token')
  if (!token) {
    store.commit("updateInfo", true);
    return;
  } else {
    // localStorage.setItem("tencent_user_id", data.info.im_data.tencent_user_id);
    router.push("/tUIKit");
  }
};

// 获取公告
const selectNoticeList=ref([])
const NoticeData=reactive({page:1,limit:10})
const lastPage=ref(0)
const getSelectNotice=()=>{
    if(tabIndex.value==1){
        selectNotice(NoticeData).then(res=>{
            if(res.code==200){
                lastPage.value = res.data.last_page;
                if (NoticeData.page == 1) {
                    selectNoticeList.value=res.data.data
                } else {
                    selectNoticeList.value = selectNoticeList.value.concat(res.data.data);
                }
            }
        })
    }else{
        selectRule(NoticeData).then(res=>{
            if(res.code==200){
                lastPage.value = res.data.last_page;
            if (NoticeData.page == 1) {
                selectNoticeList.value=res.data.data
            } else {
                selectNoticeList.value = selectNoticeList.value.concat(res.data.data);
            }
            }
        })
    }
   
}
const tabLoad=()=>{
    if (NoticeData.page < lastPage.value) {
        NoticeData.page++;
        getSelectNotice();
    }
}
const getTab=(num)=>{
    tabIndex.value=num
    console.log(tabIndex.value,'tabIndex.value')
    NoticeData.page=1
    getSelectNotice()
}
// 平台公告详情
const findNoticeData=ref({})
const getFindNotice=(id)=>{
    if(tabIndex.value==1){
        findNotice({id:id}).then(res=>{
            if(res.code==200){
                findNoticeData.value=res.data
            }
        })
    }else{
        findRule({id:id}).then(res=>{
        if(res.code==200){
            findNoticeData.value=res.data
        }
        })
    }
    
    
}
// 详情弹窗
const modelTitle=ref('')
const getTabItem=(id)=>{
    if(tabIndex.value==1){
        modelTitle.value='平台公告'
    }else{
        modelTitle.value='最新规则'  
    }
    dialogRules.value=true
    getFindNotice(id)
}
// 导航
const couponeShow=ref(false)
const gotoRouter = (val,item) => {
    console.log(item.name,'item')
    if(item.id=='33'){
        couponeShow.value=true
        console.log('进入')
    }else{
        router.push({ path:navBottom.value[val].tab, query: { platform_type_id:item.id } });
    }
}
// 二维码
const codeImg=ref([])
const getDow=()=>{
    dialogCode.value=true
    selectQrcode({}).then(res=>{
        if(res.code==200){
            codeImg.value=res.data
            console.log(res,'res')
        }
    })
}
</script>

<style lang="less" scoped>
    .dialogCodeBox{
        display: flex;
        justify-content: space-around;
      .iosBox{
        display: flex;
        flex-direction: column;
        align-items: center;
        .iosImg{
           width:120px;
           height:120px; 
           background: rgb(255, 216, 216);
           margin-bottom: 14px;
           img{
            width: 100%;
            height: 100%;
           }
        }
      }  
    }
/deep/.el-dialog{
    border-radius: 15px !important;
}
.dialogRulesBox{
    display: flex;
    height:750px;
    border:1px solid #D1D1D1;
    .title{
        width: 40%;
        border-right:1px solid #D1D1D1;
        height:740px;
        overflow: auto;
        .titleItem{
            height: 65px;
            border-bottom: 1px solid #D1D1D1;
            line-height:65px;
            display: flex;
            align-items: center;
            margin: 0 20px;
            .ridus{
                width: 8px;
                height: 8px;
                background: #D1D1D1;
                border-radius: 50%;
                margin-right:10px;
            }
        }
    }
    .tabcenter{
        width: 60%;
        .centerTitle{
            height: 76px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 20px;
            border-bottom: 1px solid #D1D1D1;
        }
        .centerC{
            margin:20px;
           /deep/ img{
                width: 100% !important;
                }
            

        }
    }
}
.sidebarItem{
    width:72px;
    height:72px;
    background: #fff;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.sidebarBox{
    position:fixed;
    width: 72px;
    top: 42%;
    right:5%; 
}
.topBox{
    width: 1472px;
    margin: auto;
    margin-top: 32px;
    .topNavLeft{
        width: 288px;
        height: 512px;
        
.cateBox {
    position: absolute;
    z-index:9;
    .categrouBox {
        color: #333;
        width: 288px;
        height: 512px;
        overflow: auto;
        // height: 576px;
        // overflow: auto;
        background: rgb(255, 255, 255);

        .lineBottom{
            border-bottom: 1px solid #EDEDED;
        }

        .li {
            padding: 17px 24px 0;
            box-sizing: border-box;

            .name {
                // margin-bottom: 18px;
                font-weight: 700;
            }

            /deep/.el-space__item {
                margin-top: 10px;
            }

            /deep/ span {
                margin-top: 10px !important;
            }
        }
    }

    .active {
        color: #25459C !important;
        background-color: #fff !important;
    }

    .rightbox {
        width: 1184px;
        height: 512px;
        background: #ffffff;
        padding: 20px 40px;
        box-sizing: border-box;
        border: 2px solid #1F4A92;

        .name {
            margin-bottom: 8px;
            font-weight: 700;
        }
        .lisbox {
            // padding: 20px 0;
            // padding-top: 20px;
            align-items: flex-start;

            .leftbox {
                width: 15%;
                padding-top: 20px;
            }

            .lisliright {
                width: 100%;
                color: #333;
                padding-bottom: 20px;
                /deep/.el-space__item {
                    margin-top: 20px;
                }

                /deep/.el-space__item:hover {
                    color: #25459C;
                }

                /deep/ span {
                    margin-top: 20px !important;
                }
            }
        }


    }
}


.bottom {
    margin-top: 40px;

    div {
        margin-right: 80px;
    }
    .allcate {
        width: 288px;
        height: 48px;
        line-height: 48px;
        background: #25459C;
        color: #fff;

    }
}

.left {
    margin-right: 90px;
    margin-top: -16px;
}

.right {
    .searchHistory {
        width: 608px;
        padding: 10px;
        box-sizing: border-box;
        background: #FFFFFF;
        opacity: 1;
        border: 1px solid #EDEDED;
        position: absolute;
        z-index: 99;

        div {
            margin-top: 16px;
        }

        div:nth-of-type(1) {
            margin-top: 0;
        }
    }

    align-items: flex-start;

    .userAndcart {
        margin-left: 40px;

        .box {
            height: 48px;
            line-height: 48px;
            padding: 0 24px;
            background: #F5F5F5;
            opacity: 1;
            border: 1px solid #CCCCCC;
        }

        .box:nth-of-type(2) {
            background: #25459C;
            border-color: #25459C;
        }
    }

    .input_B {
        margin-top: 14px;

        div {
            margin-right: 20px;
        }
    }

    .inputBox {
        width: 683px;
        height: 48px;
        border: 2px solid #25459C;
        padding-left: 20px;
        box-sizing: border-box;
        background-color: #fff;

        input {
            height: 41px;
            flex: 1;
            border: none;
            outline: none;
        }

        .seacrhIcon {
            width: 75px;
            height: 48px;
            background: #25459C;
            display: flex;
            align-items: center;
            position: relative;
            right: -1px;

            img {
                width: 29.97px;
                height: 29.97px;
                margin: 0 auto;
            }
        }
    }

}
    }
    .topcenter{
        margin: 0 20px;
        width: 856px;
        .bannerBox{
            width:100%;
            height: 372px;
            .bannerImg{
                width: 100%;
                height: 100%;
            }
        }
        .navBottom{
            display: flex;
            justify-content: space-between;
            padding: 0 30px;
            margin-top:20px;
            height: 120px;
            background: rgb(255, 255, 255);
            align-items: center;
            border-radius: 8px 8px 8px 8px;
            .navImg{
                width: 58px;
                height: 58px;
                margin-bottom: 10px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    .topRight{
       
        .uesrOrder{
            width: 288px;
            height: 331px;
            background: #ffffff;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            position:relative;
            .userInfo{
                width:72px;
                height: 72px;
                border-radius: 50%;
                background: #e5e5e5;
                margin-top: 20px;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .loginBtn{
                width: 200px;
                height: 42px;
                line-height: 42px;
                text-align: center;
                background: #1F4A92;
                border-radius: 0px 0px 0px 0px;
                color: #fff;
                margin-top: 40px;
            }
            .orderTab{
                width: 100%;
                display: flex;
                justify-content:space-between;
                text-align: center;
                position: absolute;
                bottom: 29px;
                left: 0;
            }
        }
        .pingtai{
            width: 288px;
            height: 161px;
            background: #ffffff;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            margin-top: 20px;
            .tabBox{
                display: flex;
                justify-content: space-around;
                align-items: center;
                height: 55px;
                border-bottom:1px solid #EEEEEE ;
                position: relative;
                .tabItem{
                     display: flex;
                     flex-direction: column;
                     align-items: center;
                     .tabLine{
                        width: 20px;
                        height: 2px;
                        background: #5C7DCD;
                        margin-top: 10px;
                        position: absolute;
                        bottom:0;
                     }
                }
            }
            .tabCenter{
                margin-top: 10px;
                margin-left: 20px;
                height:80px;
                .tabC{
                    margin:20px 0;
                    color: #333;
                }
            }
            .activeTab{
                color: #5C7DCD !important;
            }
        }
    }
}

</style>