 <template>
     <div class="hostGoodsOrRanking flex">
        <div class="left">
            <div class="hostGoodsBox">
                <div class="hostGoodsTitle flex">
                    <img class="imgIcon" src="@/assets/host.png" alt=""><span class="fontS28">热卖商品</span>
                </div>
                <div class="hostGoodsTop flex cur" v-if="hotGoodsList[0]" @click="router.push({path:'/goodsDetail',query:{id:hotGoodsList[0].id}})">
                    <div class="goodsImg">
                        <img :src="hotGoodsList[0].image" alt=""  style="object-fit:cover">
                    </div>
                    <div class="goodsCenter">
                        <div class="goodsRightT fontS24 textClamp">{{hotGoodsList[0].product_name}}</div>
                        <div class="goodsRightDecorate fontS20 textClamp3">{{hotGoodsList[0].product_info}}</div>
                        <div class="goodsRightprice priceColor fontS20">¥<span class="fontS28">{{hotGoodsList[0].price}}</span></div>
                        <div class="goodsRightBtn">立即购买</div>
                    </div>
                </div>
            </div>
            <div class="hostGoodsBottom flex cur">
                    <div class="goodsBottomItem" v-if="hotGoodsList[1]"  @click="router.push({path:'/goodsDetail',query:{id:hotGoodsList[1].id}})">
                        <div class="bottomTitle fontS20 textClamp">{{hotGoodsList[1].product_name}}</div>
                        <div class="bottomPrice priceColor fontS16">¥<span class="fontS24">{{hotGoodsList[1].price}}</span></div>
                        <div class="bottomGoodsImg">
                            <img :src="hotGoodsList[1].image" alt="" style="object-fit:cover">
                        </div>
                    </div>
                    <div class="goodsBottomItem leftL"  v-if="hotGoodsList[2]" @click="router.push({path:'/goodsDetail',query:{id:hotGoodsList[2].id}})">
                        <div class="bottomTitle fontS20 textClamp">{{hotGoodsList[2].product_name}}</div>
                        <div class="bottomPrice priceColor fontS16">¥<span class="fontS24">{{hotGoodsList[2].price}}</span></div>
                        <div class="bottomGoodsImg">
                            <img :src="hotGoodsList[2].image"  alt="" style="object-fit:cover">
                        </div>
                    </div>
            </div>
        </div>
        <div class="right">
            <div class="hostGoodsBox">
                <div class="hostGoodsTitle flex ">
                    <img class="imgIcon" src="@/assets/ranking.png" alt=""><span class="fontS28">热销排行</span>
                </div>
                <div class="hostGoodsTop flex cur"  v-if="rankingList[0]" @click="router.push({path:'/goodsDetail',query:{id:rankingList[0].id}})">
                    <div class="goodsImg">
                        <img :src="rankingList[0].image" alt="" style="object-fit:cover">
                    </div>
                    <div class="goodsCenter">
                        <div class="goodsRightT textClamp fontS24">{{rankingList[0].product_name}}</div>
                        <div class="goodsRightDecorate fontS20 textClamp3">{{rankingList[0].product_info }}</div>
                        <div class="goodsRightprice priceColor fontS20">¥<span class="fontS28">{{rankingList[0].price}}</span></div>
                        <div class="goodsRightBtn">立即购买</div>
                    </div>
                </div>
            </div>
            <div class="hostGoodsBottom flex cur">
                    <div class="goodsBottomItem" v-if="rankingList[1]" @click="router.push({path:'/goodsDetail',query:{id:rankingList[1].id}})">
                        <div class="bottomTitle fontS20 textClamp">{{rankingList[1].product_name}}</div>
                        <div class="bottomPrice priceColor fontS16">¥<span class="fontS24">{{rankingList[1].price}}</span></div>
                        <div class="bottomGoodsImg">
                            <img :src="rankingList[1].image" alt="" style="object-fit:cover">
                        </div>
                    </div>
                    <div class="goodsBottomItem leftL" v-if="rankingList[2]"  @click="router.push({path:'/goodsDetail',query:{id:rankingList[2].id}})">
                        <div class="bottomTitle fontS20 textClamp">{{rankingList[2].product_name}}</div>
                        <div class="bottomPrice priceColor fontS16">¥<span class="fontS24">{{rankingList[2].price}}</span></div>
                        <div class="bottomGoodsImg">
                            <img  :src="rankingList[2].image" alt="" style="object-fit:cover">
                        </div>
                    </div>
            </div>
        </div>
      </div>
 </template>
 
 <script setup>
 import {ref,reactive,onMounted} from 'vue'
import {ProductindexProoduct} from  "@/utils/api";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
const store=useStore()
const router=useRouter()
onMounted(()=>{
    getHotGoods()
    getRanking()
})

// 热销
const hotGoodsList=ref([])
const getHotGoods=()=>{
    ProductindexProoduct({page:1,limit:3,indexType:1}).then(res=>{
        if(res.code==200){
            hotGoodsList.value=res.data.data
            console.log(res,'res')
        }
    })
}
// 排行
const rankingList=ref([])
const getRanking=()=>{
    ProductindexProoduct({page:1,limit:3,indexType:0}).then(res=>{
        if(res.code==200){
            rankingList.value=res.data.data
            console.log(res,'res')
        }
    })
}
 
 </script>
 
 <style lang="scss" scoped>
 .hostGoodsOrRanking{
    width:1472px;
    height:766px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    // padding:30px 40px;
    margin: auto;
    margin-top: 32px;
}
.left,.right{
    flex: 1;
    border-right: 1px solid #EEEEEE;
    .hostGoodsBox{
    width: 676px;
    padding:30px 30px 0 30px;
    border-bottom: 1px solid #EEEEEE;
    .hostGoodsTitle{
        margin-bottom: 23px;
    }
    .goodsImg{
        width: 290px;
        height: 290px;
        background: rgb(255, 220, 220);
        margin-bottom: 18px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .imgIcon{
        width: 40px;
        height: 40px;
        margin-right: 10px;
    }
    .goodsCenter{
        flex: 1;
        margin-left: 20px;
        .goodsRightT{
            margin-bottom: 10px;
        }
        .goodsRightDecorate{
            line-height: 1.5;
            color: #666666;
        }
      
        .goodsRightprice{
            margin-top: 20px;
        }
        .goodsRightBtn{
            width: 119px;
            height: 47px;
            border: 1px solid #5C7DCD;
            line-height:47px;
            color: #5C7DCD;
            margin-top: 23px;
            text-align: center;
        }
    }
    
    
}
.goodsBottomItem{
    padding: 30px 30px 24px 30px;
    .bottomTitle{
        margin-bottom: 10px;
    }
    .bottomGoodsImg{
        margin-top: 12px;
        width: 306px;
        height: 236px;
        img{
            width: 100%;
            height: 100%;
        }
     }  
}
    .leftL{
        border-left: 1px solid #EEEEEE;
    }
}
 </style>