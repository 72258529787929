<script setup>
import {defineProps,defineEmits,computed } from 'vue';
const props=defineProps({
    deleteShow:{
        default:true,
        type:Boolean
    },
    cancel:{
        default:1,
        type:Number
    },
    reason:{
        default:()=>[],
        type:Array,
    }
})
const emit = defineEmits(['handleSuccessed','update:deleteShow','update:cancel','handRemove'])//'handleRemove', 
const getShow = computed({
  get: () => props.deleteShow,
  set: (val) => {
    emit('update:deleteShow', val)
  }
})
const getCancel = computed({
  get: () => props.cancel,
  set: (val) => {
    emit('update:cancel', val)
  }
})
const handleSuccessed=()=>{
    emit('handleSuccessed',false)
}
const handRemove=()=>{
    emit('handRemove',false)
}
</script>
<template>
  <div>
    <el-dialog v-model="getShow" title="" width="30%" center align-center :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
        <div class="center">
            <span class="co3 fontS20">请选择取消原因</span>
            <div class="fontS16 co9" style="margin-top:6px">取消后无法恢复，优惠卷红包可退回，有效期内使用</div>
        </div>
        <el-radio-group v-model="getCancel"
            style="flex-direction: column;align-items: flex-start;margin-top: 20px;width:100%">
            <div class="co9">123</div>
            <el-radio :label="item.id" v-for="item in reason" :key="item.id">{{ item.title }}</el-radio>
        </el-radio-group>

        <template #footer>
            <span class="dialog-footer">
                <el-button type="danger" @click="handleSuccessed" color="#25459C">
                    确认
                </el-button>
                <button  @click="handRemove" class="btnClose delBtn">
                        取消
                    </button>
            </span>
        </template>
    </el-dialog>
  </div>
</template>



<style>

</style>