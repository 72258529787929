<script setup>
import { Delete } from "@element-plus/icons-vue";
import {get_product_follow,store_follow} from '@/utils/api'
import { ref, reactive, onBeforeMount} from 'vue'
import { ElMessage } from 'element-plus'
import { useRouter } from "vue-router";
const router = useRouter();
const loading = ref(true)
const centerDialogVisible = ref(false)  //删除框
onBeforeMount(()=>{
    getList()
})
const data=reactive({
    page:1,
    list:[],
    last_page:3,
    activeItem:{},//删除的当前Item
})
const getList=()=>{
    loading.value=true
    get_product_follow({page:data.page,limit:8}).then(res=>{
        loading.value=false
        if(res.code==200){
            data.last_page=res.data.last_page
            if(data.page==1){
                data.list=res.data.data
            }else{
                data.list=data.list.concat(res.data.data)
            }
            data.list.forEach(item=>{
                Reflect.set(item,'show',false)
            })
        }
    })
}
const load=()=>{
    if(data.last_page>data.page){
        data.page++;
        getList()
    }
}
const del=()=>{
    store_follow({id:String(data.activeItem.follow_id),type:2,is_batch: 0,}).then(res=>{
        if(res.code==200){
            ElMessage.success("删除成功")
            data.page=1;
            centerDialogVisible.value=false
            getList()
        }
        
      
    })
}
</script>

<template>
    <div class="topHomeBox cof">
        <div class="wrapCenter flex">
            <img src="../../assets/logo1.png" alt="" class="logo" style="object-fit:cover">
            <div class="homeText fontS20 cur">我的收藏</div>
        </div>
    </div>
    <div class="wrapCenter" style="margin-top: 20px;">
        <div class="">
            <div>
                <div class="navTag co6 fontS20 flexJBC" style="margin-bottom:40px">
                    <span class="activetag cur">商品</span>
                    <!-- <span @click="centerDialogVisible=true">清空失效商品</span> -->
                </div>
                <div class="list  printLogFather" v-loading="loading"  infinite-scroll-distance="30px" v-infinite-scroll="load"  style="height:70vh;overflow: auto;">
                    <el-empty description="没有数据" v-if="data.list.length==0"/>
                    <div> 
                        <el-row :gutter="16"> 
                            <el-col :span="6" v-for="(item, index) in data.list" :key="index" @mouseenter="item.show=true" @mouseleave="item.show=false" @click="router.push({path:'/goodsDetail',query:{id:item.id}})">
                                <div class="li">
                                    <div class="btn cof fontS18 cur" v-if="item.show"  @click.stop="router.push({path:'/findSimilarity',query:{id:item.id}})">找相似</div>
                                    <!-- 删除 --> 
                                    <div class="delBox"  @click.stop="centerDialogVisible=true;data.activeItem=item" v-if="item.show" > 
                                        <el-icon size="44px" color="#fff">
                                            <Delete />
                                        </el-icon>
                                    </div>
                                    <img :src="item.image" alt="" style="object-fit:cover">
                                    <div class="title elipes2 co3 fonrS22">{{ item.product_name }}</div>
                                    <div class="flex">
                                        <div class="flex" >
                                            <span class="priceColor fontS12">￥</span>
                                            <span class="priceColor fontS28">{{ item.price }}</span>
                                            <span class="fontS18 co9" style="margin-left:14px">{{item.sales}}人付款</span>
                                        </div>
                                        <!-- <div v-else> <span class="co9 fontS18">商品失效了</span></div> -->
                                    </div>
                                </div>

                            </el-col>
                        </el-row>

                    </div>

                </div>

            </div>
        </div>
        <el-dialog v-model="centerDialogVisible" title="" width="30%" center align-center :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
            <span>
                确认删除商品？
            </span>
            <template #footer>
                <span class="dialog-footer">
                    <el-button type="danger" @click="del" color="#25459C">
                        确认
                    </el-button>
                    <button  @click="centerDialogVisible = false" class="btnClose delBtn">
                        取消
                    </button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<style scoped lang="less">
.topHomeBox {
    height: 100px;
    background: #25459C;
    display: flex;
    justify-content: center;
    align-items: center;

    .homeText {
        margin-left: 37px;
        margin-right: 80px;
        margin-top: 32px;
    }

    .active {
        line-height: 50px;
        border-bottom: 4px solid #fff;
    }

    .logo {
        // width: 140px;
        height: 62px;
        // margin-right: 32px;
        // margin: 19px 0;
    }
}

.store {
    .right {
        .right_li {
            margin-left: 20px;

            .priceBOx {
                position: absolute;
                // width: 80px;
                height: 32px;
                line-height: 32px;
                background: rgba(0, 0, 0, 0.6);
                bottom: 0;
                right: 0;
            }

            position: relative;

            img {
                width: 200px;
                height: 200px;
                background: #FAFAFA;
                opacity: 1;
                border: 1px solid #CCCCCC;
            }
        }
    }

    .left {
        .storeImg {
            width: 120px;
            height: 120px;
            background: #F2F2F2;
            opacity: 1;
            border: 1px solid #CCCCCC;
            margin-right: 20px;
        }
    }

    align-items: flex-start;
    width: 1472px;
    padding: 40px 0;
    // box-sizing: border-box;
    border-bottom: 1px solid #CCCCCC;
}

.list {

    flex-wrap: wrap;
    padding: 0 40px;
    box-sizing: border-box;
    justify-content: flex-start;

    .li {
        width: 336px;
        position: relative;

        .delBox {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60px;
            height: 60px;
            right: 0;
            background: rgba(0, 0, 0, 0.4);
        }

        .btn {
            width: 336px;
            height: 48px;
            line-height: 48px;
            text-align: center;
            position: absolute;
            background-color: rgba(0, 0, 0, 0.3);
            top: 290px;
        }

        .title {
            margin: 20px 0 16px;
            line-height: 28px;
        }

        margin-bottom: 40px;
        margin-right: 16px;

        img {
            width: 336px;
            height: 339px;
            display: block;
        }
    }
}

.paixuBox {
    .flitetBox {
        width: 390px;
        height: 352px;
        background: #FFFFFF;
        box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.12);
        position: absolute;
        padding: 20px 10px;
        box-sizing: border-box;
        top: 60px;
        left: 0;
        z-index: 9;

        .li {
            width: 110px;
            text-align: center;
            line-height: 40px;
            height: 40px;
            background: #F5F5F5;
            font-size: 16px;
            color: #666;
            margin-top: 14px;
            ;

        }
    }

    .priceBox {
        margin-left: 79px;

        /deep/.el-input-group__append,
        /deep/.el-input-group__prepend,
        /deep/.el-input__wrapper {
            background-color: #fff;
            box-shadow: none;
        }

        /deep/.el-input-group__prepend {
            padding: 0;
        }

    }

    width: 1472px;
    height: 58px;
    // line-height: 58px;
    background: #F5F5F5;
    border: 1px solid #CCCCCC;
    margin: 20px 0;

    .active {
        color: #25459C;
        background: #FFFFFF;
        border: 1px solid #CCCCCC;
    }

    .li {
        width: 140px;
        align-items: center;
        height: 58px;
        position: relative;

    }
}

.navTag {
    margin-top: 20px;
    // width: 100%;
    width: 1472px;
    border-bottom: 2px solid #25459C;

    .activetag {
        background: #25459C;
        color: #fff;
    }

    span {
        display: block;
        width: 140px;
        height: 48px;
        line-height: 48px;
        text-align: center;
    }
}
</style>
