import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17dd0e63"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"background":"#f5f5f5","padding-top":"105px","height":"83.5vh"} }
const _hoisted_2 = {
  class: "wrapCenter",
  style: {"background":"#FFF","width":"1142px","height":"70vh"}
}
const _hoisted_3 = { class: "home-TUIKit-main" }
const _hoisted_4 = { class: "chat" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TUISearch = _resolveComponent("TUISearch")!
  const _component_TUIConversation = _resolveComponent("TUIConversation")!
  const _component_TUIChat = _resolveComponent("TUIChat")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("div", {
          class: _normalizeClass(_ctx.env?.isH5 ? 'conversation-h5' : 'conversation')
        }, [
          _createVNode(_component_TUISearch, { class: "search" }),
          _createVNode(_component_TUIConversation, { onCurrent: _ctx.handleCurrentConversation }, null, 8, ["onCurrent"])
        ], 2), [
          [_vShow, !_ctx.env?.isH5 || _ctx.currentModel === 'conversation']
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_4, [
          _createVNode(_component_TUIChat)
        ], 512), [
          [_vShow, !_ctx.env?.isH5 || _ctx.currentModel === 'message']
        ])
      ])
    ])
  ]))
}