<template>
    <div>
      <div class="TiantianSpecialBox">
        <div class="flexJBC"><div class="fontS32" v-if="typeIstopItem.length>0">{{ typeIstopItem[0].name}}</div> 
        <div class="fontS18 cur" @click="router.push({ path: typeIstopItem[0].tab, query: { typeID:typeIstopItem[0].id } })">
                <span>更多</span>
            <el-icon>
                <ArrowRight />
            </el-icon>
            </div>
        </div>
        <div class="TiantianCenter flexJBC">
            <div class="TiantianCenterItem cur" v-for="(val,index) in dailyList" :key="index"  @click="router.push({path:'/goodsDetail',query:{id:val.id}})">
                    <div class="itemImg">
                        <img :src="val.image" alt="" style="object-fit:cover" />
                    </div>
                    <div class="title fontS20 textClamp">
                        {{val.product_name}}
                    </div>
                    <div class="decorateTxt fontS18 textClamp">
                        {{val.product_info}}
                    </div>
                    <div class="TiantianPrice">
                        <div class="newPrice fontS20">¥ <span class="fontS28">{{ val.price }}</span></div>
                        <div class="linePrice fontS16">¥ <span class="fontS24">{{ val.ot_price }}</span></div>
                    </div>
            </div>
        </div>
      </div>
    </div>
</template>

<script setup>
import { ArrowRight } from "@element-plus/icons-vue"; // icon 按需引入
import { computed, reactive, ref,onMounted} from "vue";
import {ProductgetIndexTypeProduct,Indexget_type} from "@/utils/api";
import { useRouter } from "vue-router";
const router = useRouter()
onMounted(()=>{
    getTypeIsTop()
})
// -- 天天特卖
const typeIstopItem=ref([])
const dailyList = ref([]);
const getTypeIsTop=()=>{
    Indexget_type({}).then((res) => {
        if (res.code == 200) {
            typeIstopItem.value=res.data.filter((item)=>item.is_top==0)
            if(typeIstopItem.value.length>0){
                getTianList()
            }
            console.log('进入')
        }
});
}
const params={
    page:1,
    limit:4,
    type_id:21
}
const getTianList=()=>{
    ProductgetIndexTypeProduct(params).then(res=>{
        if(res.code==200){
            dailyList.value = res.data.data
            console.log(dailyList.value,'天天特卖1')
        }
    })
}
</script>

<style lang="scss" scoped>
.TiantianSpecialBox{
    width: 1392px;
    height: 512px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    padding:30px 40px;
    margin: auto;
    margin-top: 32px;

    .TiantianCenter{
        margin-top: 28px;
    }
    .TiantianCenterItem{
       margin-right: 16px;
        .itemImg{
            width: 336px;
            height: 336px;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .title{
            margin-top: 20px;
            color:#333;
           
            text-align: center;
        }
        .decorateTxt{
            margin-top:8px;
            color:#999999;
            text-align: center;
        }
        .TiantianPrice{
            margin-top: 16px;
            display: flex;
             justify-content: center;
            .newPrice{
                color: #DD2131;
                margin-right: 27px;
            }
            .linePrice{
                text-decoration: line-through;
                color:#999999;
            }
        }
    }
}
</style>