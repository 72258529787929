<script setup>
import { ref, onBeforeMount } from 'vue'
import { ArrowDown, ArrowRight } from "@element-plus/icons-vue";
import { get_coupon, findCouponRule, userReceiveCoupon } from '@/utils/api'
import { ElMessage } from 'element-plus'
const list = ref([])
const info = ref({})
const width = ref({})

onBeforeMount(() => {
    width.value=document.documentElement.clientWidth
    getList()
    findCouponRule().then(res => {
        if (res.code == 200) {
            info.value = res.data
        }
    })
})

const getList = () => {
        get_coupon({ page: 1, limit: 1000, type: 1 }).then(res => {
        if (res.code == 200) {
            list.value = res.data
            list.value.forEach(item => {
                Reflect.set(item, 'showInfo', false)
            })
        }
       })
}
const getcouPon = (id) => {
    userReceiveCoupon({id:id}).then(res => {
        if (res.code == 200) {
            getList()
            ElMessage.success('领取成功')
        }else{
            ElMessage.warning(res.message)
        }
    })
}
</script>
<template>
    <div class="homepage flex" style="justify-content: center;" >
        <div class="" style="background-color: rgb(254,241,217);">
            <div class="imgBox" >
                <img  :src="info.image" alt="" class="img" v-if="info.image" style="object-fit:cover"/>
                <img src="../assets/copbgc.png"  alt="" class="img" v-else style="object-fit:cover"/>
            </div>
            <div class='lisInfoBox  ' v-if="list.length > 0"  >
                <div class="wrapCenter flexJBC printLogFather" style="flex-wrap:wrap;" >
                    <div class='lis' v-for="(item, index) in list" :key="index">
                    <div class='box'>
                        <div class='liBox'>
                            <div class='whiBox flexJBC'>
                                <div class='left'>
                                    <div class='left_t'>
                                        <div class='flex'>
                                            <div style="color: #EE8950;margin-right: 20px;" v-if="item.type == 1">
                                                <span class='fw7 fontS20'>￥</span>
                                                <span class="fw9 fontS40">{{ item.coupon_price }}</span>
                                            </div>
                                            <div style="color: #EE8950;margin-right: 20px;" v-if="item.type == 2">
                                                <span class="fw9 fontS40">{{ item.discount }}</span>
                                                <span class='fw7 fontS20'>折</span>
                                            </div>
                                            <div class="">
                                                <div class="co3 fw9 fontS24" style="margin-bottom: 10px;">
                                                    {{ item.title }}
                                                </div>
                                                <div class="fontS16 co6" style="line-height: 35px;">
                                                    <div class="">
                                                        {{ item.full_text }}
                                                    </div>
                                                    <div class="" v-if="item.time_type == 0">
                                                        <div class="">
                                                            有效期：
                                                        </div>
                                                        <div style="white-space: nowrap;">
                                                            {{ item.start_time }}-{{ item.end_time }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class='left_b ' @click="item.showInfo = !item.showInfo">
                                        <div class="flexJBC">
                                            <div class="co6 fontS20">
                                                使用说明
                                            </div>
                                            <el-icon size='16px' color="#666">
                                                <ArrowDown v-if="item.showInfo" />
                                                <ArrowRight v-else />
                                            </el-icon>
                                        </div>
                                        <div class="fontS16 co6" style="margin-top: 8px;" v-if="item.showInfo">
                                            {{ item.text }}
                                        </div>
                                    </div>
                                </div>
                                <div class='right'>
                                    <div class='cilr'></div>
                                    <div class='btn cur' @click="getcouPon(item.coupon_id)">立即领取</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div class="rulesBox" >
                    <div class='rules'>
                        <div class="center cof fontS28 fw9">
                            优惠券使用规则
                        </div>
                        <div class="cof fontS24" style="line-height: 40px;margin-top:20px;">
                            {{ info.text }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>

.rulesBox {
    padding-bottom: 6px;
    border-radius: 24px;
    width: 100%;

    .rules {
        background: linear-gradient(180deg, #F4C28F 0%, #EDAC89 100%);
        border-radius: 24px;
        padding: 20px;
        box-sizing: border-box;
    }
}

.lisInfoBox {
    position: relative;
    left: 0;
    // padding: 0 223px;
    width: 100%;
    box-sizing: border-box;
    top: 900px;
    flex-wrap: wrap;
    
 
    .lis {
        width: 48%;
        padding-bottom: 6px;
        background: #FFFFFF;
        border-radius: 24px;
        margin-bottom: 16px;

        .box {
            background: linear-gradient(180deg, #F4C28F 0%, #EDAC89 100%);
            border-radius: 24px;
            padding: 20px;
            box-sizing: border-box;

            .liBox {
                padding-bottom: 6px;
                background: #FAE5B4;
                box-shadow: 0px 8px 8px rgba(219, 140, 104, 0.5);
                border-radius: 24px;

                .whiBox {
                    background: #FFFFFF;
                    border-radius: 24px;

                    .left {
                        width: 70%;
                        border-right: 1px dashed #E0E0E0;
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;

                        .left_t {
                            flex: 1;
                            padding: 20px;
                            box-sizing: border-box;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                        }

                        .left_b {
                            // height:46px;
                            // line-height: 46px;
                            padding: 8px 20px;
                            border-top: 2px solid #F5F5F5;
                            // padding:0 20px;
                            box-sizing: border-box;
                        }
                    }

                    .right {
                        padding: 20px;
                        box-sizing: border-box;
                        position: relative;

                        .cilr {
                            width: 10px;
                            height: 10px;
                            background: #F0A54F;
                            float: right;
                            border-radius: 50%;
                            position: absolute;
                            right: 20px;
                            top: -18px;
                        }

                        .btn {
                            width: 158px;
                            height: 54px;
                            background: linear-gradient(180deg, #F3C18E 0%, #EDAC89 100%);
                            border-radius: 40px;
                            line-height: 54px;
                            text-align: center;
                            font-weight: 900;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}

.imgBox {
    position: relative;
    left: 0%;
    top: 0;

    .img {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}


</style>