<script setup >
import { reactive, onBeforeMount,defineEmits } from 'vue'
import { userget_user_info, get_phone_prefix, sms ,update_user_auth } from '@/utils/api'
// // import FormInstance from 'element-plus'
// import upImg from '@/components/upImg.vue'
import { ElMessage } from 'element-plus'
import { Cellphone } from "@element-plus/icons-vue";
// import AreaSelect from '@/components/address/address.vue'


const data = reactive({
    userInfo: {}, //用户所有的信息
    code: "",
    num: 61,
    phone_prefix: '',
    optionsPhone: [],
    newPhone:'',
    oldPhone:'',
});
const emit =defineEmits('changeShowIndex')
onBeforeMount(() => {
    getUserinfo()
    get_phone_prefix().then(res => {
        if (res.code == 200) {
            data.optionsPhone = res.data
            data.phone_prefix = res.data[0].prefix
        }
    })
})
// 获取用户信息
const getUserinfo = () => {
    userget_user_info().then(res => {
        console.log(res)
        if (res.code == 200) {
            data.userInfo = res.data
            data.oldPhone=data.userInfo.phone.substring(0, 3)+"****"+data.userInfo.phone.substr(data.userInfo.phone.length-4);
        }
    })
}
const sendClick = () => {
    if (data.num == 61) {
        data.num = 60
    }
    sms({ phone: data.userInfo.phone }).then(res => {
        if (res.code == 200) {
            ElMessage.success('验证码已发送')
            const timer = setInterval(() => {
                if (data.num == 0) {
                    clearInterval(timer)
                    data.num = 61
                } else {
                    data.num--
                }
            }, 1000)
        }
    })
}
const submit = ()=>{
    update_user_auth({
        phone_code:data.code,
        phone:data.userInfo.phone,
        type:1,
        now_phone:data.newPhone,
        phone_prefix:data.phone_prefix,
    }).then(res=>{
        if(res.code==200){
            emit('changeShowIndex',3)
            ElMessage.success('修改成功')
        }

    })
}
</script>

<template>
    <div class="right co3">
        <div class="r_info">
            <div class="r_title">
                <el-icon size="20" color="#666">
                    <Cellphone />
                </el-icon>
                <span style="margin-left:10px">修改手机号</span>
            </div>
            <div class="r_content  ">
                <div class="flex">
                    <div class=" fontS20">已绑定手机号：{{ data.oldPhone }}</div>
                    <div style="color:#5C7DCD;margin-left: 40px;" class="fontS16 cur" @click="sendClick">
                        {{ data.num == 61 ? '发送验证码' : data.num }}</div>
                </div>
                <div style="margin:60px 0 10px" class="fontS16">请输入验证码：</div>
                <el-input class="input-with-select" v-model="data.code" placeholder="请输入验证码"
                    :input-style="{ width: '134px', height: '44px', }">
                </el-input>

                <div style="margin:60px 0 10px" class="fontS16">请输入新手机号：</div>

                <el-input v-model="data.newPhone" placeholder="请输入新手机号" class="input-with-select" style="width: 300px;height: 44px;">
                    <template #prepend >
                        <el-select v-model="data.phone_prefix" placeholder="Select" style="width: 80px;">
                            <el-option v-for="item in data.optionsPhone" :key="item.id" :label="'+' + item.prefix"
                                :value="item.prefix"  />
                        </el-select>
                    </template>
                </el-input>
                <div class="btn cof fontS16 center cur" @click="submit">确定</div>
            </div>
        </div>
    </div>

</template>

<style lang="less" scoped>
.right {
    width: 88%;
    /deep/.el-input__wrapper{
        height: 42px !important;
    }
    .btn {
        width: 87px;
        height: 40px;
        background: #FFC047;
        margin-top: 40px;
        line-height: 40px;
    }

    .r_title {
        width: 100%;
        height: 72px;
        background-color: #F6F7F9;
        padding: 20px 16px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        border: 1px solid #CCCCCC;
        border-bottom: 2px solid #CCCCCC;

        span {
            font-size: 24px;
            color: #666666;
        }
    }

    .r_content {
        padding: 40px 20px;
        box-sizing: border-box;
        height: 633px;
        border: 1px solid #CCCCCC;

    }
}
</style>

