<script setup>
import { ref } from 'vue'
const type = ref('1') //1是退款无需退货  2是退款退货
const radio1 = ref('1')
const value = ref('')
const info = ref('')

const options = [
    {
        value: 'Option1',
        label: 'Option1',
    },
    {
        value: 'Option2',
        label: 'Option2',
    },
    {
        value: 'Option3',
        label: 'Option3',
    },
    {
        value: 'Option4',
        label: 'Option4',
    },
    {
        value: 'Option5',
        label: 'Option5',
    },
]

</script>

<template>
    <div class="wrapCenter">
        <img src="../../assets/logo.png" style="object-fit:cover" alt="" class="logoimg">
        <div class="co9 fontS16">首页 > 个人中心 > 已买到的商品>退货/退款  </div>
        <div class="goodsBox co3 fontS20">
            <div class="flex">
                <div class="">退款商品：</div>
                <div class="flex goods" style="align-items:flex-start">
                    <img  style="object-fit:cover" src="../../assets/Image/5494c0fa4c8d21450ef7357d0929a5d8.jpeg" alt="">
                    <div>
                        <div class="fontS20 co3">冬季保暖高帮棉拖鞋女包跟厚底室内居家用</div>
                        <div class="co9 fontS16" style='margin: 10px 0;'>
                            <span>尺码：38-39</span>
                            <span style="margin:0 40px">颜色：泡芙粉</span>
                            <span>X1</span>
                        </div>
                        <div class="homeRed fontS20">68.00</div>
                    </div>
                </div>
            </div>
            <div class="flex" style="margin-top:40px">
                <span>服务类型：</span>
                <span>{{type==1?'仅退款':'退款退货'}}</span>
            </div>
            <div class="flex" style="margin-top:40px" v-if="type==1">
                <span>货物状态：</span>
               
                    <el-radio-group v-model="radio1" class="ml-4" text-color="#333333" fill="#25459C">
                        <el-radio label="1" size="large" style="font-size:20px">未收到货</el-radio>
                        <el-radio label="2" size="large">已收到货</el-radio> 
                    </el-radio-group>
                
            </div>
            <div class="flex" style="margin-top:40px">
                <span>退款原因：</span>
                <el-select v-model="value" class="m-2" placeholder="请选择" size="large" style='width: 296px;'>
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
            </div>
            <div class="flex" style="margin-top:40px">
                <span>退款金额：</span>
                <span class="homeRed meneyBox fontS18">¥39.90</span>
            </div>
            <div class="flex" style="margin-top:40px">
                <span>退款说明：</span>
                <el-input v-model="info" placeholder="退款说明" style="width: 696px;" :rows="5" maxlength="200"
                    type="textarea" show-word-limit />
            </div>
            <div class="flex" style="margin-top:40px">
                <span>退款说明：</span>
                <div class="co9 fontS18 center ImgUpbox">
                    <div style="fontSize:40px">+</div>
                    <div>上传凭证</div>
                    <div style="margin-top:5px">（最多三张）</div>
                </div>
            </div>

            <div class="btn cof center fontS20">提交申请</div>
        </div>
    </div>
</template>
<style lang="less" scoped>
/deep/ .el-radio__label{
    font-size: 20px !important; 
}
/deep/ .is-checked{
   color: #333 !important; 
     .el-radio__label{ 
        color: #333 !important;
    }
    /deep/.el-radio__original{

    }
}
.ImgUpbox {
    width: 144px;
    height: 144px;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    padding-top: 30px;
    box-sizing: border-box;
}

.meneyBox {
    width: 296px;
    height: 40px;
    line-height: 40px;
    background: #FFFFFF;
    border: 1px solid #ddd;
    padding-left: 20px;
    box-sizing: border-box;
}

.goodsBox {
   margin-bottom: 50px;
    .btn {
        width: 244px;
        height: 48px;
        line-height: 48px;
        background: #25459C;
        margin-top: 40px;
        margin-left: 100px;
    }

    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    margin-top: 40px;
    padding: 40px;
    box-sizing: border-box;

    img {
        width: 120px;
        height: 120px;
        margin-right: 20px;
    }
}

.logoimg {
    width: 160px;
    height: 62px;
    margin: 30px 0 60px;
}
</style>