<script setup>
import { reactive, ref, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import {
  get_address,
  pre_order,
  perProductOrder,
  orderCreate,
  save_address,
  userget_user_info
} from "@/utils/api";
import { defaultNavigator } from "@vueuse/core";
import { dataType } from "element-plus/es/components/table-v2/src/common";
import { filter } from "lodash";
import { ElMessage } from "element-plus";
const router = useRouter();

const data = reactive({
  totalPriceall: "",
  coupon_id: null, //优惠券
  adresList: [],
  goodsInfo: null, // 商品列表
  activeAdress: 0, //当前选中地址的index
  query: router.currentRoute.value.query, //购物车订单预览商品的id   cart_id
});
const checked1 = ref(true);
const adresD = reactive({
  arr: [],
  id: 0,
});
onBeforeMount(() => {
  arrGetAddr();
  userInfoModel()
});
// 备案
// 获取用户数据
const userInfo=ref(null)
const dialogTableVisible=ref(false)
// 提示
const modelTxt=ref('')
const userInfoModel=()=>{
  userget_user_info().then(res => {
      if(res.code==200){
        userInfo.value = res.data
      }
      })
}
const sbumitRecord=()=>{
    if(data.totalPrice>=300000){
      dialogTableVisible.value=false
      return
    }
  if(userInfo.value.is_qualification!=1){
    router.push({ path: "/enterpriseReg"});
    return
  }
  if(userInfo.value.is_qualification==1&&userInfo.value.is_record==0){
    router.push({ path: "/enterpriseReg"});
    return
  }
  if(userInfo.value.is_qualification==1&&(userInfo.value.is_record==1||userInfo.value.is_record==3)){
    router.push({ path:"/order/orderHome" , query: { index: 6 } });
    return
  }
}
// 获取地址
const arrGetAddr = () => {
  get_address().then((res) => {
    data.adresList = res.data;
    allAddrData(data.adresList);
    // 代表是购物车订单阅览
    getinfo();
  });
};
// 收货地址
const allAddrData = (data) => {
  if (adresD.id != 0) {
    let isId = []; //修改地址的时候查看是不是修改的当前选中地址
    isId = data.filter((item) => item.id == adresD.id);
    if (isId.length != 0) {
      adresD.arr = isId;
      data.forEach((item, index) => {
        if (adresD.arr[0].id == item.id) {
          data.splice(index, 1);
        }
      });
    }
  } else {
    adresD.arr = data.filter((item) => item.is_default == 1);
    if (adresD.arr.length == 0) {
      //adresD.arr 默认地址
      if (data.length != 0) {
        adresD.arr.push(data[0]);
        data.splice(0, 1);
      }
    } else {
      //不是默认地址
      data.forEach((item, index) => {
        if (adresD.arr[0].id == item.id) {
          data.splice(index, 1);
        }
      });
    }
  }
  return data;
};
const getinfo = () => {
  if (data.query.cart_id) {
    pre_order({
      cart_id: data.query.cart_id,
      address_id: adresD.arr[0].id,
    }).then((res) => {
      if (res.code == 200) {
        data.goodsInfo = res.data;
        data.totalPriceall = res.data.total.total_real_price;
        data.goodsInfo.coupon.unshift({
          coupon_id: "",
          full_text: "不使用优惠券",
        });
      }
    });
  } else {
    perProductOrder({
      data: JSON.parse(data.query.orderinfo),
      address_id: adresD.arr[0].id,
    }).then((res) => {
      if (res.code == 200) {
        data.goodsInfo = res.data;
        data.totalPriceall = res.data.total.total_real_price;
        data.goodsInfo.coupon.unshift({
          coupon_id: "",
          full_text: "不使用优惠券",
        });
      }else{
        if(res.code==202){
          setTimeout(()=>{
            router.go(-1);
          },1000)
        }
      }
    });
  }
};
const submit = () => {
    if(userInfo.value.is_qualification!=1){
      modelTxt.value='您所认证的企业当前不具备A类资质，请前往采购商资质进行查看'
      dialogTableVisible.value=true
      return
    }
    if(userInfo.value.is_qualification==1&&userInfo.value.is_record!=2){
      switch(userInfo.value.is_record)
      {
        case 0:
        dialogTableVisible.value=true
        modelTxt.value='您所认证的企业未进行备案，请前往采购商资质进行查看';
        break;
        case 1:
        dialogTableVisible.value=true
        modelTxt.value='您所认证的企业正在备案中，请前往采购商资质进行查看';
        break;
        case 3:
        dialogTableVisible.value=true
        modelTxt.value='您所认证的企业备案失败，请前往采购商资质进行查看';
        break;
      }
      return
    }
  // 购物车去创建订单
  if (data.query.cart_id) {
    let data1 = {
      cart_id: data.query.cart_id,
      anonymous: checked1.value ? 1 : 0,
      address_id: adresD.arr[0].id,
      type: 1,
      totalPrice: data.totalPriceall,
      mark: "",
      // pay_type: 2,
    };
    if (data.coupon_id) {
      data1 = Object.assign(data1, { coupon_id: data.coupon_id });
    }
    if (data.goodsInfo.need_review == true) {
      orderCreate(data1).then((res) => {
        if (res.code == 200) {
          router.replace({
            path: "/getFuhe",
          });
        } else {
          ElMessage.warning(res.message);
        }
      });
    } else {
      router.replace({
        path: "/pay",
        query: data1,
      });
    }
  } else {
    // 详情去创建订单
    let data1 = {
      anonymous: checked1.value ? 1 : 0,
      address_id: adresD.arr[0].id,
      type: 2,
      totalPrice: data.totalPriceall,
      mark: "",
      data: data.query.orderinfo,
      // pay_type: 2,
    };
    if (data.coupon_id) {
      data1 = Object.assign(data1, { coupon_id: data.coupon_id });
    }
    if (data.goodsInfo.need_review == true) {
      orderCreate(data1).then((res) => {
        if (res.code == 200) {
          router.replace({
            path: "/getFuhe",
          });
        } else {
          ElMessage.warning(res.message);
        }
      });
    } else {
      router.replace({
        path: "/pay",
        query: data1,
      });
    }
  }
};
const addressChange = (i) => {
  data.adresList.forEach((item, index) => {
    if (i == index) {
      data.adresList.push(adresD.arr[0]);
      adresD.arr.unshift(item);
      adresD.arr.splice(1, 1);
      data.adresList.splice(index, 1);
    }
  });
  isNewAdrr.value = false;
  getinfo();
};
const selchange = () => {
  let itemco = {};
  data.goodsInfo.coupon.forEach((item) => {
    console.log(
      item.coupon_id == data.coupon_id,
      item.coupon_id,
      data.coupon_id
    );
    if (item.coupon_id == data.coupon_id) {
      itemco = item;
    }
  });
  if (data.coupon_id) {
    if (itemco.coupon_type == 1) {
      //满减卷
      data.totalPriceall = ( data.goodsInfo.total.total_price - itemco.coupon_price + data.goodsInfo.total.total_free ).toFixed(2);
    } else {
      // 折扣券
      data.totalPriceall = ( data.goodsInfo.total.total_price * itemco.discount + data.goodsInfo.total.total_free).toFixed(2);
    }
  } else {
    data.totalPriceall = data.goodsInfo.total.total_real_price;
  }
  console.log(data.coupon_id, "选择优惠券");
};
const isNewAdrr = ref(false);
const getNewAdrr = () => {
  if (data.adresList.length >= 1 && adresD.arr.length >= 1) {
    isNewAdrr.value == true
      ? (isNewAdrr.value = false)
      : (isNewAdrr.value = true);
  } else {
    router.push({ path: "/set", query: { index: 2 } });
  }
};
// 修改地址 val区分是修改的哪个数组
const updetaAdr = (index, val) => {
  if (val == 1) {
    adresD.id = adresD.arr[index].id;
    formInline.id = adresD.arr[index].id;
    formInline.real_name = adresD.arr[index].real_name;
    formInline.phone = adresD.arr[index].phone;
    formInline.take_phone = adresD.arr[index].take_phone;
    formInline.take_name = adresD.arr[index].take_name;
    formInline.is_default = adresD.arr[index].is_default == 1 ? true : false;
    formInline.take_company_name = adresD.arr[index].take_company_name;
    centerDialogVisible.value = true;
  } else if (val == 2) {
    adresD.id = 0;
    formInline.id = data.adresList[index].id;
    formInline.real_name = data.adresList[index].real_name;
    formInline.phone = data.adresList[index].phone;
    formInline.take_phone = data.adresList[index].take_phone;
    formInline.take_name = data.adresList[index].take_name;
    formInline.is_default =
      data.adresList[index].is_default == 1 ? true : false;
    formInline.take_company_name = data.adresList[index].take_company_name;
    centerDialogVisible.value = true;
  }
};
const centerDialogVisible = ref(false);
const formInline = reactive({
  real_name: "",
  phone: "",
  take_name: "",
  take_phone: "",
  take_company_name: "",
  is_default: 0,
  id: 0,
});

const onSubmit2 = () => {
  let ob = {};
  if (formInline.id) {
    ob = { id: formInline.id };
  }
  save_address(
    Object.assign(
      {
        real_name: formInline.real_name,
        phone: formInline.phone,
        take_company_name: formInline.take_company_name,
        take_phone: formInline.take_phone,
        take_name: formInline.take_name,
        is_default: formInline.is_default ? 1 : 0,
      },
      ob
    )
  ).then((res) => {
    if (res.code == 200) {
      arrGetAddr();
      centerDialogVisible.value = false;
      isNewAdrr.value = false;
    } else {
      ElMessage.warning(res.message);
    }
  });
};
</script>

<template>
  <div class="wrapCenter" v-if="data.goodsInfo">
    <div class="top flex">
      <img
        src="../../assets/logo.png"
        style="width: 160px; height: 70px; object-fit: cover"
        alt=""
      />
      <span class="fontS20" style="margin: 30px 0 0 10px">确认订单</span>
    </div>
    <div class="title fontS16 flexJBC marB2">
      <span>确认收货地址</span>
      <!-- @click=" router.push({path:'/set',query:{index:2}})" -->
      <span class="cur" @click="getNewAdrr()">使用新地址</span>
    </div>
    <div class="adress">
      <div class="">
        <div
          class="ad_li add_check"
          v-for="(item, index) in adresD.arr"
          :key="index"
        >
          <div class="ad_liBox">
            <div style="display: flex; margin-top: -6px">
              <div class="fontS18 homeRed" style="display: flex">
                <img
                  src="../../assets/Image/address_red.png"
                  alt=""
                  class="addressImg"
                />
                <span style="line-height: 40px; margin-left: 6px"> 寄送至</span>
              </div>
              <div class="fontS20 co3 flex" style="margin-left: 82px">
                <div class="">
                  <div class="marginBottom">提货仓库：宁波仓</div>
                  <div class="marginBottom">
                    提货公司：{{ item.take_company_name }}
                  </div>
                  <div class="marginBottom">
                    下单人：{{ item.real_name }} {{ item.phone }}
                  </div>
                  <div class="marginBottom">
                    提货人：{{ item.take_name }} {{ item.take_phone }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="fontS18 cur"
              style="width: 200px; text-align: right; color: #5c7dcd"
            >
              <span @click="updetaAdr(index, 1)"> 修改本地址 </span>
            </div>
          </div>
        </div>
        <div v-for="(item, index) in data.adresList" :key="index">
          <div
            class="ad_li add_liBox"
            :style="{ display: isNewAdrr == false ? 'none' : 'block' }"
          >
            <div class="ad_liBox">
              <div
                style="display: flex; margin-top: -6px"
                @click="addressChange(index)"
              >
                <div class="fontS18 homeRed" style="display: flex">
                  <img
                    src="../../assets/Image/address_red.png"
                    alt=""
                    class="addressImg"
                  />
                  <span style="line-height: 40px; margin-left: 6px">
                    寄送至</span
                  >
                </div>
                <div class="fontS20 co3 flex" style="margin-left: 82px">
                  <div class="">
                    <div class="marginBottom">提货仓库：宁波仓</div>
                    <div class="marginBottom">
                      提货公司：{{ item.take_company_name }}
                    </div>
                    <div class="marginBottom">
                      下单人：{{ item.real_name }} {{ item.phone }}
                    </div>
                    <div class="marginBottom">
                      提货人：{{ item.take_name }} {{ item.take_phone }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="fontS18 cur" style="width: 200px; text-align: right">
                <span @click="updetaAdr(index, 2)">
                  <!-- router.push({ path: '/set', query: { index: 2 } }) -->
                  修改本地址
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </el-radio-group> -->
    </div>
    <div class="title fontS16 flexJBC marB2">
      <span>确认订单信息</span>
    </div>
    <div
      class="fontS18 co6"
      style="height: 60px; background-color: #f6f7f9; line-height: 60px"
    >
      <span style="margin-left: 124px">商品信息</span>
      <span style="margin-left: 480px">单价</span>
      <span style="margin-left: 180px">数量</span>
      <span style="margin-left: 180px">优惠方式</span>
      <span style="margin-left: 180px">小计</span>
    </div>
    <div
      class="marB2"
      v-for="(item, index) in data.goodsInfo.list"
      :key="index"
    >
      <div style="height: 60px; line-height: 60px">
        {{ item.stroe_name }}
        <!-- <span class="co3 fonsS16"><el-checkbox v-model="checked2" label="芒果家鞋屋" size="large" /></span> -->
      </div>
      <div
        style="
          display: flex;
          height: 190px;
          border-top: 1px solid #cccccc;
          border-bottom: 1px solid #cccccc;
          box-sizing: border-box;
          padding-top: 10px;
        "
        v-for="(item1, index1) in item.goods"
        :key="index1"
      >
        <div
          class="fontS16"
          style="display: flex; line-height: 20px; width: 594px"
        >
          <img
            :src="item1.image"
            style="height: 140px; width: 140px; object-fit: cover"
            alt=""
          />
          <div style="margin-left: 20px; width: 290px">
            <p class="marB2 co6">
              {{ item1.product_name }}
            </p>
            <p class="co9" style="margin-bottom: 10px">
              <span v-if="item1.suk_name"> 规格:{{ item1.suk_name }}</span>
            </p>
          </div>
        </div>
        <div style="width: 210px" class="center">
          <!-- <p class="fontS18 co9" style="text-decoration: line-through;">¥{{ item1.price }}</p> -->
          <p class="fontS20 co6">¥{{ item1.price }}</p>
        </div>
        <div style="width: 230px" class="center">
          {{ item1.num }}
          <!-- <el-input-number v-model="item1.num" :min="1" :max="9999" @change="handleChange" /> -->
        </div>
        <div style="width: 210px" class="center">
          <p class="fontS20 co9">无优惠</p>
        </div>
        <div style="width: 210px; text-align: right">
          <p class="fontS20 priceColor">
            ¥{{ (item1.num * item1.price).toFixed(2) }}
          </p>
        </div>
      </div>
    </div>
    <div style="height: 60px" v-if="data.goodsInfo.coupon.length > 0">
      <div class="flex" style="float: right">
        <span>使用优惠券（优惠券不能抵扣运费）：</span>
        <el-select
          v-model="data.coupon_id"
          class="m-2"
          placeholder="请选择优惠券"
          size="large"
          @change="selchange"
        >
          <el-option
            v-for="item in data.goodsInfo.coupon"
            :key="item.coupon_id"
            :label="item.full_text"
            :value="item.coupon_id"
          />
        </el-select>
      </div>
    </div>

    <div
      class="fontS16"
      style="height: 60px; background-color: #eff3ff; line-height: 60px"
    >
      <span style="margin-left: 1030px">运费：</span>
      <span style="margin-left: 10px"
        >¥{{ data.goodsInfo.total.total_free }}</span
      >
      <!-- <span style="margin-left: 40px;">（标准配送 DHL 预计8月19日送达）</span> -->
    </div>
    <div
      class="fontS16"
      style="
        height: 60px;
        line-height: 60px;
        border-bottom: 1px dashed #cccccc;
        margin-bottom: 40px;
      "
    >
      <span style="float: right"
        ><el-checkbox
          v-model="checked1"
          label="匿名购买"
          size="large"
          class="myRedCheckBox"
      /></span>
    </div>
    <div class="marB2" style="display: flex; justify-content: flex-end">
      <div style="width: 640px; box-sizing: border-box">
        <p class="fontS16 co3 marB2" style="text-align: right">
          实付款： <span class="fontS20 priceColor">￥</span>

          <span class="fontS32 priceColor">{{ data.totalPriceall }} </span>
        </p>
      </div>
    </div>
    <div style="display: flex; justify-content: flex-end" @click="submit">
      <button
        class="sontS20 cur"
        style="
          border: 0;
          background-color: #25459c;
          color: white;
          width: 240px;
          height: 50px;
          margin-bottom: 100px;
        "
      >
        提交订单
      </button>
    </div>
    <!-- 修改地址 -->
    <el-dialog
      v-model="centerDialogVisible"
      title="修改地址"
      width="30%"
      align-center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <!-- :inline="true" -->
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        label-width="140px"
      >
        <el-form-item label="下单人：">
          <el-input v-model="formInline.real_name" placeholder="请输入下单人" />
        </el-form-item>
        <el-form-item label="下单人手机号：">
          <el-input
            v-model="formInline.phone"
            placeholder="请输入下单人手机号"
          />
        </el-form-item>
        <el-form-item label="提货人：">
          <el-input v-model="formInline.take_name" placeholder="请输入提货人" />
        </el-form-item>
        <el-form-item label="提货人手机号：">
          <el-input
            v-model="formInline.take_phone"
            placeholder="请输入提货人手机号"
          />
        </el-form-item>
        <el-form-item label="提货公司：">
          <el-input
            v-model="formInline.take_company_name"
            placeholder="请输入提货公司"
          />
        </el-form-item>
        <el-form-item style="margin-left: 130px">
          <div class="flex redioBox" style="margin-left: 10px">
            <el-checkbox
              v-model="formInline.is_default"
              label=""
              size="large"
              class="myRedCheckBox"
            />
            <div style="margin-left: 10px">
              <span class="co6 fontS16">设为默认地址</span>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="onSubmit2()" color="#25459C"
            >保存</el-button
          >
          <button @click="centerDialogVisible = false" class="btnClose delBtn">
            取消
          </button>
        </span>
      </template>
    </el-dialog>
    <!-- 备案 -->
    <el-dialog v-model="dialogTableVisible" title="" width="594">
        <div class="flex">
            <div class="suceImg" style="margin-right:14px">
              <img src="@/assets/Group48831.png" alt="" />
            </div>
            <div class="fontS20" style="line-height:1.5">
             {{modelTxt}}
            </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogTableVisible = false" class="closeBtn">取消</el-button>
            <el-button type="primary" @click="sbumitRecord" class="submitBtn">
              确认
            </el-button>
          </span>
        </template>
      </el-dialog>
  </div>
</template>


<style lang="less" scoped>
.title {
  margin-top: 40px;
}
.marginBottom {
  margin-bottom: 10px;
}
.ad_li {
  .ad_liBox {
    display: flex;
    justify-content: space-between;
  }
  //   height: 168px;
  padding: 16px 20px;
}
.addressImg {
  object-fit: cover;
  width: 40px;
  height: 40px;
}
.padl {
  padding-left: 410px;
}

.add_check {
  background-color: rgb(239, 243, 255);
  border: 1px solid #25459c;
  box-sizing: border-box;
}
.add_liBox {
  background-color: #fff;
  border: 1px solid #ccc;
  margin-top: 10px;
}
.redioBox {
  /* 设置带边框的checkbox，选中后边框的颜色 */
  .myRedCheckBox.is-bordered.is-checked {
    border-color: #25459c !important;
  }

  /* 设置选中后的文字颜色 */
  .myRedCheckBox .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #25459c !important;
  }

  /* 设置选中后对勾框的边框和背景颜色 */
  .myRedCheckBox /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
  .myRedCheckBox .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    border-color: #25459c !important;
    background-color: #25459c !important;
  }

  /* 设置checkbox获得焦点后，对勾框的边框颜色 */
  .myRedCheckBox /deep/.el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #25459c !important;
  }

  /* 设置鼠标经过对勾框，对勾框边框的颜色 */
  .myRedCheckBox /deep/.el-checkbox__inner:hover {
    border-color: #25459c !important;
  }
}
</style>
