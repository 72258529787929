
import { defineComponent, reactive, watchEffect, toRefs, computed , onBeforeMount} from 'vue';

export default defineComponent({
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    selectedList: {
      type: Array,
      default: () => [],
    },
    isSearch: {
      type: Boolean,
      default: () => true,
    },
    isRadio: {
      type: Boolean,
      default: () => false,
    },
    isCustomItem: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => '',
    },
    type: {
      type: String,
      default: () => '',
    },
    isH5: {
      type: Boolean,
      default: () => false,
    },
    resultShow: {
      type: Boolean,
      default: () => true,
    },
  },
  setup(props: any, ctx: any) {
    const data = reactive({
      type: '',
      list: [],
      selectedList: [],
      isSearch: true,
      isCustomItem: false,
      title: '',
      userId:localStorage.getItem('tencent_user_id'),
      show:false, // 当左侧筛选出来的用户列表显示后再进行选中
    });
    const selected = (item: any) => {
      console.log(item,'item')
      if (item.isDisabled) {
        return;
      }
      let list: any = data.selectedList;
      const index: number = list.indexOf(item);
      if (index > -1) {
        return data.selectedList.splice(index, 1);
      }
      if (props.isRadio) {
        list = [];
      }
      list.push(item);
      data.selectedList = list;
    };

    watchEffect(() => {
      if (props.isCustomItem) {
        for (let index = 0; index < props.list.length; index++) {
          if (props.list[index].conversationID.indexOf('@TIM#SYSTEM') > -1) {
            // eslint-disable-next-line vue/no-mutating-props
            props.list.splice(index, 1);
          }
          data.list = props.list;
          console.log(data.list[0],data.show,'data.list123456')
        }
        if(data.show && data.list[0]){
            selected(data.list[0])
        }
      } else {
        // 目前测试走的这里的代码
        data.list = props.list;
        console.log(data.list[0],data.show,'data.list')
        if(data.show && data.list[0]){
            setTimeout(()=>{
              selected(data.list[0])
            },100)
          }
      }
      data.selectedList = props.selectedList;
      data.isSearch = props.isSearch;
      data.isCustomItem = props.isCustomItem;
      data.title = props.title;
      data.type = props.type;
    });

    // 搜索用户Id
    onBeforeMount(()=>{
      setTimeout(() => {
        onMountedhandleInput(data.userId)
        data.show=true
      }, 1500);
    });
    // 可选项
    const optional = computed(() => data.list.filter((item: any) => !item.isDisabled));
    const handleInput = (e: any) => {
      console.log(e,'eeeeeeeee')
      ctx.emit('search', e.target.value);
      console.log(e,'e.target.value')
    };
    const onMountedhandleInput = (e: any) => {
      console.log(e,'eeeeeeeee')
      ctx.emit('search', e);
      console.log(e,'e.target.value')
    };
    const selectedAll = () => {
      if (data.selectedList.length === optional.value.length) {
        data.selectedList = [];
      } else {
        data.selectedList = [...optional.value];
      }
    };

    const submit = () => {
      ctx.emit('submit', data.selectedList);
    };

    const cancel = () => {
      ctx.emit('cancel');
    };

    return {
      ...toRefs(data),
      optional,
      handleInput,
      selected,
      selectedAll,
      submit,
      cancel,
    };
  },
});
