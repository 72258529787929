<script setup>

import { reactive, onBeforeMount, ref } from 'vue';
import { merchantJoin, findMerchJoin } from '@/utils/api'
import { ElMessage } from 'element-plus'
import upImg from '@/components/upImg.vue'
const data = reactive({
    name_jp: '',
    name_zh: '',
    account: '',
    passwd: '',
    email: '',
    phone: '',
    image: [],
    image1: [],
})
const data1 = reactive({
    fileList: []
})
const status = ref(2) //申请状态 0入驻中 1已入驻- 1拒绝 2待入驻
onBeforeMount(() => {
    info()
})
// 删除图片
const handleRemove = (file) => {
    console.log('shanchu', file)
    let fileJS = JSON.parse(JSON.stringify(file))
    const filePath = fileJS.response ? fileJS.response.data.file_path : fileJS.url;
    const index = data.image1.findIndex((x) => x === filePath);
    data.image1.splice(index, 1);
}
const loading = ref(true)
const info = () => {
    loading.value = true
    findMerchJoin().then(res => {
        loading.value = false
        if (res.code == 200) {
            if (res.data.name_zh) {
                data.name_zh = res.data.name_zh;
                data.name_jp = res.data.name_jp;
                data.phone = res.data.phone;
                data.account = res.data.account;
                data.email = res.data.email;
                data.passwd = res.data.passwd
                if (res.data.image) {
                    data.image1 = res.data.image.split(',')
                    data.image1.forEach((item, index) => {
                        data1.fileList.push({
                            name: 'food.jpeg' + index,
                            url: item
                        })
                    })
                } else {
                    data.image1 = []
                }

                status.value = res.data.status
            }


        }
    })
}
// 图片上传成功
const handleSuccessed = (img, limit) => {
    // 如果limit==1
    if (limit) {
        data.image1[0] = img
        console.log(data.image1[0], ' data.image[0]')
    } else {
        data.image1.push(img)
    }
}
const submit = () => {
    if (data.image) {
        data.image = data.image1.join(',')
    } else {
        data.image = []
    }

    merchantJoin(data).then(res => {
        if (res.code == 200) {
            ElMessage.success('提交成功')
            info()
        } else {
            ElMessage.warning(res.message)
        }
    })
}
</script>
<template>
    <div v-loading="loading">
        <div class="box" v-if="!loading">
            <div class="li">
                <span class="left"> 商户名称（日文）： </span>
                <div class="right"><el-input :disabled="status != 2 && status != '-1'" v-model="data.name_jp" placeholder="请设置商户名" /></div>
            </div>
            <div class="li">
                <span class="left"> 商户名称（中文）： </span>   
                <div class="right"><el-input :disabled="status != 2 && status != '-1'" v-model="data.name_zh"  placeholder="请设置商户名" /></div>
            </div>
            <div class="li">
                <span class="left"> 账号： </span>
                <div class="right"><el-input :disabled="status != 2 && status != '-1'" v-model="data.account"
                        placeholder="请输入账号" /></div>
            </div>
            <div class="li">
                <span class="left">密码： </span>
                <div class="right"><el-input :disabled="status != 2 && status != '-1'" v-model="data.passwd" type="password"
                        placeholder="设置密码" /></div>
            </div>
            <div class="li">
                <span class="left"> 手机号码：</span>
                <div class="right"><el-input :disabled="status != 2 && status != '-1'" v-model="data.phone"
                        placeholder="请填写手机号码" /></div>
            </div>
            <div class="li">
                <span class="left"> 邮箱：</span>
                <div class="right"><el-input :disabled="status != 2 && status != '-1'" v-model="data.email"
                        placeholder="请填写邮箱" /></div>
            </div>
            <div class="li">
                <span class="left">添加誊本：</span>
                <div class="right">
                    <div v-if="status == 1 || status == 0"> <img :src="item"
                            style="width:145px;height:145px;object-fit:cover" v-for="(item, index) in data.image1"
                            :key="index" /></div>
                    <upImg :fileList="data1.fileList" :limit="1" @handleSuccessed="handleSuccessed" v-else
                        @handleRemove="handleRemove" />
                </div>
            </div>
            <div class="li">
                <div class="btn center fontS16 cof cur" @click="submit" v-if="status == 2 || status == '-1'">{{ status == 2 ?
                    '注册' : '重新申请' }} </div>
                <div class=" btn btn1 center fontS16 cur cof" v-else>{{ status == 0 ? '入驻中，等待审核' : status == 1 ? '已入驻' : '' }}
                </div>
            </div>
        </div>
</div>
</template>
<style lang="less" scoped>
.box {
    .btn {
        padding: 0 20px;
        height: 40px;
        margin-left: 160px;
        line-height: 40px;
        background: #FFC047;
        margin-top: 30px;
    }

    .btn1 {
        background: #ccc;
    }

    width: 1080px;
    margin-top: 40px;
    border: 1px solid #CCCCCC;
    padding: 20px;
    box-sizing: border-box;

    .li {
        margin-bottom: 20px;
        display: flex;
        align-items: center;

        .left {
            width: 144px;
            margin-right: 10px;
            font-size: 16px;
            color: #333;
            text-align: right;
        }

        .right {
            /deep/.el-input__wrapper {
                height: 44px !important;
                outline: none !important;
            }

            width: 337px;
        }

    }

}
</style>