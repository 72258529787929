<script setup>
import Navbar from "./components/Navbar.vue";
import Floter from "./components/floter.vue";
import {  ref } from "@vue/runtime-core";
import { useRoute } from "vue-router";
const route = useRoute();

</script>

<template>
  <Navbar />
  <div style="min-height: 70vh">
    <router-view ></router-view>
    <!-- <router-view v-slot="{ Component }">
      <keep-alive>
        <component
          v-if="route.meta && route.meta.keepAlive"
          :key="$route.name"
          :is="Component"
        />
      </keep-alive>
      <component
        v-if="!route.meta.keepAlive"
        :is="Component"
        :key="$route.name"
      />
    </router-view> -->
  </div>
  <Floter />
</template>
<style scoped lang="less">
</style>
