<script setup>
import { reactive, onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';
import { orderDetails, getGuesslike, orderdel, ordercancel, delivery, getProductReason } from '@/utils/api'
import { ElMessage } from 'element-plus'
import deleteModel from './components/deleteModel'
import gatherModel from './components/gatherModel'
const router = useRouter()
const centerDialogVisible1 = ref(false)
const centerDialogVisible = ref(false)
const data = reactive({
    detailInfo: null, //详细信息
    page: 1,
    last_page: 1,
    likeList: [],//猜你喜欢
    query: router.currentRoute.value.query,

    type: 1,// 1是删除订单  2是确认收货
    cancel: 3,//取消订单原因
    reason: [],//取消订单原因列表
})
onBeforeMount(() => {
    orderDetails({ order_id: data.query.order_id, sub_order_id: data.query.sub_order_id }).then(res => {
        if (res.code == 200) {
            data.detailInfo = res.data
            // data.detailInfo.order.status='4'
            console.log(data.detailInfo.order.status)
        }
    })
    // 取消订单原因
    getProductReason({ type: 0 }).then(res => {
        if (res.code == 200) {
            data.reason = res.data
        }
    })
    // 猜你喜欢
    getLike()
})
const load = () => {
    if (data.last_page > data.page) {
        data.page++;
        getLike()
    }
}
const getLike = () => {
    getGuesslike({ page: data.page, limit: 12 }).then(res => {
        if (res.code == 200) {
            data.last_page = res.data.last_page
            if (data.page == 1) {
                data.likeList = res.data.data
            } else {
                data.likeList = data.likeList.concat(res.data.data)
            }
        }
    })
}
// 催发货
const urgeDelivery = () => {
    ElMessage.success('催发货成功')
}
// 立即付款
const payment = () => {
    data.detailInfo.order.store = data.detailInfo.list
    router.push({
        path: '/pay',
        query: data.detailInfo.order
    })
}
//取消订单 
const cancalOrder = () => {
    ordercancel({ cancel: data.cancel, order_id: data.detailInfo.order.order_id }).then(res => {
        if (res.code == 200) {
            centerDialogVisible1.value = false;
            ElMessage.success('取消成功')
            router.go('-1')
        }
    })
}

// // 删除订单 // 确认收货
const sureDelAndsurDelivery = () => {
    let sub_order_id = []
    data.detailInfo.list.forEach(item => {
        sub_order_id.push(item.sub_order_id)
    })
    if (data.type == 1) {
        orderdel({ order_id: data.detailInfo.order.order_id, sub_order_id: sub_order_id.join(',') }).then(res => {
            if (res.code == 200) {
                centerDialogVisible.value = false;
                ElMessage.success('删除成功')
                router.go('-1')
            }
        })
    } else {
        delivery({ order_id: data.detailInfo.order.order_id, sub_order_id: sub_order_id.join(',') }).then(res => {
            if (res.code == 200) {
                centerDialogVisible.value = false;
                ElMessage.success('收货成功')
                router.go('-1')
            }
        })
    }

}

// 查看物流
const logisticsClick = () => {
    let sub_order_id = [];
    data.detailInfo.list.forEach(item => {
        sub_order_id.push(item.sub_order_id)
    })
    router.push({
        path: '/logistics',
        query: {
            sub_order_id: sub_order_id.join(','),
            order_id: data.detailInfo.order.order_id
        }
    })

}
//去评价
const goComment = () => {
    let sub_order_id = [];
    data.detailInfo.list.forEach(item => {
        sub_order_id.push(item.sub_order_id)
    })
    router.push({
        path: '/order/comment', query: { sub_order_id: sub_order_id.join(',') }
    })
}

</script>
<template>
      <!-- 删除/收货/退款 -->
    <gatherModel v-model:gatherShow="centerDialogVisible" :text="data.type" @handleSuccessed="sureDelAndsurDelivery" @handRemove="centerDialogVisible = false;"></gatherModel>
      <!-- 取消订单 -->
    <deleteModel v-model:deleteShow="centerDialogVisible1" v-model:cancel="data.cancel" :reason=data.reason @handleSuccessed="cancalOrder" @handRemove="centerDialogVisible1 = false;"></deleteModel>
    <div class="wrapCenter" v-if="data.detailInfo">
        <img src="../../assets/logo.png" alt="" class="logoimg" style="object-fit:cover">
        <div class="co9 fontS16">首页 > 个人中心 > 已买到的商品 </div>
        <!-- 待支付 --> 
        <div class="orderStatus flexJBC marT2" v-if="data.detailInfo.order.status == 0">
            <span class="co3 fontS20">当前订单状态：请在{{ data.detailInfo.order.auto_close_date }}内付款；若未及时付款，系统将自动取消订单</span>
            <div class="btnBox fontS16 flex"  v-if="disable_show_pay==false">
                <div class="btn cof cur" @click="payment">立即付款</div>
                <div class="co6 cur" @click="centerDialogVisible1 = true">取消订单</div>
            </div>
        </div>
         <!-- 待复核 --> 
         <div class="orderStatus flexJBC marT2" v-if="data.detailInfo.order.status == 5">
            <span class="co3 fontS20">当前订单状态：运费复核中，复核成功后请在30分钟内付款</span>
            <div class="btnBox fontS16 flex" v-if="disable_show_pay==false">
                <div class="co6 cur" @click="centerDialogVisible1 = true">取消订单</div>
            </div>
        </div>
        <!-- 待发货 -->
        <div class="orderStatus flexJBC marT2" v-if="data.detailInfo.order.status == 1">
            <span class="co3 fontS20">当前订单状态：宝贝已拍下，等待卖家发货</span>
            <div class="btnBox fontS16 flex cur" @click="urgeDelivery">
                <div style="color: #5C7DCD;">催发货</div>
            </div>
        </div>
        <!-- 待收货 -->
        <div class="orderStatus flexJBC marT2" v-if="data.detailInfo.order.status == 2">
            <div>
                <span class="co3 fontS20" style="margin-right:32px">当前订单状态：卖家已发货，等待卖家确认</span>
                <span class="fontS16">你还有<span class="homeRed">{{
                    data.detailInfo.list[0].auto_deliver_date
                }}</span>；来确认收货，超时订单自动确认收货</span>
            </div>
            <div class="btnBox fontS16 flex cur" @click="centerDialogVisible = true; data.type = 2">
                <div class="btn1 cof">确认收货</div>
            </div>
        </div>
        <!-- 待评价 -->
        <div class="orderStatus flexJBC marT2"
            v-if="data.detailInfo.order.status == 3 || data.detailInfo.order.status == 4">
            <div class="fontS20 co3">当前订单状态：当前交易已完成</div>
            <div class="btnBox fontS16 flex cur" v-if="data.detailInfo.order.status == 3">
                <div class="btn2 co6" @click="goComment">去评价</div>
            </div>
        </div>

        <!-- 交易关闭 超时未支付 -->
        <div class="orderStatus flexJBC marT2" v-if="data.detailInfo.order.status == '-2'">
            <div class="flex">
                <div class="fontS20 co3" style="margin-right:59px">当前订单状态：交易关闭</div>
                <span class="co6 fontS16">超时未支付</span>
            </div>
        </div>
        <!-- 交易关闭 取消订单 -->
        <div class="orderStatus flexJBC marT2" v-if="data.detailInfo.order.status == '-1'">
            <div class="flex">
                <div class="fontS20 co3" style="margin-right:59px">当前订单状态：交易关闭</div>
                <span class="co6 fontS16">取消订单</span>
            </div>
        </div>

        <div class="co3 fontS16 shouhuoBox">
            <div>提货信息</div>
           <div class="flex" style="margin:10px 0 40px">
            <div> 
                下单人：{{data.detailInfo.order.real_name}}—{{ data.detailInfo.order.user_phone }}
            </div>
            <div style="margin-left:50px">
                提货人：{{data.detailInfo.order.take_name}}—{{ data.detailInfo.order.take_phone }}
            </div>
            <div style="margin-left:50px">
                提货公司：{{data.detailInfo.order.take_company_name}}
            </div>
           </div>
            <!-- <div style="margin:10px 0 40px">
                {{ data.detailInfo.order.real_name }}，{{
                    data.detailInfo.order.user_phone
                }}，{{ data.detailInfo.order.user_address }}


            </div> -->
            <div>订单详情</div>
        </div>
        <div class='goodsBox fontS16 co3 '>
            <div class="flex">
                <div>订单编号：{{ data.detailInfo.order.sn }}</div>
                <!-- <div style="margin-left:107px">交易方式：微信支付</div> -->
                <div style="margin-left:107px">创建时间：{{ data.detailInfo.order.create_time }}</div>
                <!-- <div style="margin-left:107px">下单时间：2022-11-12 15:35:35</div> -->
            </div>
            <div class="tableHeade fontS18 co6 flex">
                <div style="margin-left:265px ">宝贝</div>
                <div style="margin-left:354px">宝贝属性</div>
                <div style="margin-left:158px">状态</div>
                <div style="margin-left:151px">单价</div>
                <div style="margin-left:223px">数量</div>
            </div>
            <!-- 待收货 / 待评价 / 交易关闭显示     如果有物流信息就展示物流信息-->
            <div class="daishouhuo fontS16 co6 flex" v-if="data.detailInfo.express.com">
                <div>物流</div>
                <div style="margin-left:53px">{{ data.detailInfo.express.com }}</div>
                <div class="marL2">快递单号:{{ data.detailInfo.express.nu }}</div>
                <div style="color: #5C7DCD;" class="marL2">{{ data.detailInfo.express.data[0].time }}
                    {{ data.detailInfo.express.data[0].context }} </div>
                <div class="co6 marL2 cur" @click="logisticsClick">更多</div>
            </div>
            <div class="table fontS16 flex" style="align-items:flex-start" v-for="(item, index) in data.detailInfo.list"
                :key="index">
                <div>
                    <div v-for="(item1, index1) in item.goods" :key="index1" class="flex" @click="router.push({path:'/goodsDetail',query:{id:item1.goods_id}})"
                        style="align-items:flex-start">
                        <div class="flex left box cur">
                            <img :src="item1.image" alt="" class="goodsimg" style="object-fit:cover">
                            <div>
                                <div class="elipes2  co3" style="line-height:25px">{{ item1.product_name }}</div>
                            <div style="margin-top:10px" class="co9"> {{item1.warehouse_type_text}} </div>
                            </div>
                        </div>
                        <div class="box co9" style="width:274px">
                         <div v-if="item1.detail">规格：{{ item1.detail }}</div>
                        </div>
                        <div style="width: 160px;" class="box co3 center">{{ item.status_text }}</div>
                        <div style="width: 220px;" class="box co3 center">{{ item1.product_price }}</div>
                        <div style="width: 220px;" class=" box center">{{ item1.product_num }}</div>
                    </div>
                </div>
            </div>
            <div class="total">
                <div class="co3 fontS16">
                    <div class="flex">
                        <span class="name">商品总价：</span>
                        <span>¥{{ (data.detailInfo.order.total_price -
                        data.detailInfo.order.total_postage).toFixed(2)}}</span>
                    </div>
                    <div class="flex">
                        <span class="name">运费：</span>
                        <span>¥{{ data.detailInfo.order.total_postage }}</span>
                    </div>
                    <div class="flex">
                        <span class="name">优惠：</span>
                        <span>¥{{ data.detailInfo.order.coupon_price ? data.detailInfo.order.coupon_price : 0 }}</span>
                    </div>
                    <div class="flex">
                        <span class="name">实付款：</span>
                        <span class="homeRed">¥{{ data.detailInfo.order.real_price }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="youLikeBox marT2">
            <div class="flexJBC">
                <div class="flex">
                    <span class="homeRed fontS28">猜你喜欢</span>
                </div>
                <el-icon size="40px " color="#999">
                    <RefreshRight />
                </el-icon>
            </div>
            <div class="printLogFather" infinite-scroll-distance="30px" v-infinite-scroll="load"
                style="height:92vh;overflow: auto;">
                <div class="list marT2">
                    <el-row :gutter="16">
                        <el-col :span="6" v-for="item in data.likeList" :key="item.id" @click="router.push({path:'/goodsDetail',query:{id:item.id}})">
                            <div class="li">
                                <img :src="item.image" alt="" style="object-fit:cover">
                                <div class="title elipes2 co3 fonrS22">{{ item.product_name }}</div>
                                <div class="flex">
                                    <span class="priceColor fontS12">￥</span>
                                    <span class="priceColor fontS28">{{ item.price }}</span>
                                    <span class="fontS18 co9" style="margin-left:14px">{{ item.sales }}人付款</span>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="less" scoped>
.daishouhuo {
    height: 62px;
    line-height: 62px;
    background: #E5EEFD;
    border: 1px solid #CCCCCC;
    padding: 0 20px;
    box-sizing: border-box;

}

.youLikeBox {
    .list {

        flex-wrap: wrap;
        // padding: 0 40px;
        box-sizing: border-box;
        justify-content: flex-start;

        .li {
            width: 336px;
            position: relative;

            .title {
                margin: 20px 0 16px;
                line-height: 28px;
            }

            margin-bottom: 40px;
            margin-right: 16px;

            img {
                width: 336px;
                height: 336px;
                display: block;
            }
        }
    }

    background: #FFFFFF;
    padding: 20px;
    box-sizing: border-box;
    border: 1px solid #CCCCCC;
}

.goodsBox {
    .total {
        display: flex;
        justify-content: flex-end;
        padding-top: 20px;

        .name {
            // margin-right: 97px;
            width: 200px;
            margin-top: 10px;
        }
    }

    .table {
        .left {

            width: 554px;
            align-items: flex-start;
        }

        .box {
            height: 203px;
            padding: 20px;
            box-sizing: border-box;
            border-right: 1px solid #CCCCCC;
        }

        background: #FFFFFF;
        border: 1px solid #CCCCCC;

        .goodsimg {
            width: 120px;
            height: 120px;
            margin-right: 10px;
        }
    }

    .tableHeade {
        height: 58px;
        line-height: 58px;
        background: #F6F7F9;
        border: 1px solid #CCCCCC;
        margin-top: 40px;
    }

    margin-top: 10px;
    padding:20px;
    box-sizing:border-box;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
}

.shouhuoBox {
    padding: 0 20px;
    box-sizing: border-box;
}

.orderStatus {

    .btnBox {
        .btn2 {
            width: 143px;
            height: 40px;
            background: #FFFFFF;
            border: 1px solid #666666;
            text-align: center;
            line-height: 40px;
        }

        .btn1 {
            width: 143px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background: #FFC047;
        }

        .btn {
            width: 143px;
            height: 40px;
            background: #25459C;
            text-align: center;
            line-height: 40px;
            margin-right: 80px;
        }
    }

    height: 108px;
    background: rgba(37, 69, 156, 0.2);
    border: 1px solid #25459C;
    padding: 0 119px 0 20px;
    box-sizing: border-box;
    margin-bottom: 40px;
}

.logoimg {
    width: 160px;
    height: 62px;
    margin: 40px 0 80px;
}
</style>