<script setup >
import { reactive, ref , onBeforeMount} from 'vue'
import { getComplaintCategory,complaint} from '@/utils/api'
import { useRouter } from "vue-router";
import { ElMessage } from 'element-plus'
import upImg from '@/components/upImg.vue'
const router = useRouter();
const data = reactive({
    fileList: [], //上传图片列表
    image: [], //图片列表
    typeList:[],//问题列表

});
onBeforeMount(()=>{
    getComplaintCategory().then(res=>{
        if(res.code==200){
            data.typeList=res.data
        }
    })
})

const isCheck = ref(0) //当先选中问题index
const textarea = ref('')
const checkType = (val) => {
    isCheck.value = val
}
const phone = ref('')
// 提交

const submit=()=>{
    complaint({content:textarea.value,image:data.image.join(','),category_id:data.typeList[isCheck.value].id,phone:phone.value}).then(res=>{
        if(res.code==200){
            // 返回上一页面
            router.go(-1);
            ElMessage.success('反馈成功！')
        }else{
            ElMessage.warning(res.message)
        }
    })
}

// 删除图片
const handleRemove = (file) => {
    const filePath = JSON.parse(JSON.stringify(file)).response.data.file_path;
    const index = data.image.findIndex((x) => x === filePath);
    data.image.splice(index, 1);
}
// 图片上传成功
const handleSuccessed = (img, files) => {
    data.image.push(img)
}
</script>

<template>
    <div style="width: 1500px;margin: 0 auto;">
        <div class="top flex" style="margin:57px 0 60px">
            <img src="../../assets/logo.png" style="width: 160px;height: 60px;object-fit:cover" alt="">
            <span class="fontS20 homeText">意见/反馈</span>
        </div>
      
        <div class="title fontS20">
            类型
        </div>
        <div class="flexJBC" style="margin-top: 20px;">
            <div class="type co9 center cur" @click="checkType(index)" :class="isCheck==index?'active':''" v-for="(item ,index) in data.typeList" :key="index">{{ item.name }}</div>
        </div>
        <div class="title fontS20">
            请输入您对网页版奈特喜的的问题反馈或建议吧～
        </div>
        <div style="margin-top: 20px;">
            <el-input v-model="textarea" :rows="8" type="textarea" placeholder="请详细说明，以便我们解决问题，您最多可填写300字" />
        </div>
        <div class="title fontS20">
            上传图片（最多可上传5张图片）
        </div>
        <div style="margin-top: 20px;">
            <upImg :fileList="data.fileList" :limit="5" @handleSuccessed="handleSuccessed" @handleRemove="handleRemove"/>
        </div>
        <div class="title fontS20">
            联系电话
        </div>
        <div style="margin-top: 20px;">
            <el-input style="width: 350px;" v-model="phone" placeholder="请输入您的手机号" />
        </div>

        <div class="btn cof center fontS20" @click="submit">提交</div>

    </div>
</template>


<style lang="less" scoped>
.btn {
    width: 244px;
    height: 48px;
    line-height: 48px;
    background: #25459C;
    margin-top: 48px;
}

.title {
    margin-top: 40px;
}

.type {
    width: 150px;
    height: 50px;
    line-height: 50px;
    background-color: #F5F5F5;
}

.active {
    background-color: #25459C;
    color: white;
}
.homeText {
        margin: 32px 80px 0 16px;
    }
</style>
