<script setup>
import { apiGetUserInfo, getloginsms, userget_user_info } from "@/utils/api";
import { reactive, ref, onBeforeMount } from "vue";

import { useRouter} from "vue-router";
import { ElMessage } from 'element-plus'

import { useStore } from "vuex";
const router = useRouter();
const store = useStore()
let CompanyStatusData=localStorage.getItem('CompanyStatus')
const CompanyStatusShow=ref(false)
if(CompanyStatusData&&CompanyStatusData!=0){
  CompanyStatusShow.value=true
}
const userInfo = ref('')
const form = reactive({
  phone: '',
  phone_code: '',
})
const data = reactive({
  num: 61,
})

const gotoRouter = (val,entReg) => {
  let userInfoData=localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')):null
  if(entReg==1&&userInfoData==null){
    login()
  }else{
   router.push({ path: val })
  }
};

const login = () => {
  store.commit("updateInfo", true);
}
const loginSure = () => {
  if (!form.phone || !form.phone_code) {
    ElMessage.warning('请正确输入手机号和验证码')
    return
  }
  apiGetUserInfo(form).then(res => {
    if(res.code == 200){
      localStorage.setItem('token',res.data.token)
      ElMessage.success('登录成功！')
      form.phone = ''
      form.phone_code = ''
      store.commit("updateInfo", false);
      userget_user_info().then(res => {
        userInfo.value = res.data
        let userObj={
          nickname:userInfo.value.nickname,
          avatar:userInfo.value.avatar
        }
        store.commit("updateUserInfo", userObj);//用户头像
        store.commit("updateCompanyStatus",res.data.company_status);//商家是否认证成功
        localStorage.setItem('CompanyStatus',res.data.company_status)
        localStorage.setItem('userInfo', JSON.stringify(res.data))
        location.reload()
      })
    }else{
      ElMessage.warning(res.message)
    }
  })
}
const go = () => {
  localStorage.removeItem('leftactive')
  router.push({ path: '/order/orderHome' })
}
const getloginsmsFn = () => {

 
  if (!form.phone) { 
    ElMessage.warning('请先输入手机号')
    return
  }
  if (data.num == 61) {
    getloginsms({ phone: form.phone }).then(res => {
      if (res.code == 200) {
        ElMessage.success('发送成功！')
        data.num--;
        const timer = setInterval(() => {
          data.num--
          if (data.num == '-1') {
            data.num = '61'
            clearInterval(timer)
          }
        }, 1000);
      }else{ 
        ElMessage.warning(res.message)
      }
    })
  }
}
onBeforeMount(() => {
  userInfo.value = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
  if(userInfo.value!=null){
    let userObj={
          nickname:userInfo.value.nickname,
          avatar:userInfo.value.avatar
        }
    store.commit("updateUserInfo", userObj);//用户头像
  }
  
 
})

// 退出登录
const confirmLogin = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('userInfo')
  localStorage.removeItem('CompanyStatus')
  localStorage.removeItem('findBusine')
  CompanyStatusShow.value=false
  userInfo.value='';
  store.commit("updateCompanyStatus",0);//商家是否认证成功
  let userObj={ nickname:'', avatar:''}
  store.commit("updateUserInfo", userObj);//用户头像
  router.push({ path: '/' })
}
</script>
<template>
 <div class="navTop">
  <div class="nBox"><div class="NavbarBox">
    <div class="loginBox">
      <div class="headImgbox ">
        <div v-if="userInfo">
          <el-popconfirm width="220" confirm-button-text="确认退出" @confirm="confirmLogin" confirm-button-type="danger"
            cancel-button-text="取消" icon-color="#626AEF" title="你确定要退出登录吗?">
            <template #reference>
              <div class="flex cur">
                <img :src="store.state.userInfo.avatar" alt="" style="object-fit:cover"/>
                <span class="hovClo text">{{ store.state.userInfo.nickname }}</span>
              </div>
            </template>
          </el-popconfirm>
        </div>
        <div class="textBox" v-else>
          <div class="headImg"></div>
          <span class="text cur" @click="login">登录</span>
          <!-- <el-divider direction="vertical" />
        <span class="text cur">注册</span> -->
        </div>
      </div>
    </div>
    <div class="routerBox">
      <span class="cur" @click="gotoRouter('/')">商城首页</span>
      <span class="cur" @click="go">个人中心</span>
      <span class="cur" @click="gotoRouter('/cart')">购物车</span>
      <span class="cur" @click="gotoRouter('/collect')">收藏夹</span>
    </div>
  </div></div>
    <div class="CertificationB">
      <div class="CertificationNow" v-if="store.state.company_status==0&&CompanyStatusShow==false">
      <div class="CertificationTip">点击<span class="tiphref cur" @click="gotoRouter('/enterpriseReg',1)">立即认证</span>可查看商品价格</div>
      </div>
    </div>
 </div>
<!-- <div class="navCreate"></div> -->
  <el-dialog class="diago" width="650px" v-model="store.state.showlogin" style="font-size: 28px;">
    <template #header="{ titleId, titleClass }">
      <div class="my-header">
        <div class="title" :id="titleId" :class="titleClass">欢迎登录奈特喜</div>
        <div class="tips">未注册用户将自动创建账号</div>
      </div>
    </template>

    <div class="inputPhone">
      <span>+86</span>
      <el-divider style="height:36px;margin: 0;margin-right: 20px;" direction="vertical" />
      <input v-model="form.phone" type="text" placeholder="请输入手机号" style="outline: none;" />
    </div>
    <div class="inputPhoneCode">
      <input v-model="form.phone_code" type="text" placeholder="请输入验证码" style="outline: none;" />
      <span @click="getloginsmsFn" class="cur">{{ data.num == 61 ? '发送验证码' : data.num + 's' }}</span>
    </div>
    <div class="btn" @click="loginSure">登录</div>

    <!-- <div class="qita">其他登录方式</div>

    <div class="wechatLogin"><img  style="object-fit:cover" src="../assets/Image/5494c0fa4c8d21450ef7357d0929a5d8.jpeg" alt=""> <img
        src="../assets/Image/5494c0fa4c8d21450ef7357d0929a5d8.jpeg" alt=""></div> -->

    <!-- <input class="inputPhoneCode" v-model="form.phone_code" type="text" placeholder="请输入验证码" /> -->


  </el-dialog>
</template>

<style scoped lang="less">
// .navCreate{
//   height: 54px;
//   position: relative;
// }
// .navTop{
//     position: fixed;
//     width: 1920px;
//     top: 0;
//     left: 0;
//     bottom: 0;
//     right: 0;
//     margin: 0 auto;
//     z-index:120;
// }
.navTop{
  width:100%;

}
.nBox{
  background: #f5f5f5;
}
.text:hover {
  color: #25459C
}

.NavbarBox {
  width:1472px;
  margin: 0 auto;
  height: 54px;
  line-height: 54px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;

  .routerBox {
    span {
      margin-right: 40px;
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }

    span:hover {
      color: #25459C
    }
  }

  .loginBox {
    display: flex;
    align-items: center;

    .textBox {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 54px;

      .line {
        border-right: 1px #707070 solid;
        height: 16px !important;
        box-sizing: border-box;
        margin: 0 4px;
      }
    }

    .headImgbox {
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
      }

      .headImg {
        width: 40px;
        height: 40px;
        background: #ebebeb;
        border-radius: 50%;
        margin-right: 12px;


      }
    }

  }
}
.CertificationB{
  background: rgba(255,192,71,0.13);
}
.CertificationNow{
  width: 1472px;
  height:54px;
  line-height: 54px;
  margin: 0 auto;
  .CertificationTip{
    font-size:20px;
    color: #666666;
  }
  .tiphref{
    color: #B97C05;
    text-decoration:underline;
    padding: 0 10px;
  }
}
.diago {
  /deep/.el-dialog__body {
    padding: 0 !important;
  }
}

.my-header {
  padding-top: 20px;
  margin-bottom: 13px;
  box-sizing: border-box;

  .title {
    font-size: 32px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    text-align: center;
    margin-bottom: 20px;
  }

  .tips {
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    text-align: center;
  }
}

.inputPhone {
  width: calc(100% - 120px);
  height: 64px;
  margin: 0 auto;
  margin-bottom: 18px;
  box-sizing: border-box;
  border-bottom: 1px solid #CCCCCC;
  display: flex;
  align-items: center;

  input {
    border: none;
    font-size: 20px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    flex: 1;
    padding-right: 10px;
    box-sizing: border-box;
  }

  span {
    width: 94px;
    display: inline-block;
    line-height: 64px;
    text-align: center;
    font-size: 20px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
  }

}

.inputPhoneCode {
  width: calc(100% - 120px);
  height: 64px;
  margin: 0 auto;
  margin-bottom: 18px;
  box-sizing: border-box;
  border-bottom: 1px solid #CCCCCC;
  display: flex;
  justify-content: space-between;

  input {
    border: none;
    font-size: 20px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    flex: 1;
    padding-right: 10px;
    box-sizing: border-box;
  }

  span {
    display: inline-block;
    line-height: 64px;
    text-align: center;
    font-size: 20px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #25459C;
    width: 100px;
  }

}

.btn {
  width: 490px;
  height: 60px;
  background: #25459C;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #CCCCCC;
  line-height: 60px;
  text-align: center;
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  margin: 0 auto;
  margin-top: 60px;
  margin: 60px;
}

.qita {
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  text-align: center;
  margin-bottom: 34px;
}

.wechatLogin {
  display: flex;
  width: 200px;
  margin: 0 auto;

  img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin: 0 40px;
  }

  img:nth-child(1) {
    margin-left: 0;
  }

  img:nth-child(2) {
    margin-right: 0;
  }

}

</style>
