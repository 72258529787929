<script setup>
// import {  Plus } from '@element-plus/icons-vue'
import { defineProps, defineEmits, onBeforeMount } from 'vue'
import { Plus } from '@element-plus/icons-vue'
import { ref, } from 'vue'
import { ElMessage } from 'element-plus'
const props = defineProps({
    limit: {
        default: 1
    },
    fileList: {
        default: []
    },
    index:{
        default:null  //如果有循环此组件 （评价页面）
    },
    enterpriseShow:{
        default:true 
    },
    avatar:{
        default:false
    }
})
let httpRequestImg = ref(false)

onBeforeMount(() => {
    httpRequestImg.value = props.limit == 1 && JSON.parse(JSON.stringify(props.fileList)).length == 1
})

//文件上传  
const dialogImageUrl = ref('')
const dialogVisible = ref(false)
// 图片上传成功
const emit = defineEmits(['handleRemove', 'handleSuccessed'])
const handleSuccessed = (response, files) => {
    if (response.code == 200) {
        console.log(response.data.file_path, files)
        if (props.limit == 1) {
            httpRequestImg.value = true
        }
        emit('handleSuccessed', response.data.file_path, props.limit == 1,props.index)
    }
}
// // 删除图片
const handleRemove = (file) => {
    httpRequestImg.value = false
    emit('handleRemove', file,props.index)
}

// 查看图片
const handlePictureCardPreview = (file) => {
    dialogImageUrl.value = file.url
    dialogVisible.value = true
}
// 限制图片上传数量
const handleExceed = () => {
    ElMessage({
        message: `最多选择${props.limit}张图片`,
        type: 'warning',
    })
}
const handleimageChange = () => {
    if (props.limit == 1) {
        httpRequestImg.value = true
    }
}
</script>
<template>
    <!-- https://netsea.aucfan-cn.com/pc/ -->
    <!-- https://api.aucfan-cn.com/pc/ -->
    <el-upload :class="[{ 'demo-httpRequestImg': httpRequestImg },props.avatar?'touxiang':'']" class="avatar-uploader" 
        action="https://netsea.aucfan-cn.com/pc/com/file" :on-remove="handleRemove" accept=".jpg,.png" :on-preview="handlePictureCardPreview"
        :on-change="handleimageChange" :on-exceed="handleExceed" list-type="picture-card" :auto-upload="true"
        :limit=props.limit :file-list="props.fileList" :on-success='handleSuccessed' ref="pictureUpload">
        <div class="center">
                <!-- <img v-if="enterpriseShow==false"   src="../assets/Group33508.png" alt=""  /> -->
            <div  v-if="enterpriseShow==false" class="enterbeian">
                <img   src="../assets/Group108.png" alt=""  />
                <div style="margin-top: 10px;color: #B9B9B9;" class="fontS14">点击上传图片</div>
            </div>
           <div v-else>
            <el-icon> 
                <Plus />
            </el-icon>
            <div class="marT2">{{ props.limit > 1 ? '上传图片' : '上传图片' }}</div>
           </div>
        </div>  
    </el-upload>
    <el-dialog v-model="dialogVisible" width="30%">
        <img w-full :src="dialogImageUrl" alt="Preview Image" style="object-fit:cover;width: 100%;height: 100%;" />
    </el-dialog>
</template>
<style lang="less" scoped>
.demo-httpRequestImg {
    /deep/ .el-upload--picture-card {
        display: none;
    }
}
.enterbeian{
width: 236px;
height: 160px;
border: 1px dashed #25459C;
border-radius: 4px;
display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
</style>
