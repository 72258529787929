<script setup>
import { ref, reactive, onBeforeMount } from 'vue'
import { ElMessage } from 'element-plus'
import { get_follow, store_follow } from '@/utils/api'
import { useRouter } from "vue-router";
// 

const router = useRouter();
const data = reactive({
    list: [],
    page: 1,
    last_page: 3,
    delItem: {},
    show: false,
});

const active = ref(1)  //1点铺  3工厂
const centerDialogVisible = ref(false)  //1点铺  3工厂
const loading = ref(false)
onBeforeMount(() => {
    getList()
})
const load = () => {
    if (data.last_page > data.page) {
        data.page++;
        getList()
    }
}
const getList = (val) => {
    if (val) {
        loading.value=true
        active.value = val;
        data.page = 1;
    }
    get_follow({ type: active.value == 1 ? 1 : 3, page: data.page, limit: 3 }).then(res => {
        loading.value=false
        if (res.code == 200) {
            data.show=true;
            data.last_page = res.data.last_page;
            if (data.page == 1) {
                data.list = res.data.data
            } else {
                data.list = data.list.concat(res.data.data)
            }
           
        }
    })
}
const sureDel = () => {
    store_follow({ id: String(data.delItem.id), type: active.value == 1 ? 1 : 3, is_batch: 0 }).then(res => {
        centerDialogVisible.value = false;
        if (res.code == 200) {
            ElMessage.success('取消成功')
            data.page = 1;
            data.list = []
            getList()
        }
    })
}


</script>

<template>
    <el-dialog v-model="centerDialogVisible" title="" width="30%" center align-center :destroy-on-close="true" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
        <span>
            确认要取消关注吗？
        </span>
        <template #footer>
            <span class="dialog-footer">
                <el-button type="danger" @click="sureDel" color="#25459C">
                    确认
                </el-button>
                <button  @click="centerDialogVisible = false" class="btnClose delBtn"> 取消</button>
            </span>
        </template>
    </el-dialog>
    <div class="topHomeBox cof">
        <div class="wrapCenter flex">
            <img src="../../assets/logo1.png" alt="" class="logo" style="object-fit:cover">
            <div class="homeText fontS20 cur">关注门店</div>

        </div>
    </div>

    <div class="wrapCenter" style="margin-top: 20px;">
        <div class="">
            <div v-if="data.show">
                <div class="navTag co6 fontS20 flex">
                    <span @click="getList(1)" :class="active == 1 ? 'activetag cur' : 'cur'">旗舰店</span>
                    <span @click="getList(2);" :class="active == 2 ? 'activetag cur' : 'cur'">批发商</span>
                </div>
                <div class="printLogFather" infinite-scroll-distance="30px" v-infinite-scroll="load"
                    style="height:76.9vh;overflow: auto;" v-if="active == 1" v-loading="loading">
                    <!-- 店铺 -->
                    <el-empty description="没有数据" v-if="data.list.length==0"/>
                    <div v-for="item in 1" :key="item">
                        <div class="store flexJBC" v-for="item in data.list" :key="item.id">
                            <div class="left" style="width:30%;" @click="router.push({path:'/store',query:{id:item.id}})">
                                <div class="flex">
                                    <img :src="item.store_image" alt="" class="storeImg" style="object-fit:cover"/>
                                    <div class="co6 fontS22">
                                        <div class="flexJBC">
                                            <div style="width:230px;" class="elipes1">{{ item.stroe_name }}</div>
                                            <div class="gunzhuBox co9 fontS16 center cur"
                                                @click="data.delItem = item; centerDialogVisible = true;">已关注</div>
                                        </div>
                                        <div class="flex" style="margin:13px 0px 20px ;">
                                            <el-rate v-model="item.postage_score" disabled />
                                            <span class="fontS20" style="color:#FFC047">{{ item.postage_score }}分</span>
                                        </div>
                                        <div class="co9 fontS16">已成交{{ item.sales }}</div>
                                    </div>
                                </div>
                                <div class="co9 fontS18 flex" style="margin-top:10px ;">
                                    主营：
                                    <el-space :size="5" spacer="|">
                                        <span v-for="(text, index) in item.main_business" :key="index">{{ text }}</span>
                                    </el-space>
                                </div>
                            </div>
                            <div class="right flex">
                                <div v-for="(item, index) in item.product" class="" :key="item.id" @click="router.push({path:'/goodsDetail',query:{id:item.id}})">
                                    <div v-if="index <= 3" class="right_li">
                                        <img :src="item.image" alt="" style="object-fit:cover">
                                        <div class="priceBOx fontS20 cof">￥{{ item.price }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- 工厂 -->
                <div class="printLogFather" v-loading="loading" infinite-scroll-distance="30px" v-infinite-scroll="load"
                    style="height:76.9vh;overflow: auto;" v-if="active == 2">
                    <el-empty description="没有数据" v-if="data.list.length==0"/>
                    <div class="store flexJBC factory" v-for="item in data.list" :key="item.id" >
                        <div class="left" style="width:30%;" @click="router.push({path:'/store',query:{id:item.id}})">
                            <div class="flex" style="align-items:flex-start">
                                <img :src="item.store_image" alt="" class="storeImg" style="object-fit:cover"/>
                                <div class="co6 fontS22">
                                    <div class="flexJBC">
                                        <div style="width:230px;" class="elipes1">{{ item.stroe_name }}</div>
                                        <div class="gunzhuBox co9 fontS16 center cur"
                                            @click="data.delItem = item; centerDialogVisible = true;">已关注</div>
                                    </div>
                                    <div class="co9 fontS18" style="margin:10px 0">
                                        <el-space :size="5" spacer="|">
                                            <span v-for="(text, index) in item.main_business" :key="index">{{ text
}}</span>
                                        </el-space>
                                    </div>
                                    <div class="flex co6 fontS16">
                                        <span :style="{ background: index % 2 == 0 ? '#FFF2D0' : '#FFDED3' }"
                                            style="padding:6px 18px;margin-right: 10px;"
                                            v-for="(item1, index1) in item.tags" :key="index1">{{ item1 }}</span>
                                    </div>
                                    <div class="co9 fontS18 elipes2" style="margin:10px 0;line-height: 25px;">
                                        {{ item.store_info ? item.store_info : '暂无门店简介' }}</div>
                                    <div class="co9 fontS18">已成交{{ item.turnovers }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="right flex">
                            <div v-for="(item, index) in item.product" class="" :key="item.id" @click="router.push({path:'/goodsDetail',query:{id:item.id}})">
                                <div v-if="index <= 3" class="right_li">
                                    <img :src="item.image" alt="" style="object-fit:cover">
                                    <div class="priceBOx fontS20 cof">￥{{ item.price }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                

            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.gunzhuBox {
    width: 68px;
    height: 32px;
    border-radius: 36px;
    border: 1px solid #CCCCCC;
    line-height: 32px;
}

.topHomeBox {
    height: 100px;
    background: #25459C;
    display: flex;
    justify-content: center;
    align-items: center;

    .homeText {
        margin-left: 37px;
        margin-right: 80px;
        margin-top: 32px;
    }

    .active {
        line-height: 50px;
        border-bottom: 4px solid #fff;
    }

    .logo {
        // width: 140px;
        height: 62px;
        // margin-right: 32px;
        // margin: 19px 0;
    }
}

.store {
    .right {
        justify-content: flex-end;

        .right_li {
            margin-left: 20px;

            .priceBOx {
                position: absolute;
                // width: 80px;
                height: 32px;
                line-height: 32px;
                background: rgba(0, 0, 0, 0.6);
                bottom: 0;
                right: 0;
            }

            position: relative;

            img {
                width: 200px;
                height: 200px;
                background: #FAFAFA;
                opacity: 1;
                border: 1px solid #CCCCCC;
            }
        }
    }

    .left {
        .storeImg {
            width: 120px;
            height: 120px;
            background: #F2F2F2;
            margin-right: 20px;
        }
    }

    align-items: flex-start;
    width: 1472px;
    padding: 40px 0;
    // box-sizing: border-box;
    border-bottom: 1px solid #CCCCCC;
}

.list {

    flex-wrap: wrap;
    padding: 0 40px;
    box-sizing: border-box;
    justify-content: flex-start;

    .li {
        width: 336px;
        position: relative;

        .title {
            margin: 20px 0 16px;
            line-height: 28px;
        }

        margin-bottom: 40px;
        margin-right: 16px;

        img {
            width: 336px;
            height: 339px;
            display: block;
        }
    }
}

.paixuBox {
    .flitetBox {
        width: 390px;
        height: 352px;
        background: #FFFFFF;
        box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.12);
        position: absolute;
        padding: 20px 10px;
        box-sizing: border-box;
        top: 60px;
        left: 0;
        z-index: 9;

        .li {
            width: 110px;
            text-align: center;
            line-height: 40px;
            height: 40px;
            background: #F5F5F5;
            font-size: 16px;
            color: #666;
            margin-top: 14px;
            ;

        }
    }

    .priceBox {
        margin-left: 79px;

        /deep/.el-input-group__append,
        /deep/.el-input-group__prepend,
        /deep/.el-input__wrapper {
            background-color: #fff;
            box-shadow: none;
        }

        /deep/.el-input-group__prepend {
            padding: 0;
        }

    }

    width: 1472px;
    height: 58px;
    // line-height: 58px;
    background: #F5F5F5;
    border: 1px solid #CCCCCC;
    margin: 20px 0;

    .active {
        color: #25459C;
        background: #FFFFFF;
        border: 1px solid #CCCCCC;
    }

    .li {
        width: 140px;
        align-items: center;
        height: 58px;
        position: relative;

    }
}

.navTag {
    margin-top: 20px;
    // width: 100%;
    width: 1472px;
    border-bottom: 2px solid #25459C;

    .activetag {
        background: #25459C;
        color: #fff;
    }

    span {
        display: block;
        width: 140px;
        height: 48px;
        line-height: 48px;
        text-align: center;
    }
}
</style>
