<template>
    <el-dialog
      v-model="getBusinessShow"
      width="880"
      align-center
      @close="closeDialog"
      :close-on-click-modal="false"
      title="单位基本情况表"
    >
      <div class="flex">
        <div style="margin-right:20px">单位基本情况表:</div> <div>新建</div>
        <!-- <div>
          <el-radio-group v-model="formRecord.data.type">
            <el-radio :label="1">单位基本情况新建表</el-radio>
            <el-radio :label="2">单位情况变更表</el-radio>
            <el-radio :label="3">单位基本情况表停用</el-radio>
          </el-radio-group>
        </div> -->
      </div>
      <el-form :model="formRecord.data" class="m-t20">
        <div class="flexJBC">
          <div>
            组织机构名称：
            <el-form-item prop="custName" class="m-t12">
              <el-input
                v-model="formRecord.data.custName"
                autocomplete="off"
                style="width:390px;"
                placeholder="请填写"
              />
            </el-form-item>
          </div>
          <div>
            组织机构代码：
            <el-form-item prop="custCode" class="m-t12">
              <el-input
                v-model="formRecord.data.custCode"
                autocomplete="off"
                style="width: 390px"
                placeholder="统一信用代码代码倒数第二位开始往前数第9位数"
              />
            </el-form-item>
          </div>
        </div>
        <div class="flexJBC">
          <div>
            组织机构住所：
            <el-form-item prop="city_addr" class="m-t12">
              <el-select
              style="width: 390px"
               v-model="formRecord.data.city_addr"
                filterable
                remote
                placeholder="请选择"
                :remote-method="getCity"
                :loading="loading"
                @visible-change="visibleChage"
                :automatic-dropdown="true"
                class="selectIcon"
              >
                  <el-option
                    v-for="item in CityOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                  <el-pagination v-model:current-page="cityData.page" :page-size="cityData.limit"   layout="prev, pager, next" :total="cityTotal"  @current-change="handleCurrentChange"/>
              </el-select>
            </el-form-item>
          </div>
          <div>
            常驻国家(地区)名称及代码：
            <el-form-item prop="countries_id" class="m-t12">
              <el-select
              style="width: 390px"
              v-model="formRecord.data.countries_id"
                filterable
                remote
                reserve-keyword
                placeholder="请选择"
                :remote-method="chiesCity"
                :loading="loading"
                @visible-change="visibleChageChies"
                :automatic-dropdown="true"
                class="selectIcon"
              >
                  <el-option
                    v-for="item in chiesCityOptions"
                    :key="item.id"
                    :label="`${item.short_name},${item.number_code}`"
                    :value="item.id"
                  />
                  <el-pagination v-model:current-page="chiesData.page" :page-size="chiesData.limit"   layout="prev, pager, next" :total="chiesCityTotal"  @current-change="handleCurrentchies"/>
              </el-select>
            </el-form-item>
          </div>
        </div>
        <div class="flexJBC">
          <div>
            外方投资者国别(地区)名称及代码：0/5
            <el-form-item prop="foreign_country_codetxt" class="m-t12">
              <el-select
              style="width: 390px"
              v-model="formRecord.data.foreign_country_codetxt"
                filterable
                multiple
                remote
                :multiple-limit="5"
                :automatic-dropdown="true"
                placeholder="请选择"
                :remote-method="payCity"
                :loading="loading"
                @visible-change="visibleChagepay"
                class="selectIcon"
              >
                  <el-option
                    v-for="item in payCityOptions"
                    :key="item.id"
                    :label="`${item.short_name},${item.number_code}`"
                    :value="item.id"
                  />
                  <el-pagination v-model:current-page="payData.page" :page-size="payData.limit"   layout="prev, pager, next" :total="payCityTotal"  @current-change="handleCurrentpay"/>
              </el-select>
            </el-form-item>
          </div>
          <div>
            经济类型代码：
            <el-form-item prop="economy_id" class="m-t12">
              <el-select
              style="width: 390px"
              v-model="formRecord.data.economy_id"
                filterable
                remote
                placeholder="请选择"
                :automatic-dropdown="true"
                :remote-method="YeepayEconomyCode"
                :loading="loading"
                @visible-change="visibleChageEconomy"
                class="selectIcon"
              >
                  <el-option
                    v-for="item in EconomyCodeOptions"
                    :key="item.id"
                    :label="`${item.type_name},${item.code}`"
                    :value="item.id"
                  />
                  <el-pagination v-model:current-page="economyData.page" :page-size="economyData.limit"   layout="prev, pager, next" :total="economyTotal"  @current-change="handleCurrentEconomy"/>

              </el-select>
            </el-form-item>
          </div>
        </div>
        <div class="flexJBC">
          <div>
            行业属性代码：
            <el-form-item prop="industry_id" class="m-t12">
              <el-select
              style="width: 390px"
                v-model="formRecord.data.industry_id"
                filterable
                remote
                placeholder="请选择"
                :remote-method="IndustryCode"
                :loading="loading"
                @visible-change="visibleChageIndustry"
                :automatic-dropdown="true"
                class="selectIcon"
              >
                  <el-option
                    v-for="item in IndustryOptions"
                    :key="item.id"
                    :label="`${item.title},${item.code}`"
                    :value="item.id"
                  />
                  <el-pagination v-model:current-page="IndustryData.page" :page-size="IndustryData.limit"   layout="prev, pager, next" :total="IndustryTotal"  @current-change="handleCurrentIndustry"/>
              </el-select>
            </el-form-item>
          </div>
          <div>
            特殊经济区企业：
            <el-form-item prop="isTaxFree" class="m-t12">
              <el-select
              style="width: 390px"
              v-model="formRecord.data.isTaxFree"
                placeholder="请选择"
              >
                  <el-option label="是" value="Y" />
                  <el-option  label="否" value="N" />
              </el-select>
            </el-form-item>
          </div>
        </div>
        <div class="flexJBC">
          <div>
            企业类型：
            <el-form-item prop="special_id" class="m-t12">
              <el-select
              style="width: 390px"
              v-model="formRecord.data.special_id"
                filterable
                remote
                placeholder="请选择"
                :remote-method="YeepaySpecialCode"
                :loading="loading"
                @visible-change="visibleChageSpecial"
                :automatic-dropdown="true"
                class="selectIcon"
              >
                  <el-option
                    v-for="item in specialCodeOptions"
                    :key="item.id"
                    :label="item.title"
                    :value="item.id"
                  />
              <el-pagination v-model:current-page="specialData.page" :page-size="specialData.limit"   layout="prev, pager, next" :total="specialTotal"  @current-change="handleCurrentSpecial"/>
              </el-select>
            </el-form-item>
          </div>
          <div>
            所属外汇局代码：
            <el-form-item prop="foreign_exchange_code" class="m-t12">
              <el-input
                v-model="formRecord.data.foreign_exchange_code"
                autocomplete="off"
                style="width: 390px"
                placeholder="请填写"
              />
            </el-form-item>
          </div>
        </div>
        <div class="flexJBC">
          <div>
            申报方式：
            <el-form-item prop="method" class="m-t12">
              <el-select
              style="width: 390px"
              v-model="formRecord.data.method"
                placeholder="请选择"
              >
                  <el-option  label="纸质或电子单据" :value="1" />
                  <el-option  label="开通网上申报" :value="2" />
                  <el-option  label="关闭网上申报" :value="3" />
              </el-select>
            </el-form-item>
          </div>
          <div>
            机构地址：
            <el-form-item prop="custAddr" class="m-t12">
              <el-input
                v-model="formRecord.data.custAddr"
                autocomplete="off"
                style="width: 390px"
                placeholder="请填写"
              />
            </el-form-item>
          </div>
        </div>
        <div class="flexJBC">
          <div>
            邮政编码：
            <el-form-item prop="postCode" class="m-t12">
              <el-input
                v-model="formRecord.data.postCode"
                autocomplete="off"
                style="width: 390px"
                placeholder="请填写"
              />
            </el-form-item>
          </div>
          <div>
            联系用的Email：
            <el-form-item prop="email" class="m-t12">
              <el-input
                v-model="formRecord.data.email"
                autocomplete="off"
                style="width: 390px"
                placeholder="请填写"
              />
            </el-form-item>
          </div>
        </div>
        <div class="flexJBC">
          <div>
            经办行名称：
            <el-form-item prop="bank_name" class="m-t12">
              <el-input
                v-model="formRecord.data.bank_name "
                autocomplete="off"
                style="width: 390px"
                placeholder="请填写"
              />
            </el-form-item>
          </div>
          <div>
            机构联系人：
            <el-form-item prop="contact" class="m-t12">
              <el-input
                v-model="formRecord.data.contact"
                autocomplete="off"
                style="width: 390px"
                placeholder="请填写"
              />
            </el-form-item>
          </div>
        </div>
        <div class="flexJBC">
          
          <div>
            机构联系人电话：
            <el-form-item prop="tel" class="m-t12">
              <el-input
                v-model="formRecord.data.tel"
                autocomplete="off"
                style="width: 390px"
                placeholder="请填写"
              />
            </el-form-item>
          </div>
          <div>
            机构传真号码：
            <el-form-item prop="facsimile" class="m-t12">
              <el-input
                v-model="formRecord.data.facsimile"
                autocomplete="off"
                style="width: 390px"
                placeholder="请填写"
              />
            </el-form-item>
          </div>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="primary"
            color="#25459C"
            class="dialogBtn"
            @click="Staging"
          >
            暂存
          </el-button>
          <el-button @click="sbumintShen"  type="primary"  color="#25459C" class="dialogBtn">提交审核</el-button>
        </span>
      </template>
    </el-dialog>
</template>

<script setup>
import { reactive,ref,defineProps,computed,defineEmits,onMounted,defineExpose} from "vue";
import { ElMessage} from 'element-plus'
import {getYeepayCity,getYeepayCountriesCity,editBusinessRecord,getYeepayEconomyCode,getYeepayIndustryCode,getYeepaySpecialCode,addBusinessRecord,findBusinessRecordBringInData} from "@/utils/api";
const props=defineProps({
  businesShow:{
          default:false
    },
})
const emit = defineEmits(['update:businesShow','parentUserinfo','findBa'])//'handleRemove', 
const getBusinessShow=computed({
          get:()=>props.businesShow,
          set:(val)=> {
          // console.log('jinru',val)
          emit('update:businesShow',val)
        }
      })
      const loading = ref(false)
// 提交
const sbumintShen=()=>{
  formRecord.data.foreign_country_code=formRecord.data.foreign_country_codetxt.toString()
  console.log(formRecord.data,'formRecord.data')
  
 if(isrecord.value!=1){
  addBusinessRecord(formRecord.data).then(res=>{
    if(res.code==200){
      emit('update:businesShow',false)
      emit('parentUserinfo')
      ElMessage.success('添加成功')
      emit('parentUserinfo')
      emit('findBa')
      localStorage.removeItem('findBusine')
    }else{
      ElMessage.warning(res.message)
    }
  })
 }else{
  editBusinessRecord(formRecord.data).then(res=>{
    if(res.code==200){
      emit('update:businesShow',false)
      ElMessage.success('修改成功')
      emit('parentUserinfo')
     emit('findBa')
    localStorage.removeItem('findBusine')
    }else{
      ElMessage.warning(res.message)
    }
  })
 }
}
// 获取添加备案可以带入的参数
const getfindBusiness=()=>{
  findBusinessRecordBringInData({}).then(res=>{
    if(res.code==200){
      formRecord.data.custCode=res.data.companyCode// 组织机构代码
      formRecord.data.custName=res.data.company_name//织机构名称
      formRecord.data.contact=res.data.company_legal
      formRecord.data.custAddr=res.data.company_addr
      console.log(res,'res')
    }
  })
}
// 暂存
const Staging=()=>{
    localStorage.setItem('findBusine',JSON.stringify(formRecord.data))
  emit('update:businesShow',false)
}
const closeDialog=()=>{
  localStorage.setItem('findBusine',JSON.stringify(formRecord.data))
  emit('update:businesShow',false)
}

// 添加备案
const formRecord=reactive({
data:{
  type:1, //1:单位基本情况新建表2:单位情况变更表3:单位基本情况表停用
  custCode:'',// 组织机构代码
  custName:'',//织机构名称
  city_addr:'',// 营业场所代码
  countries_id:'',//常驻国家代码
  foreign_country_codetxt:[],//外方投资者国别（地区）名称及代码
  foreign_country_code:'',//外方投资者国别（地区）名称及代码
  economy_id:'', //经济类型代码必须是经济类型代码表中存在的最细分类的记录
  industry_id:'', //行业属性代码
  isTaxFree:'', //N-非特殊经济区内企业Y-特殊经济区内企业 是否特殊经济区内企业
  foreign_exchange_code :'', //所属外汇局代码
  special_id:'',//企业类型-特殊经济类型代码
  method:'',//申报方式1:纸质或电子单据,2:开通网上申报,3:关闭网上申报 1
  custAddr:'',  //单位地址
  postCode:'', //邮政编码
  email:'',//联系邮箱
  remark:'', //备注
  contact:'',//单位联系人
  tel:'', //联系电话
  facsimile :'',//传真
  bank_name:'',//经办行名称
},

})
// 机构住所
const CityOptions=ref([])
const cityTotal=ref(0)
const cityData=reactive({
    keyword:'',
    page:1,
    limit:10,
    id:0
})
const getCity=(query)=>{
  cityData.keyword=query
  cityData.id=0
  getYeepayCityModel(cityData)
}
const getYeepayCityModel=(param)=>{
  getYeepayCity(param).then(res=>{
    if(res.code==200){
      cityTotal.value=res.data.total
      CityOptions.value=res.data.data
      console.log(res,'res')
    }
  })
}
// 下拉框隐藏
const visibleChage=(val)=>{
  console.log(val,'val')
  if(!val){
    cityData.page=1
  }else{
    if(cityData.page==1){
      cityData.id=0
      getYeepayCityModel(cityData)
    }
  }
}
// 分页
const handleCurrentChange = (val) => {
    cityData.page = val
    getYeepayCityModel(cityData)
}
// ======分隔=========
// 行业所属代码
const IndustryOptions=ref([])
const IndustryTotal=ref(0)
const  IndustryData=reactive({
   keyword:'',
    page:1,
    limit:10,
    id:0
})
const IndustryCode=(query)=>{
  IndustryData.keyword=query
  IndustryData.id=0
  getYeepayIndustryCodeModel(IndustryData)
}
const getYeepayIndustryCodeModel=(param)=>{
  getYeepayIndustryCode(param).then(res=>{
    if(res.code==200){
      IndustryTotal.value=res.data.total
      IndustryOptions.value=res.data.data
    }
  })
}
const visibleChageIndustry=(val)=>{
  if(!val){
    IndustryData.page=1
  }else{
    if(IndustryData.page==1){
      IndustryData.id=0
      getYeepayIndustryCodeModel(IndustryData)
    }
    
  }
}
// 分页
const handleCurrentIndustry = (val) => {
  IndustryData.page=val
  getYeepayIndustryCodeModel(IndustryData)
}
// 经济类型
const EconomyCodeOptions=ref([])
const economyTotal=ref(0)
const economyData=reactive({
    keyword:'',
    page:1,
    limit:10,
    id:0
})
const YeepayEconomyCode=(query)=>{
  economyData.keyword=query
  economyData.id=0
  getYeepayEconomyCodeModel(economyData)
}
const getYeepayEconomyCodeModel=(param)=>{
  getYeepayEconomyCode(param).then(res=>{
    if(res.code==200){
      economyTotal.value=res.data.total
      EconomyCodeOptions.value=res.data.data
    }
  })
}
const visibleChageEconomy=(val)=>{
  if(!val){
    economyData.page=1
  }else{
    if( economyData.page==1){
      economyData.id=0
      getYeepayEconomyCodeModel(economyData)
    }
  }
}
// 分页
const handleCurrentEconomy = (val) => {
  economyData.page=val
  getYeepayEconomyCodeModel(economyData)
}
// 特殊经济类型
const specialCodeOptions=ref([])
const specialTotal=ref(0)
const specialData=reactive({
    keyword:'',
    page:1,
    limit:10,
    id:0
})
const YeepaySpecialCode=(query)=>{
  specialData.id=0
  specialData.keyword=query
  getYeepaySpecialCodeModel(specialData)
}
const getYeepaySpecialCodeModel=(param)=>{
  getYeepaySpecialCode(param).then(res=>{
    if(res.code==200){
      specialTotal.value=res.data.total
      specialCodeOptions.value=res.data.data
    }
  })
}
const visibleChageSpecial=(val)=>{
  if(!val){
    specialData.page=1
  }else{
    if( specialData.page==1){
      specialData.id=0
      getYeepaySpecialCodeModel(specialData)
    }
  }
}
// 分页
const handleCurrentSpecial = (val) => {
  specialData.page=val
  getYeepaySpecialCodeModel(specialData)
}
// 获取国家代码
const payCityOptions=ref([])
const payCityTotal=ref(0)
const  payData=reactive({
    keyword:'',
    page:1,
    limit:10,
    id:0
})
const payCity=(query)=>{
  payData.id=0
  payData.keyword=query
  getpayModel(payData)
}
const getpayModel=(param)=>{
  getYeepayCountriesCity(param).then(res=>{
    if(res.code==200){
      payCityTotal.value=res.data.total
      payCityOptions.value=res.data.data
    }
  })
}
const visibleChagepay=(val)=>{
  if(!val){
    payData.page=1
  }else{
    if(payData.page==1){
      payData.id=0
      getpayModel(payData)
    }
  }
}
// 分页
const handleCurrentpay = (val) => {
  payData.page=val
  getpayModel(payData)
}
// =========
// 获取本地国家代码
const chiesCityOptions=ref([])
const chiesCityTotal=ref(0)
const chiesData=reactive({
    keyword:'',
    page:1,
    limit:10,
    id:0
})
const chiesCity=(query)=>{
  chiesData.keyword=query
  chiesData.id=0
  getYeepayCountriesCityModel(chiesData)
}
const getYeepayCountriesCityModel=(param)=>{
  getYeepayCountriesCity(param).then(res=>{
    if(res.code==200){
      chiesCityTotal.value=res.data.total
      chiesCityOptions.value=res.data.data
    }
  })
}
const visibleChageChies=(val)=>{
  if(!val){
    chiesData.page=1
  }else{
    if(chiesData.page==1){
      chiesData.id=0
      getYeepayCountriesCityModel(chiesData)
    }
  }
}
// 分页
const handleCurrentchies = (val) => {
  chiesData.page=val
  getYeepayCountriesCityModel(chiesData)
}
// 未提交
const isrecord=ref(0)
const show=()=>{
  let findBusine= JSON.parse(localStorage.getItem('findBusine'))
  if(findBusine!=undefined){
     formRecord.data=findBusine
  }
  if(formRecord.data.custName==''|| formRecord.data.custCode==''){
        getfindBusiness()
    }
  getPost()
}
// 备案失败
const showBusiness=(val)=>{
  console.log(val,'val')
  isrecord.value=1
  let findBusine= JSON.parse(localStorage.getItem('findBusine'))
  if(findBusine!=undefined&&findBusine.id!=undefined){
     formRecord.data=findBusine
  }else{
    formRecord.data.id=val.id
    formRecord.data.foreign_country_codetxt=[]
    formRecord.data.type=val.businessRecordDetails.type
    formRecord.data.custCode=val.custCode
    formRecord.data.custName=val.custName
    formRecord.data.city_addr=val.city_addr
    formRecord.data.countries_id=val.countries_id
    formRecord.data.economy_id=val.economy_id
    formRecord.data.industry_id=val.industry_id
    formRecord.data.isTaxFree=val.isTaxFree
    formRecord.data.foreign_exchange_code=val.businessRecordDetails.foreign_exchange_code
    formRecord.data.special_id=val.special_id
    formRecord.data.method=val.businessRecordDetails.method
    formRecord.data.custAddr=val.custAddr
    formRecord.data.postCode=val.postCode
    formRecord.data.email=val.businessRecordDetails.email
    formRecord.data.contact=val.contact
    formRecord.data.tel=val.tel
    formRecord.data.facsimile=val.businessRecordDetails.facsimile
    formRecord.data.bank_name=val.businessRecordDetails.bank_name
    val.foreign_country_code_data.forEach(item=>{
      formRecord.data.foreign_country_codetxt.push(item.id)
    })
    formRecord.data.foreign_country_code=formRecord.data.foreign_country_codetxt.toString()
  }
  getPost()
console.log( formRecord.data,' formRecord.data')
}
const getPost=()=>{
  cityData.id=formRecord.data.city_addr
  IndustryData.id=formRecord.data.industry_id
  economyData.id=formRecord.data.economy_id
  chiesData.id=formRecord.data.countries_id
  payData.id=formRecord.data.foreign_country_codetxt.toString()
  specialData.id=formRecord.data.special_id
  getYeepaySpecialCodeModel(specialData)
  getYeepayCityModel(cityData)
  getYeepayIndustryCodeModel(IndustryData)
  getYeepayEconomyCodeModel(economyData)
  getpayModel(payData)
  getYeepayCountriesCityModel(chiesData)
}
defineExpose({
  show,
  showBusiness
});
</script>

<style lang="less" scoped>
.m-t20{
  margin-top: 20px;
}
.m-t12{
  margin-top:12px;
}
.selectIcon {
    /deep/.el-input__inner {  //如果你的style 加了scoped 需要加/deep/
      background: url('~@/assets/Frame8.png') no-repeat; //引入icon
      background-size:24px 24px;  //这个是图片的大小，在这里不能直接设置width  height,设置宽高其实是select的宽高，图片可能会失真只设置宽度  高度auto也行
      background-position: 95% 3px; //在input中定位置  这两个参数是x  y坐标
      box-sizing: border-box;
      font-size: 14px;
    }
    /deep/.el-input__wrapper{
      background: url('~@/assets/icon.png') no-repeat; //引入icon
      background-size:24px 24px;  //这个是图片的大小，在这里不能直接设置width  height,设置宽高其实是select的宽高，图片可能会失真只设置宽度  高度auto也行
      background-position:98% 4px; //在input中定位置  这两个参数是x  y坐标
      box-sizing: border-box;
      font-size: 14px;
    }
   
  }

</style>