<template>
    <div>
        <li :class="backTopFlag ? 'active' : 'inactive'" @click="backTop">
        <div class="sidebarItem cur">
          <img src="@/assets/top.png" alt="" style="width:40px;height:40px;margin-bottom:3px;">
          <p class="fontS16">回到顶部</p>
        </div>
    </li>
    </div>
</template>

<script setup>
import { ref ,toRefs,onMounted,onUnmounted} from "vue";
 const backTopFlag = ref(false)//用来判断样式
 const backTop = () => {
      let top = document.documentElement.scrollTop//获取点击时页面的滚动条纵坐标位置
      console.log('123top',top)
      const timeTop = setInterval(() => {
        document.documentElement.scrollTop = top -= 50//一次减50往上滑动
        if (top <= 0) {
          clearInterval(timeTop)
        }
      }, 8)//定时调用函数使其更顺滑
    }
    const handleScroll = () => {
      if (document.documentElement.scrollTop > 20) {
        backTopFlag.value = true
      } else {
        backTopFlag.value = false
      }
      //往下滑超过20则显示 否则则不显示按钮
    }
 
    onMounted(() => {
      window.addEventListener('scroll', handleScroll)
    })//监听滚动事件
    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll)
    })//离开页面时移除监听事件
</script>

<style  scoped>
.sidebarItem{
    width:72px;
    height:72px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.side .active {
  animation: active 1s;
  -webkit-animation: active 1s;
  overflow: hidden;
}
@keyframes active {
  0% {
    height: 0px;
  }
 
  100% {
    height: 60px;
  }
}
.side .inactive {
  animation: inactive 1s;
  -webkit-animation: inactive 1s;
  animation-fill-mode: forwards;
  overflow: hidden;
}
 
@keyframes inactive {
  0% {
    height: 60px;
  }
 
  100% {
    height: 0px;
  }
}
</style>