import { createApp } from 'vue';
import App from './App.vue';
import './style.css'
import 'reset-css'
import store from "./store";
import 'element-plus/theme-chalk/index.css'

import ElementUI from 'element-plus'
import router from './router';

import { TUIComponents, TUICore, genTestUserSig } from './TUIKit';

const SDKAppID = 1400767345; // Your SDKAppID
const secretKey = '0884990c96d14f171ef8e73c43743274815b3fb40b69d91848d79bf176ce0d64'; //Your secretKey
const userID = 'CUSTOMER_8'; // User ID

// init TUIKit
const TUIKit = TUICore.init({
  SDKAppID,
});
// TUIKit add TUIComponents
TUIKit.use(TUIComponents);

// login TUIKit
TUIKit.login({
  userID: userID,
  userSig: genTestUserSig({
    SDKAppID,
    secretKey,
    userID,
  }).userSig, // The password with which the user logs in to IM. It is the ciphertext generated by encrypting information such as userID.For the detailed generation method, see Generating UserSig
});

router.beforeEach((to, from, next) => {
  // 让页面回到顶部
  // chrome
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  // safari
  // window.pageYOffset = 0;
  // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
  next() 
})
// createApp(App).use(ElementUI).use(router).use(store).mount('#app')
createApp(App).use(TUIKit).use(ElementUI).use(router).use(store).mount('#app') 