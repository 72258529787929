
<script setup>
import { onMounted ,reactive} from 'vue'
import { useRouter, } from "vue-router";
import { orderDetails } from '@/utils/api'
const router = useRouter()
const data = reactive({
    query: router.currentRoute.value.query,
    info: {},
    isSHow:false,
})
onMounted(() => {
    orderDetails({ order_id: data.query.order_id, sub_order_id: data.query.sub_order_id }).then(res => {
        if (res.code == 200) {
            data.info = res.data
            data.isSHow=true;
        }
    })
}) 
</script> 
<template >
    <div class="wrapCenter" v-show="data.isSHow">
        <img src="../../assets/logo.png" alt="" class="logoimg" style="object-fit:cover">
        <div class="co9 fontS16">首页 > 个人中心 > 已买到的商品>物流详情 </div>
        <div class="goodsBox">
            <div v-for="(item, index) in data.info.list" :key="index">
                <div class="flex goods" style="align-items:flex-start" v-for="(item1, index1) in item.goods"
                    :key="index1">
                    <img :src="item1.image" alt="" style="object-fit:cover">
                    <div>
                        <div class="fontS20 co3">{{ item1.product_name }}</div>
                        <div class="co9 fontS16" style="margin: 10px 0;" >
                            <span v-if="item1.detail">规格：{{ item1.detail }}</span>
                            <span v-if="item1.detail">&nbsp;&nbsp;&nbsp;&nbsp;X{{ item1.product_num }}</span>
                        </div>
                        <div class="priceColor fontS20">￥{{ item1.total_price }}</div>
                    </div>
                </div>
            </div>
            <div v-if="data.info.express">
                <div>
                    <div class="co3  box">
                        <div class="fontS20" style="margin-bottom:33px">运输中</div>
                        <el-timeline>
                            <el-timeline-item v-for="(activity, index) in data.info.express.data" :key="index"
                                :timestamp="activity.time" :color="index == 0 ? 'red' : ''">
                                {{ activity.context }}
                            </el-timeline-item>
                        </el-timeline>
                    </div> 
                    <div class="co3 fontS16">
                        <div class="flex box1">
                            <!-- <div>
                                运单号码：{{ data.info.express.nu }}
                            </div>
                            <div style="margin:0 60px">
                                物流公司：{{ data.info.express.com }}
                            </div> -->
                            <!-- <div>
                        客服电话：11183
                    </div> -->
                        </div>
                        <!-- <div class="flex" style="margin-top:20px">
                    <div>
                        卖家昵称：dw123321
                    </div>
                    <div class="homeRed lianxi">
                        联系卖家
                    </div>
                </div>
                <div  style="margin-top:20px">
                    发货地址：河南省南阳市邓州市 花洲街道 御豪庭菜鸟驿站 45000 田田 15938882142
                </div> -->
                        <div style="margin-top:20px">
                            收货地址：{{ data.info.order.user_address }}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<style lang="less" scoped>
.goodsBox {
    margin-bottom: 30px;

    .lianxi {
        padding: 6px 12px;
        background: #f2babf;
        margin-left: 20px;
    }

    .box1 {
        padding-top: 40px;
        margin-top: 40px;
        border-top: 1px solid #CCCCCC;
    }

    .box {
        width: 100%;
        padding: 40px 20px;
        box-sizing: border-box;
        background: #F3F3F3;
    }

    .goods {
        padding-bottom: 40px;
        margin-bottom: 40px;
        border-bottom: 1px solid #CCCCCC;
    }

    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    margin-top: 40px;
    padding: 40px;
    box-sizing: border-box;

    img {
        width: 120px;
        height: 120px;
        margin-right: 20px;
    }
}

.logoimg {
    width: 160px;
    height: 62px;
    margin: 30px 0 60px;
}
</style>