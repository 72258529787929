<template>
    <div class="wrapCenter">
        <img style="object-fit:cover" src="../../assets/logo.png" alt="" class="logoimg">
        <div class="co9 fontS16">首页 > 个人中心 > 已买到的商品>退货/退款 </div>
        <div class="goodsBox">
            <div class="flex goods" style="align-items:flex-start">
                <img style="object-fit:cover" src="../../assets/Image/5494c0fa4c8d21450ef7357d0929a5d8.jpeg" alt="">
                <div>
                    <div class="fontS20 co3">冬季保暖高帮棉拖鞋女包跟厚底室内居家用</div>
                    <div class="co9 fontS16" style='margin: 10px 0;'>
                        <span>尺码：38-39</span>
                        <span style="margin:0 40px">颜色：泡芙粉</span>
                        <span>X1</span>
                    </div>
                    <div class="homeRed fontS20">68.00</div>
                </div>
            </div>
            <div class="co3 fontS20">选择服务类型</div>
            <div class="flex">
                <div class="box center" style="margin-right:40px">
                    <div class="co3 fontS24 " style="margin-bottom:10px">我要退款（无需退货）</div>
                    <div class="fontS20 co9">未收到货</div>
                </div>
                <div class="box center" style="border: 1px solid #CCCCCC;">
                    <div class="co3 fontS24 " style="margin-bottom:10px">已收到货，我要退货退款</div>
                    <div class="fontS20 co9">需要您寄回已收到的货物</div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="less" scoped>
.goodsBox {
    .box{
        width: 600px;
        height: 157px;
        background: #FFFFFF;
        border: 1px solid #25459C;
        margin-top: 20px;
        padding-top: 40px;
        box-sizing: border-box;
    }
    .goods{
        padding-bottom: 40px;
        margin-bottom: 40px;
border-bottom: 1px solid #CCCCCC;
    }
    height: 667px;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    margin-top: 40px;
    padding: 40px;
    box-sizing: border-box;
    img{
        width: 120px;
height: 120px;
margin-right: 20px;
    }
}

.logoimg {
    width: 160px;
    height: 62px;
    margin: 30px 0 60px;
}
</style>