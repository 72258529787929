
<script setup>
import { User, ArrowRight, } from "@element-plus/icons-vue";
import { Indexget_type, getCategory, findSearchHisory } from "../utils/api"
import { reactive, defineProps, computed, ref, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
const cateList = ref([])
const searchList = ref([])

// defineProps({
//   msg: String,
// });
defineProps({
    isBottmNavigation: {
        type: Boolean,
        default: true,
        required: false
    },
    lisac:{
        default:1
    }
});

onBeforeMount(() => {
    getCategory({ is_tree: 1 }).then(res => {
        if (res.code == 200) {
            cateList.value = res.data
        }
    })
    findSearchHisory({ type: 1 }).then(res => {
        if (res.code == 200) {
            searchList.value = res.data.orUser
        }
    })
})

const router = useRouter();
const showcategry = ref(false); //是否显示分类框
const keyvalue = ref(''); //是否显示分类框
const activecategry = ref(null); //当前选中的分类
const data = reactive({
    showSearchHistory: false, //显示搜索历史
    // activeNav: 1,  //navLIst的当前项
    navLIst: []
});

const datalist = computed(() => {
    let arr = [...data.navLIst]
    arr.forEach((item, index) => {
        if (!item.is_top) {
            arr.splice(index, 1)
        }
    })
    return arr;
})



Indexget_type().then(res => {
    if (res.code == 200) {
        data.navLIst = res.data
    }
})

const gotoRouter = (val, id) => {
    // data.activeNav = val
    router.push({ path: data.navLIst[val].tab, query: { platform_type_id: id } });
};

</script>

<template>
    <!-- style="padding:0 224px" -->
    <div class="wrapCenter" style="padding-top: 40px;">
        <div class="navTopCenter">
            <div class="flex" style="align-items:flex-start;padding-bottom: 18px;">
                <div class="left">
                    <img src="../assets/logo.png" style="object-fit:cover" class="logoWH">
                </div>
                <div class="right flex">
                    <div @mouseleave="data.showSearchHistory = false">
                        <div class="inputBox flex">
                            <input type="text" placeholder="请输入商品名称" @mouseenter="data.showSearchHistory = true"
                                 v-model="keyvalue" />
                            <div class="seacrhIcon cur"
                                @click.stop="router.push({ path: '/allGoods', query: { searchText: keyvalue } })">
                                <img src="../assets/search.png" style="object-fit:cover">
                            </div>
                        </div>
                        <div class="searchHistory fontS16" v-if="data.showSearchHistory">
                            <div class="co9">最近搜过</div>
                            <div class="cur sechli" v-for="(item, index) in searchList" :key="index"
                                @click.stop="router.push({ path: '/allGoods', query: { searchText: item.name } })">
                                <span v-if="index < 10">{{ item.name }}</span>
                            </div>
                        </div>
                        <div class="flex fontS16 input_B elipes1" style="width: 683px;" v-if="searchList.length > 0">
                            <div class=" cur" :class="[{ homeRed: index < 3 }]" v-for="(item, index) in searchList"
                                :key="index" @click.stop="router.push({ path: '/allGoods', query: { searchText: item.name } })">
                                {{ item.name }}</div>
                        </div>
                    </div>
                    <div class="userAndcart flex">
                        <div class="box co6 fontS16 flex cur" @click="router.push({ path: '/order/orderHome' })">
                            <el-icon :size="24" color="#666">
                                <User />
                            </el-icon> 
                            <span>个人中心</span>
                        </div>
                        <div class="box cof cur flex" @click="router.push({ path: '/cart' })">
                            <img src="../assets/cart.png" alt="" style="width:28px;height:25px;object-fit:cover">
                            <span style="padding:0 12px">购物车</span>
                            <el-icon :size="14" color="#fff">
                                <ArrowRight />
                            </el-icon>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    <!-- <div class="clear"></div> -->
</template>

<style scoped lang="less">
// .clear{
//     height:248px;
//     position: relative;
// }
// .wrapCenter{
//     position: fixed;
//     width: 1920px;
//     top:54px;
//     left: 0;
//     bottom:0;
//     height: 200px;
//     right: 0;
//     margin: 0 auto;
//     z-index:500;
//     background: #fff;
// }
.wrapCenter{
    width: 100%;
    background: #fff;
    margin-top: 32px;
}
.navTopCenter{
    width:1472px;
    margin: auto;
}
.sechli {
    color: #5C7DCD
}

.sechli:hover {
    color: #25459C;
}

.cateBox {
    position: absolute;
    z-index: 9999;

    .categrouBox {

        color: rgba(255, 255, 255, 0.8);

        width: 288px;
        height: 576px;
        overflow: auto;
        background: rgba(0, 0, 0, 0.8);


        .li {
            padding: 17px 24px 0;
            box-sizing: border-box;

            .name {
                // margin-bottom: 18px;
                font-weight: 700;
            }

            /deep/.el-space__item {
                margin-top: 10px;
            }

            /deep/ span {
                margin-top: 10px !important;
            }
        }
    }

    .active {
        color: #25459C !important;
        background-color: #fff !important;
    }

    .rightbox {

        width: 1184px;
        height: 576px;
        background: #FFFFFF;
        padding: 20px 40px;
        box-sizing: border-box;

        .name {
            margin-bottom: 8px;
            font-weight: 700;
        }

        .lisbox {
            // padding: 20px 0;
            // padding-top: 20px;
            align-items: flex-start;

            .leftbox {
                width: 15%;
                padding-top: 20px;
            }

            .lisliright {
                width: 100%;
                color: #333;
                padding-bottom: 20px;
                /deep/.el-space__item {
                    margin-top: 20px;
                }

                /deep/.el-space__item:hover {
                    color: #25459C;
                }

                /deep/ span {
                    margin-top: 20px !important;
                }
            }
        }


    }
}


.bottom {
    margin-top: 40px;

    div {
        margin-right: 80px;
    }

    .allcate {
        width: 288px;
        height: 48px;
        line-height: 48px;
        background: #25459C;
        color: #fff;

    }
}

.left {

    margin-right: 90px;
    margin-top: -16px;
}

.right {
    .searchHistory {

        width: 608px;
        padding: 10px;
        box-sizing: border-box;
        background: #FFFFFF;
        opacity: 1;
        border: 1px solid #CCCCCC;
        position: absolute;
        z-index: 99;

        div {
            margin-top: 16px;
        }

        div:nth-of-type(1) {
            margin-top: 0;
        }
    }

    align-items: flex-start;

    .userAndcart {
        margin-left: 40px;

        .box {
            height: 48px;
            line-height: 48px;
            padding: 0 24px;
            background: #F5F5F5;
            opacity: 1;
            border: 1px solid #CCCCCC;
        }

        .box:nth-of-type(2) {
            background: #25459C;
            border-color: #25459C;
        }
    }

    .input_B {
        margin-top: 14px;

        div {
            margin-right: 20px;
        }
    }

    .inputBox {
        width: 683px;
        height: 48px;
        border: 2px solid #25459C;
        padding-left: 20px;
        box-sizing: border-box;
        background-color: #fff;

        input {
            height: 41px;
            flex: 1;
            border: none;
            outline: none;
        }

        .seacrhIcon {
            width: 75px;
            height: 48px;
            background: #25459C;
            display: flex;
            align-items: center;
            position: relative;
            right: -1px;

            img {
                width: 29.97px;
                height: 29.97px;
                margin: 0 auto;
            }
        }
    }

}
</style>
