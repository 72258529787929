

<template>
 <el-carousel height="576px" :interval="5000" arrow="always">
    <el-carousel-item v-for="item in imgList" :key="item">
     <img style="width: 100%;" :src="item.image" alt="">
    </el-carousel-item>
  </el-carousel>

</template>

<script setup>
import { ref ,toRefs,defineProps } from "vue";
import { Edit, Delete } from "@element-plus/icons-vue"; // icon 按需引入
//   <el-button type="primary" :icon="Edit" circle></el-button> ---> element plus 使用范例
// defineProps({
//   msg: String,
// });

const props = defineProps({
  //子组件接收父组件传递过来的值
  imgList: Array,
})

const {imgList} =toRefs(props)

</script>

<style scoped>
</style>
