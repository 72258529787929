<script setup>
import { cartList, cartDel, plus_or_minus, get_address,userget_user_info } from "@/utils/api";
import { ref, onBeforeMount, reactive, nextTick, watch } from "vue";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import delModel from "@/components/delModel";
const router = useRouter();
const checkAll = ref(false); //全选是否选中

let centerDialogVisible = ref(false); //是否显示删除框
const addressData = reactive({
  arrData: [],
});
onBeforeMount(() => {
  getList();
  userInfoModel()
});
get_address().then((res) => {
  addressData.arrData = res.data;
});
// 获取用户数据
const userInfo=ref(null)
const dialogTableVisible=ref(false)
// 提示
const modelTxt=ref('')
const userInfoModel=()=>{
  userget_user_info().then(res => {
    if(res.code==200){
      userInfo.value = res.data
        console.log(userInfo.value ,'userInfo.value ')
    }
      })
}
const sbumitRecord=()=>{
  if(data.totalPrice>=300000){
    dialogTableVisible.value=false
    return
  }
if(userInfo.value.is_qualification!=1){
  router.push({ path: "/enterpriseReg"});
  return
}
if(userInfo.value.is_qualification==1&&userInfo.value.is_record==0){
  router.push({ path: "/enterpriseReg"});
  return
}
if(userInfo.value.is_qualification==1&&(userInfo.value.is_record==1||userInfo.value.is_record==3)){
  router.push({ path:"/order/orderHome" , query: { index: 6 } });
  return
}
}
const data = reactive({
  totalpage: 3, //总页数
  type: 1, //1列表删除，2清空购物车 3多选删除
  page: 1,
  list: [],
  list1: [], //失效商品
  total: 0,
  delItem: [],
  checkList: [], //选中的cartId
  totalNum: 0, //总数量
  totalPrice: 0, //总价格
});
const loading = ref(true);

const getList = (val) => {
  loading.value = true;
  cartList({ page: data.page, limit: 10 }).then((res) => {
    loading.value = false;
    data.totalpage = res.data.last_page;
    data.list1 = []; //失效商品
    // 计算失效商品
    if (val) {
      // 步进器
      let listCopy = data.list;
      data.list = res.data.data;
      console.log(listCopy, data.list);
      data.list.forEach((item, index) => {
        item.storeCheckAll = listCopy[index].storeCheckAll;
      });
    } else {
      let listCopy = data.list;
      if (data.page == 1) {
        data.list = res.data.data;
      } else {
        data.list = data.list.concat(res.data.data);
      }
      data.list.forEach((item) => {
        // 商铺是否被选中  (删除后要保持之前选中的状态)
        listCopy.forEach((item1) => {
          if (item.store_id == item1.store_id) {
            Reflect.set(item, "storeCheckAll", item1.storeCheckAll);
          }
        });
        if (!item.storeCheckAll) {
          Reflect.set(item, "storeCheckAll", []);
        }
        let goodsArray = [];
        let shixiaoStore = {
          stroe_name: item.stroe_name,
          goods: [],
          store_image: item.store_image,
          storeCheckAll: item.storeCheckAll ? item.storeCheckAll : [],
        };
        item.goods.forEach((itemm) => {
          if (itemm.disabled) {
            //失效商品  只有在total总条数等于列表的长度才会显示 (当商品展示完毕再显示过期商品)
            // if (data.list.length == res.data.total) {
            shixiaoStore.goods.push(itemm);
            // }
          } else {
            goodsArray.push(itemm);
          }
        });
        // 失效商品
        console.log(shixiaoStore);
        if (shixiaoStore.goods.length > 0) {
          data.list1.push(shixiaoStore);
        }
        item.goods = goodsArray;
      });
    }
    // 计算总条数
    data.total = 0;
    data.list.forEach((item) => {
      data.total += item.goods.length;
    });

    // 全选是否被选中
    if (data.checkList.length < data.total) {
      console.log(123213,'')
      checkAll.value = false;
    } else {
      console.log(123213,'true')
      checkAll.value = true;
    }
  });
};
const sureDel = () => {
  let ids = [];
  if (data.type == 1) {
    //列表删除
    ids.push(data.delItem.cart_id);
  } else if (data.type == 2) {
    //2清空购物车
    data.list1.forEach((item) => {
      item.goods.forEach((item1) => {
        ids.push(item1.cart_id);
      });
    });
  } else if (data.type == 3) {
    //3多选删除
    ids = data.checkList;
  }
  cartDel({ id: ids.join(",") }).then((res) => {
    if (res.code == 200) {
      centerDialogVisible.value = false;
      data.list.forEach((item) => {
        item.storeCheckAll = item.storeCheckAll.filter((o) => !ids.includes(o)); //
        console.log(item.storeCheckAll);
      });
      data.checkList = data.checkList.filter((o) => !ids.includes(o)); //
      console.log(data.checkList);
      ElMessage.success("删除成功！");
      data.page = 1;
      getList();
    } else {
      ElMessage.warning(res.message);
    }
  });
};
// 店铺点击全选
const handleCheckAllChange = (item) => {
  // nextTick(() => {
    if (item.storeCheckAll.length > 0) {
      item.goods.forEach((item1) => {
        if (data.checkList.length > 0) {
          data.checkList.forEach((item2) => {
            if (item1.cart_id != item2) {
              data.checkList.push(item1.cart_id);
              // 去重
              data.checkList = Array.from(new Set(data.checkList));
            }
          });
        } else {
          data.checkList.push(item1.cart_id);
          //  console.log('进入12345',data.checkList)
        }
      });
    } else {
      item.goods.forEach((item1) => {
        data.checkList.forEach((item2, index) => {
          if (item1.cart_id == item2) {
            data.checkList.splice(index, 1);
          }
        });
      });
    }
  // });
};
// 点击列表的每一项
const listCheckChange = () => {
  // console.log('进入')
  data.list.forEach((item1) => {
    item1.storeCheckAll = [];
  });
  nextTick(() => {
    data.checkList.forEach((item) => {
      data.list.forEach((item1) => {
        item1.goods.forEach((item2) => {
          if (item == item2.cart_id) {
            item1.storeCheckAll.push(item);
            item1.storeCheckAll = Array.from(new Set(item1.storeCheckAll));
          }
          if (item1.storeCheckAll.length == item1.goods.length) {
            item1.storeCheckAll.unshift("all");
          }
        });
      });
    });
  });
};
//点击全选按钮
const checkboxAllChange = (e) => {
  if(data.list.length==0)return
  data.checkList = [];
  data.list.forEach((item) => {
    item.goods.forEach((item1) => {
      if (e) {
      console.log('12',e)
        data.checkList.push(item1.cart_id);
        item.storeCheckAll.push("all");
      }else {
      console.log('1',e)
        item.storeCheckAll = [];
        data.checkList = [];
      }
    });
  });
};
// 计算总价格和总数量
const totalChangePrice = () => {
  data.totalPrice = 0;
  data.totalNum = 0;
  data.checkList.forEach((item) => {
    data.list.forEach((item1) => {
      item1.goods.forEach((item2) => {
        if (item == item2.cart_id) {
          data.totalPrice += item2.price * item2.num;
          data.totalNum += item2.num;
        }
      });
    });
  });
  data.totalPrice = data.totalPrice.toFixed(2);
  if(data.totalPrice>=300000){
    dialogTableVisible.value=true
    modelTxt.value='根据外汇要求，单笔订单金额不能超过300,000人民币，请您分批选择商品进行结算';
  }
};
const showModel = (val) => {
  centerDialogVisible.value = true;
  data.type = val;
  console.log(val,'val', data.type)
};

// 点击步进器
const setupHandleChange = (item) => {
  nextTick(() => {
    plus_or_minus({ id: item.cart_id, num: item.num }).then((res) => {
      if (res.code == 200) {
        data.page = 1;
        getList(true);
        totalChangePrice();
      } else {
        data.page = 1;
        getList(true);
        // totalChangePrice()
        ElMessage.warning(res.message);
      }
    });
  });
};

// 触底函数
const load = () => {
  // return
  if (data.page < data.totalpage) {
    data.page++;
    getList();
  }
};

// 监听选中的cartId 列表
watch(
  () =>data.checkList,
  () => {
    totalChangePrice();
    if (data.checkList.length < data.total) {
      checkAll.value = false;
    } else {
      checkAll.value = true;
    }
  },
  { deep: true, immediate: false }
);

const submit = () => {
  if(data.totalPrice>=300000){
    dialogTableVisible.value=true
    modelTxt.value='根据外汇要求，单笔订单金额不能超过300,000人民币，请您分批选择商品进行结算';
    return 
  }
  if (data.checkList.length == 0) {
    ElMessage.warning("请选择要购买的商品");
    return false;
  } else if (addressData.arrData.length == 0) {
    ElMessage.warning("请先去添加收货地址");
    setTimeout(() => {
      router.push({ path: "/set", query: { index: 2 } });
    }, 1000);
    return false;
  }
  console.log(userInfo.value,'userInfo.value.is_qualification')
  if(userInfo.value.is_qualification!=1){
    modelTxt.value='您所认证的企业当前不具备A类资质，请前往采购商资质进行查看'
    dialogTableVisible.value=true
    return
  }
  if(userInfo.value.is_qualification==1&&userInfo.value.is_record!=2){
    switch(userInfo.value.is_record)
    {
      case 0:
      dialogTableVisible.value=true
      modelTxt.value='您所认证的企业未进行备案，请前往采购商资质进行查看';
      break;
      case 1:
      dialogTableVisible.value=true
      modelTxt.value='您所认证的企业正在备案中，请前往采购商资质进行查看';
      break;
      case 3:
      dialogTableVisible.value=true
      modelTxt.value='您所认证的企业备案失败，请前往采购商资质进行查看';
      break;
    }
    return
  }
  router.push({
    path: "/sureOrder",
    query: {
      cart_id: data.checkList.join(","),
    }
  })

 
};

</script>
<template>
  <div class="topHomeBox co3">
    <div class="wrapCenter flex">
      <img
        src="../../assets/logo.png"
        alt=""
        class="logo"
        style="object-fit: cover"
      />
      <div class="homeText fontS20">购物车({{ data.total }})</div>
    </div>
  </div>
  <div class="wrapCenter">
    <!-- 退款售后 -->
    <div class="desBox flex fontS18 co6">
      <div>
        <el-checkbox
          v-model="checkAll"
          class="myRedCheckBox"
          style="margin-left: 20px"
          size="large"
          @change="checkboxAllChange"
        />
        <span style="margin-left: 10px">全选</span>
      </div>
      <div style="margin-left: 122px">商品</div>
      <div style="margin-left: 546px">单价</div>
      <div style="margin-left: 145px">数量</div>
      <div style="margin-left: 145px">金额</div>
      <div style="margin-left: 145px">操作</div>
    </div>
    <div>
      <div
        v-infinite-scroll="load"
        style="height: 50vh; overflow-anchor: none; overflow: auto"
        infinite-scroll-distance="30px"
        class="printLogFather"
        v-loading="loading"
        :infinite-scroll-immediate="false" :infinite-scroll-disabled="false"
      >
        <el-checkbox-group
          v-model="data.checkList"
          fill="#25459C"
          @change="listCheckChange(item)"
        >
          <el-empty description="购物车为空" v-if="data.list.length == 0" />
          <div
            v-for="(item, index) in data.list"
            :key="index"
            style="margin-bottom: 40px"
          >
            <div v-if="item.goods.length > 0">
              <el-checkbox-group v-model="item.storeCheckAll" fill="#25459C">
                <el-checkbox
                  class="myRedCheckBox"
                  label="all"
                  @change="handleCheckAllChange(item)"
                  >{{ item.stroe_name }}</el-checkbox
                >
              </el-checkbox-group>
              <div
                class="goods co3 fontS16 flex"
                style="align-items: flex-start"
                v-for="(item1, index1) in item.goods"
                :key="index1"
              >
                <div class="flex">
                  <el-checkbox
                    class="myRedCheckBox"
                    :label="item1.cart_id"
                    style="margin-left: 20px"
                    >{{
                  }}</el-checkbox>

                  <div class="flex" style="align-items: flex-start">
                    <div
                      class="left flexJBC cur"
                      @click="
                        router.push({
                          path: '/goodsDetail',
                          query: { id: item1.goods_id },
                        })
                      "
                      style="
                        align-items: flex-start;
                        border: none;
                        width: 600px;
                      "
                    >
                      <div class="flex" style="align-items: flex-start">
                        <img
                          :src="item1.image"
                          alt=""
                          class="goodsimg"
                          style="object-fit: cover"
                        />
                        <div class="nameBox">
                          <div class="name elipes2">
                            {{ item1.product_name }}
                          </div>
                          <div class="co9 marT2" v-if="item1.suk_name">
                            规格：{{ item1.suk_name }}
                          </div>
                          <div style="margin-top: 30px" class="co9">
                            {{ item1.warehouse_type_text }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="flexJBC co3 fontS16"
                      style="padding-top: 20px; align-items: flex-start"
                    >
                      <div style="width: 300px; height: 120px" class="center">
                        <div v-if="item1.is_start_wholesale == 1">
                          <span style="margin-right: 5px" class="co9 fontS16">{{
                            item1.warehouse_seller_num
                          }}</span>
                          <span class="fontS20">￥{{ item1.price }}</span>
                        </div>
                        <div v-else>
                          <span
                            style="text-decoration: line-through"
                            class="co9 fontS16">{{
                              item1.price != item1.ot_price
                                ? "￥" + item1.ot_price
                                : ""
                            }}</span>
                          <span class="fontS20">￥{{ item1.price }}</span>
                        </div>
                      </div>
                      <div style="margin-top: -8px">
                        <el-input-number
                          :min="1"
                          :max="9999"
                          @change="setupHandleChange(item1)"
                          style="width:128px"
                          v-model="item1.num"
                        />
                      </div>
                      <div class="priceColor center" style="width: 250px">
                        ¥{{ (item1.price * item1.num).toFixed(2) }}
                      </div>
                      <div
                        style="width: 100px"
                        class="center cur"
                        @click="showModel(1); data.delItem = item1;">
                        删除
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-checkbox-group>
        <!-- 失效商品 -->
        <div v-if="data.list1.length > 0">
          <div class="co6 fontS18" style="margin: 40px 0 20px">已失效商品</div>
          <el-checkbox-group
            fill="#25459C"
            v-for="(item, index) in data.list1"
            :key="index"
            style="margin-bottom: 40px"
          >
            <div>
              <el-checkbox
                v-model="checkAll"
                disabled
                :indeterminate="isIndeterminate"
                @change="handleCheckAllChange"
                >{{ item.stroe_name }}</el-checkbox
              >
            </div>
            <div
              class="goods co3 fontS16 flex"
              style="align-items: flex-start"
              v-for="(item1, index1) in item.goods"
              :key="index1"
            >
              <div class="flex">
                <el-checkbox
                  :label="1"
                  style="margin-left: 20px"
                  disabled
                  >{{
                }}</el-checkbox>
                <div class="flex" style="align-items: flex-start">
                  <div
                    class="left flexJBC"
                    style="align-items: flex-start; border: none; width: 600px"
                  >
                    <div class="flex" style="align-items: flex-start">
                      <img
                        :src="item1.image"
                        alt=""
                        class="goodsimg"
                        style="object-fit: cover"
                      />
                      <div class="nameBox">
                        <div class="name elipes2">
                          {{ item1.product_name }}
                        </div>
                        <div class="co9 marT2" v-if="item1.suk_name">
                          规格：{{ item1.suk_name }}
                        </div>
                        <div style="margin-top: 30px" class="co9">
                          {{ item1.warehouse_type_text }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="flexJBC co3 fontS16"
                    style="padding-top: 20px; align-items: flex-start"
                  >
                    <div style="width: 300px; height: 120px" class="center">
                      <div v-if="item1.is_start_wholesale == 1">
                        <span style="margin-right: 5px" class="co9 fontS16">{{
                          item1.warehouse_seller_num
                        }}</span>
                        <span class="fontS20">￥{{ item1.price }}</span>
                      </div>
                      <div v-else>
                        <span
                          style="text-decoration: line-through"
                          class="co9 fontS16"
                          >{{
                            item1.price != item1.ot_price
                              ? "￥" + item1.ot_price
                              : ""
                          }}</span
                        >
                        <span class="fontS20">￥{{ item1.price }}</span>
                      </div>
                    </div>
                    <div style="margin-top: -8px;">
                      <el-input-number
                        :min="item1.num"
                        :max="item1.num"
                        @change="handleChange"
                        style="width: 128px"
                        v-model="item1.num"
                        v-if="false"
                      />
                    </div>
                    <div class="homeRed center" style="width: 250px">
                      ¥{{ (item1.price * item1.num).toFixed(2) }}
                    </div>
                    <div
                      style="width: 100px"
                      class="center cur"
                      @click="showModel(1);data.delItem=item1;"
                    >
                      删除
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-checkbox-group>
        </div>
      </div>
    </div>
    <div class="bottomB_box flexJBC">
      <div>
        <el-checkbox
          v-model="checkAll"
          class="myRedCheckBox"
          style="margin-left: 20px"
          size="large"
          @change="checkboxAllChange"
        />
        <span style="margin-left: 10px">全选</span>
        <span
          style="margin: 0 40px"
          class="cur"
          @click="showModel(3)"
          v-if="data.checkList.length > 0"
          >删除</span
        >
        <span
          style="margin-left: 10px"
          @click="showModel(2)"
          class="cur"
          v-if="data.list1.length > 0"
          >清空失效商品</span
        >
      </div>
      <div class="flex">
        <div class="fontS15" v-if="data.totalPrice>=300000" style="color:#DD2131;margin-right: 20px;">根据外汇要求，单笔订单金额不能超过300,000人民币，请您分批选择商品进行结算</div>
        <div class="co6 fontS18">
          已选商品
          <span class="priceColor">{{ data.totalNum }}</span>
          件，合计(不含运费)：
        </div>
        <div class="fontS22 priceColor">¥{{ data.totalPrice }}</div>
        <div class="btn cof fontS22 cur" @click="submit">结算</div>
      </div>
    </div>
    <!-- 删除提示 -->
    <delModel
      v-model:delShow="centerDialogVisible"
      @handleSuccessed="sureDel"
      @handRemove="centerDialogVisible = false"
    ></delModel>
  </div>
  <el-dialog v-model="dialogTableVisible" title="" width="594">
        <div class="flex">
            <div class="suceImg" style="margin-right:14px">
              <img src="@/assets/Group48831.png" alt="" />
            </div>
            <div class="fontS20" style="line-height:1.5">
             {{modelTxt}}
            </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogTableVisible = false" class="closeBtn">取消</el-button>
            <el-button type="primary" @click="sbumitRecord" class="submitBtn">
              确认
            </el-button>
          </span>
        </template>
      </el-dialog>
</template>
<style lang="scss" scoped>
.bottomB_box {
  // margin-bottom: 100px;

  .btn {
    width: 160px;
    text-align: center;
    line-height: 80px;
    height: 80px;
    background: #25459c;
    margin-left: 24px;
  }

  margin-top: 60px;
  // position: fixed;
  // bottom: 20px;
  width: 1472px;
  height: 80px;
  line-height: 80px;
  background: #ffffff;
  border: 1px solid #cccccc;
  padding-left: 20px;
  box-sizing: border-box;
}

.goods {
  .priceBox,
  .Box {
    height: 203px;
    padding: 20px;
    box-sizing: border-box;
    border-right: 1px solid #cccccc;

    .btn1 {
      width: 143px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #ffffff;
      border: 1px solid #666666;
    }
  }

  .Box {
    width: 137px;

    .btn {
      width: 143px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #25459c;
    }
  }

  .left {
    height: 203px;
    width: 816px;
    padding: 20px;
    box-sizing: border-box;
    border-right: 1px solid #cccccc;

    .goodsimg {
      width: 120px;
      height: 120px;
      margin-right: 10px;
    }

    .nameBox {
      width: 312px;
      height: 120px;
      .name {
        line-height: 25px;
      }
    }
  }

  border: 1px solid #cccccc;
}

.desBox {
  height: 56px;
  background: #f6f7f9;
  margin: 40px 0;
}

.topHomeBox {
  height: 100px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  .homeText {
    margin: 32px 80px 0 16px;
  }

  .active {
    line-height: 50px;
    border-bottom: 4px solid #fff;
  }

  .logo {
    width: 160px;
    height: 62px;
    // margin-right: 32px;
    // margin: 19px 0;
  }
}
</style>