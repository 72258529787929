<script setup>
import { Check } from "@element-plus/icons-vue";
import { ref } from 'vue'
const active = ref(0)
</script>
<template>
    <div class="page">
        <div class="topHomeBox co3">
            <div class="wrapCenter flex">
                <img src="../../assets/logo.png" alt="" style="object-fit:cover" class="logo">
                <div class="homeText fontS20 cur">钱包</div>
            </div>
        </div>
        <div class="wrapCenter  ">
            <div class="whitBox">
                <div class="co6 fontS16">奈特喜钱包</div>
            <div class="priceBox flex co3" style="align-items: flex-start">
                <div class="left">
                    <div class="fontS16">￥<span class="fontS32">1100.78</span></div>
                    <div class="fontS20 marT2">当前余额</div>
                </div>
                <div class="btn cof fontS16 center ">提现</div>
            </div>
            <div class="co6 fontS16 marT2 marB2">充值余额</div>
            <div class="flex" style="flex-wrap: wrap;">
                <div class="li center fontS20" v-for="(item, index) in 10" :key="index"
                    :class="active == index ? 'active' : ''" @click="active = index">
                    <span>{{ item }}100</span>
                    <div class="triangle " v-if="active == index">
                        <span class="icon ">
                            <el-icon color="#fff" size="15">
                                <Check />
                            </el-icon>
                        </span>
                    </div>

                </div>
            </div>
            <div class="btn1 center cof">充值</div>
            </div>
            <div class="mingxiBox">
                <div class="co6 fontS16">账单明细</div>
                 <div class="flexJBC li" v-for="(item,index) in 3" :key="index" :style="index==0?'border:none':' border-top: 1px solid #CCCCCC;'">
                     <div class="fontS20"> 
                        <div class="co3">钱包支付</div>
                        <div class="co9" style="margin-top:10px">2022-09-20 22:00:08</div>
                     </div>
                     <div class="fontS20">
                        <div class="co3">-600.00</div>
                        <div class="co9" style="margin-top:10px">余额：1100.00</div>
                     </div>
                 </div>
            </div>
        </div>
          
    </div>
</template>
<style lang="scss" scoped>
.mingxiBox{
    .li{
        padding: 20px 0; 
       
    }
   
    padding: 20px;
    box-sizing: border-box;
    // width: 1472px; 
    background-color: #fff;
    margin-top: 40px;
}
.whitBox {
    .btn1 {
        width: 244px;
        height: 48px;
        line-height: 48px;
        background: #25459C;
        margin-top: 20px;
    }

    .li {
        width: 240px;
        height: 86px;
        border: 1px solid #CCCCCC;
        line-height: 86px;
        margin: 0 20px 20px 0px;
        color: #333;
        position: relative;

        .triangle {
            .icon {
                position: absolute;
                z-index: 99;
                right: 50%;
                bottom: -5px;

            }

            position: absolute;
            bottom: 0;
            right: 0;
            width: 0;
            height: 0;
            border-left:41px solid transparent;
            border-right: 0px solid transparent;
            border-bottom:41px solid #25459C;
        }
    }

    .active {
        color: #25459C;
        border-color: #25459C;
    }

    .priceBox {
        .btn {
            width: 80px;
            height: 28px;
            line-height: 28px;
            background: #FFC047;
            margin-left: 120px;
        }




        padding-left: 40px;
        margin-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px dotted #CCCCCC;
    }

    padding: 20px;
    box-sizing: border-box;
    width: 1472px;
    height: 554px;
    background-color: #fff;
    margin-top: 40px;
}

.page {
    background-color: #f5f5f5;
    min-height: 100vh;
    padding-bottom: 50px;
}

.topHomeBox {
    height: 100px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    .homeText {
        margin-right: 80px;
    }

    .active {
        line-height: 50px;
        border-bottom: 4px solid #fff;
    }

    .logo {
        width: 160px;
        height: 62px;
        // margin-right: 32px;
        // margin: 19px 0;
    }
}
</style>