<script setup>
import { onBeforeMount ,reactive } from 'vue';
import {getGuesslike} from '@/utils/api'
import { useRouter } from 'vue-router';
const router= useRouter()
   
    const data=reactive({
        list:[],
        last_page:3,
        page:1,
    })
    const getList=()=>{
        getGuesslike({page:data.page,limit:12}).then(res=>{
            if(res.code==200){
                data.last_page=res.data.last_page
                if(data.page==1){
                    data.list=res.data.data
                }else{
                    data.list=data.list.concat(res.data.data)
                }
                console.log(data.list)
            }
        }) 
    } 
    onBeforeMount(()=>{ 
        getList()
    })
    const load=()=>{
    if(data.last_page>data.page){
        data.page++;
        getList()
    }
}
</script>
<template>

    <div class="wrapCenter">
        <div class="topBox fontS24 flex co3">
            <img src="../../assets/success.png" alt="" style="object-fit:cover">
            旗舰店评分成功! 
            <span></span>
        </div>
        <div class="youLikeBox marT2" v-if="data.list.length>0">
            <div class="flexJBC">
                <div class="flex">
                    <span class="homeRed fontS28">猜你喜欢</span>
                </div>
                <el-icon size="40px " color="#999">
                    <RefreshRight />
                </el-icon>
            </div>
            <div class="list marT2 printLogFather"   infinite-scroll-distance="30px" v-infinite-scroll="load" style="height:90vh;overflow: auto;">
                <el-row :gutter="16">
                    <el-col :span="6" v-for="item in data.list" :key="item.id"  @click="router.push({ path: '/goodsDetail', query: { id: item.id } })" >
                        <div class="li">
                            <img :src="item.image" alt="" style="object-fit:cover">
                            <div class="title elipes2 co3 fonrS22">{{ item.product_name }}</div>
                            <div class="flex">
                                <span class="priceColor fontS12">￥</span>
                                <span class="priceColor fontS28">{{ item.price }}</span>
                                <span class="fontS18 co9" style="margin-left:14px">{{item.sales}}人付款</span>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.youLikeBox {
    margin-bottom:20px;
    .list {

        flex-wrap: wrap;
        // padding: 0 40px;
        box-sizing: border-box;
        justify-content: flex-start;

         .li {
            width: 336px;
            position: relative;

            .title {
                margin: 20px 0 16px;
                line-height: 28px;
            }

            margin-bottom: 40px;
            margin-right: 16px;

            img {
                width: 336px;
                height: 336px;
                display: block;
            }
        }
    }

    background: #FFFFFF;
    padding: 20px;
    box-sizing: border-box;
    border: 1px solid #CCCCCC;
}

.topBox {
    width: 1472px;
    height: 128px;
    background: #FFFFFF;
    padding: 40px;
    box-sizing: border-box;
    border: 1px solid #CCCCCC;
    margin: 40px 0;
    img{
        margin-right: 20px;
        width: 48px;
        height: 48px;
    }
}
</style>