<script setup>
import { reactive, onBeforeMount, ref } from 'vue';
import { merchantJoin, findMerchJoin } from '@/utils/api'
import { ElMessage, TabsPaneContext } from 'element-plus'
import { Delete, } from "@element-plus/icons-vue";
import upImg from '@/components/upImg.vue'
import { waitEvaluate, evaluated, orderdel } from '@/utils/api'


const activeName = ref('0')
const value1 = ref(null)
let page1 = ref(1)
let page1Last = ref(1)
let page2 = ref(1)
let page2Last = ref(1)
const centerDialogVisible = ref(false)
let item = reactive({})
const loading = ref(true) 
//展示删除弹框
const DelAndsurDelivery = (val) => {
    centerDialogVisible.value = true
    item.order_id = val.order_id
    item.sub_order_id = val.sub_order_id
}
//确认删除
const sureDelAndsurDelivery = () => {
    // centerDialogVisible.value=true
    orderdel({
        order_id: item.order_id,
        sub_order_id: item.sub_order_id,
    }).then(res => {
        if (res.code == 200) {
            ElMessage.success('删除成功')
            centerDialogVisible.value = false
            page1.value = 1
            page2.value = 1
            getWaitEvaluateList()

        } else {
            centerDialogVisible.value = false
            ElMessage.danger(res.message)
        }
    })
    console.log(999999);
}
const handleClick = (tab, event) => {
    console.log(activeName.value)
    if (activeName.value == 0) {
        page1.value = 1
        getWaitEvaluateList()
    } else if (activeName.value == 1) {
        page2.value = 1
        getEvaluatedList()
    }
}
const getWaitEvaluateList = (page) => {
    loading.value=true
    waitEvaluate({
        page: page || 1,
        limit: 10
    }).then(res => {
        loading.value=false
        if (res.data.current_page == 1) {
            page1.value = res.data.current_page
            page1Last.value = res.data.last_page
            waitEvList.value = res.data.data
        }else{
            page1.value = res.data.current_page
            page1Last.value = res.data.last_page
            waitEvList.value.push(res.data.data) 
        }
    })
}
const getEvaluatedList = (page) => {
    loading.value=true
    evaluated({
        page: page || 1,
        limit: 10
    }).then(res => {
        loading.value=false
        evtedList.value = res.data.data
        page2Last.value = res.data.last_page
        page2.value = res.data.current_page
        if(res.data.current_page == 1){
            page2.value = res.data.current_page
            page2Last.value = res.data.last_page
            evtedList.value = res.data.data
        }else{
            page2.value = res.data.current_page
            page2Last.value = res.data.last_page
            evtedList.value.push(res.data.data) 
        }
    })
}
const load = () => {
    console.log(999999999999999);
    // return
    if (activeName.value == 0) {
        if (page1.value > page1Last.value || page1.value == page1Last.value) {
            return
        }
        page1.value++
        getWaitEvaluateList(page1.value)
    } else if (activeName.value == 1) {
        if (page2.value > page2Last.value || page2.value == page2Last.value) {
            return
        }
        page2.value++
        getEvaluatedList(page2.value)
    }
}
getWaitEvaluateList()
getEvaluatedList()
const waitEvList = ref([])
const evtedList = ref([])

</script>
<template>
    <div class="box">
        <!-- 、切换 -->
        <el-tabs v-model="activeName" class="demo-tabs" @tab-change="handleClick">
            <el-tab-pane label="待评价" name="0"></el-tab-pane>
            <el-tab-pane label="已评价" name="1"></el-tab-pane>
        </el-tabs>
        <!-- list -->
        <!-- 待评价 -->
        <div class="li printLogFather" v-if="activeName == 0" v-infinite-scroll="load"
            style="overflow: auto;height: 70vh;" v-loading="loading">
            <el-empty description="没有数据" v-if="waitEvList.length==0"/>
            <div v-for=" (item, index) in waitEvList" :key="index"
                style="border: 1px solid #cccccc;margin-bottom: 20px;">
                <div class="title flex">
                    <img :src="item.store_image" alt=""
                        style="width: 40px;height: 40px;border-radius: 50%;margin-right: 10px;object-fit:cover">
                    <p class="name fontS20 c06 " style="width: 400px;">{{ item.store_name }}</p>
                    <div class="flexJBC" style="flex-grow: 1;">
                        <div class="order_num fontS20 c06 " style="margin-right: 40px;width: 400px;"></div>
                        <div class="time fontS20 c06 " style="margin-right: 40px;">{{ item.create_time.slice(0, 10) }}
                        </div>
                        <!-- <div > <el-icon @click="DelAndsurDelivery(item)" >
                                <Delete />
                            </el-icon>
                        </div> -->
                    </div>
                </div>
                <div class="content_li">
                    <div>
                        <div v-for="(jitem, jindex) in item.orderProduct" :key="jindex" class="c_li "
                            style="display:flex;justify-content: space-between;">
                            <img :src="jitem.image" style="width: 120px ;height: 120px;object-fit:cover" alt="">
                            <div style="margin-right: 50px;margin-left: 10px;">
                                <div class="c_liName co3 fontS16" style="width: 310px; line-height: 20px;">
                                    {{ jitem.goods_name }}
                                </div>
                                <div class="c_liGg fontS16 co9" style="margin-top: 10px;" v-if="jitem.details">
                                    规格：{{ jitem.details }}
                                </div>
                            </div>
                            <div>
                                <span style="margin-right: 10px;">
                                    ¥{{ jitem.product_price }}
                                </span>
                                <span style="margin-right: 10px;">
                                    {{ jitem.product_num }}
                                </span>
                                <!-- <span style="margin-right: 10px;">
                                    退款/退换货
                                </span> -->
                            </div>

                        </div>
                    </div>
                    <div class="content2">
                        <div class="fontS16 co3 center bord_l cur" style="flex-grow: 1;padding-top: 20px;">
                            <p>¥{{ item.total_price }}</p>
                            <p style="margin-top: 10px;">(含运费：¥{{ item.total_postage }})</p>
                        </div>
                        <div class="center fontS16 co3 bord_l " style="flex-grow: 1;padding-top: 20px;">
                            <p>{{ item.status_text }}</p>
                            <p style="margin-top: 10px;"
                                @click="$router.push({ 'path': '/order/orderDetail', query: { 'sub_order_id': item.sub_order_id, 'order_id': item.order_id } })"
                                class="cur">订单详情</p>
                            <!-- <p style="margin-top: 10px;" class="cur">查看物流</p> -->
                        </div>
                        <div class="fontS16 co3 center bord_l " style="flex-grow: 1;padding-top: 20px;">
                            <div class="co6 cur"
                                @click="$router.push({ 'path': '/comment', query: { 'sub_order_id': item.sub_order_id } })"
                                style="width: 140px;height: 40px;border: 1px solid #666666;line-height: 40px;margin: 0 auto;">
                                去评价
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 已评价 -->
        <div class="li printLogFather" v-if="activeName == 1" v-infinite-scroll="load"
            style="overflow: auto;height: 70vh;" v-loading="loading">
            <el-empty description="没有数据" v-if="evtedList.length==0"/>
            <div v-for=" (item, index) in evtedList" :key="index" style="border: 1px solid #cccccc;overflow: hidden;">
                <div class="title flex" style="border-bottom: 1px solid #cccccc;">
                    <img :src="item.store_image" alt=""
                        style="width: 40px;height: 40px;border-radius: 50%;margin-right: 10px;object-fit:cover">
                    <p class="name fontS20 c06 " style="width: 400px;">{{ item.store_name }}</p>
                    <div class="flexJBC" style="flex-grow: 1;">

                        <div class="order_num fontS20 c06 " style="margin-right: 40px;width: 400px;">
                            订单号:{{  item.subOrderSign.sub_sn }}</div>
                        <div class="time fontS20 c06 " style="margin-right: 40px;">{{ item.create_time.slice(0, 10) }}
                        </div>
                        <!-- <div> <el-icon @click="DelAndsurDelivery(item)">
                                <Delete />
                            </el-icon>
                        </div> -->
                    </div>
                </div>
                <div v-for="(item, index) in item.commentDetails" :key="index">
                    <div class="">
                        <div class="c_li2 " style="display:flex;">
                            <img :src="item.orderProduct.image" style="width: 120px ;height: 120px;object-fit:cover" alt="">
                            <div style="margin-right: 50px;margin-left: 10px;">
                                <div class="c_liName co3 fontS16" style="width: 310px; line-height: 20px;">
                                    {{ item.orderProduct.goods_name }}
                                </div>
                                <!-- 等于1则显示 -->
                                <div v-if="item.orderProduct.details" class="c_liGg fontS16 co9" style="margin-top: 10px;">
                               规格：{{item.orderProduct.details}}
                        </div>
                            </div>

                        </div>
                        <div style="padding-left: 20px;">
                            相符描述：<el-rate disabled v-model="item.start" />
                        </div>
                    </div>
                    <div style="padding-left: 20px;">
                        <div style="margin-top: 10px;">
                            评价：{{ item.comment_text }}
                        </div>
                        <div style="margin-top: 10px;">
                            <img v-for="(litem, lindex) in item.image" :key="lindex" :src="litem"
                                style="width: 80px ; height: 80px;object-fit:cover" alt="">
                        </div>
                    </div>
                    <div style="margin-top: 10px;padding-left: 20px;margin-bottom: 40px;">
                        卖家回复：{{item.reply}}
                    </div>
                </div>
                <div style="float: right;margin-right: 20px; margin-bottom: 20px;" class="flexJBC">
                    <img :src="item.member.avatar" alt="" style="width: 30px;height: 30px;margin-right: 10px;object-fit:cover">
                    <span>
                        {{ item.member.nickname }}
                    </span>
                </div>

            </div>
        </div>

        <!-- 删除 -->
        <el-dialog v-model="centerDialogVisible" title="" width="30%" center align-center :destroy-on-close="true" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
            <span>
                确认要删除该订单吗？
            </span>
            <template #footer>
                <span class="dialog-footer">
                    <el-button type="danger" color="#25459C" @click="sureDelAndsurDelivery">
                        确认
                    </el-button>
                    <button @click="centerDialogVisible = false;" class="btnClose delBtn">取消</button>
                </span>
            </template>
        </el-dialog>

    </div>
</template>
<style lang="less" scoped>
.box {
    width: 1300px;
    margin-top: 40px;

    box-sizing: border-box;
}

.demo-tabs>.el-tabs__content {
    padding: 32px;
    color: #6b778c;
    font-size: 32px;
    font-weight: 600;
}

/deep/.is-active {
    color: #25459C;
}

/deep/.el-tabs__active-bar {
    background-color: #25459C;
}

/deep/.el-tabs__item:hover {
    color: #25459C;
}

/deep/.el-tabs__item {
    font-size: 22PX;
}

.bord_l {
    border-left: 1px solid #CCCCCC;
    border-top: 1px solid #CCCCCC;
    // border-bottom: 1px solid #CCCCCC;
}

.li {
    width: 100%;

    // background-color: red;
    .title {
        background-color: #F6F7F9;
        padding: 16px 20px;
        box-sizing: border-box;
    }
}

.c_li {
    height: 200px;
    padding-top: 20px;
    padding-left: 20px;
    border-top: 1px solid #cccccc;
}

.c_li2 {
    // height: 200px;
    padding-top: 20px;
    padding-left: 20px;
    // border-top: 1px solid #cccccc;
}

.content_li {
    display: flex;

    .content2 {
        display: flex;
        flex-grow: 1;
    }
}
</style>