<script setup>
import { reactive, ref, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
const stepBarData = reactive({
  arrData: [
    {
      num: 1,
      txt: "提交订单",
    },
    {
      num: 2,
      txt: "运费复核",
    },
    {
      num: 3,
      txt: "支付订单",
    },
    {
      num: 4,
      txt: "支付完成",
    },
  ],
});
const getOrder=()=>{
  router.replace({
      path:'/order/orderHome',
      query:{
        title:1,
        id:1
      }
    });
}
const getHome=()=>{
  router.replace({
      path:'/',
    });
}
</script>
<template>
  <div class="toReviewBigBox">
    <div class="EnterprisrBoxCenter">
      <div class="navTitle">
        <img src="../../assets/logo.png" alt="" />
        <span style="margin:52px 0 0 10px;"> 运费复核 </span>
      </div>
    </div>
    <div class="toReviewBigToBox">
      <!-- 步骤条 -->
      <div class="StepBar">
        <div
          class="stepNumBox"
          v-for="(item, index) in stepBarData.arrData"
          :key="index"
        >
          <div
            class="stepNum"
            :class="index == 3 ? 'detalisLine' : ''"
            :style="{
              borderColor: index == 1 ? '#25459C' : '#999',
              color: index == 1 ? '#25459C' : '#999',
            }"
          >
            {{ item.num }}
          </div>
          <div
            class="stepTxt"
            :style="{ color: index == 1 ? '#25459C' : '#999' }"
          >
            {{ item.txt }}
          </div>
        </div>
      </div>
      <div class="toReviewTitleBox">
        <div class="toReviewTitle">运费复核中，请稍后……</div>
        <div class="toReviewMinTitle">
          请等待运费复核，复核成功后请在<span class="tipColor">30分钟</span
          >内继续支付
        </div>
      </div>
      <div class="toReviewTipBox">
        <div style="margin-bottom: 10px">什么是运费复核？</div>
        <div>
          提交订单后，客服会同货运公司二次核实运费，确保运费无误，避免多收、误收。这个
          过程会持续0～30分钟，请您耐心等待，复核完成后，最终需要支付的金额会有所变化，
          请在30分钟内继续支付。
        </div>
      </div>
      <div>
        <button
          class="sontS20 toReviewBtns"
          @click="getOrder()"
        >
          返回订单
        </button>
        <button  @click="getHome()"
          class="sontS20 toReviewBtn" >
          返回首页
        </button>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.toReviewBigBox {
  background: #f5f5f5;
}
.EnterprisrBoxCenter {
  padding-left: 200px;
  width: 100%;
  background: #fff;
  height: 100px;
  display: flex;
  align-items: center;
  .navTitle {
    width: 820px;
    display: flex;
    font-size: 20px;
    color: #333;
    img {
      width: 200px;
      height: 80px;
      vertical-align: middle;
    }
    // margin-bottom: 50px;
  }
}
.toReviewBigToBox {
  margin-left: 224px;
  .StepBar {
    margin: 64px 0 56px 0;
    display: flex;
    .stepNumBox {
      display: flex;
      flex-direction: column;
      align-content: center;
      width: 64px;
      margin-right: 110px;
      .stepNum {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        border: 1px solid #999999;
        text-align: center;
        line-height: 28px;
        position: relative;
        margin: 0 auto;
        margin-bottom: 6px;
      }
      .stepNum::after {
        content: "";
        display: block;
        width: 120px;
        height: 1px;
        border-top: 1px dashed #999999;
        position: absolute;
        top: 14px;
        left: 43px;
      }
      .detalisLine::after {
        border-top: 0 !important;
      }
    }
  }
  .toReviewTitleBox {
    margin-bottom: 50px;
    .toReviewTitle {
      font-size: 24px;
      color: #333;
      margin-bottom: 10px;
    }
    .toReviewMinTitle {
      font-size: 20px;
      color: #666;
      .tipColor {
        color: #25459C;
      }
    }
  }
  .toReviewTipBox {
    height: 124px;
    border: 1px dashed #25459C;
    background: #fff;
    font-size: 20px;
    color: #666;
    padding: 20px;
    width: 822px;
    line-height: 1.4;
    margin-bottom: 80px;
  }
}
.toReviewBtn{
  border: 2px solid #999999;
            background-color: white;
            color: #999;
            width: 240px;
            height: 50px;
            margin-bottom: 100px;
            margin-left: 40px;
}
.toReviewBtns{
  border: 0;
            background-color: #25459C;
            color: white;
            width: 240px;
            height: 50px;
            margin-bottom: 100px;
}
</style>