<script setup >
import { reactive, defineEmits, onBeforeMount } from 'vue'
import { update_real_auth, userget_user_info } from '@/utils/api'
import { ElMessage } from 'element-plus'
import { User } from "@element-plus/icons-vue";
onBeforeMount(() => {
    getUserinfo()
})

const data = reactive({
    name: '',
    card: '',
    isshow: true, //是否实名
});
const emit = defineEmits('changeShowIndex')
const submit = () => {
    update_real_auth({
        real_name: data.name,
        card_id: data.card
    }).then(res => {
        if (res.code == 200) {
            ElMessage.success('认证成功')
            emit('changeShowIndex', 3)
        }
    })
}
// 获取用户信息
const getUserinfo = () => {
    userget_user_info().then(res => {
        if (res.code == 200) {
            data.isshow = res.data.card_id
            if (data.isshow) {
                data.card = res.data.card_id.substring(0, 3) + "***********" + res.data.card_id.substr(res.data.card_id.length - 4);
                data.name = res.data.real_name.slice(0, 1)
                let le = data.name.length
                for (var i = 0; i <= le; i++) {
                    data.name += '*'
                }
            }
        }
    })
}
</script>

<template>
    <div class="right co3">
        <div class="r_info">
            <div class="r_title">
                <el-icon size="25" color="#666">
                    <User />
                </el-icon>
                <span style="margin-left:10px">实名认证</span>
            </div>
            <div class="r_content  ">
                <div>
                    <div style="margin:0 0 10px" class="fontS16">请输入真实姓名：</div>
                    <el-input class="input-with-select" v-model="data.name" placeholder="请输入" :disabled="data.isshow"
                        :input-style="{ width: '280px', height: '44px', }">
                    </el-input>
                </div>
                <div style="margin:20px 0 10px" class="fontS16">请输入身份证号：</div>
                <el-input class="input-with-select" v-model="data.card" placeholder="请输入号"
                    :input-style="{ width: '280px', height: '44px', }" :disabled="data.isshow">
                </el-input>
                <div class="btn cof fontS16 center cur" @click="submit" v-if="!data.isshow">确定</div> 
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
.right {
    width: 88%;

    /deep/.el-input__wrapper {
        height: 42px !important;
    }

    .btn {
        width: 87px;
        height: 40px;
        background: #FFC047;
        margin-top: 40px;
        line-height: 40px;
    }

    .r_title {
        width: 100%;
        height: 72px;
        background-color: #F6F7F9;
        padding: 20px 16px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        border: 1px solid #CCCCCC;
        border-bottom: 2px solid #CCCCCC;

        span {
            font-size: 24px;
            color: #666666;
        }
    }

    .r_content {
        padding: 40px 20px;
        box-sizing: border-box;
        height: 633px;
        border: 1px solid #CCCCCC;

    }
}
</style>

