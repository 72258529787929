// 导入axios实例
import httpRequest from './index'
import "./basData"
// 定义接口的传参
const url = 'https://netsea.aucfan-cn.com/pc/'
// const url = 'https://api.aucfan-cn.com/pc/'


// 登录
export function apiGetUserInfo(param: any) {
    return httpRequest({
        url: url + 'login/login',
        method: 'post',
        data: param,
    })
}
// 手机号登录 - 获取验证码
export function getloginsms(param: any) {
    return httpRequest({
        url: url + 'login/sms',
        method: 'post',
        data: param,
    })
}

// 获取用户普通信息
export function userget_user_info() {
    return httpRequest({
        url: url + 'user/get_user_info',
        method: 'post',
    })
}


// 获取首页及分类下轮播图
export function comgetAd(params: any) {
    return httpRequest({
        url: url + 'com/getAd',
        method: 'post',
        data: params
    })
}

// 获取首页平台大分类
export function Indexget_type(params: any) {
    return httpRequest({
        url: url + 'Index/get_type',
        method: 'post',
        data: params
    })
}

// 获取 热卖/热销商品
export function ProductindexProoduct(params: any) {
    return httpRequest({
        url: url + 'Product/indexProoduct',
        method: 'post',
        data: params
    })
}

// 获取 猜你喜欢
export function ProductgetGuesslike(params: any) {
    return httpRequest({
        url: url + 'Product/getGuesslike',
        method: 'post',
        data: params
    })
}

// 获取 天天特卖
export function ProductgetIndexTypeProduct(params: any) {
    return httpRequest({
        url: url + 'Product/getIndexTypeProduct',
        method: 'post',
        data: params
    })
}

// 获取 大分类下的小分类
export function ProductgetPlatFormTypeProductType(params: any) {
    return httpRequest({
        url: url + 'Product/getPlatFormTypeProductType',
        method: 'post',
        data: params
    })
}

// 获取 品牌特卖列表
export function ProductgetPlatformTypeProductBrand(params: any) {
    return httpRequest({
        url: url + 'Product/getPlatformTypeProductBrand',
        method: 'post',
        data: params
    })
}

// 文件上传
export function com_file(param: any) {
    return httpRequest({
        url: url + 'com/file',
        method: 'post',
        data: param,
    })
}
// 投诉建议获取分类
export function getComplaintCategory() {
    return httpRequest({
        url: url + 'com/getComplaintCategory',
        method: 'post',
    })
}
// 投诉建议
export function complaint(param: any) {
    return httpRequest({
        url: url + 'user/complaint',
        method: 'post',
        data: param,
    })
}
// 修改用户信息
export function update_user(param: any) {
    return httpRequest({
        url: url + 'user/update_user',
        method: 'post',
        data: param,
    })
}
// 获取区号信息
export function get_phone_prefix(param: any) {
    return httpRequest({
        url: url + 'com/get_phone_prefix',
        method: 'post',
        data: param,
    })
}
// 编辑新增地址
export function save_address(param: any) {
    return httpRequest({
        url: url + 'user/save_address',
        method: 'post',
        data: param,
    })
}
// 获取用户地址列表
export function get_address(param: any) {
    return httpRequest({
        url: url + 'user/get_address',
        method: 'post',
        data: param,
    })
}
// 删除用户地址列表
export function del_address(param: any) {
    return httpRequest({
        url: url + 'user/del_address',
        method: 'post',
        data: param,
    })
}

// 购物车列表
export function cartList(param: any) {
    return httpRequest({
        url: url + 'cart/list',
        method: 'post',
        data: param,
    })
}
// 删除购物车
export function cartDel(param: any) {
    return httpRequest({
        url: url + 'cart/del',
        method: 'post',
        data: param,
    })
}
// 购物车步进器
export function plus_or_minus(param: any) {
    return httpRequest({
        url: url + 'cart/plus_or_minus',
        method: 'post',
        data: param,
    })
}


// 根据分类来查找商品(严选)
export function getPlatformTypeProductStrict(param: any) {
    return httpRequest({
        url: url + 'Product/getPlatformTypeProductStrict',
        method: 'post',
        data: param,
    })
}

// 购物车订单预览
export function pre_order(param: any) {
    return httpRequest({
        url: url + 'cart/order/pre_order',

        method: 'post',
        data: param,
    })
}


// 获取(品牌或批 发商)的分类
export function getFactoryCategory(param: any) {
    return httpRequest({
        url: url + 'merchantStore/getFactoryCategory',

        method: 'post',
        data: param,
    })
}

// 创建订单
export function orderCreate(param: any) {
    return httpRequest({
        url: url + 'order/create',

        method: 'post',
        data: param,
    })
}


// (找品牌)品牌榜单接口(只返回前三条数据)
export function selectMerchantStoreRank(param: any) {
    return httpRequest({
        url: url + 'merchantStore/selectMerchantStoreRank',
        method: 'post',
        data: param,
    })
}

// 订单支付
export function orderPay(param: any) {
    return httpRequest({
        url: url + 'order/pay',

        method: 'post',
        data: param,
    })
}


// 找品牌
export function merchantStoregetStore(param: any) {
    return httpRequest({
        url: url + 'merchantStore/getStore',
        method: 'post',
        data: param,
    })
}

// 收藏列表
export function get_product_follow(param: any) {
    return httpRequest({
        url: url + 'user/get_product_follow',
        method: 'post',
        data: param,
    })
}
// 添加/删除收藏
export function store_follow(param: any) {
    return httpRequest({
        url: url + 'user/store_follow',
        method: 'post',
        data: param,
    })
}
// 关注列表
export function get_follow(param: any) {
    return httpRequest({
        url: url + 'user/get_follow',
        method: 'post',
        data: param,
    })
}
// 获取用户浏览记录 19
export function get_history(param: any) {
    return httpRequest({
        url: url + 'user/get_history',
        method: 'post',
        data: param,
    })
}
// 修改用户手机号发送验证码
export function sms(param: any) {
    return httpRequest({
        url: url + 'user/sms',
        method: 'post',
        data: param,
    })
}
// 编辑用户信息
export function update_user_auth(param: any) {
    return httpRequest({
        url: url + 'user/update_user_auth',
        method: 'post',
        data: param,
    })
}
// 实名认证
export function update_real_auth(param: any) {
    return httpRequest({
        url: url + 'user/update_real_auth',
        method: 'post',
        data: param,
    })
}
// 用户订单列表
export function user_order(param: any) {
    return httpRequest({
        url: url + 'user_order/list',
        method: 'post',
        data: param,
    })
}
// 猜你喜欢
export function getGuesslike(param: any) {
    return httpRequest({
        url: url + 'Product/getGuesslike',
        method: 'post',
        data: param,
    })
}
// 退款列表
export function refundList(param: any) {
    return httpRequest({
        url: url + 'refund/order/list',
        method: 'post',
        data: param,
    })
}
// 订单详情
export function orderDetails(param: any) {
    return httpRequest({
        url: url + 'user_order/details',
        method: 'post',
        data: param,
    })
}
// 退款订单详情
export function refundOrderDetails(param: any) {
    return httpRequest({
        url: url + 'refund/order/details',

        method: 'post',
        data: param,
    })
}
// 取消订单
export function ordercancel(param: any) {
    return httpRequest({
        url: url + 'user_order/cancel',
        method: 'post',
        data: param,
    })
}
// 取消订单
export function orderdel(param: any) {
    return httpRequest({
        url: url + 'user_order/del',
        method: 'post',
        data: param,
    })
}
// 确认收货
export function delivery(param: any) {
    return httpRequest({
        url: url + 'user_order/delivery',
        method: 'post',
        data: param,
    })
}
// 获取退货理由和订单取消原因
export function getProductReason(param: any) {
    return httpRequest({
        url: url + 'com/getProductReason',
        method: 'post',
        data: param,
    })
}
// 待评价 查询商品
export function findToEvaluateList(param: any) {
    return httpRequest({
        url: url + 'orderComment/findToEvaluateList',
        method: 'post',
        data: param,
    })
}
// 评价
export function addComment(param: any) {
    return httpRequest({
        url: url + 'orderComment/addComment',
        method: 'post',
        data: param,
    })
}
// 取消退款
export function refundCancel(param: any) {
    return httpRequest({
        url: url + 'refund/order/cancel',
        method: 'post',
        data: param,
    })
}
// 删除退款订单
export function refundDelete(param: any) {
    return httpRequest({
        url: url + 'refund/order/delete',
        method: 'post',
        data: param,
    })
}
// 商户入驻
export function merchantJoin(param: any) {
    return httpRequest({
        url: url + 'MerchantJoin/merchantJoin',
        method: 'post',
        data: param,
    })
}
// 商户入驻 详细信息
export function findMerchJoin(param: any) {
    return httpRequest({
        url: url + 'MerchantJoin/findMerchJoin',
        method: 'post',
        data: param,
    })
}
// 商品详情
export function findProduct(param: any) {
    return httpRequest({
        url: url + 'productDetail/findProduct',
        method: 'post',
        data: param,
    })
}
// 商品评价
export function selectProductComment(param: any) {
    return httpRequest({
        url: url + 'storeComment/selectProductComment',
        method: 'post',
        data: param,
    })
}
// 商品评价
export function cartStore(param: any) {
    return httpRequest({
        url: url + 'cart/store',
        method: 'post',
        data: param,
    })
}
// 直接购买商品订单预览
export function perProductOrder(param: any) {
    return httpRequest({
        url: url + 'proudct/order/perProductOrder',
        method: 'post',
        data: param,
    })
}
// 商品收缩
export function getGoods(param: any) {
    return httpRequest({
        url: url + 'Search/searchDataProduct',
        method: 'post',
        data: param,
    })
}
// 获取优惠券列表
export function get_coupon(param: any) {
    return httpRequest({
        url: url + 'coupon/get_coupon',
        method: 'post',
        data: param,
    })
}
// 店铺收缩
export function getShops(param: any) {
    return httpRequest({
        url: url + 'merchantStore/getStore',
        method: 'post',
        data: param,
    })
}
// 获取优惠券规则
export function findCouponRule(param: any) {
    return httpRequest({
        url: url + 'coupon/findCouponRule',
        method: 'post',
        data: param,
    })
}
// 工厂搜索
export function getFactorys(param: any) {
    return httpRequest({
        url: url + 'merchantStore/getFactory',
        method: 'post',
        data: param,
    })
}
// 领取优惠券
export function userReceiveCoupon(param: any) {
    return httpRequest({
        url: url + 'coupon/userReceiveCoupon',
        method: 'post',
        data: param,
    })
}
// 工厂详情
export function getShopDetail(param: any) {
    return httpRequest({
        url: url + 'merchantStore/findStoreDetails',
        method: 'post',
        data: param,
    })
}
// 用户优惠券
export function selectUserCoupon(param: any) {
    return httpRequest({
        url: url + 'coupon/selectUserCoupon',
        method: 'post',
        data: param,
    })
}
//详情页找商品
export function shopgetGoods(param: any) {
    return httpRequest({
        url: url + 'Search/searchStoreProduct',
        method: 'post',
        data: param,
    })
}
// 找相似获取商品
export function get_product(param: any) {
    return httpRequest({
        url: url + 'product/similar/get_product',
        method: 'post',
        data: param,
    })
}
// 找相似获列表
export function similarList(param: any) {
    return httpRequest({
        url: url + 'product/similar/list',
        method: 'post',
        data: param,
    })
}

// 工厂详情
export function factoryDetail(param: any) {
    return httpRequest({
        url: url + 'merchantStore/findFactoryDetails',
        method: 'post',
        data: param,
    })
}

// 分类
export function getCategory(param: any) {
    return httpRequest({
        url: url + 'com/getCategory',

        method: 'post',
        data: param,
    })
}

// 工厂的热门商品
export function factoryGoods(param: any) {
    return httpRequest({
        url: url + 'Product/getFactoryProduct',
        method: 'post',
        data: param,
    })
}
// 工厂详情的评论
export function factoryRe(param: any) {
    return httpRequest({
        url: url + 'Product/getFactoryProduct',
        method: 'post',
        data: param,
    })
}
// 海外专区头部商品
export function overSeagoods1(param: any) {
    return httpRequest({
        url: url + 'Product/getOverseaTop',
        method: 'post',
        data: param,
    })
}
// 海外专区根据分类找商品
export function overSeagoods2(param: any) {
    return httpRequest({
        url: url + 'Product/getPlatformTypeProductOverseas',
        method: 'post',
        data: param,
    })
}
// 待评价列表
export function waitEvaluate(param: any) {
    return httpRequest({
        url: url + 'orderComment/selectToEvaluateList',
        method: 'post',
        data: param,
    })
}
// 已评价列表
export function evaluated(param: any) {
    return httpRequest({
        url: url + 'orderComment/selectHaveEvaluation',
        method: 'post',
        data: param,
    })
}

// 搜索历史
export function findSearchHisory(param: any) {
    return httpRequest({
        url: url + 'Search/findSearchHisory',
        method: 'post',
        data: param,
    })
}
// 查询订单支付结果
export function getOrderPayStatus(param:any) {
    return httpRequest({ 
        url: url + 'order/getOrderPayStatus',
        method: 'post',
        data: param,
    })
}
// 首页自动领取优惠券
export function receiveCoupon(param:any) {
    return httpRequest({ 
        url: url + 'coupon/receiveCoupon',
        method: 'post',
        data: param,
    })
}
// 获取协议
export function getAgreement(param:any) {
    return httpRequest({ 
        url: url + 'com/getAgreement',
        method: 'post',
        data: param,
    })
}
// 企业认证
// 查询
export function findBuiness(param:any) {
    return httpRequest({ 
        url: url + 'Business/findBuiness',
        method: 'post',
        data: param,
    })
}
//识别企业信息
export function Business_qrcode(param: any) {
    return httpRequest({
        url: url + 'Business/qrcode',
        method: 'post',
        data: param,
    })
}
// 添加
export function Business_addBusiness(param: any) {
    return httpRequest({
        url: url + 'Business/addBusiness',
        method: 'post',
        data: param,
    })
}

// 添加备案
export function addBusinessRecord(param: any) {
    return httpRequest({
        url: url + 'BusinessRecord/addBusinessRecord',
        method:'post',
        data: param,
    })
}
// 编辑备案
export function editBusinessRecord(param: any) {
    return httpRequest({
        url: url + 'BusinessRecord/editBusinessRecord',
        method: 'post',
        data: param,
    })
}
// 变更营业执照
export function updateBusinessFile(param: any) {
    return httpRequest({
        url: url + 'Business/updateBusinessFile',
        method: 'post',
        data: param,
    })
}
// 易宝行政区域
export function getYeepayCity(param: any) {
    return httpRequest({
        url: url + 'BusinessRecord/getYeepayCity',
        method: 'post',
        data: param,
    })
}
//获取易宝国家地区代码表
export function getYeepayCountriesCity(param: any) {
    return httpRequest({
        url: url + 'BusinessRecord/getYeepayCountriesCity',
        method: 'post',
        data: param,
    })
}
//获取易宝经济类型代码
export function getYeepayEconomyCode(param: any) {
    return httpRequest({
        url: url + 'BusinessRecord/getYeepayEconomyCode',
        method:'post',
        data: param,
    })
}

//获取易宝行业所属代码
export function getYeepayIndustryCode(param:any) {
    return httpRequest({
        url: url + 'BusinessRecord/getYeepayIndustryCode',
        method: 'post',
        data: param,
    })
}
//获取易宝特殊经济类型代码
export function getYeepaySpecialCode(param: any) {
    return httpRequest({
        url: url + 'BusinessRecord/getYeepaySpecialCode',
        method: 'post',
        data: param,
    })
}
//企业资质查询
export function selectBusinessQualification(param: any) {
    return httpRequest({
        url: url + 'BusinessRecord/selectBusinessQualification',
        method: 'post',
        data: param,
    })
}
//获取添加备案可以带入的参数
export function findBusinessRecordBringInData(param: any) {
    return httpRequest({
        url: url + 'BusinessRecord/findBusinessRecordBringInData',
        method: 'post',
        data: param,
    })
}
//查询详情
export function findData(param: any) {
    return httpRequest({
        url: url + 'BusinessRecord/findData',
        method: 'post',
        data: param,
    })
}

//下载
export function downFile(param: any) {
    return httpRequest({
        url: url + 'downFile/downFile',
        method: 'post',
        data: param,
    })
}
// 上传文件

export function uploadRecordFile(param: any) {
    return httpRequest({
        url: url + 'BusinessRecord/uploadRecordFile',
        method: 'post',
        data: param,
    })
}

// 猜你喜欢 tab
export function getPlatformTypeProduct(param: any) {
    return httpRequest({
        url: url + 'Product/getPlatformTypeProduct',
        method: 'get',
        params: param,
    })
}
//查询公告列表
export function selectNotice(param: any) {
    return httpRequest({
        url: url + 'Index/selectNotice',
        method: 'post',
        data: param,
    })
}
//查询规则列表
export function selectRule(param: any) {
    return httpRequest({
        url: url + 'Index/selectRule',
        method: 'post',
        data: param,
    })
}

//查询公告详情
export function findNotice(param: any) {
    return httpRequest({
        url: url + 'Index/findNotice',
        method: 'post',
        data: param,
    })
}
//查询公告详情
export function findRule(param: any) {
    return httpRequest({
        url: url + 'Index/findRule',
        method: 'post',
        data: param,
    })
}

// app 二维码
export function selectQrcode(param: any) {
    return httpRequest({
        url: url + 'Index/selectQrcode',
        method: 'post',
        data: param,
    })
}
