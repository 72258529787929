<script setup >
import { reactive, ref, onBeforeMount } from "vue";
import {
  userget_user_info,
  update_user,
  get_phone_prefix,
  save_address,
  get_address,
  del_address,
  getAgreement,
  findBuiness,
} from "@/utils/api";
import { useStore } from "vuex";
import FormInstance from "element-plus";
import upImg from "@/components/upImg.vue";
import { ElMessage } from "element-plus";
import { User, Cellphone, ArrowRight } from "@element-plus/icons-vue";
// import AreaSelect from '@/components/address/address.vue'
import ChangePhone from "@/views/userInfo/ChangePhone.vue";
import { useRouter } from "vue-router";
import Authentication from "@/views/userInfo/authentication.vue";
import options from "@/components/address/city";
const store = useStore()
onBeforeMount(() => {
  getUserinfo();
  getadd();
  get_phone_prefix().then((res) => {
    if (res.code == 200) {
      optionsPhone.value = res.data;
    }
  });
  let routerIndex = router.currentRoute.value.query.index;
  if (routerIndex == 6) {
    getFindBuiness();
  }else if(routerIndex==2){
    isShowIndex.value = routerIndex;
  }
});
const router = useRouter();
const data = reactive({
  fileList: [], //上传图片列表
  image: [], //图片列表
  addList: [], // 地址列表
  editAddItem: {},
  id: "", //删除的id
  userInfo: {}, //用户所有的信息
  showPhoneOrrealName: 1, //1是实名认证 2是修改手机号
});
// 获取用户地址
const getadd = () => {
  get_address().then((res) => {
    if (res.code == 200) {
      data.addList = res.data;
    }
  });
};
const formLabelAlign = reactive({
  detail: "",
  username: "",
  phone: "",
  take_phone: "",
  take_name: "",
  is_default: false,
  phone_prefix: 86,
});
//地址
const changeAddress = (value) => {
  //地址选择
  console.log(value);
  address.value = value;
};
//新增地址  省市区
const address = ref([]);
const optionsPhone = ref([]); //区号列表
const dialogRules = ref(false); //协议弹窗
const showRulesinfo = ref(""); //协议内容

//删除显示
const dialogVisible = ref(false);
const editAddress = (item) => {
  console.log(item);
  data.editAddItem = JSON.parse(JSON.stringify(item));
  (formLabelAlign.detail = data.editAddItem.take_company_name), // address.value = [item.province + ',' + item.province_id, item.city + ',' + item.city_id, item.district + ',' + item.district_id, item.street + ',' + item.street_id]
    (formLabelAlign.is_default = data.editAddItem.is_default == 1),
    (formLabelAlign.phone = data.editAddItem.phone),
    (formLabelAlign.username = data.editAddItem.real_name),
    (formLabelAlign.take_name = data.editAddItem.take_name);
  formLabelAlign.take_phone = data.editAddItem.take_phone; // formLabelAlign.phone_prefix = data.editAddItem.phone_prefix,
  console.log(item);
};
const deladdress = () => {
  del_address({ id: data.id }).then((res) => {
    if (res.code == 200) {
      getadd();
      dialogVisible.value = false;
      ElMessage.success("删除成功！");
    } else {
      ElMessage.warning(res.message);
    }
  });
};

//密码设置
const ruleFormRef = ref(FormInstance);

const checkAge = () => {
  // setTimeout(() => {
  //     if (!Number.isInteger(value)) {
  //         callback(new Error('Please input digits'))
  //     } else {
  //         if (value < 18) {
  //             callback(new Error('Age must be greater than 18'))
  //         } else {
  //             callback()
  //         }
  //     }
  // }, 1000)
};

const validatePass = () => {
  // if (value === '') {
  //     callback(new Error('Please input the password'))
  // } else {
  //     if (ruleForm.checkPass !== '') {
  //         if (!ruleFormRef.value) return
  //         ruleFormRef.value.validateField('checkPass', () => null)
  //     }
  //     callback()
  // }
};
const validatePass2 = () => {
  // if (value === '') {
  //     callback(new Error('Please input the password again'))
  // } else if (value !== ruleForm.pass) {
  //     callback(new Error("Two inputs don't match!"))
  // } else {
  //     callback()
  // }
};

const ruleForm = reactive({
  pass: "",
  checkPass: "",
});

const rules = reactive({
  pass: [{ validator: validatePass, trigger: "blur" }],
  checkPass: [{ validator: validatePass2, trigger: "blur" }],
  age: [{ validator: checkAge, trigger: "blur" }],
});

const submitForm = () => {
  // if (!formEl) return
  // formEl.validate((valid) => {
  //     if (valid) {
  //         console.log('submit!')
  //     } else {
  //         console.log('error submit!')
  //         return false
  //     }
  // })
};

// 删除图片
const handleRemove = (file) => {
  console.log("shanchu", file);
  let fileJS = JSON.parse(JSON.stringify(file));
  const filePath = fileJS.response
    ? fileJS.response.data.file_path
    : fileJS.url;
  const index = data.image.findIndex((x) => x === filePath);
  data.image.splice(index, 1); 
};
// 图片上传成功
const handleSuccessed = (img, limit) => {
  // 如果limit==1
  if (limit) {
    data.image[0] = img;
    console.log(data.image[0], " data.image[0]");
  } else {
    data.image.push(img);
  }
};
let isShowIndex = ref(
  router.currentRoute.value.query.index
    ? router.currentRoute.value.query.index
    :1
);
const tochexk = (val) => {
  isShowIndex.value = val;
  if (val == 3) {
    getUserinfo();
  } else if (val == 6) {
    getFindBuiness();
  }
};

// 获取用户信息
const getUserinfo = () => {
  userget_user_info().then((res) => {
    console.log(res);
    if (res.code == 200) {
      formLabelAlign.name = res.data.nickname;
      data.image[0] = res.data.avatar;
      data.userInfo = res.data;
      data.fileList = [
        {
          name: "food.jpeg",
          url: res.data.avatar,
        },
      ];
    }
  });
};

const getFindBuiness = () => {
  let userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;
  let userData = {
    id: "",
  };
  if (userInfo !== null) {
    userData.id = userInfo.id;
  }
  // 企业入驻查询
  findBuiness(userData).then((res) => {
    if (res.data.length == 0) {
      enterpriseData.show = false;
    } else {
      enterpriseData.show = true;
      (enterpriseData.data.company_number = res.data.company_number), //税号
        (enterpriseData.data.email = res.data.email); //邮箱
      enterpriseData.data.mobile = res.data.mobile; //手机
      enterpriseData.data.company_name = res.data.company_name; //公司名字
      enterpriseData.data.company_addr = res.data.company_addr; //公司地址
      enterpriseData.data.company_legal = res.data.company_legal; //法人=
      enterpriseData.business_image = res.data.business_image;
    }
  });
};
// 修改用户信息 //添加 删除 图片提交
const onSubmit = (val) => {
  if (val == 1) {
    console.log(
      data.image,
      JSON.parse(JSON.stringify(data.image)),
      "data.image"
    );
    update_user({
      nickname: formLabelAlign.name,
      avatar: JSON.parse(JSON.stringify(data.image))[0],
    }).then((res) => {
      if (res.code == 200) {
        getUserinfo();
        let userObj={
          nickname: formLabelAlign.name,
          avatar:JSON.parse(JSON.stringify(data.image))[0]
        }
        store.commit("updateUserInfo",userObj);//修改头像
        ElMessage.success("修改成功！");
      }

    });
  }
  // 新增/编辑收货地址
  if (val == 2) {
    let ob = {};
    if (data.editAddItem.id) {
      ob = { id: data.editAddItem.id };
    }
    save_address(
      Object.assign(
        {
          real_name: formLabelAlign.username,
          phone: formLabelAlign.phone,
          take_company_name: formLabelAlign.detail,
          take_phone: formLabelAlign.take_phone,
          take_name: formLabelAlign.take_name,
          is_default: formLabelAlign.is_default ? 1 : 0,
        },
        ob
      )
    ).then((res) => {
      if (res.code == 200) {
        getadd();
        data.editAddItem = {};
        address.value = {};
        formLabelAlign.take_name = "";
        formLabelAlign.take_phone = "";
        formLabelAlign.detail = "";
        formLabelAlign.is_default = false;
        formLabelAlign.phone = "";
        formLabelAlign.username = "";
        if (ob.id) {
          ElMessage.success("编辑成功");
        } else {
          ElMessage.success("添加成功");
        }
      } else {
        ElMessage.warning(res.message);
      }
    });
  }
};
const showRules = (val) => {
  dialogRules.value = true;
  getAgreement({ type: val }).then((res) => {
    if (res.code == 200) {
      showRulesinfo.value = res.data;
    }
  });
};
// 企业认证
const enterpriseData = reactive({
  business_image: "", //营业执照图片地址
  show: false,
  data: {
    company_number: "", //税号
    email: "", //邮箱
    mobile: "", //手机
    company_name: "", //公司名字
    company_addr: "", //公司地址
    company_legal: "", //法人
  },
});
const getEnterprise = (val) => {
  if(store.state.showlogin==false){
    store.commit("updateInfo", true);
  }else{
    router.push({ path: val });
  }
};
</script>

<template>
  <!-- 协议弹窗 -->
  <el-dialog v-model="dialogRules" :title="showRulesinfo.name" width="1000px">
    <!-- <span>This is a message</span> -->
    <div v-html="showRulesinfo.text" style="line-height: 30px"></div>
    <template #footer>
      <div class="dialog-footer12 flex" style="justify-content: flex-end">
        <div
          style="border: 1px solid #ccc; padding: 10px 20px; float: right"
          @click="dialogRules = false"
          class="cur"
        >
          我已了解
        </div>
      </div>
    </template>
  </el-dialog>
  <div class="topHomeBox cof">
    <div class="wrapCenter flex">
      <img src="../../assets/logo1.png" alt="" class="logo" style="object-fit: cover" />
      <div class="homeText fontS20 cur"  @click="()=>router.push({ path: '/order/orderHome' })">
        个人中心
      </div>
      <div class="homeText fontS20 cur active"  style="line-height: 50px">
        账户设置
      </div>
    </div>
  </div>
  <div style="margin: 40px auto; display: flex" class="wrapCenter">
    <div class="left">
      <ul>
        <li :class="isShowIndex == 0 ? 'homeRed cur' : 'cur'">设置</li>
        <li
          @click="tochexk(1)"
          :class="isShowIndex == 1 ? 'homeRed cur' : 'cur'"
        >
          个人资料
        </li>
        <li
          @click="tochexk(6)"
          :class="isShowIndex == 6 ? 'homeRed cur' : 'cur'"
        >
          企业认证
        </li>
        <li
          @click="tochexk(2)"
          :class="isShowIndex == 2 ? 'homeRed cur' : 'cur'"
        >
          收货地址
        </li>
        <li
          @click="tochexk(3)"
          :class="isShowIndex == 3 ? 'homeRed cur' : 'cur'"
        >
          账户与安全
        </li>
        <!-- <li @click="tochexk(4)"  :class="isShowIndex==4?'homeRed':''">支付设置</li> -->
        <li
          @click="tochexk(5)"
          :class="isShowIndex == 5 ? 'homeRed cur' : 'cur'"
        >
          规则中心
        </li>
      </ul>
    </div>
    <div class="right">
      <!-- 修改手机号 -->
      <div v-if="isShowIndex == 0">
        <ChangePhone
          @changeShowIndex="tochexk"
          v-if="data.showPhoneOrrealName == 2"
        />
        <Authentication
          @changeShowIndex="tochexk"
          v-if="data.showPhoneOrrealName == 1"
        />
      </div>

      <div class="r_info" v-if="isShowIndex == 1">
        <div class="r_title">
          <img
            src="../../assets/Image/user.png"
            style="object-fit: cover"
            alt=""
          />
          <span>基本资料</span>
        </div>
        <div class="r_content">
          <el-form
            label-width="150px"
            :model="formLabelAlign"
            style="border: 1px solid #cccccc; padding-top: 20px"
          >
            <el-form-item label="当前头像：">
              <div style="">
                <upImg
                :fileList="data.fileList"
                :limit="1"
                @handleSuccessed="handleSuccessed"
                @handleRemove="handleRemove"
                :avatar="true"
                v-if="formLabelAlign.name"
              />
              </div>
              <!-- <el-avatar :size="110" src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" /> -->
            </el-form-item>
            <el-form-item label="当前昵称：">
              <el-input
                :input-style="{ width: '480px' }"
                v-model="formLabelAlign.name"
              />
            </el-form-item>
            <el-form-item>
              <span style="color: #999999; font-size: 16px"
                >昵称填写须知：与奈特喜业务或卖家品牌冲突的昵称，奈特喜将有可能收回</span
              >
            </el-form-item>
            <el-form-item>
              <el-button color="#FFC047" type="primary" @click="onSubmit(1)"
                >保存</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div
        calss="r_address"
        v-if="isShowIndex == 2"
        style="padding-bottom: 100px"
      >
        <div class="r_title">
          <img
            src="../../assets/Image/address.png"
            style="object-fit: cover"
            alt=""
          />
          <span>提货地址</span>
        </div>
        <div class="r_content">
          <div class="address_title" style="margin-top: 40px">
            {{ data.editAddItem.id ? "编辑" : " 新增" }}提货地址
          </div>
          <el-form
            label-width="150px"
            :model="formLabelAlign"
            style="
              margin-top: 10px;
              border: 1px solid #cccccc;
              padding-top: 20px;
            "
          >
            <el-form-item label="下单人：">
              <el-input
                :input-style="{ width: '480px' }"
                v-model="formLabelAlign.username"
                placeholder="请输入下单人"
              />
            </el-form-item>
            <el-form-item label="下单人手机号：">
              <div style="display: flex">
                <el-input
                  :input-style="{ width: '480px' }"
                  v-model="formLabelAlign.phone"
                  placeholder="请输入下单人手机号"
                />
              </div>
            </el-form-item>
            <el-form-item label="提货人：">
              <el-input
                :input-style="{ width: '480px' }"
                v-model="formLabelAlign.take_name"
                placeholder="请输入提货人"
              />
            </el-form-item>
            <el-form-item label="提货人手机号：">
              <div style="display: flex">
                <el-input
                  :input-style="{ width: '480px' }"
                  v-model="formLabelAlign.take_phone"
                  placeholder="请输入提货人手机号"
                />
              </div>
            </el-form-item>
            <el-form-item label="提货公司：">
              <el-input
                :input-style="{ width: '480px' }"
                v-model="formLabelAlign.detail"
                placeholder="请输入提货公司"
              />
            </el-form-item>
            <el-form-item>
              <div class="flex redioBox">
                <el-checkbox
                  v-model="formLabelAlign.is_default"
                  label=""
                  size="large"
                  class="myRedCheckBox"
                />
                <div style="margin-left: 10px">
                  <span class="co6 fontS16">设为默认地址</span>
                </div>
              </div>
            </el-form-item>
            <el-form-item>
              <el-button color="#FFC047" type="primary" @click="onSubmit(2)"
                >保存</el-button
              >
            </el-form-item>
          </el-form>
          <div class="address_title" style="margin-top: 40px">已有提货地址</div>
          <div
            style="
              width: 100%;
              margin-top: 10px;
              display: flex;
              flex-wrap: wrap;
            "
          >
            <div
              v-for="(item, index) in data.addList"
              :key="index"
              class="address_card"
            >
              <div
                class="default address_card_div"
                style="
                  width: 94px;
                  height: 32px;
                  text-align: center;
                  line-height: 32px;
                "
                :style="
                  item.is_default == 1
                    ? 'color:#25459C;border-color:#25459C'
                    : ''
                "
              >
                默认地址
              </div>
              <div
                style="
                  display: flex;
                  margin-top: 35px;
                  margin-bottom: 20px;
                  margin-left: 20px;
                  font-weight: bold;
                "
              >
                <div class="infotextleft">下单人：</div>
                <div class="infotextright">
                  {{ item.real_name }}
                </div>
              </div>
              <div
                style="
                  display: flex;
                  margin-top: 35px;
                  margin-bottom: 20px;
                  margin-left: 20px;
                  font-weight: bold;
                "
              >
                <div class="infotextleft">下单人手机号：</div>
                <div class="infotextright">
                  {{ item.phone }}
                </div>
              </div>
              <div
                style="
                  display: flex;
                  margin-top: 35px;
                  margin-bottom: 20px;
                  margin-left: 20px;
                  font-weight: bold;
                "
              >
                <div class="infotextleft">提货人：</div>
                <div class="infotextright">
                  {{ item.take_name }}
                </div>
              </div>
              <div
                style="
                  display: flex;
                  margin-top: 35px;
                  margin-bottom: 20px;
                  margin-left: 20px;
                  font-weight: bold;
                "
              >
                <div class="infotextleft">提货人手机号：</div>
                <div class="infotextright">
                  {{ item.take_phone }}
                </div>
              </div>
              <div
                style="
                  display: flex;
                  margin-top: 35px;
                  margin-bottom: 20px;
                  margin-left: 20px;
                  font-weight: bold;
                "
              >
                <div class="infotextleft">提货公司：</div>
                <div class="infotextright">
                  {{ item.take_company_name }}
                </div>
              </div>
              <div
                style="
                  display: flex;
                  margin-bottom: 20px;
                  margin-left: 20px;
                  font-weight: bold;
                  font-size: 16px;
                  color: #999999;
                "
              >
                <div style="margin-left: 305px; width: 100px; margin-top: 80px">
                  <span
                    type=""
                    link
                    :style="item.is_default == 1 ? 'color:#25459C' : ''"
                    @click="editAddress(item)"
                    class="fontS16 cur"
                  >
                    修改
                  </span>
                  <span>/</span>
                  <span
                    @click="
                      data.id = item.id;
                      dialogVisible = true;
                    "
                    class="fontS16 cur"
                  >
                    删除
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="r_safe" v-if="isShowIndex == 3">
        <div class="r_title" style="margin-bottom: 30px">
          <img
            src="../../assets/Image/safe.png"
            style="object-fit: cover"
            alt=""
          />
          <span>安全服务</span>
        </div>
        <div class="r_content">
          <div>
            <div
            v-if="false"
              class="r_content_safe"
              style="
                padding: 20px 74px 40px 0;
                border-top: 2px solid #cccccc;
                border-bottom: 2px solid #cccccc;
                font-weight: bold;
              "
            >
              <div class="flexJBC" >
                <div
                  style="width: 90px; justify-content: flex-start"
                  class="center flex"
                >
                  <div v-if="data.userInfo.card_id">
                    <img
                      src="../../assets/Image/right.png"
                      style="object-fit: cover"
                      alt=""
                    />
                    <div style="margin-top: 10px" class="co3 fontS16 fw7">
                      <span>已认证</span>
                    </div>
                  </div>
                  <div v-else>
                    <el-icon size="40" color="#333">
                      <User />
                    </el-icon>
                    <div style="margin-top: 10px" class="co3 fontS16 fw7">
                      <span>未认证</span>
                    </div>
                  </div>
                </div>
                <span class="co3 fontS16">实名认证</span>
                <span class="co3 fontS16"
                  >用于提升账号的安全性和信任级别。认证后的有卖家记录的账号不能修改认证信息。</span
                >
                <div
                  style="width: 109px"
                  class="center"
                  @click="
                    data.showPhoneOrrealName = 1;
                    isShowIndex = 0;
                  "
                >
                  <el-button class="blue" type="primary" text>{{
                    data.userInfo.card_id ? "已经认证" : "立即认证"
                  }}</el-button>
                </div>
              </div>
            </div>
            <div
              class="r_content_safe"
              style="
                padding: 20px 74px 40px 0;
                border-top: 2px solid #cccccc;
                border-bottom: 2px solid #cccccc;
                font-weight: bold;
              "
            >
              <div class="flexJBC">
                <div
                  style="width: 90px; justify-content: flex-start"
                  class="center flex"
                >
                  <div>
                    <el-icon size="40" color="#333">
                      <Cellphone />
                    </el-icon>
                    <div style="margin-top: 10px" class="co3 fontS16 fw7 co3">
                      <span>手机号</span>
                    </div>
                  </div>
                </div>
                <span class="co3 fontS16">修改手机号</span>
                <span class="co3 fontS16" style=""
                  >当你发现账户有风险或者你更换了手机号可以使用此服务来保障你的账户安全</span
                >
                <el-button
                  class="blue cur"
                  type="primary"
                  text
                  @click="
                    data.showPhoneOrrealName = 2;
                    isShowIndex = 0;
                  "
                  >修改手机号</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="r_pwd" v-if="isShowIndex == 4">
        <div class="r_title">
          <img
            src="../../assets/Image/key.png"
            style="object-fit: cover"
            alt=""
          />
          <span>支付密码</span>
        </div>
        <div
          class="r_content"
          v-if="false"
          style="
            padding: 20px;
            border: 1px solid #cccccc;
            border-top: 0px;
            height: 658px;
          "
        >
          <div
            class="r_content_safe"
            style="
              padding-bottom: 20px;
              background-color: #fdfdd3;
              padding-left: 50px;
              padding-top: 32px;
              padding-bottom: 32px;
              padding-right: 40px;
              height: 105px;
              box-sizing: border-box;
            "
          >
            <div class="flexJBC">
              <div class="flex">
                <img
                  style="margin-right: 20px; object-fit: cover"
                  src="../../assets/Image/right.png"
                  alt=""
                />
                <span
                  style="
                    margin-right: 90px;
                    font-size: 16px;
                    font-weight: bold;
                    color: #333;
                  "
                  >已设置支付密码</span
                >
              </div>

              <el-button class="blue" type="primary" text>修改</el-button>
            </div>
          </div>
        </div>
        <div
          class="r_content r_content_pwd"
          style="padding: 20px; border: 1px solid #cccccc; border-top: 0px"
        >
          <div class="r_content_safe r_content_safe1" style="">
            <el-form
              ref="ruleFormRef"
              :model="ruleForm"
              status-icon
              :rules="rules"
              label-width="120px"
              class="demo-ruleForm"
            >
              <el-form-item label="" prop="pass">
                <p>请设置六位数支付密码：</p>
                <el-input
                  v-model="ruleForm.pass"
                  type="password"
                  autocomplete="off"
                />
              </el-form-item>
              <el-form-item label="" prop="checkPass">
                <p>再次输入密码：</p>
                <el-input
                  v-model="ruleForm.checkPass"
                  type="password"
                  autocomplete="off"
                />
              </el-form-item>
              <el-form-item>
                <el-button
                  color="#FFC047"
                  type="primary"
                  @click="submitForm(ruleFormRef)"
                  >确认</el-button
                >
                <!-- <el-button @click="resetForm(ruleFormRef)">Reset</el-button> -->
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div class="r_rules flex" v-if="isShowIndex == 5">
        <div class="li flexJBC" @click="showRules(1)">
          <span>奈特喜平台隐私协议</span>
          <el-icon size="14px" color="#333">
            <ArrowRight />
          </el-icon>
        </div>
        <div class="li flexJBC" @click="showRules(3)">
          <span>平台评价规范</span>
          <el-icon size="14px" color="#333">
            <ArrowRight />
          </el-icon>
        </div>
        <div class="li flexJBC" @click="showRules(6)">
          <span>电商平台服务协议</span>
          <el-icon size="14px" color="#333">
            <ArrowRight />
          </el-icon>
        </div>
      </div>
      <div class="r_enterprise" v-if="isShowIndex == 6">
        <div class="r_title">
          <img
            src="../../assets/enterprise_1.png"
            style="object-fit: cover; width: 32px; height: 32px"
            alt=""
          />
          <span>企业认证</span>
        </div>
        <div class="r_content r_border r_center_enterprise">
          <!-- 未认证 -->
          <div class="NotCertified" v-if="!enterpriseData.show">
            <div class="NotCertifiedLeft">
              <div>
                <div class="imgIcon"></div>
                <div style="margin-top: -10px">未认证</div>
              </div>
              <div class="NotCertifiedTitlle">企业认证</div>
              <div>认证后即可查看商品价格及购买商品</div>
            </div>
            <div class="submitBtn" @click="getEnterprise('/enterpriseReg')">
              立即认证
            </div>
          </div>
          <!-- 认证 -->
          <div v-else>
            <div class="centerOne">
              <div>营业执照上传</div>
              <!-- <div > -->
              <img class="oneImg" :src="enterpriseData.business_image" alt="" />
              <!-- </div> -->
            </div>
            <div class="centerTow">
              <div>电子邮箱：</div>
              <div>{{ enterpriseData.data.email }}</div>
            </div>
            <div class="centerTow">
              <div>手机号码：</div>
              <div>{{ enterpriseData.data.mobile }}</div>
            </div>
            <div class="centerTow">
              <div>公司法人：</div>
              <div>{{ enterpriseData.data.company_legal }}</div>
            </div>
            <div class="centerTow">
              <div>公司名称：</div>
              <div>{{ enterpriseData.data.company_name }}</div>
            </div>
            <div class="centerTow">
              <div>公司税号：</div>
              <div>{{ enterpriseData.data.company_number }}</div>
            </div>
            <div class="centerTow">
              <div>公司地址：</div>
              <div>{{ enterpriseData.data.company_addr }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 删除的对话框 -->
  <el-dialog v-model="dialogVisible" :show-close="false" width="400px" :close-on-click-modal="false" :close-on-press-escape="false" >
    <span class="co6 fontS20">确认删除这条地址吗？</span>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" color="#25459C" @click="deladdress">
          确认
        </el-button>
        <button  @click="dialogVisible = false" class="btnClose delBtn">取消</button>
      </span>
    </template>
  </el-dialog>
</template>

<style lang="less">
.touxiang .el-upload-list--picture-card .el-upload-list__item{
  border: none !important;
}
.touxiang .el-upload-list{
  border-radius: 50% !important;
  overflow: hidden !important;
}
.touxiang .avatar-uploader{
  border-radius: 50% !important;
}
.touxiang .el-upload-list--picture-card .el-upload-list__item-thumbnail{
  object-fit: unset !important;
  border-radius: 50% !important;
}
.imgAvatar{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
}
.topHomeBox {
  height: 100px;
  background: #25459C;
  display: flex;
  justify-content: center;
  align-items: center;
  .homeText {
    margin-left: 37px;
    margin-right: 80px;
    margin-top: 32px;
  }
  .active {
    line-height: 50px;
    border-bottom: 4px solid #fff;
  }
  .logo {
    // width: 140px;
    height: 62px;
    // margin-right: 32px;
    // margin: 19px 0;
  }
}
.infotextright {
  font-size: 18px;
  color: #333333;
  font-weight: bold;
  line-height: 22px;
  padding-right: 20px;
}
.infotextleft {
  font-size: 16px;
  color: #666666;
  font-weight: bold;
  line-height: 22px;
  min-width: 80px;
}
.r_enterprise {
  .r_center_enterprise {
    padding: 0 20px;
  }
  // 未认证
  .NotCertified {
    display: flex;
    justify-content: space-between;
    height: 630px;
    padding-top: 10px;
    .NotCertifiedLeft {
      display: flex;
      line-height: 50px;
      font-size: 16px;
      color: #333333;
      .NotCertifiedTitlle {
        margin: 0 88px 0 52px;
      }
      .imgIcon {
        width: 40px;
        height: 40px;
        background: url(../../assets/enterprise_2.png) no-repeat;
        border: 1px dashed #cccccc;
        background-size: 100% 100%;
      }
    }
    .submitBtn {
      line-height: 50px;
      color: #5c7dcd;
      margin-right: 74px;
    }
  }
  .centerOne {
    margin-top: 20px;
    font-size: 20px;
    color: #333333;
  }
  .r_border {
    border: 1px solid rgb(204, 204, 204);
  }
  .oneImg {
    margin: 20px 0;
    width: 430px;
    height: 250px;
    background: #ffffff;
    padding: 6px;
    opacity: 1;
    border: 1px solid #707070;
  }
  .centerTow {
    display: flex;
    margin-bottom: 20px;
    font-size: 16px;
    color: #333333;
  }
}
.r_rules {
  padding-left: 80px;
  flex-wrap: wrap;

  /deep/.el-dialog__body {
    p {
      line-height: 25px !important;
    }
  }

  // .dialog-footer12 {
  // }

  .li {
    width: 400px;
    height: 80px;
    line-height: 400px;
    background: #ffffff;
    border: 2px solid #999;
    padding: 0 30px;
    box-sizing: border-box;
    margin-bottom: 40px;
    margin-right: 40px;
  }

  .li:hover {
    border-color: #25459C;
  }
}

.r_content_safe1 {
  padding-bottom: 20px;
  padding-left: 50px;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-right: 40px;
  height: 652px;
  box-sizing: border-box;
}

.redioBox {
  /* 设置带边框的checkbox，选中后边框的颜色 */
  .myRedCheckBox.is-bordered.is-checked {
    border-color: #25459C !important;
  }

  /* 设置选中后的文字颜色 */
  .myRedCheckBox .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #25459C !important;
  }

  /* 设置选中后对勾框的边框和背景颜色 */
  .myRedCheckBox /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
  .myRedCheckBox .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    border-color: #25459C !important;
    background-color: #25459C !important;
  }

  /* 设置checkbox获得焦点后，对勾框的边框颜色 */
  .myRedCheckBox /deep/.el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #25459C !important;
  }

  /* 设置鼠标经过对勾框，对勾框边框的颜色 */
  .myRedCheckBox /deep/.el-checkbox__inner:hover {
    border-color: #25459C !important;
  }
}

.r_content_pwd:deep(.el-input) {
  width: 280px;
}

.r_content_pwd:deep(.el-form-item__content) {
  display: block;
}

.blue {
  color: #5c7dcd !important;
  font-size: 16px;
  font-weight: bold;
}

:deep(.el-form-item__label) {
  font-size: 16px;
  color: #333333;
}

:deep(.el-button--primary) {
  color: white;
}

:deep(.el-form-item) {
  display: flex;
  align-items: center;
}

:deep(.el-input__wrapper) {
  width: 618px;
}

.m-2 {
  :deep(.el-input__wrapper) {
    width: 100px;
    margin-right: 10px;
  }
}

.read-the-docs {
  color: #888;
}

.address_card {
  width: 33.3%;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
}

.address_card_div {
  border: 2px solid #cccccc;
  color: #cccccc;
  position: relative;
  top: -1px;
  left: -1px;
}

.left {
  width: 12%;

  ul {
    li {
      font-size: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

.right {
  width: 88%;

  .r_title {
    width: 100%;
    height: 72px;
    background-color: #f6f7f9;
    padding: 20px 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border: 1px solid #cccccc;
    // border-bottom: 2px solid #CCCCCC;
    // border-bottom: 0;

    img {
      margin-right: 10px;
    }

    span {
      font-size: 24px;
      color: #666666;
    }
  }

  .r_content {
    // padding: 20px;

    .address_title {
      font-size: 16px;
      color: #333333;
      font-weight: bold;
    }
  }
}
</style>

