

<template>
  <div class="wrapCenterpx">
    <div class="Dailysale">
      <!-- <div class="titleBox">
   <div class="titleLogo">
     <img src="../../../assets/Image/like.png" alt="" />
     <div>猜你喜欢</div>
   </div>
 </div> -->
      <div class="DailysaleList" v-if="likeList && likeList.length">
        <div class="list" v-for="item in likeList" :key="item.id"
          @click="router.push({ path: '/goodsDetail', query: { id: item.id,navA:navActive } })">
          <img :src="item.image" alt="" style="object-fit:cover"/>
          <div class="name elipes1">{{ item.product_name }}</div>
        
          <div class="Price">
            <div class="yesPrice"><span class="￥">￥</span>{{ item.price }}</div>
            <div class="saleType">{{ item.sales }}人付款</div>
          </div>
        </div>


      </div>
      <el-empty v-else description="暂无商品" />
    </div>
  </div>
</template>

<script setup>
import { defineProps, } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
defineProps({
  //子组件接收父组件传递过来的值
  likeList: Array,
  navActive:Number
});

// const emit = defineEmits(["chageBrand"]);
</script>

<style scoped lang="less">
.wrapCenterpx {
  width: 1472px;
  margin: 0 auto;
  margin-top: 40px;


  .Dailysale {
    width: 1472px;
    background: #ffffff;
    margin: 0 auto;
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-height: 600px;

    .DailysaleList {
      flex: 1;
      // background: pink;
      display: flex;
      //   justify-content: space-between;
      flex-wrap: wrap;

      .list:nth-child(4n) {
        margin-right: 0;
      }

      .list {
        margin-right: 16px;
        width: 336px;
        // background: #ccc;
        margin-bottom: 40px;
        cursor: pointer;

        img {
          width: 336px;
          height: 336px;
          margin-bottom: 20px;
        }

        .name {
          font-size: 20px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          //   text-align: center;
          line-height: 30px;
          margin-bottom: 16px;
        }

        .saleType {
          font-size: 18px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #999999;
          //   text-align: center;
          //   margin-bottom: 15px;
        }

        .Price {
          display: flex;
          //   justify-content: space-between;
          align-items: center;

          .yesPrice {
            // text-align: right;
            font-size: 28px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #DD2131 ;
            // flex: 1;
            margin-right: 30px;
          }

          .noPrice {
            // text-align-last: left;
            font-size: 18px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            line-height: 18px;
            color: #999999;
            // flex: 1;
          }

          .￥ {
            font-size: 20px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
          }
        }
      }
    }
  }
}
</style>
