<template>
    <div>
      <!-- :destroy-on-close="true" -->
      <el-dialog v-model="getDelShow" title="" width="405px" center align-center  :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
              <span>
                  确认将删除这些宝贝吗？
              </span>
              <template #footer>
                  <span class="dialog-footer">
                      <el-button type="danger"  color="#25459C" @click="handleSuccessed">
                          确认
                      </el-button>
                      <button  @click="handRemove" class="btnClose delBtn">
                          取消
                      </button>
                  </span>
              </template>
          </el-dialog>
    </div>
  </template>
  
  <script setup>
      import {defineProps,defineEmits,computed } from 'vue';
      const props=defineProps({
          delShow:{
              default:false,
              type:Boolean
          }
      })
      const emit=defineEmits(['update:delShow','handleSuccessed','handRemove'])
      const getDelShow=computed({
          get:()=>props.delShow,
          set:(val)=> {emit('update:delShow',val)}
      })
      const handleSuccessed=()=>{
      emit('handleSuccessed',false)
  }
  const handRemove=()=>{
      emit('handRemove',false)
  }
  </script>
  
  <style lang="less" >
  
  </style>