<template>
  <div class="userLikeBox "  v-infinite-scroll="loadLike"  style="max-height:1610px;  overflow-anchor: none; overflow: auto"  infinite-scroll-distance="30px" :infinite-scroll-immediate="false"  :infinite-scroll-disabled="false">
         <div class="userLikeTab fontW">
            <el-scrollbar>
                <div class="scrollbar-flex-content cur">
                    <p v-for="(item,index) in userLike" :class="activeIndex==index?'activeTab':''" :key="index" class="fontS20 scrollbar-demo-item" @click="getTabCategory(item.store_category_id,index)">
                        {{item.cate_name}}
                    </p>
                </div>
            </el-scrollbar>
         </div>
         <div class="userLikeCenter">
            <div class="likeitem cur" v-for="(item,index) in likeList" :key="index" @click="router.push({path:'/goodsDetail',query:{id: item.id}})">
                <div class="likeItemImg"><img :src="item.image" alt="" style="object-fit:cover"></div>
                <div class="likeTitle fontS22 textClamp2">
                   {{ item.product_name}}
                </div>
                <div class="likePrice flex">
                    <div class="priceColor fontS20">¥ <span class="fontS28">{{item.price}}</span></div>
                    <div class="textColor9 fontS18 m-l14" style="margin-top: 7px;">{{item.pay_number}}人付款</div>
                </div>
            </div>
            <el-empty description="暂无产品" v-if="likeList.length == 0" />
         </div>
     </div>
</template>

<script setup>
import {ref,reactive,onMounted,computed} from 'vue'
import {ProductgetGuesslike,getCategory,getPlatformTypeProduct} from "@/utils/api";
import { useRouter } from "vue-router";
const router = useRouter()
const activeIndex=ref(0)
const userLike=ref([])
// -- 猜你喜欢
const likeList = ref([]);
onMounted(()=>{
    userLikes()
    getCate()
})

const likeUser=reactive({
    page:1,
    limit:12,
    category_id:0
})
const likeLast_page=ref(1)
const userLikes=()=>{
    ProductgetGuesslike(likeUser).then((res) => {
        likeLast_page.value = res.data.last_page;
        if (likeUser.page == 1) {
          likeList.value = res.data.data;
          console.log(likeList.value,'likeList.value')
        } else {
          likeList.value = likeList.value.concat(res.data.data);
          console.log(likeList.value,'data.list2')
        }
    });
}
const loadLike = () => {
  if (likeUser.page < likeLast_page.value) {
        likeUser.page++
       if(activeIndex.value==0){
         userLikes()
       }else{
        getPlatProduct()
       }
  }
};
// 左边导航
const getCate=()=>{
     getCategory({ is_tree: 0,level:1 }).then(res => {
        if (res.code == 200) {
            userLike.value = res.data
            userLike.value.unshift({store_category_id:0,pid: 0,cate_name: "猜你喜欢",pic: ""})
            console.log( userLike.value,'res')

        }
    })
}
// 一级导航的数据
const getTabCategory=(id,index)=>{
    console.log(index)
    activeIndex.value=index
    likeUser.page=1
    if(id==0){
        userLikes()
    }else{
        likeUser.category_id=id
        getPlatProduct()
    }
    
}
const getPlatProduct=()=>{
    getPlatformTypeProduct(likeUser).then(res => {
            if (res.code == 200) {
                likeLast_page.value = res.data.last_page;
                if (likeUser.page == 1) {
                likeList.value = res.data.data;
                } else {
                likeList.value = likeList.value.concat(res.data.data);
                }
            }
        })
}

</script>

<style lang="less" scoped>
.el-empty{
    margin: auto;
}
.userLikeBox{
    width: 1472px;
    background: #fff;
    border-radius: 8px 8px 8px 8px;
    padding: 30px 0 30px 0;
    margin: auto;
    margin-top: 32px;
    .userLikeTab{
        // width: 1392px;
        // // overflow: hidden;
        // overflow-x: scroll;
        // display:flex;
        // flex-wrap: nowrap;
        .scrollbar-flex-content {
        display: flex;
        }
.scrollbar-demo-item {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
//   width: 100px;
  height: 50px;
  margin: 10px;
  text-align: center;
  border-left: 1px solid #eee;
//   background: var(--el-color-danger-light-9);
color: #999;
padding: 0 10px 0 25px;
}
        .tabCenter{
            display: inline-block;
            // width: 210px;
            height: 48px;
            color: #999;
            border-left: 1px solid #eee;
            text-align: center;
            line-height:48px;
            padding: 0 20px;
           
        }
        :nth-child(1){
                border-left: none !important;
            }
        .activeTab{
        color: #1F4A92;
        }
    }
   .userLikeCenter{
    display: flex;
    flex-wrap: wrap;
    margin:32px 20px 0 20px;
    .likeitem{
        width: 336px;
        margin-left: 16px;
        margin-bottom: 40px;
    }
    .likeItemImg{
        width:336px;
        height: 336px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .likeTitle{
        margin: 20px 0 16px 0;
        line-height: 1.5;
    }
   }
}

</style>