
import { defineComponent, reactive, toRefs } from 'vue';
import { TUIEnv } from '../TUIKit/TUIPlugin';


export default defineComponent({
    name: 'App',
    setup() {
        const data = reactive({
            env: TUIEnv(),
            currentModel: 'conversation',
        });
        const handleCurrentConversation = (value: string) => {
            data.currentModel = value ? 'message' : 'conversation';
        };
        return {
            ...toRefs(data),
            handleCurrentConversation,
        };
    },
});
