 <script setup>
import { reactive, ref,onBeforeMount, onMounted } from "vue";
// import { ElTable, FormInstance } from "element-plus";
import { ElMessage } from "element-plus";
// import businessRecord from './components/businessRecord.vue'
import {
  Business_addBusiness,
  Business_qrcode,
  getAgreement,
  selectBusinessQualification,
  userget_user_info
} from "@/utils/api";
import upImg from "@/components/upImg.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { fa } from "element-plus/es/locale";
const store = useStore();
const router = useRouter();
const formEnterprisr = reactive({
  company_number: "", //税号
  email: "", //邮箱
  mobile: "", //手机
  company_name: "", //公司名字
  company_addr: "", //公司地址
  business_image: "", //营业执照图片地址
  company_legal: "", //法人
});
const imgEnter = reactive({
  business_image: "",
});
const centerDialogVisible = ref(false);
const checkIs = ref(false);
const timeNum=ref(5)
const businessType=ref(0)//步骤条
const submitEnter = () => {
  if (checkIs.value == false) {
    ElMessage.warning("请先点击同意奈特喜会员协议");
    return false;
  }

  Business_addBusiness(formEnterprisr).then((res) => {
    if (res.code == 200) {
      let userInfos = JSON.parse(localStorage.getItem("userInfo"));
      userInfos.company_status = 1;
      localStorage.setItem("userInfo", JSON.stringify(userInfos));
      store.commit("updateCompanyStatus", 1);
      localStorage.setItem("CompanyStatus", 1);
      businessType.value = 2;
      ElMessage.success("提交成功");
        const timer = setInterval(() => {
          console.log(timeNum.value)
          timeNum.value--
          if (timeNum.value == '-1') {
            timeNum.value = '0'
            clearInterval(timer)
            selectBusiness()
          }
        }, 1000);
    } else {
      ElMessage.warning(res.message);
    }
  });
};
// 删除图片
const handleRemove = (file) => {
  console.log(file);
};
// 图片上传成功
const handleSuccessed = (img, limit) => {
  imgEnter.business_image = img;
  Business_qrcode(imgEnter).then((res) => {
    formEnterprisr.company_number = res.data.creditCode || ""; //税号
    formEnterprisr.company_name = res.data.companyName; //公司名字
    formEnterprisr.company_addr = res.data.businessAddress; //公司地址
    formEnterprisr.business_image = img; //营业执照图片地址
    formEnterprisr.company_legal = res.data.legalPerson; //法人
  });
};
const getEnterpriseRouter = () => {
  centerDialogVisible.value = false;
  router.replace({ path: "/set", query: { index: 6 } });
};
const getIndex = () => {
  centerDialogVisible.value = false;
  router.replace({ path: "/" });
};
const getHttpText = () => {
  getAgreement({ type: 7 }).then((res) => {
    dialogRules.value = true;
    if (res.code == 200) {
      showRulesinfo.value = res.data;
    }
  });
};
const dialogRules = ref(false); //协议弹窗
const showRulesinfo = ref(""); //协议内容
// 同意协议
const getCheck = (val) => {
  checkIs.value = val;
};
const userData=ref(null)
const userInfo=()=>{
    userget_user_info().then(res => {
        if(res.code==200){
            userData.value = res.data
            if(userData.value.company_status==0){
              businessType.value = 0;
            }
            if(userData.value.company_status==3){
              selectBusiness()
            }
        console.log(userData.value ,'res')
        }
      })
}
// 添加备案
onBeforeMount(()=>{
  userInfo()
})


// 企业资质查询
const zhiStatus=ref(0)//企业资质查询状态
const chongxinLoading=ref(false)
const selectBusiness=()=>{
  chongxinLoading.value=true
  selectBusinessQualification({}).then(res=>{
    if(res.code==200){
      chongxinLoading.value=false
      zhiStatus.value=res.data.status
      if(zhiStatus.value==1){//是A类
        businessType.value = 3;
      }else if(zhiStatus.value==2){//不是A类资质
        businessType.value = 4;
      }
    }else{
      chongxinLoading.value=false
      ElMessage.warning(res.message);
    }
  })
}

// 跳转首页
const getUrlHome=()=>{
  router.replace({ path: "/" });
}
// 跳 备案
const goBeian=()=>{
  router.replace({ path:"/order/orderHome" , query: { index: 6 } });
}
</script>
 <template>
  <div class="EnterprisrBox">
    <div class="EnterprisrBoxCenter">
      <div class="navTitle">
        <img src="../../assets/logo.png" alt="" />
        <span class="homeText"> 填写注册信息 </span>
      </div>
    </div>
    <!-- 步骤条 -->
    <div class="flex" style="justify-content: center" @click="click1">
      <div class="flex bzTitle fontS24">
        <div v-if="businessType==0" class=" zcT titleNum">1</div>
        <div v-else ><img src="@/assets/Group134.png" alt="" /></div>
        <div class="m-20 activeTxt">企业认证</div>
      </div>
      <div class="nomalLine fontS18"  :class="businessType>=2?'lineW':''">认证已通过</div>
      <div class="flex bzTitle fontS24">
        <div v-if="businessType<=2" class="zcT">2</div>
        <div  v-else >
        <div v-if="zhiStatus==2"><img src="@/assets/Group4273.png" alt="" /></div>
          <div v-else><img src="@/assets/Group134.png" alt="" /></div>
        </div>
        
        <div class="m-20 nomalTxt"  :class="zhiStatus==1?'activeTxt':''" :style="{color:zhiStatus==2&&businessType==4?'#DE2626':''}">A类资质审核</div>
      </div>
      <div class="nomalLine fontS18"  :class="zhiStatus==1?'lineW':''">审核已通过</div>
      <div class="flex bzTitle fontS24">
        <div class="zcT" >3</div>
        <div class="m-20 nomalTxt ">采购商备案</div>
      </div>
    </div>
    <!-- 第一步 -->
    <div v-if="businessType==0" class="EnterprisrBoxCenter" style="margin-top:57px">
      <div class="EnterprisrTitle">营业执照上传</div>
      <div class="EnterprisrCenter">
        <upImg
          :fileList="formEnterprisr.fileList"
          :limit="1"
          @handleSuccessed="handleSuccessed"
          @handleRemove="handleRemove"
          :enterpriseShow="false"
        >
        </upImg>
      </div>
      <el-form :model="formEnterprisr">
        <div class="formCenter">
          <el-form-item label="电子邮箱：">
            <el-input
              :input-style="{ width: '260px' }"
              v-model="formEnterprisr.email"
              placeholder="联系邮箱"
            />
          </el-form-item>
          <el-form-item label="手机号码：" class="marginLeft">
            <el-input
              :input-style="{ width: '260px' }"
              v-model="formEnterprisr.mobile"
              placeholder="请输入手机号码"
            />
          </el-form-item>
        </div>
        <div class="formCenter">
          <el-form-item label="公司名称：">
            <el-input
              :input-style="{ width: '260px' }"
              v-model="formEnterprisr.company_name"
              placeholder="请输入公司名称"
            />
          </el-form-item>
          <el-form-item label="公司法人：" class="marginLeft">
            <el-input
              :input-style="{ width: '260px' }"
              v-model="formEnterprisr.company_legal"
              placeholder="请输入公司法人"
            />
          </el-form-item>
        </div>
        <el-form-item label="公司税号：">
          <el-input
            :input-style="{ width: '260px' }"
            v-model="formEnterprisr.company_number"
            placeholder="请输入公司税号"
          />
        </el-form-item>
        <el-form-item label="公司地址：">
          <el-input
            :input-style="{ width: '710px' }"
            v-model="formEnterprisr.company_addr"
            :autosize="{ minRows: 2, maxRows: 4 }"
            type="textarea"
            placeholder="请输入公司地址"
          />
        </el-form-item>
        <el-form-item>
          <div class="redioBox" style="margin-left: 86px">
            <el-checkbox
              v-model="checkIs"
              @change="getCheck"
              label=""
            ></el-checkbox>
            <div class="tipHttp">
              创建网站账号的同时：我同意遵守<span
                class="importantTip"
                @click="getHttpText()"
                >奈特喜会员协议</span
              >-愿意接收相关来自奈特喜的会员及服务邮件
            </div>
          </div>
        </el-form-item>
        <div class="formCenter" style="padding-left:25px">
          <el-form-item>
            <el-button
              color="#25459C"
              type="primary"
              class="submitBtn"
              @click="submitEnter()"
              >确认提交</el-button
            >
          </el-form-item>
        </div>
      </el-form>
    </div>
    <!-- 协议弹窗 -->
    <el-dialog v-model="dialogRules" :title="showRulesinfo.name" width="1000px">
      <!-- <span>This is a message</span> -->
      <div v-html="showRulesinfo.text" style="line-height: 30px"></div>
      <template #footer>
        <div class="dialog-footer12 flex" style="justify-content: flex-end">
          <div
            style="border: 1px solid #ccc; padding: 10px 20px; float: right"
            @click="dialogRules = false"
            class="cur"
          >
            我已了解
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
  <el-dialog
    v-model="centerDialogVisible"
    width="30%"
    align-center
    :show-close="false"
    :close-on-click-modal="false"
  >
    <template #title>
      <div class="DialogCenter">
        <img src="../../assets/enterSucces.png" alt="" /><span class="centerTxt"
          >提交成功，账号申请审核通过</span
        >
      </div>
    </template>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          type="primary"
          color="#25459C"
          class="dialogBtn"
          @click="getEnterpriseRouter()"
        >
          确认
        </el-button>
        <el-button @click="getIndex()" class="dialogBtn">返回首页</el-button>
      </span>
    </template>
  </el-dialog>
  <!-- 第二步 企业认证成功 -->
  <div class="enterpriseSucee" v-if="businessType==2">
    <div class="suceImg"><img src="@/assets/Group115.png" alt="" /></div>
    <div class="fontS28 sucTitle">企业认证成功</div>
    <div class="fontS18 sucTitleMin">
      <div>所提交内容已成功完成验证</div>
      <div>正在进行A类资质验证，请稍后... ({{timeNum}})</div>
    </div>
  </div>
  <!-- 第三步 A类值 -->
  <div class="enterpriseSucee" v-if="businessType==3||businessType==4">
    <div class="suceImg" v-if="zhiStatus==2">
      <img src="@/assets/Group131.png" alt="" />
    </div>
    <div class="suceImg" v-if="zhiStatus==1">
      <img src="@/assets/Group115.png" alt="" />
    </div>
    <div class="fontS28 sucTitle" style="color: red" v-if="zhiStatus==2">
      A类资质验证未通过
    </div>
    <div class="fontS28 sucTitle" v-if="zhiStatus==1">
      A类资质验证完成，是否继续进行采购商备案？
    </div>
    <div class="fontS18 sucTitleMin normalW" v-if="zhiStatus==2">
      <div>
        未通过原因：该企业不具备A类资质或未在外汇局进行过名录登记(可前往全国企业征信系统查询)
      </div>
      <div>
        目前已可以查看商品价格，根据国家政策要求，采购商购买跨境产品需要具备A类资质
      </div>
    </div>
    <div class="fontS18 sucTitleMin normalW" v-if="zhiStatus==1">
      <div>
        目前已可以查看商品价格，根据国家政策要求，采购商购买跨境产品需要在银行进行备案。
      </div>
    </div>
    <div class="btncenter fontS18 flex">
      <div class="btnleft" @click="getUrlHome">暂不验证，先看商品</div>
      <el-button type="primary" :loading="chongxinLoading" class="btnright" @click="selectBusiness" v-if="zhiStatus==2" style="height:54px;font-size: 18px;">重新验证</el-button>
      <div class="btnright"  @click="goBeian"  v-if="zhiStatus==1">立即备案</div>
    </div>
  </div>
 
</template>
 
 <style lang="less" scoped>
//  步骤条
.zcT{
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: #999999;
  border:  1px solid #999999;
  border-radius: 50% !important;
}
.titleNum{
  color: #25459c !important;
  border: 1px solid #25459c !important;
}

.nomalTxt{
  color: #999999;
}
.activeTxt{
  color: #25459c !important;
}
.nomalLine{
  text-align: center;
  margin-top: 20px;
  padding-top: 10px;
  margin: 20px 52px 0 52px;
  border-top: 2px dashed #999999;
  width: 300px;
  color: #999999;
}
.lineW {
  border-top: 2px dashed #25459c !important;
  color: #25459c !important;
}

.huiTxt{

}
.m-20 {
  margin-left: 20px;
}
.bzTitle {
  color: #25459c;
}
//  第二步
.enterpriseSucee {
  margin-top: 200px;
  .sucTitle {
    margin: 0 auto;
    text-align: center;
    color: #333;
    margin-top: 24px;
    margin-bottom: 30px;
  }
  .suceImg {
    margin: 0 auto;
    width: 80px;
    height: 80px;
  }
  .sucTitleMin {
    margin: 0 auto;
    width: 294px;
    color: #999;
    line-height: 28px;
    text-align: center;
  }
  .btncenter {
    margin-top: 60px;
    justify-content: center;
    .btnleft,
    .btnright {
      padding: 18px 48px !important;
      background: #25459c;
      color: #fff;
      border-radius: 4px !important;
    }
    .btnleft {
      margin-right: 161px;
    }
  }
  .normalW {
    width: 763px !important;
  }
}
.EnterprisrBox {
  /deep/.el-upload-list--picture-card .el-upload-list__item {
    width:360px !important;
    height: 280px !important;
  }
  /deep/.el-upload {
    border: none;
    width: 360px;
    height: 200px !important;
  }
  max-width: 1920px;
  margin: 0 auto;
  .EnterprisrBoxCenter {
    width: 820px;
    padding: 0 224px;
  }
  .navTitle {
    display: flex;
    align-content: center;
    img {
      height: 62px;
    }
    margin-bottom: 50px;
    margin-top: 16px;
    .homeText {
      margin-left: 37px;
      margin-right: 80px;
      margin-top: 32px;
    }
  }
  .marginLeft {
    margin-left: 80px;
  }
  font-size: 20px;
  color: #333333;
  font-weight: 600;
  .EnterprisrCenter {
    margin: 20px 0;
    .centerImg {
      width: 600px;
      height: 300px;
      border: 1px dashed #e5e5e5;
      margin-top: 10px;
      // background-image: url(/assets/Group33508.png);
      // background-repeat: no-repeat;
      // background-size: 100%;
    }
  }
  .formCenter {
    display: flex;
    width: 100%;
  }
  .submitBtn {
    width: 118px;
    height: 40px;
    border-radius: 0 !important;
  }
  .redioBox {
    margin-left: 18px;
    display: flex;
  }
  /deep/.el-checkbox__inner {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  /deep/.el-checkbox__inner::after {
    height: 10px;
    left: 6px;
    top: 2px;
    width: 6px;
  }
  /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #25459c;
    border-color: #25459c;
  }
  /deep/.el-radio__inner {
    width: 18px;
    height: 18px;
  }
  /deep/.el-checkbox__inner:hover {
    border-color: #25459c;
  }
  .tipHttp {
    font-size: 16px;
    color: #666666;
    margin-left: 10px;
  }
  .importantTip {
    color: #25459c;
    cursor: pointer;
  }
}
.DialogCenter {
  display: flex;
  color: #333333;
  .centerTxt {
    margin-left: 16px;
    font-size: 20px;
    line-height: 2;
  }
}
.dialogBtn {
  width: 86px;
  height: 40px;
  border-radius: 0 !important;
}
</style>