<script setup>
import { reactive, onBeforeMount, } from 'vue';
import { useRouter } from 'vue-router';
import {  getGuesslike,refundOrderDetails } from '@/utils/api'
const router = useRouter()
const data = reactive({
    detailInfo: {}, //详细信息
    page: 1,
    last_page: 1,
    likeList: [],//猜你喜欢
    query: router.currentRoute.value.query
})
onBeforeMount(() => {
    refundOrderDetails({refund_id:data.query.refund_id}).then(res=>{
            if(res.code==200){
                data.detailInfo = res.data
            }
    })
    // 猜你喜欢
    getLike()
})
const load = () => {
    if (data.last_page > data.page) {
        data.page++;
        getLike()
    }
}
const getLike = () => {
    getGuesslike({ page: data.page, limit: 12 }).then(res => {
        if (res.code == 200) {
            data.last_page = res.data.last_page
            if (data.page == 1) {
                data.likeList = res.data.data
            } else {
                data.likeList = data.likeList.concat(res.data.data)
            }
        }
    })
}

</script>
<template>
    <div class="wrapCenter" >
        <img src="../../assets/logo.png" style="object-fit:cover" alt="" class="logoimg">
        <div class="co9 fontS16">首页 > 个人中心 > 已买到的商品 </div>
        <div class="orderStatus flexJBC marT2">
            <div>
                <span class="co3 fontS20" style="margin-right:30px">当前订单状态：{{ data.detailInfo.refund_type_text }}</span>
               <span class="co6 fontS16">{{ data.detailInfo.status_text }}</span>
               <span class="priceColor" style="margin-left:20px">￥{{ data.detailInfo.refund_price }}</span>
            </div>
            <div class="btnBox fontS16 flex" v-if="data.detailInfo.status=='-2'">
                <div class="btn cof cur">再次申请</div>
            </div> 
        </div>
        <div class="co3 fontS16 shouhuoBox">
            <div>收货地址</div>
            <div style="margin:10px 0 40px">
                {{ data.detailInfo.real_name }}，{{ data.detailInfo.user_phone }}，{{data.detailInfo.user_address}}
            </div>
            <div>订单详情</div>
        </div>
        <div class='goodsBox fontS16 co3 '>
            <div class="flex">
                <div>订单编号：{{ data.detailInfo.refund_sn }}</div>
                <!-- <div style="margin-left:107px">交易方式：微信支付</div> -->
                <div style="margin-left:107px">创建时间：{{ data.detailInfo.create_time }}</div>
                <!-- <div style="margin-left:107px">下单时间：2022-11-12 15:35:35</div> -->
            </div>
            <div class="tableHeade fontS18 co6 flex">
                <div style="margin-left:265px ">宝贝</div>
                <div style="margin-left:354px">宝贝属性</div>
                <div style="margin-left:158px">状态</div>
                <div style="margin-left:151px">单价</div>
                <div style="margin-left:223px">数量</div>
            </div>
            <!-- 待收货 / 待评价 / 交易关闭显示 -->
            <div class="daishouhuo fontS16 co6 flex" v-if="data.detailInfo.status==2">
                <div>商家收货信息</div> 
                <!-- <div style="margin-left:53px">顺丰速运</div>
                <div class="marL2">快递单号:0992320993239</div> -->
                <div style="color: #5C7DCD;" class="marL2">{{ data.detailInfo.mer_delivery_user }} &nbsp; &nbsp; {{ data.detailInfo.mer_delivery_phone }}  &nbsp; &nbsp;  {{ data.detailInfo.mer_delivery_address }}</div>
                <div class="co6 marL2">更多</div>
            </div>
            <div class="table fontS16 flex" style="align-items:flex-start" >
                    <div  class="flex"
                        style="align-items:flex-start">
                        <div class="flex left box">
                            <img style="object-fit:cover" :src="data.detailInfo.goods.image" alt="" class="goodsimg">
                            <div class="elipes2  co3" style="line-height:25px">{{  data.detailInfo.goods.product_name }}</div>
                        </div>
                        <div class="box co9" style="width:274px">
                            <div v-if="data.detailInfo.goods.detail">规格：{{data.detailInfo.goods.detail}}</div>
                        </div>
                        <div style="width: 160px;" class="box co3 center">{{ data.detailInfo.status_text }}</div>
                        <div style="width: 220px;" class="box co3 center">{{ data.detailInfo.goods.product_price }}</div>
                        <div style="width: 220px;" class=" box center">{{ data.detailInfo.goods.product_num }}</div>
                    </div>
            </div>
            <div class="total">
                <div class="co3 fontS16">
                    <div class="flex">
                        <span class="name">商品总价：</span>
                        <span>¥{{ data.detailInfo.total_price }}</span>
                    </div>
                    <div class="flex">
                        <span class="name">运费：</span>
                        <span>¥{{ data.detailInfo.goods.postage_price }}</span>
                    </div>
                    <div class="flex">
                        <span class="name">优惠：</span>
                        <span>¥{{ data.detailInfo.goods.coupon_price }}</span>
                    </div>
                    <div class="flex">
                        <span class="name">实付款：</span>
                        <span class="homeRed">¥{{((Number( data.detailInfo.total_price)+Number(data.detailInfo.goods.postage_price))-Number(data.detailInfo.goods.coupon_price)).toFixed(2) }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="youLikeBox marT2">
            <div class="flexJBC">
                <div class="flex">
                    <span class="homeRed fontS28">猜你喜欢</span>
                </div>
                <el-icon size="40px " color="#999">
                    <RefreshRight />
                </el-icon>
            </div>
            <div class="printLogFather" infinite-scroll-distance="30px" v-infinite-scroll="load"
                style="height:92vh;overflow: auto;" >
                <div class="list marT2"> 
                    <el-row :gutter="16">
                        <el-col :span="6" v-for="item in data.likeList" :key="item.id">
                            <div class="li">
                                <img :src="item.image" alt="" style="object-fit:cover" >
                                <div class="title elipes2 co3 fonrS22">{{ item.product_name }}</div>
                                <div class="flex">
                                    <span class="priceColor fontS12">￥</span>
                                    <span class="priceColor fontS28">{{ item.price }}</span>
                                    <span class="fontS18 co9" style="margin-left:14px">{{item.sales}}人付款</span>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="less" scoped>
.daishouhuo {
    height: 62px;
    line-height: 62px;
    background: #E5EEFD;
    border: 1px solid #CCCCCC;
    padding: 0 20px;
    box-sizing: border-box;

}

.youLikeBox {
    .list {

        flex-wrap: wrap;
        // padding: 0 40px;
        box-sizing: border-box;
        justify-content: flex-start;

        .li {
            width: 336px;
            position: relative;

            .title {
                margin: 20px 0 16px;
                line-height: 28px;
            }

            margin-bottom: 40px;
            margin-right: 16px;

            img {
                width: 336px;
                height: 336px;
                display: block;
            }
        }
    }

    background: #FFFFFF;
    padding: 20px;
    box-sizing: border-box;
    border: 1px solid #CCCCCC;
}

.goodsBox {
    .total {
        display: flex;
        justify-content: flex-end;
        padding-top: 20px;

        .name {
            // margin-right: 97px;
            width: 200px;
            margin-top: 10px;
        }
    }

    .table {
        .left {

            width: 554px;
            align-items: flex-start;
        }

        .box {
            height: 203px;
            padding: 20px;
            box-sizing: border-box;
            border-right: 1px solid #CCCCCC;
        }

        background: #FFFFFF;
        border: 1px solid #CCCCCC;

        .goodsimg {
            width: 120px;
            height: 120px;
            margin-right: 10px;
        }
    }

    .tableHeade {
        height: 58px;
        line-height: 58px;
        background: #F6F7F9;
        border: 1px solid #CCCCCC;
        margin-top: 40px;
    }

    margin-top: 10px;
    padding:20px;
    box-sizing:border-box;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
}

.shouhuoBox {
    padding: 0 20px;
    box-sizing: border-box;
}

.orderStatus {

    .btnBox {
        .btn2 {
            width: 143px;
            height: 40px;
            background: #FFFFFF;
            border: 1px solid #666666;
            text-align: center;
            line-height: 40px;
        }

        .btn1 {
            width: 143px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background: #FFC047;
        }

        .btn {
            width: 143px;
            height: 40px;
            background: #25459C;
            text-align: center;
            line-height: 40px;
            margin-right: 80px;
        }
    }

    height: 108px;
    background: rgba(37, 69, 156, 0.2);
    border: 1px solid #25459C;
    padding: 0 119px 0 20px;
    box-sizing: border-box;
    margin-bottom: 40px;
}

.logoimg {
    width: 160px;
    height: 62px;
    margin: 40px 0 80px;
}
</style>