<script setup>
import { reactive, ref } from 'vue'
import { ElTable } from 'element-plus'
const multipleTableRef = ref()
const multipleSelection = ref([])
const toggleSelection = (rows) => {
  if (rows) {
    rows.forEach((row) => {
      // TODO: improvement typing when refactor table
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      multipleTableRef.value(row, undefined)
    })
  } else {
    multipleTableRef.value()
  }
}
const handleSelectionChange = (val) => {
  multipleSelection.value = val
}

const tableData= [
  {
    date: '2016-05-03',
    name: 'Tom',
    address: 'No. 189, Grove St, Los Angeles',
  },
  {
    date: '2016-05-02',
    name: 'Tom',
    address: 'No. 189, Grove St, Los Angeles',
  },
  {
    date: '2016-05-04',
    name: 'Tom',
    address: 'No. 189, Grove St, Los Angeles',
  },
  {
    date: '2016-05-01',
    name: 'Tom',
    address: 'No. 189, Grove St, Los Angeles',
  },
  {
    date: '2016-05-08',
    name: 'Tom',
    address: 'No. 189, Grove St, Los Angeles',
  },
  {
    date: '2016-05-06',
    name: 'Tom',
    address: 'No. 189, Grove St, Los Angeles',
  },
  {
    date: '2016-05-07',
    name: 'Tom',
    address: 'No. 189, Grove St, Los Angeles',
  },
]



</script>

<template>
    <div class="wrapCenter">
        <div class="top flex">
            <img src="../../assets/logo.png" style="width: 150px;height: 60px;object-fit:cover" alt="">
            <span class="fontS20 co3">购物车</span>
        </div>
        <div>
            <el-table ref="multipleTableRef" :data="tableData" style="width: 100%"
                @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55" />
                <el-table-column label="商品信息" width="400">
                    <template #default="scope">{{ scope.row.date }}</template>
                </el-table-column>
                <el-table-column property="name" label="单价" width="250" />
                <el-table-column property="name" label="数量" width="250" show-overflow-tooltip />
                <el-table-column property="name" label="金额" width="250" show-overflow-tooltip />
                <!-- <el-table-column property="name" label="" width="250" show-overflow-tooltip /> -->
                <el-table-column label="操作" width="250">
                    <span>删除</span>
                </el-table-column>
            </el-table>
            <div style="margin-top: 20px">
                <el-button @click="toggleSelection([tableData[1], tableData[2]])">Toggle selection status of second and
                    third rows</el-button>
                <el-button @click="toggleSelection()">Clear selection</el-button>
            </div>
        </div>
    </div>
</template>


<style lang="less" scoped>

</style>
