<script  setup>
import  SwiperCore, { Navigation, A11y}from 'swiper'
import { Swiper, SwiperSlide, useSwiper } from "swiper/vue/swiper-vue";
import 'swiper/swiper-bundle.css';
SwiperCore.use([Navigation, A11y]);
import searchTopNew from "@/components/searchTopNew.vue"; //搜索组件
import {
  Star,
  Delete,
  StarFilled,
  ArrowUp,
  ChatDotRound,
} from "@element-plus/icons-vue";
import { ref, reactive, onBeforeMount } from "vue";
import {
  findProduct,
  cartList,
  cartDel,
  store_follow,
  selectProductComment,
  cartStore,
  get_address,
  userReceiveCoupon,
} from "@/utils/api";
import { ArrowDown } from "@element-plus/icons-vue";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import delModel from '@/components/delModel'
const store = useStore();
const router = useRouter();
const activeName = ref(1);
const showcoupone = ref(false); //优惠券折叠
const num = ref(1); //单规格时购买的数量
const activeEval = ref(1); //evaluate的当前项id
const activeImg = ref("0"); // 图片当前项
const activeGuige = ref(0); // 普通商品当前选中的大规格
const centerDialogVisible = ref(false); //evaluate的当前项
const data = reactive({
  code: 200,
  id: router.currentRoute.value.query.id, //详情id
  info: null, //详细信息
  delId: "", //删除购物车id
  cartList: [], //购物车列表
  page: 1, //评论page
  selList: [],
  evaluate: [
    { name: "全部", id: 1, num: 0 },
    { name: "最新", id: 2, num: 0 },
    { name: "图片/视频", id: 3, num: 0 },
    { name: "好评", id: 4, num: 0 },
    { name: "中评", id: 5, num: 0 },
    { name: "差评", id: 6, num: 0 },
  ],
  productImg:[],
  CollectionNum:0,//收藏数字
  CollectionColor:0,//收藏icon颜色
  // navActive:router.currentRoute.value.query.navA?router.currentRoute.value.query.navA:0//导航选中
});
const cartShow = ref(true);
onBeforeMount(() => {
  getInfo();
  // 购车列表
  getCartList();
  selectProduct();
});
// console.log(router.currentRoute.value.query.navA,'navActive')
const getInfo = () => {
  console.log( data.id,'data.id')
  findProduct({ product_id: data.id }).then((res) => {
    data.code = res.code;
    if (res.code == 200) {
      data.info = res.data;
      data.CollectionNum=data.info.care_count//收藏
      data.CollectionColor=data.info.focus//收藏icon颜色
      if(data.info.slider_image.length>0){
        data.productImg=data.info.slider_image.split(',')
        console.log(data.productImg,'data.productImg')
      }
      // 视频
      if(data.info.video_link!=''){
        data.productImg.unshift(data.info.video_link)
      }
      // 多规格
      if (data.info.spec_type == 1) {
        data.info.new_attr.forEach((item) => {
          item.attr.forEach((item1) => {
            if(item1.attrDetails.length==0)return false
            item1.attrDetails.data.forEach((item2) => {
              // 每一个规格的数量
              item2.attrDetails_num = 0;
            });
          });
        });
      } else {
        data.info.attrDetails.forEach((item) => {
          item.data.forEach((item1) => {
            item1.attrDetails_num = 0;
          });
        });
      }
      
    }
  });
};
// 购车列表
const getCartList = () => {
  cartList({ page: 1, limit: 10 }).then((res) => {
    if (res.code == 200) {
      data.cartList = [];
      res.data.data.forEach((item) => {
        item.goods.forEach((item1) => {
          data.cartList.push(item1);
        });
      });
    }
  });
};
// 删除购物车
const sureDel = () => {
  cartDel({ id: data.delId }).then((res) => {
    if (res.code == 200) {
      ElMessage.success("删除成功！");
      centerDialogVisible.value = false;
      getCartList();
    }
  });
};

// 收藏 和 取消收藏
const follow = () => {
  store_follow({ type: "2", id: String(data.info.id) }).then((res) => {
    if (res.code == 200) {
      data.CollectionColor=data.CollectionColor==1?0:1//修改收藏icon颜色 
      if(res.message=="收藏成功"){
        data.CollectionNum++
      } else if(res.message=='移除收藏'){
        data.CollectionNum--
      }
      ElMessage.success(res.message);
    } else {
      ElMessage.warning(res.message);
    }
  });
};

// 评价列表
const selectProduct = (val) => {
  if (val) {
    activeEval.value = val;
    data.page = 1;
  }
  selectProductComment({
    page: data.page,
    product_id: data.id,
    type: activeEval.value,
    limit: 10,
  }).then((res) => {
    if (res.code == 200) {
      let result = res.data.result;
      data.evaluate[0].num = result.all_number;
      data.evaluate[1].num = result.new_number;
      data.evaluate[2].num = result.image_number;
      data.evaluate[3].num = result.good_number;
      data.evaluate[4].num = result.the_number;
      data.evaluate[5].num = result.poor_number;
      if (data.page == 1) {
        data.selList = res.data.data;
      } else {
        data.selList = data.selList.concat(res.data.data);
      }
    }
  });
};
// 加入购物车
const addCart = () => {
  // 多规格
  let data1 = {
    data: [],
  };
  let allnum = 0;
  if (data.info.spec_type == 1) {
    // console.log(data.info.spec_type,'data.info.spec_type')
    // 获取所选规格
    data.info.new_attr.forEach((item) => {
      item.attr.forEach((item1) => {
        item1.attrDetails.data.forEach((item2) => {
          allnum += item2.attrDetails_num;
          if (
            item2.attrDetails_num != 0 &&
            item2.attrDetails_num < item2.wholesale_number
          ) {
            ElMessage.warning("未达到起批量");
            cartShow.value = false;
            return false;
          } else {
            if (item2.attrDetails_num > 0) {
              cartShow.value = true;
              data1.data.push({
                goods_id: item.product_id,
                num: item2.attrDetails_num,
                sku_id: item2.id,
              });
            }
          }
        });
      });
    });
  } else {

    // 单规格
    data.info.attrDetails.forEach((item) => {
      item.data.forEach((item1) => {
    console.log('进入12345',item1)
        if (
          item1.attrDetails_num != 0 &&
          item1.attrDetails_num < item1.wholesale_number
        ) {
          ElMessage.warning("未达到起批量");
          cartShow.value = false;
          return false;
        } else {
          allnum += item1.attrDetails_num;
          if (item1.attrDetails_num > 0) {
            cartShow.value = true;
            data1.data.push({
              goods_id: item.product_id,
              num: item1.attrDetails_num,
              sku_id: item1.id,
            });
          }
        }
      });
    });
  }
  if(allnum == 0){
    ElMessage.warning("未达到最低购买量");
  }
  if (cartShow.value == false) {
    return false;
  }
  if (allnum > 0) {
    data1.data = JSON.stringify(data1.data);
    cartStore(data1).then((res) => {
      if (res.code == 200) {
        getCartList();
        ElMessage.success(res.message);
      } else if (res.code != 204) {
        ElMessage.warning(res.message);
      }
    });
  } else {
    ElMessage.warning("未达到最低购买量");
  }
};
// 立即购买
const gopay = () => {
  let data1 = {
    data: [],
  };
  let allnum = 0;
  if (data.info.spec_type == 1) {
    // 获取所选规格
    data.info.new_attr.forEach((item) => {
      item.attr.forEach((item1) => {
        item1.attrDetails.data.forEach((item2) => {
          if (
            item2.attrDetails_num != 0 &&
            item2.attrDetails_num < item2.wholesale_number
          ) {
            ElMessage.warning("未达到起批量");
            cartShow.value = false;
            return false;
          } else {
            allnum += item2.attrDetails_num;
            if (item2.attrDetails_num > 0) {
              cartShow.value = true;
              data1.data.push({
                product_id: item.product_id,
                number: item2.attrDetails_num,
                sku_id: item2.id,
              });
            }
          }
        });
      });
    });
  } else {
    data.info.attrDetails.forEach((item) => {
      item.data.forEach((item1) => {
        if (
          item1.attrDetails_num != 0 &&
          item1.attrDetails_num < item1.wholesale_number
        ) {
          ElMessage.warning("未达到起批量");
          cartShow.value = false;
          return false;
        } else {
          allnum += item1.attrDetails_num;
          if (item1.attrDetails_num > 0) {
            cartShow.value = true;
            data1.data.push({
              product_id: item.product_id,
              number: item1.attrDetails_num,
              sku_id: item1.id,
            });
          }
        }
      });
    });
  }
  if(allnum == 0){
    ElMessage.warning("未达到最低购买量");
  }
  if (cartShow.value == false) {
    return false;
  }
  if (allnum > 0) {
    data1.data = JSON.stringify(data1.data);
    get_address().then((res) => {
      if (res.code == 200 && res.data.length > 0) {
        router.push({
          path: "/sureOrder",
          query: {
            orderinfo: data1.data,
          },
        });
      } else {//跳转到收货地址页面编辑
        ElMessage.warning("你还没有添加收货地址，快去添加吧");
        setTimeout(() => {
            router.push({
            path: '/set',
            query:{index:2},
           })
        }, 1000);
      }
    });
  } else {
    ElMessage.warning("请先添加数量");
  }
};
const getuserReceiveCoupon = (id) => {
  userReceiveCoupon({ id: id }).then((res) => {
    if (res.code == 200) {
      getInfo();
      ElMessage.success("领取成功");
    } else {
      ElMessage.warning(res.message);
    }
  });
};
// 联系客服
const toTUIKit = () => {
  let token=localStorage.getItem('token')
  if (!token) {
    store.commit("updateInfo", true);
    return;
  } else {
    localStorage.setItem("tencent_user_id", data.info.im_data.tencent_user_id);
    router.push("/tUIKit");
  }
};
</script>
<template>
  <div>
    <!-- 删除提示 -->
    <delModel v-model:delShow="centerDialogVisible"  @handleSuccessed="sureDel" @handRemove="centerDialogVisible = false;"></delModel>
    <searchTopNew></searchTopNew>

    <!-- 顶部 -->
    <div class="wrapCenter" v-if="data.info && data.code == 200">
      <div class="nav">
        <!-- <div class="fontS16 co9">首页 > 服饰 > 女装 > 商品详情</div> -->
        <!-- <el-page-header>
                    <template #content>
                        <el-breadcrumb separator="/">
                            <el-breadcrumb-item :to="{ path: './page-header.html' }">首页</el-breadcrumb-item>
                            <el-breadcrumb-item><a href="./page-header.html">服饰</a></el-breadcrumb-item>
                            <el-breadcrumb-item><a href="./page-header.html">女装</a></el-breadcrumb-item>
                            <el-breadcrumb-item>商品详情</el-breadcrumb-item>
                        </el-breadcrumb>
                    </template>
                </el-page-header> -->
      </div>
      <div class="flexJBC" style="align-items: flex-start">
        <div class="imgBox">
         <div class="imgCenterBox">
          <video class="goodsimg" :src="data.productImg[activeImg]" v-if="data.productImg[activeImg].indexOf('.mp4')!==-1"  ref="myVideo" :controls="true" :control="true"
          ></video>
          <img :src="data.productImg[activeImg]" alt="" class="goodsimg" object-fit="cover" v-else/>
         </div>
          <div  class="cur detailsP" v-if="data.productImg.length>4">
            <div class="my-prev-el" style="outline:none; !important"><img src="@/assets/disabled-prev.png" alt=""  style="margin-right: 10px;"/></div>
            <swiper
                class="swiper"
                :space-between="19"
                :slides-per-view="4"
                :pagination="{ clickable: true }"
                :navigation="{ nextEl: '.my-next-el', prevEl: '.my-prev-el' }"
              >
                <swiper-slide class="slide"  v-for="(item, index) in data.productImg"  :key="index" @click="activeImg = index">
                  <video class="goodsimglit" :src="item" v-if="item.indexOf('.mp4')!==-1"  :style="{border: activeImg == index ? '1px solid rgb(37, 69, 156)' : '#fff',}"></video>
                   <img :src="item" alt="" class="goodsimglit" style="object-fit: cover" v-else
                  :style="{border: activeImg == index ? '1px solid rgb(37, 69, 156)' : '#fff',}"/></swiper-slide>
              </swiper>
            <div class="my-next-el" style="outline:none; !important"><img src="@/assets/disabled-next.png" alt=""  style="margin-left: 10px;"/></div>
          </div>
          <el-row :gutter="12" v-else>
            <el-col
              :span="4.8"
              v-for="(item, index) in data.productImg"
              @click="activeImg = index"
              :key="index"
              class="cur"
            >
            <video class="goodsimglit" :src="item" v-if="item.indexOf('.mp4')!==-1"  :style="{border: activeImg == index ? '1px solid rgb(37, 69, 156)' : '#fff',}"></video>
              <img
              v-else
                :src="item"
                alt=""
                class="goodsimglit"
                style="object-fit: cover"
                :style="{border: activeImg == index ? '1px solid rgb(37, 69, 156)' : '#fff',}"
              />
            </el-col>
          </el-row>
        </div>
        <div class="detailinfo">
          <span class="fontS24 co3 elipes2" style="line-height: 30px">{{
            data.info.product_name
          }}</span>
          <div class="priceBox flexJBC">
            <!-- 批发 -->
            <div class="">
              <div class="flex">
                <span class="co9 fontS16">价格</span>
                <div class="priceColor" style="margin-left: 9px">
                  <span class="fontS20">￥</span>
                  <span class="fontS28">{{ data.info.price }}</span>
                </div>
                <div class="flex">
                  <span class="co9 fontS16" style="margin-left: 20px"
                    >官方售价:</span
                  >
                  <div class="Strikethrough">
                    <span class="priceLine" style="margin-left: 6px"
                      >￥{{ data.info.ot_price }}</span
                    >
                  </div>
                </div>
              </div>
              <div
                class="flex"
                style="margin-top: 33px"
                v-if="data.info.is_start_wholesale == 1"
              >
                <span class="co9 fontS16">起批量</span>
                <div class="fontS20 co3 flex" style="margin-left: 9px">
                  {{ data.info.wholesale_number }}{{ data.info.unit_name }}起批
                  <div style="margin-left: 9px">(大批量购买请联系客服)</div>
                </div>
              </div>
            </div>
            <div>
              <div class="co9 fontS16" style="text-align: right">总销量</div>
              <div
                class="co3 fontS20"
                style="text-align: right; margin-top: 10px"
              >
                {{ data.info.sales }}
              </div>
            </div>
          </div>
          <div class="couponeBox" v-if="data.info.coupon.length > 0">
            <div
              class="li fontS16"
              v-for="(item, index) in data.info.coupon"
              :key="item.coupon_id"
            >
              <div v-if="!showcoupone ? index <= 0 : true" class="flexJBC">
                <div class="flex">
                  <span :style="{ color: index == 0 ? '#999' : '#F5F5F5' }"
                    >优惠券</span
                  >
                  <div class="quan flex">
                    <div>{{ item.full_text }}</div>
                    <div
                      class="homeRed cur"
                      @click="getuserReceiveCoupon(item.coupon_id)"
                      style="margin-left: 20px; text-decoration: underline"
                    >
                      领券
                    </div>
                  </div>
                </div>
                <el-icon
                  size="16px"
                  color="#666"
                  v-if="index == 0"
                  @click="showcoupone = !showcoupone"
                >
                  <ArrowDown v-if="!showcoupone" />
                  <ArrowUp v-else />
                </el-icon>
              </div>
            </div>
          </div>
          <div style="padding: 0 20px; box-sizing: border-box">
            <div class="flex marB2">
              <span class="co9 fontS16">发货</span>
              <div class="fontS20 co3" style="margin-left: 9px">
                {{ data.info.delivery_address }}
              </div>
            </div>
            <div class="flex marB2">
              <span class="co9 fontS16">运费</span>
              <div class="fontS20 co3" style="margin-left: 9px">
                ¥{{ data.info.free }}
              </div>
            </div>
            <div class="flex marB2">
              <span class="co9 fontS16">品牌</span>
              <div class="fontS20 co3" style="margin-left: 9px">
                {{ data.info.brand.brand_name}}
              </div>
            </div>
            <!-- 重构开始点 -->
            <div class="productColorBox marB2" v-if="data.info.spec_type == 1">
              <!-- <span class="co9 fontS16">颜色</span> -->
              <!-- 多规格 -->
              <div class="productColorRight fontS20 co3">
                <div
                  class="productColor"
                  v-for="(item, index) in data.info.new_attr"
                  :key="index"
                  @click="activeGuige = index"
                >
                  <div
                    class="productColorMin"
                    :style="{
                      border:
                        activeGuige == index
                          ? '1px solid #25459C'
                          : ' 1px solid #CCCCCC',
                    }"
                  >
                    <img
                      v-if="item.image != ''"
                      :src="item.image"
                      alt=""
                      class="colorImg"
                    />
                    <div>{{ item.name }}</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 尺码 -->
            <div class="productSizeBox marB2">
              <!-- <span class="co9 fontS16">尺码</span> -->
              <!-- 多规格 -->
              <div
                class="productSize fontS20 co3"
                v-if="data.info.spec_type == 1"
              >
                <div
                  v-for="(item, index) in data.info.new_attr[activeGuige].attr"
                  :key="index"
                >
                  <div>
                    <div
                      class="productSizeMin"
                      v-for="(val, i) in item.attrDetails.data"
                      :key="i"
                    >
                      <div class="left">
                        <div class="leftTop">{{ item.attrDetails.detail }}</div>
                        <div class="bottomTop">
                          {{ val.warehouse_type_name }}{{ val.stock }}
                        </div>
                      </div>
                      <div class="center" v-if="val.is_start_wholesale == 1">
                        <!-- 批发多规格is_start_wholesale=1  -->
                        <div
                          class="centerPrice"
                          v-for="(v, indx) in val.wholesale.setting_seller_data"
                          :key="indx"
                        >
                          <div class="centerLeft">
                            {{ v.small_number }}～{{ v.big_number
                            }}{{ data.info.unit_name }}
                          </div>

                          <div class="centerRight">
                            ¥{{ v.wholesale_price }}
                          </div>
                        </div>
                      </div>
                      <div class="center" v-if="val.is_start_wholesale == 0">
                        <!-- 批发单规格is_start_wholesale=1  -->
                        <div class="centerPrice">
                          <div class="centerLeft"></div>
                          <div class="centerRight">¥{{ val.price }}</div>
                        </div>
                      </div>
                      <!-- class="right"/ -->
                      <div >
                        <el-input-number
                          v-model="val.attrDetails_num"
                          :min="0"
                          v-if="val.stock > 0"
                          :max="val.stock"
                        />
                        <div
                          class="co9 fontS20 center"
                          style="width: 150px"
                          v-else
                        >
                          缺货
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 单规格 -->
              <div
                class="productSize fontS20 co3"
                v-if="data.info.spec_type == 0"
              >
                <div
                  v-for="(item, index) in data.info.attrDetails"
                  :key="index"
                >
                  <div>
                    <div
                      class="productSizeMin"
                      v-for="(val, i) in item.data"
                      :key="i"
                    >
                      <div class="left">
                        <div class="leftTop">{{ item.detail }}</div>
                        <div class="bottomTop">
                          {{ val.warehouse_type_name }}{{ val.stock }}
                        </div>
                      </div>
                      <div class="center" v-if="val.is_start_wholesale == 1">
                        <!-- 批发单规格is_start_wholesale=1  -->
                        <div
                          class="centerPrice"
                          v-for="(v, indx) in val.wholesale.setting_seller_data"
                          :key="indx"
                        >
                          <div class="centerLeft" v-if="v.big_number < 1000">
                            {{ v.small_number }}～{{ v.big_number
                            }}{{ data.info.unit_name }}
                          </div>
                          <div class="centerLeft" v-else>
                            ≥1000{{ data.info.unit_name }}
                          </div>
                          <div class="centerRight">
                            ¥{{ v.wholesale_price }}
                          </div>
                        </div>
                      </div>
                      <div class="center" v-if="val.is_start_wholesale == 0">
                        <!-- 不批发单规格-->
                        <div class="centerPrice">
                          <div class="centerLeft"></div>
                          <div class="centerRight">¥{{ val.price }}</div>
                        </div>
                      </div>
                      <!-- class="right" -->
                      <div>
                        <!-- -->
                        <el-input-number
                          v-model="val.attrDetails_num"
                          :min="0"
                          v-if="val.stock > 0"
                          :max="val.stock"
                        />
                        <div
                          class="co9 fontS20 center"
                          style="width: 150px"
                          v-else
                        >
                          缺货
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 重构结束点 -->
          </div>

          <div class="flex btnBox fontS20">
            <div class="btn btn2 center homeRed cur" @click="addCart">
              加入购物车
            </div>
            <div class="btn btn1 center cof cur marL2" @click="gopay">
              立即购买
            </div>

            <div class="marL2 flex" @click="follow">
              <el-icon size="40" color="#25459C" v-if="data.CollectionColor">
                <StarFilled />
              </el-icon>
              <el-icon size="40" color="#666" v-else>
                <Star />
              </el-icon>
              <span class="fontS20 co6" style="margin-left: 5px"
                >收藏（{{data.CollectionNum}}）</span
              >
              <!-- ata.info.care_count  -->

            </div>
          </div>
        </div>
        <!-- 最新加入购物车 -->
        <div class="cartBox">
          <div class="center topbox">
            <img
              :src="data.info.store.store_image"
              alt=""
              style="object-fit: cover"
            />
            <div class="co3 fontS18 elipes1" style="width: 100%">
              {{ data.info.store.store_name }}
            </div>
            <div
              class="flex co6 fontS16"
              style="width: 100%; margin: 20px 0 14px"
            >
              <span>评分：</span>
              <el-rate
                style="width: 119px"
                size="small"
                v-model="data.info.store.postage_score"
                disabled
                text-color="#ff9900"
              />
              <span syle="color:#FFC047">{{
                data.info.store.postage_score
              }}</span>
            </div>
            <div class="flex co6" style="width: 100%">
              成交量：{{ data.info.store.pay_number }}
            </div>
            <div
              class="flex co6 cur"
              style="width: 100%; margin-top: 20px"
              @click="toTUIKit"
            >
              <span>联系：</span>
              <el-icon style="vertical-align: middle; color: #3388e8">
                <ChatDotRound />
              </el-icon>
            </div>
            <div
              class="box cur"
              @click="
                router.push({
                  path: '/store',
                  query: { id: data.info.store.id },
                })
              "
            >
              进店
            </div>
          </div>
          <div v-if="data.cartList.length > 0">
            <div class="top homeRed flex flexCenter">
              <img
                src="../../assets/cart1.png"
                alt=""
                style="object-fit: cover"
              />
              <span>最新加入购物车</span>
            </div>
            <div class="lisBox">
              <div
                class="li flex"
                v-for="(item, index) in data.cartList.slice(0,5)"
                :key="index"
              >
                <div class="flex">
                  <img
                    :src="item.image"
                    alt=""
                    style="margin-right: 10px; object-fit: cover"
                  />
                  <div style="flex: 1">
                    <div class="elipes1" style="width: 150px">
                      {{ item.product_name }}
                    </div>
                    <div class="flexJBC co9 fontS14 cur" style="margin-top: 6px">
                      <div>
                        <span>{{ item.suk_name }}</span>
                        <span style="margin-left: 10px">X{{ item.num }}</span>
                      </div>
                      <el-icon
                        size="24px"
                        color="#999"
                        @click="
                          data.delId = item.cart_id;
                          centerDialogVisible = true;
                        "
                      >
                        <Delete />
                      </el-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="btnmore fontS16 co9 cur" @click="router.push('/cart')">
              查看更多
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="detailAndF" v-if="data.code == 200">
      <div class="wrapCenter" style="background: #fff">
        <div class="topNav flex fontS20 co3">
          <div
            class="li cur"
            @click="activeName = 1"
            :class="activeName == 1 ? 'active' : ''"
          >
            商品详情
          </div>
          <div
            class="li cur"
            @click="activeName = 2"
            :class="activeName == 2 ? 'active' : ''"
          >
            商品评价
          </div>
        </div>
        <!-- 商品详情 -->
        <div class="imgBox" v-if="activeName == 1 && data.info">
          <div v-html="data.info.content"></div>
        </div>
        <!-- 评价 -->
        <div class="evaluate" v-if="activeName == 2">
          <div class="flex">
            <div
              v-for="(item, index) in data.evaluate"
              :key="index"
              class="litop fontS16 cur"
              :class="activeEval == item.id ? 'active' : ''"
              @click="selectProduct(item.id)"
            >
              {{ item.name }}({{ item.num }})
            </div>
          </div>
          <div
            class="lis flex"
            style="align-items: flex-start"
            :key="index"
            v-for="(item, index) in data.selList"
          >
            <div class="left">
              <img :src="item.avatar" alt="" style="object-fit: cover" />
              <span class="fontS16 co3">{{ item.nickname }}</span>
            </div>
            <div class="right">
              <div class="flex" style="margin: 13px 0px 20px">
                <el-rate v-model="item.start" disabled />
              </div>
              <div class="co6 fontS18">
                <span>{{ item.create_time }}</span>
                <!-- <span class="num">1件</span> -->
                <span>{{ item.product_detail }}</span>
              </div>
              <div class="co3 fontS18 info">
                {{ item.comment_text }}
              </div>
              <div class="flex" style="flex-wrap: wrap">
                <div
                  class="demo-image__preview"
                  v-for="(item1, index) in item.image"
                  :key="index"
                >
                  <el-image
                    style="width: 100px; height: 100px"
                    :src="item1"
                    :zoom-rate="1.2"
                    :preview-src-list="item.image"
                    :initial-index="0"
                    fit="cover"
                  />
                </div>
                <!-- <div class="evaimgBox demo-image__preview" v-for="(item, index) in item.image"
                                    :key="index">
                                    <el-image style="width: 80px; height: 80px" :src="item" :zoom-rate="1.2"
                                        :preview-src-list="item.image" :initial-index="0" fit="cover" />
                                </div> -->

                <!-- <img :src="item" style="object-fit:cover" alt="" v-for="(item, index) in item.image" :key="index" /> -->
              </div>
              <div
                class="huifu flex fontS18"
                style="line-height: 28px"
                v-if="item.reply"
              >
                <div class="homeRed" style="width: 100px">商家回复:</div>
                <div class="" style="flex: 1">{{ item.reply }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="data.code == 208"
      style="margin-top: 100px"
      class="center homeRed fontS28"
    >
      <div>商品已失效</div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.slide{
  width:96px !important;
  margin-right: 10px !important;
}
.detailsP{
  display: flex;
  align-items: center;
}
// 修改
.Strikethrough {
  font-size: 24px;
  color: #999999;
  .priceLine {
    text-decoration: line-through;
  }
}
.productColorBox {
  display: flex;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 20px 0;
  .productColorRight {
    margin-left: 9px;
    flex-wrap: wrap;
    display: flex;
    flex: 1;
  }
  .productColor {
    margin: 6px;
    .productColorMin {
      display: flex;
      line-height: 48px;
      padding: 6px 10px;
      align-content: center;
      justify-content: center;
      .colorImg {
        width: 48px;
        height: 48px;
        margin-right: 10px;
      }
    }
  }
}
.productSizeBox {
  display: flex;
  .productSize {
    display: flex;
    flex: 1;
    // margin-left:9px;
    flex-direction: column;
    .productSizeMin {
      display: flex;
      flex: 1;
      border-bottom: 1px dashed #cccccc;
      justify-content: space-between;
      padding: 20px 0 20px 10px;
      .bottomTop {
        font-size: 16px;
        color: #999999;
      }
      .leftTop {
        margin-bottom: 10px;
      }
      .left {
        flex: 30%;
      }
      .center {
        flex: 40%;
      }
      .centerPrice {
        display: flex;
        font-size: 20px;
        margin-bottom: 10px;
        text-align: left;
        .centerLeft {
          color: #666;
          width: 126px;
          margin-right: 20px;
        }
        .centerRight {
          color: #DD2131 ;
        }
      }
    }
  }
}
// 结束
.couponeBox {
  .li {
    padding: 0 20px;
    box-sizing: border-box;
  }

  .quan {
    margin-left: 10px;
  }

  width: 661px;
  line-height: 50px;
  background: #f5f5f5;
  margin-bottom: 20px;
}

.detailAndF {
  .evaluate {
    .lis {
      .right {
        .huifu {
          align-items: flex-start;
          width: 952px;
          min-height: 120px;
          background: #f5f5f5;
          padding: 20px;
          box-sizing: border-box;
        }

        .evaimgBox {
          margin-right: 10px;
          margin-bottom: 10px;
        }

        .info {
          line-height: 28px;
          margin: 10px 0 24px;
        }

        .num {
          margin: 0 20px;
          padding: 0 10px;
          border-left: 1px solid #707070;
          border-right: 1px solid #707070;
        }
      }

      .left {
        text-align: center;
        margin-right: 40px;

        img {
          width: 80px;
          height: 80px;
          margin-bottom: 24px;
          border-radius: 50%;
        }
      }

      padding: 40px 0;
      box-sizing: border-box;

      border-bottom: 1px solid #cccccc;
    }

    padding: 40px 200px;
    box-sizing: border-box;

    .active {
      background: #25459C !important;
      color: #fff !important;
    }

    .litop {
      padding: 0 20px;
      text-align: center;
      line-height: 48px;
      height: 48px;
      color: #999999;
      background: #f5f5f5;
      border: 1px solid #cccccc;
      margin-right: 20px;
    }
  }

  .active {
    border-bottom: 6px solid #25459C;
    color: #25459C;
  }

  .imgBox {
    width: 100%;
    padding: 20px 200px;
    box-sizing: border-box;
    /deep/.ql-align-center {
      line-height: 30px !important;
    }
    img {
      width: 1072px;
      height: 1324px;
      background: #ebebeb;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
  }

  .li {
    padding: 0 40px;
  }
  .topNav {
    height: 80px;
    line-height: 80px;
    border-bottom: 1px solid #cccccc;
  }

  padding-top: 40px;
  background-color: #f5f5f5;
  min-height: 80vh;

  .wrapCenter {
    // margin-top:40px;
    min-height: 80vh;
  }
}

.cartBox {
  .topbox {
    .box {
      width: 72px;
      height: 32px;
      line-height: 32px;
      border: 1px solid #25459C;
      color: #25459C;
      margin-top: 10px;
      font-size: 16px;
      margin-top: 21px;
    }

    border: 1px solid #cccccc;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 60px;
      height: 60px;
      margin-bottom: 10px;
    }
  }

  .lisBox {
    border: 1px solid #cccccc;
    border-top: none;
    border-bottom: none;
    padding-bottom: 20px;
  }

  .btnmore {
    width: 249px;
    height: 48px;
    background: #f5f5f5;
    border: 1px solid #999999;
    line-height: 48px;
    text-align: center;
  }

  .li {
    padding: 20px 20px 0;
    box-sizing: border-box;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    img {
      width: 48px;
      height: 48px;
    }
  }

  .top {
    width: 246px;
    height: 54px;
    background-color: rgba(37, 69, 156, 0.4) !important;
    border: 2px solid #25459C;

    img {
      width: 22px;
      height: 25px;
      margin-right: 10px;
    }
  }

  width: 249px;
  background: #ffffff;
}

.detailinfo {
  .btnBox {
    margin-top: 40px;
    padding-bottom: 40px;

    .btn {
      width: 168px;
      height: 54px;
      line-height: 54px;

      border: 2px solid #25459C;
    }

    .btn1 {
      background: #25459C;
    }
  }

  .sizeBox {
    .li {
      height: 68px;
      line-height: 68px;
      border-bottom: 1px dotted #cccccc;
    }

    border-bottom: 1px solid #cccccc;
  }

  .colorBox {
    flex-wrap: wrap;
    padding: 10px 20px 20px;
    box-sizing: border-box;
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;

    .li {
      // width: 106px;
      height: 58px;
      margin-right: 20px;
      border: 1px solid #cccccc;
      padding: 5px 10px;
      box-sizing: border-box;
      margin-top: 10px;

      img {
        width: 48px;
        height: 48px;
        // margin-right: 10px;
      }
    }
  }

  width: 657px;

  .priceBox {
    margin: 20px 0;
    width: 661px;
    height: 128px;
    background: #f5f5f5;
    padding: 22px 20px;
    box-sizing: border-box;
  }
}

.imgBox {
  width: 466px;

  .goodsimglit {
    width: 84px;
    height: 84px;
    margin-top: 12px;
    padding: 1px;
  }

  .goodsimg {
    width: 466px;
    height: 466px;
  }
}

.nav {
  margin: 20px 0;
}
</style>
