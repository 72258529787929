<script setup>
import { ref, reactive, onBeforeMount } from "vue";
import {
  CaretTop,
  CaretBottom,
} from "@element-plus/icons-vue";
import { ElMessage } from 'element-plus'
import { getShopDetail, shopgetGoods, store_follow } from "@/utils/api";
import { useRouter } from "vue-router";
const router = useRouter();
const data = reactive({
  page: 1,
  last_page: 3,
  id: router.currentRoute.value.query.id, //详情id
  // showFi工产ltet:false, //是否显示筛选框
  maxPrice: "",
  minPrice: "",
  showSearchHistory: false,
  // activeNav: 0, //shaixunList的当前项
  // shaixunList: ["首页", "全部商品", "价格"],
  shaixunList1: ["综合排序", "销量", "价格"],
  // shaixunList2: ["综合排序", "成交量", "筛选"],
  filtetTime: [
    { name: "小于1年" },
    { name: "1～2年" },
    { name: "2～3年" },
    { name: "3～4年" },
    { name: "4年以上" },
  ],
  filtettotal: [
    { name: "0～5k" },
    { name: "5k～1w" },
    { name: "1w～2w" },
    { name: "2w～4w" },
    { name: "4w以上" },
  ],
});
// defineProps({
//     msg: String,
// })
let shopInfo = ref({});
let salestype = ref(''); //销量
let pricetype = ref(''); //销量
let goodShop = ref([]);
let newShop = ref([]);
let allShop = ref([]);
let keyword = ref("");
const loading = ref(true);
// let custom_price = ref("");
const shopDetail = (id) => {
  getShopDetail({
    id: id,
  }).then((res) => {
    console.log(res, '/详情');
    if (res.code == 200) {
      shopInfo.value = res.data;
    }
  });
};

const active = ref(0);
const search = () => {
  let custom_price = "";
  let min = data.minPrice ? Number(data.minPrice) : 0
  let max = data.maxPrice ? Number(data.maxPrice) : 0
  if (min == 0 && max == 0) {
    custom_price = ''
  } else if (min > max) {
    custom_price = max + "," + min;
  } else {
    custom_price = min + "," + max;
  }
  // loading.value=true
  shopgetGoods({
    search_key: keyword.value,
    store_id: data.id,
    sales: salestype.value,
    price: pricetype.value,
    custom_price: custom_price,
  }).then(res => {
    if (res.code == 200) {
      allShop.value = res.data.data
      // loading.value=false
      // data.last_page = res.data.last_page
      // if (data.page == 1) {
      //   allShop.value = res.data.data
      // } else {
      //   allShop.value = allShop.value.concat(res.data.data)
      // }

    }
  })
};
const typeChang = (index) => {
  console.log(index,'1234')
  data.activeNav = index;
  if (index == 0) {
    pricetype.value = '';
    salestype.value = '';
  }
  if (index == 1) {
    salestype.value = salestype.value == 'desc' ? 'asc' : 'desc';
  } else if (index == 2) {
    pricetype.value = pricetype.value == 'desc' ? 'asc' : 'desc';
  }
  search();
};
const changeFirst = (index) => {
  active.value = index;
  data.activeNav = 0;
  data.page = 1;
  search(0);
  console.log(index,'123')
};
const tocare = () => {
  console.log('点击关注');
  store_follow({
    id: shopInfo.value.id.toString(),
    type: shopInfo.value.store_type == 1 ? 3 : 1
  }).then(res => {
    if (res.code == 200) {
      // ElMessage.success(res.message)
      if (shopInfo.value.focus == 0) {
        ElMessage.success('关注成功')
      } else {
        ElMessage.success('取消关注')
      }
      shopDetail(data.id)
    } else {
      ElMessage.warning(res.message)
    }
  })
}
onBeforeMount(() => {
  shopDetail(data.id);
  shopgetGoods({
    store_id: data.id,
    is_good: 1,
    // is_new:1
  }).then((res) => {
    console.log(res, "/////res shop");
    //推荐产品
    goodShop.value = res.data.data;
    console.log(goodShop, "/////res shop13");
  });
  shopgetGoods({
    store_id: data.id,
    is_good: 1,
    is_new: 1,
  }).then((res) => {
    console.log(res, "/////res shop");
    //新品产品
    newShop.value = res.data.data;
  });
});
// const load = () => {
//   if (data.page < data.last_page) {
//     data.page++;
//     search()
//     console.log('进入1')
//   }
// }
</script>

<template>
  <div class="wrapCenter" style="margin-top: 20px">
    <div class="">
      <div>
        <div class="topsearch">
          <div class="flexJBC">
            <div class="navLeft flex">
              <img src="../../assets/logo.png" style="object-fit:cover" alt="" />
              <div class="store flexJBC" style="margin-left:20px">
                <div class="left">
                  <div class="flex">
                    <img :src="shopInfo.store_image" style="object-fit:cover" class="storeImg" />
                    <div class="co6 fontS22" style="display: flex;">
                      <div class="" style="display: flex;flex-direction: column;width:190px;">
                        <div style="margin:10px 0 20px 0;">{{ shopInfo.store_name }}</div>
                        <div>
                          <el-rate v-model="shopInfo.postage_score" disabled />
                          <span class="fontS20" style="color: #ffc047">{{ shopInfo.postage_score }}分</span>
                        </div>
                      </div>
                     
                        <div class="flex cur" style="flex-direction: column;margin-left: 60px;">
                          <div class="fontS16 co9" style="margin-bottom: 20px;">
                            {{ shopInfo.focus_number }}人关注
                           </div>
                            <div>
                                <div v-if="shopInfo.focus == 0 || shopInfo.focus == 2" class="fontS18 cof favi "
                                  @click="tocare()">
                                  +关注
                                </div>
                                <div v-if="shopInfo.focus == 1" class="fontS18 cof favi " @click="tocare()">
                                  已关注
                                </div>
                            </div>
                        </div>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="rightNav flex">
              <div>
                <div class="inputBox flex">
                  <input v-model="keyword" type="text" placeholder="请输入商品名称" @change="active = 1; data.page = 1; search()" style="font-size:18px;"/>
                  <div class="seacrhIcon cur" @click="active = 1; data.page = 1; search()">
                    <img src="../../assets/search.png" style="object-fit:cover"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="navTag co6 fontS20 flex">
          <span @click="changeFirst(0)" :class="active == 0 ? 'activetag cur' : 'cur'">首页</span>
          <span @click="changeFirst(1)" :class="active == 1 ? 'activetag cur' : 'cur'">全部商品</span>
        </div>
        <div class="paixuBox flex fontS16 co6" v-if="active == 1">
          <div v-for="(item, index) in data.shaixunList1" :key="index" @click="typeChang(index)"
            class="cur flexCenter li center flex" :class="data.activeNav == index ? 'active' : ''"
            style="align-items:center">
            <span> {{ item }}</span>
            <div style="margin-bottom:3px">
              <div v-if="index == 2">
                <div style="margin-bottom: -8px">
                  <el-icon size="10" :color="pricetype == 'asc' ? '#25459C' : '#666'">
                    <CaretTop />
                  </el-icon>
                </div>
                <el-icon size="10" :color="pricetype == 'desc' ? '#25459C' : '#666'">
                  <CaretBottom />
                </el-icon>
              </div>
              <div v-if="index == 1">
                <div style="margin-bottom: -8px">
                  <el-icon size="10" :color="salestype == 'asc' ? '#25459C' : '#666'">
                    <CaretTop />
                  </el-icon>
                </div>
                <el-icon size="10" :color="salestype == 'desc' ? '#25459C' : '#666'">
                  <CaretBottom />
                </el-icon>
              </div>
            </div>
          </div>
          <div class="priceBox co6 flex">
            <span>价格区间：</span>
            <el-input v-model="data.minPrice" style="box-shadow: none;
                width: 90px;
                background: #f5f5f5;
                border: 1px solid #cccccc;
              ">
              <template #prepend style="background: none">￥</template>
            </el-input>
            <div style="padding: 0 10px">-</div>
            <el-input v-model="data.maxPrice" style="
                box-shadow: none;
                width: 90px;
                background: #f5f5f5;
                border: 1px solid #cccccc;
              ">
              <template #prepend style="background: none">￥</template>
            </el-input>
          </div>

          <div class="btn cof fontS16" @click="search(3)">确认</div>
        </div>
        <div>
          <div class="home" v-if="active == 0">
            <!-- <img src="../../assets/Image/5494c0fa4c8d21450ef7357d0929a5d8.jpeg" alt=""> -->
            <el-carousel height="340px">
              <el-carousel-item v-for="item in shopInfo.store_banner" :key="item">
                <img :src="item" alt="" style="object-fit:cover"/>
                <!-- <h3 class="small justify-center" text="2xl">{{ item }}</h3> -->
              </el-carousel-item>
            </el-carousel>
            <div class="titlebox cof">商品推荐</div>
            <div class="list">
              <el-row :gutter="20">
                <el-col :span="6" v-for="item in goodShop" :key="item" @click="router.push({path:'/goodsDetail',query:{id: item.id}})">
                  <div class="li">
                    <img :src="item.image" alt="" style="object-fit:cover"/>
                    <div class="title elipes2 co3 fonrS22">
                      {{ item.product_name }}
                    </div>
                    <div class="flex">
                      <span class="priceColor fontS12">￥</span>
                      <span class="priceColor fontS28">{{ item.price }}</span>
                      <span class="fontS18 co9" style="margin-left: 14px">{{ item.month_number }}人付款</span>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>

            <div class="titlebox cof">新品上市</div>

            <div class="list">
              <el-row :gutter="20">
                <el-col :span="6" v-for="item in newShop" :key="item"  @click="router.push({path:'/goodsDetail',query:{id: item.id}})">
                  <div class="li">
                    <img :src="item.image" alt="" style="object-fit:cover" />
                    <div class="title elipes2 co3 fonrS22">
                      {{ item.product_name }}
                    </div>
                    <div class="flex">
                      <span class="priceColor fontS12">￥</span>
                      <span class="priceColor fontS28">{{ item.price }}</span>
                      <span class="fontS18 co9" style="margin-left: 14px">{{ item.month_number }}人付款</span>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <!-- infinite-scroll-distance="30px"
            v-infinite-scroll="load" -->
          <div class="list printLogFather" v-if="active == 1"  style="height:50vh;overflow: auto;"  >
            <el-empty description="暂无商品" v-if="allShop.length == 0" />
            <el-row :gutter="20" v-else>
              <el-col :span="6" v-for="item in allShop" :key="item"  @click="router.push({path:'/goodsDetail',query:{id: item.id}})">
                <div class="li">
                  <img :src="item.image" alt="" style="object-fit:cover"/>
                  <div class="title elipes2 co3 fonrS22">
                    {{ item.product_name }}
                  </div> 
                  <div class="flex">
                    <span class="priceColor fontS12">￥</span>
                    <span class="priceColor fontS28">{{ item.price }}</span>
                    <span class="fontS18 co9" style="margin-left: 14px">{{ item.month_number }}人付款</span>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.store {
  .left {
    .storeImg {
      width: 80px;
      height: 80px;
      margin-right:10px;
    }
  }

  align-items: flex-start;
  padding: 40px 0;
}

.home {
  img {
    width: 1472px;
    height: 340px;
    background: #e3e3e3;
    // margin: 20px 0;
  }

  .titlebox {
    width: 1472px;
    height: 40px;
    background: #25459C;
    padding-left: 20px;
    box-sizing: border-box;
    line-height: 40px;
    margin-bottom: 20px;
  }
}

.list {
  flex-wrap: wrap;
  padding: 0 40px;
  box-sizing: border-box;
  justify-content: flex-start;

  .li {
    width: 336px;
    position: relative;

    .title {
      margin: 20px 0 16px;
      line-height: 28px;
    }

    margin-bottom: 40px;
    margin-right: 16px;

    img {
      width: 336px;
      height: 339px;
      display: block;
    }
  }
}

.paixuBox {
  .btn {
    width: 72px;
    height: 32px;
    background: #ffc047;
    line-height: 32px;
    text-align: center;
    margin-left: 20px;
  }

  .priceBox {
    margin-left: 79px;

    /deep/.el-input-group__append,
    /deep/.el-input-group__prepend,
    /deep/.el-input__wrapper {
      background-color: #fff;
      box-shadow: none;
    }

    /deep/.el-input-group__prepend {
      padding: 0;
    }
  }

  width: 1472px;
  height: 58px;
  // line-height: 58px;
  background: #f5f5f5;
  border: 1px solid #cccccc;
  margin: 20px 0;

  .active {
    color: #25459C;
    background: #ffffff;
    border: 1px solid #cccccc;
  }

  .li {
    width: 140px;
    align-items: center;
    height: 58px;
    position: relative;
  }
}

.navTag {
  margin-top: 20px;
  // width: 100%;
  width: 1472px;
  border-bottom: 2px solid #25459C;

  .activetag {
    background: #25459C;
    color: #fff;
  }

  span {
    display: block;
    width: 140px;
    height: 48px;
    line-height: 48px;
    text-align: center;
  }
}

.topsearch {
  .navLeft {
    .favi {
      width: 68px;
      text-align: center;
      line-height: 32px;
      height: 32px;
      background: #25459C;
      border-radius: 36px;
      // margin-left: 50px;
    }

    img {
            width:200px;
            height:70px;
        }

    // margin-right: 90px;
  }

  .rightNav {
    .inputBox {
      width: 683px;
      //   height: 48px;
      border: 2px solid #25459C;
      padding-left: 20px;
      box-sizing: border-box;

      input {
        height: 41px;
        flex: 1;
        border: none;
        outline: none;
      }

      .seacrhIcon {
        width: 75px;
        height: 48px;
        background: #25459C;
        display: flex;
        align-items: center;
        position: relative;
        right: -1px;

        img {
          width: 29.97px;
          height: 29.97px;
          margin: 0 auto;
        }
      }
    }
  }
}

.demonstration {
  color: var(--el-text-color-secondary);
}

.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
