
import { defineComponent, reactive, toRefs } from "vue";
import { TUIEnv } from "./TUIPlugin";

export default defineComponent({
  name: "App",
  setup() {
    const data = reactive({
      env: TUIEnv(),
      currentModel: "conversation",
    });
    const handleCurrentConversation = (value: string) => {
      data.currentModel = value ? "message" : "conversation";
      console.log(value, "value123456");
    };
    return {
      ...toRefs(data),
      handleCurrentConversation,
    };
  },
});
