
<template>
    <div class="page">
        <div class="wrapCenter">
            <div class="flex" style="align-items:flex-start;justify-content: space-between;">
                <div class="lisBox">
                    <div class="li">企业邮箱：info@aucfan-cn.com</div>
                    <div class="li">联系电话：400-9906-266</div>
                    <div class="li">服务时间：周一至周五9:00-18:00</div>
                </div>
                <div class="lisBox">
                    <div class="li">Aucfan中国官网：<a
                            href="http://www.aucfan-cn.com/#/home">http://www.aucfan-cn.com/#/home</a></div>
                    <div class="li">Aucfan集团官网：<a href="https://aucfan.co.jp/">https://aucfan.co.jp/</a></div>
                </div>
                <div class="lisBox">
                    <div class="li">奈特喜商城：<a href="http://netsea-cn.com">http://netsea-cn.com</a></div>
                    <div class="li ">奈特喜商家后台：<a href="http://merchant.netsea.aucfan-cn.com/#/login">http://merchant.netsea.aucfan-cn.com/#/login</a>
                    </div>
                </div>
               
            </div> 
            <div class="beians">
                    <div class="li" style="display:flex"><img src="@/assets/beian.png" alt="" style="margin-right:5px"><a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010102002019"> 琼公网安备 46010502000651号</a></div>
                    <div class="li" style="display:flex"><a href="https://beian.miit.gov.cn/">琼ICP备2022015403号</a></div>
                </div>
        </div>
    </div>
</template>
<style scoped  lang="less">
.page {
    margin-top: 30px;
    .lisBox {
        // width: 30%;
        text-align: left;
        font-size: 16px;
        color: #666666;
        .li {
            a{
                color: #666;
            }
            margin-bottom: 10px;
            a:hover {
                color: #25459C;
            }
        }


    }
    .beians{
        font-size: 16px;
        color: #666666;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        .li {
            margin-right:15px;
            a{
                color: #666;
            }
            margin-bottom: 10px;
            a:hover {
                color: #25459C;
            }
        }

    }

    background-color: #f5f5f5;
    border-top: 1px solid #CCCCCC;
    padding: 40px 0 20px 0;
    box-sizing: border-box;
}
</style>