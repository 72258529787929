
<template>
  <div class="contentBox">
    <!-- <searchTop :lisac="navBrandActive.num"></searchTop> -->
    <searchTopNew></searchTopNew>
    <swperImg :imgList="imgList"></swperImg>
    <Sortbycategory :typeList="typeList" :typeID="typeID" @clickType="clickType" ></Sortbycategory>
    <ProductList v-loading="loading" :likeList="likeList" :navActive="navBrandActive.num"></ProductList>
 
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import { ref, reactive } from "vue";
import swperImg from "./swperImg.vue"; //轮播图
import searchTopNew from "@/components/searchTopNew.vue"; //搜索组件
import ProductList from "./ProductList.vue" // 商品列表
import Sortbycategory from "./Sortbycategory.vue"//商品分类和排序
import { } from "@element-plus/icons-vue"; // icon 按需引入
import { ProductgetPlatFormTypeProductType, ProductgetPlatformTypeProductBrand, comgetAd } from "../../../utils/api"

const route = useRoute();
const platform_type_id = route.query.platform_type_id
const loading = ref(false)
// -- 商品列表
const likeList = ref([]);
const navBrandActive=reactive({
  num:1
})
const getData = (is_one, order_sales, order_price) => {
  loading.value = true
  let params1 = { platform_type_id: platform_type_id, category_id: is_one ? 0 : typeID.value,
    order:{price:order_price || 'desc',sales:order_sales || 'desc'}}
  ProductgetPlatformTypeProductBrand(params1).then(res => {
    likeList.value = res.data.data
    loading.value = false
  })
}
getData(true)


const imgList = ref([
  { url: "../../../assets/Image/5494c0fa4c8d21450ef7357d0929a5d8.jpeg" },
  { url: "../../../assets/Image/q.jpg" },
]);

comgetAd({ type: 0, type_id: platform_type_id }).then(res => {
  console.log(res, '品牌banner');
  imgList.value = res.data
})
// -- 分类列表
const typeID = ref(0)
const typeList = ref([
])
const clickType = (obj) => {
  typeID.value = obj.id;
  // category_id: typeID.value
  getData(false, obj.order_sales, obj.order_price)

}

let params2 = { platform_type_id }
ProductgetPlatFormTypeProductType(params2).then(res => {
  typeList.value = res.data
})



</script>


<style scoped>
.contentBox {
  max-width: 1920px;
  margin: 0 auto;
  background: #f5f5f5;
  padding-bottom: 50px;
}
</style>
