<script setup>
import { ref, reactive,onMounted } from 'vue'
import { CaretTop, CaretBottom, Star, StarFilled, Filter } from "@element-plus/icons-vue";
import { factoryDetail,factoryGoods } from "@/utils/api";
import { useRouter } from "vue-router";
const router = useRouter();
const data = reactive({
    // showFi工产ltet:false, //是否显示筛选框
    id:router.currentRoute.value.query.id,
    maxPrice: '',
    minParice: '',
    showSearchHistory: false,
    activeNav: 0,  //shaixunList的当前项
    shaixunList: ['首页', '全部商品', '价格',],
    shaixunList1: ['综合排序', '销量', '价格'],
    shaixunList2: ['综合排序', '成交量', '筛选'],
    filtetTime: [
        { name: "小于1年" },
        { name: "1～2年" },
        { name: "2～3年" },
        { name: "3～4年" },
        { name: "4年以上" },
    ],
    filtettotal: [
        { name: "0～5k" },
        { name: "5k～1w" },
        { name: "1w～2w" },
        { name: "2w～4w" },
        { name: "4w以上" },
    ],
});
let factoryInfo=reactive({})
let hotGoods=reactive([])
let reviewList=reactive([])

const active = ref(0)
const intrduction = ref('') //输入框介绍文字 
const name = ref('')
const phone = ref('')
onMounted(() => {
    console.log(data.id,'/id');
    factoryDetail({id:data.id}).then(
        res=>{
            factoryInfo=res.data
            console.log(factoryInfo);
        }
    )
    factoryGoods({
        store_id:data.id,
        page:1,
        limit:10
    }).then(
        res=>{
            hotGoods=res.data.data
            console.log(hotGoods);
        }
    )
})
</script>

<template>

    <div class="" style="margin-top: 20px;">
        <div class="">
            <div>
                <div class="topsearch wrapCenter">
                    <div class="flexJBC">
                        <div class="left flex">
                            <img src="../../assets/logo.png" alt="" style="object-fit:cover">
                            <div class="store flexJBC">
                                <div class="left">
                                    <div class="flex">
                                        <img :src="factoryInfo.store_image" alt=""
                                            class="storeImg" style="object-fit:cover">
                                        <div class="co6 fontS22">
                                            <div class="flexJBC">
                                                <div class="fontS24 co6">{{factoryInfo.store_name}}</div>
                                                <div class="btn fontS18 cof">+关注</div>
                                            </div>
                                            <div class="flex">
                                                <div class="flex" style="margin:30px 0px 0px ;">
                                                    <span class="fontS16 co6">成立时间:{{factoryInfo.set_up_time}} 主营：<span :key="item" v-for="item in factoryInfo.main_business">{{ item }} | </span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="right flex">
                            <div>
                                <div class="inputBox flex">
                                    <input type="text" placeholder="请输入商品名称" />
                                    <div class="seacrhIcon cur">
                                        <img src="../../assets/search.png" style="object-fit:cover">
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <div style="background:#f5f5f5;padding-bottom: 100px;">
                    <div class="wrapCenter">
                        <div class="navTag co6 fontS20 flex">
                            <span @click="active = 0; data.activeNav = 0"
                                :class="active == 0 ? 'activetag cur' : 'cur'">首页</span>
                            <span @click="active = 1; data.activeNav = 0"
                                :class="active == 1 ? 'activetag cur' : 'cur'">全部商品</span>

                        </div>
                        <div class="paixuBox flex fontS16 co6" v-if="active == 1">
                            <div v-for="(item, index) in data.shaixunList1" :key="index" @click="data.activeNav = index"
                                class=" cur flexCenter  li center" :class="data.activeNav == index ? 'active' : ''">
                                <span> {{ item }}</span>
                                <div v-if="index == 2">
                                    <div style="marginBottom:-10px"><el-icon size="8" color="#666">
                                            <CaretTop />
                                        </el-icon></div>
                                    <el-icon size="8" color="#666">
                                        <CaretBottom />
                                    </el-icon>
                                </div>
                            </div>
                            <div class="priceBox co6 flex">
                                <span>价格区间：</span>
                                <el-input v-model="data.minParice"
                                    style="box-shadow:none;width: 90px;background: #F5F5F5;border: 1px solid #CCCCCC;">
                                    <template #prepend style="background:none">￥</template>
                                </el-input>
                                <div style="padding:0 10px"> - </div>
                                <el-input v-model="data.maxPrice"
                                    style="box-shadow:none;width: 90px;background: #F5F5F5;border: 1px solid #CCCCCC;">
                                    <template #prepend style="background:none">￥</template>
                                </el-input>
                            </div>
                            <div class="btn cof fontS16">确认</div>
                        </div>
                        <div class="list flexJBC" v-if="active == 1">
                            <el-row :gutter="20">
                                <el-col :span="6" v-for="item in 8" :key="item">
                                    <div class="li">
                                        <img style="object-fit:cover" src="../../assets/Image/5494c0fa4c8d21450ef7357d0929a5d8.jpeg" alt="">
                                        <div class="title elipes2 co3 fonrS22">纯棉重工连帽卫衣女秋冬慵懒加绒加厚字母印花外套</div>
                                        <div class="flex">
                                            <span class="priceColor fontS12">￥</span>
                                            <span class="priceColor fontS28">27.00</span>
                                            <span class="fontS18 co9" style="margin-left:14px">340人付款</span>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>

                        <div class="home" v-if="active == 0">
                            <div class="flexJBC" style="align-items:flex-start">
                                <div class="leftBox">
                                    <div class="leftwhiteBox marB2">
                                        <div class="swiper">
                                            <el-carousel :interval="5000" arrow="always" height="572px"
                                                indicator-position="none">
                                                <el-carousel-item v-for="item in factoryInfo.store_banner" :key="item">
                                                    <!-- <h3 text="2xl" justify="center">{{ item }}</h3> -->
                                                    <img :src="item" style="object-fit:cover" alt="">
                                                </el-carousel-item>
                                            </el-carousel>
                                            <div>
                                                <el-row :gutter="12">
                                                    <el-col :span="4.5" v-for="item in factoryInfo.store_banner" :key="item">
                                                        <img :src="item" 
                                                            alt="" style="width:180px;height:102px;margin-top: 10px;object-fit:cover">
                                                    </el-col>
                                                </el-row>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="leftwhiteBox marB20">
                                        <div class="fontS20 co3 marB2">热门商品</div>
                                        <div style="padding:0 40px;box-sizing:border-box">
                                            <el-row :gutter="0">
                                                <el-col :span="6" v-for="item in hotGoods" :key="item">
                                                    <div class="imgBox">
                                                        <img :src="item.image" style="object-fit:cover"
                                                            alt="">
                                                        <div class="priceBox fontS20 cof">￥{{item.price}}</div>
                                                    </div>
                                                </el-col>
                                            </el-row>
                                        </div>
                                    </div>
                                    <div class="leftwhiteBox">
                                        <div class="fontS20 co3 marB2">精选评价</div>
                                        <div class="lis flex" style="align-items:flex-start" v-for="item in 5" :key="item">
                                            <div class="left">
                                                <img src="../../assets/Image/5494c0fa4c8d21450ef7357d0929a5d8.jpeg"
                                                    alt="" style="object-fit:cover">
                                                <span class="fontS16 co3">t**2</span>
                                            </div>
                                            <div class="right">
                                                <div class="flex" style="margin:13px 0px 20px ;">
                                                    <el-icon size="24" color="#FFC047" v-for="item in 4" :key="item">
                                                        <StarFilled />
                                                    </el-icon>
                                                    <el-icon size="20" color="#ccc">
                                                        <Star />
                                                    </el-icon>
                                                </div>
                                                <div class="co6 fontS18">
                                                    <span>2021-12-09</span>
                                                    <span class="num">1件</span>
                                                    <span>颜色/油白/尺码/S</span>
                                                </div>
                                                <div class="co3 fontS18 info ">
                                                    质量蛮好的 手感不错 在上海冬天穿着暖和 比我实体店买的八百多的要好一些 会厚一点 质量蛮好的 手感不错 在上海冬
                                                    天穿着暖和 比我实体店买的八百多的要好一些 会厚一点 质量蛮好的 手感不错 在上海冬天穿着暖和
                                                </div>
                                                <div class="evaimgBox flex" style="flex-wrap:wrap">
                                                    <img src="../../assets/Image/5494c0fa4c8d21450ef7357d0929a5d8.jpeg"
                                                        alt="" v-for="item in 15" :key="item" style="object-fit:cover"/>
                                                </div>
                                                <div class="huifu flex fontS18" style="line-height:28px">
                                                    <div class="homeRed " style="width:100px">商家回复:</div>
                                                    <div class="" style="flex:1;">质量蛮好的 </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="rightBox">
                                    <div class="whiteBox marB2">
                                        <div class="flexJBC">
                                            <img :src="factoryInfo.store_image" alt=""
                                                class="factory_img" style="object-fit:cover">
                                            <div class="fontS24 co3 ">{{factoryInfo.store_name}}</div>
                                        </div>
                                        <div class="fontS16 co6" style="margin:10px 0">成立时间:{{factoryInfo.set_up_time}}</div>
                                        <div class="fontS16 co6">主营：<span :key="item" v-for="item in factoryInfo.main_business">{{ item }} | </span></div>
                                        <div class="flex" style="flex-wrap:wrap">
                                            <div v-for="item in factoryInfo.tags" class="lisTag fontS16 co6" :key="item">{{item}}</div>
                                        </div>
                                        <div class="flexJBC map">
                                            <div class="elipes1 co3 fontS16" style="width:352px">{{factoryInfo.address}}
                                            </div>
                                            <span style="color: #5C7DCD;" class="fontS16">查看地图</span>
                                        </div>
                                    </div>
                                    <div class="whiteBox">
                                        <div class="fontS20 co3">联系工厂</div>
                                        <div class="co6 fontS16 marB2" style="margin-top:10px">有任何问题欢迎电话或在线沟通咨询</div>
                                        <div class="flex">
                                            <div class="btn fontS20 cur" style="margin-right:20px">电话联系</div>
                                            <div class="btn fontS20 cur">在线沟通</div>
                                        </div>
                                        <div class="fontS20 co3" style="margin-top:40px">立刻询价</div>
                                        <div class="co6 fontS16 marB2" style="margin-top:10px">提交后，工厂将第一时间为您服务</div>
                                        <div class="marB2"><el-input v-model="name" placeholder="姓名"
                                                style="height:48px" /></div>
                                        <div class="marB2"> <el-input v-model="phone" placeholder="手机号"
                                                style="height:48px" /></div>
                                        <div class="marB2"> <el-input v-model="intrduction" placeholder="请简单描述您的需求"
                                                type="textarea" style="min-height:48px" /></div>

                                        <div class="btn1 cof fontS20 cur">立即发送</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>



            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.store {


    .left {
        .storeImg {
            width: 80px;
            height: 80px;
            background: #E8E8E8;
            opacity: 1;
            border: 1px solid #CCCCCC;
            margin-right: 20px;
        }

        .btn {
            width: 68px;
            text-align: center;
            line-height: 32px;
            height: 32px;
            background: #5C7DCD;
            border-radius: 36px 36px 36px 36px;
            opacity: 1;
        }
    }

    align-items: flex-start;
    padding: 40px 0;
}

.home {
    margin-top: 20px;

    .leftwhiteBox {
        width: 989px;
        background: #FFFFFF;
        padding: 20px;
        box-sizing: border-box;

        .lis {
            .right {
                width: 825px;

                .huifu {
                    align-items: flex-start;
                    // width: 952px;
                    min-height: 120px;
                    background: #F5F5F5;
                    padding: 20px;
                    box-sizing: border-box;
                }

                .evaimgBox {
                    img {
                        width: 80px;
                        height: 80px;
                        background: #F8F8F8;
                        border: 1px solid #CCCCCC;
                        margin-right: 10px;
                        margin-bottom: 10px;
                    }
                }

                .info {

                    line-height: 28px;
                    margin: 10px 0 24px;
                }

                .num {
                    margin: 0 20px;
                    padding: 0 10px;
                    border-left: 1px solid #707070;
                    border-right: 1px solid #707070;
                }
            }

            .left {
                text-align: center;
                margin-right: 40px;

                img {
                    width: 80px;
                    height: 80px;
                    margin-bottom: 24px;
                    border-radius: 50%;

                }
            }

            padding: 40px 0;
            box-sizing: border-box;

            border-bottom: 1px solid #CCCCCC;

        }

        .imgBox {
            position: relative;

            img {
                width: 202px;
                height: 202px;
                margin-bottom: 20px;

            }

            .priceBox {
                position: absolute;
                height: 32px;
                line-height: 32px;
                padding: 0 17px;
                bottom: 0;
                right: 15px;
                background-color: rgba(0, 0, 0, .6);
            }

        }
    }

    .rightBox {
        width: 463px;

        .whiteBox {
            width: 463px;
            padding: 20px;
            box-sizing: border-box;
            background: #FFFFFF;
            margin-left: 20px;

            .btn1 {
                width: 423px;
                height: 48px;
                background: #5C7DCD;
                line-height: 48px;
                text-align: center;
            }

            .btn {
                width: 160px;
                height: 48px;
                background: rgb(222, 229, 245, );
                border-radius: 24px;
                text-align: center;
                line-height: 48px;
                color: #5C7DCD;
            }

            .factory_img {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background: #E2E2E2;
                border: 1px solid #CCCCCC;
                margin-right: 10px;
            }

            .lisTag {
                height: 32px;
                line-height: 32px;
                background: #F5F5F5;
                padding: 0 8px;
                margin-right: 10px;
                margin-top: 10px;
            }

            .map {
                margin-top: 20px;
                // height: 62px;
                // line-height: 62px;
                padding-top: 20px;
                border-top: 1px solid #CCCCCC;
            }
        }

    }
}

.list {

    flex-wrap: wrap;
    padding: 0 40px;
    box-sizing: border-box;
    justify-content: flex-start;

    .li {
        width: 336px;
        position: relative;

        .title {
            margin: 20px 0 16px;
            line-height: 28px;
        }

        margin-bottom: 40px;
        margin-right: 16px;

        img {
            width: 336px;
            height: 339px;
            display: block;
        }
    }
}

.paixuBox {

    .btn {
        width: 72px;
        height: 32px;
        background: #FFC047;
        line-height: 32px;
        text-align: center;
        margin-left: 20px;
    }

    .priceBox {
        margin-left: 79px;

        /deep/.el-input-group__append,
        /deep/.el-input-group__prepend,
        /deep/.el-input__wrapper {
            background-color: #fff;
            box-shadow: none;
        }

        /deep/.el-input-group__prepend {
            padding: 0;
        }

    }

    width: 1472px;
    height: 58px;
    // line-height: 58px;
    background: #F5F5F5;
    border: 1px solid #CCCCCC;
    margin: 20px 0;

    .active {
        color: #5C7DCD;
        background: #FFFFFF;
        border: 1px solid #CCCCCC;
    }

    .li {
        width: 140px;
        align-items: center;
        height: 58px;
        position: relative;

    }
}

.navTag {
    padding-top: 20px;
    // width: 100%;
    width: 1472px;
    border-bottom: 2px solid #5C7DCD;

    .activetag {
        background: #5C7DCD;
        color: #fff;
    }

    span {
        display: block;
        width: 140px;
        height: 48px;
        line-height: 48px;
        text-align: center;
    }
}

.topsearch {
    .left {

        img {
            width:180px;
            height:70px;
        }
    }

    .right {
        .inputBox {
            width: 683px;
            height: 48px;
            border: 2px solid #5C7DCD;
            padding-left: 20px;
            box-sizing: border-box;

            input {
                height: 41px;
                flex: 1;
                border: none;
                outline: none;
            }

            .seacrhIcon {
                width: 75px;
                height: 48px;
                background: #5C7DCD;
                display: flex;
                align-items: center;
                position: relative;
                right: -1px;

                img {
                    width: 29.97px;
                    height: 29.97px;
                    margin: 0 auto;
                }
            }
        }

    }
}
</style>
