<script setup>
import { ref, onBeforeMount,defineProps,defineEmits,computed} from 'vue'
import { ArrowDown, ArrowRight } from "@element-plus/icons-vue";
import { get_coupon, findCouponRule, receiveCoupon } from '@/utils/api'
const props=defineProps({
    centerDialogVisible:{
              default:false,
              type:Boolean
          }
      })
const emit=defineEmits(['update:centerDialogVisible','handleSuccessed','handRemove'])
const getCenterShow=computed({
    get:()=>props.centerDialogVisible,
    set:(val)=> {emit('update:centerDialogVisible',val)}
})
const list = ref([])
const info = ref({})
const width = ref({})
// const centerDialogVisible = ref(false)
onBeforeMount(() => {
    width.value = document.documentElement.clientWidth
    getList()
    findCouponRule().then(res => {
        if (res.code == 200) {
            info.value = res.data
        }
    })
})
const getList = () => {
    get_coupon({ page: 1, limit: 100, type: 0 }).then(res => {
        if (res.code == 200 && res.data.length > 0) {
            // centerDialogVisible.value = true
            emit('update:centerDialogVisible',true)
            list.value = res.data
            list.value.forEach(item => {
                Reflect.set(item, 'showInfo', false)
            })
            receiveCoupon()
        }
    })
}
</script>
<template>
    <el-dialog v-model="getCenterShow" width="800px" style="background-color: rgb(255,249,231);height:740px;">
        <div class="homepage flex">
            <div class="" style="background-color: rgb(254,241,217);">
                <div class="imgBox">
                    <!-- <img :src="info.image" alt="" class="img" v-if="info.image" /> -->
                    <img src="../../assets/copbgc.png" alt="" class="img" style="object-fit:cover"/>
                </div>
                <div class='lisInfoBox printLogFather'
                    style="height:325px;background-color: #EE8950;overflow: auto;background-color: rgb(255,249,231);">
                    <div class="flexJBC printLogFather" style="flex-wrap:wrap;">
                        <div class='lis' v-for="(item, index) in list" :key="index">
                            <div class='box'>
                                <div class='liBox'>
                                    <div class='whiBox flexJBC'>
                                        <div class='left'>
                                            <div class='left_t'>
                                                <div class='flex'>
                                                    <div style="color: #EE8950;margin-right: 10px;white-space: nowrap;"
                                                        v-if="item.type == 1">
                                                        <span class='fw7 fontS18'>￥</span>
                                                        <span class="fw9 fontS20">{{ item.coupon_price }}</span>
                                                    </div>
                                                    <div style="color: #EE8950;margin-right: 10px;white-space: nowrap;"
                                                        v-if="item.type == 2">
                                                        <span class="fw9 fontS20">{{ item.discount }}</span>
                                                        <span class='fw7 fontS18'>折</span>
                                                    </div>
                                                    <div class="">
                                                        <div class="co3 fw9 fontS16" style="margin-bottom: 10px;">
                                                            {{ item.title }}
                                                        </div>
                                                        <div class="fontS12 co6">
                                                            <div class="">
                                                                {{ item.full_text }}
                                                            </div>
                                                            <div class="" style="margin-top: 4px;" v-if="item.time_type == 0">
                                                                <div class="" >
                                                                    有效期：
                                                                </div>
                                                                <div style="white-space: nowrap;margin-top: 4px;">
                                                                    {{ item.start_time.slice(0,10)}}-{{ item.end_time.slice(0,10)}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class='left_b ' @click="item.showInfo = !item.showInfo">
                                                <div class="flexJBC">
                                                    <div class="co6 fontS16">
                                                        使用说明
                                                    </div>
                                                    <el-icon size='16px' color="#666">
                                                        <ArrowDown v-if="item.showInfo" />
                                                        <ArrowRight v-else />
                                                    </el-icon>
                                                </div>
                                                <div class="fontS16 co6" style="margin-top: 8px;" v-if="item.showInfo">
                                                    {{ item.text }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class='right'>
                                            <div class='cilr'></div>
                                            <div class='btn cur'>自动领取</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="rulesBox" v-if="false">
                        <div class='rules'>
                            <div class="center cof fontS28 fw9">
                                优惠券使用规则
                            </div>
                            <div class="cof fontS24" style="line-height: 40px;margin-top:20px;">
                                {{ info.text }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </el-dialog>
</template>
<style lang="less" scoped>
/deep/ .el-dialog__body {
    padding: 0 !important;
}

/deep/ .el-dialog__title .el-dialog__header {
    display: none !important;
}

.rulesBox {
    padding-bottom: 6px;
    border-radius: 24px;
    width: 100%;

    .rules {
        background: linear-gradient(180deg, #F4C28F 0%, #EDAC89 100%);
        border-radius: 24px;
        padding: 20px;
        box-sizing: border-box;
    }
}

.lisInfoBox {
    position: relative;
    left: 0;
    padding: 0 20px;
    width: 100%;
    box-sizing: border-box;
    top: 350px;
    flex-wrap: wrap;
    background-color: rgb(255, 249, 231);


    .lis {
        // width: 46%;
        padding-bottom: 6px;
        background: #FFFFFF;
        border-radius: 24px;
        margin-bottom: 10px;

        .box {
            background: linear-gradient(180deg, #F4C28F 0%, #EDAC89 100%);
            border-radius: 24px;
            padding: 10px;
            box-sizing: border-box;

            .liBox {
                padding-bottom: 6px;
                background: #FAE5B4;
                box-shadow: 0px 8px 8px rgba(219, 140, 104, 0.5);
                border-radius: 24px;

                .whiBox {
                    background: #FFFFFF;
                    border-radius: 24px;

                    .left {
                        width: 70%;
                        border-right: 1px dashed #E0E0E0;
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;

                        .left_t {
                            flex: 1;
                            padding: 10px;
                            box-sizing: border-box;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                        }

                        .left_b {
                            // height:46px;
                            // line-height: 46px;
                            padding: 8px 12px;
                            border-top: 2px solid #F5F5F5;
                            // padding:0 20px;
                            box-sizing: border-box;
                        }
                    }

                    .right {
                        padding: 20px 10px;
                        box-sizing: border-box;
                        position: relative;

                        .cilr {
                            width: 10px;
                            height: 10px;
                            background: #F0A54F;
                            float: right;
                            border-radius: 50%;
                            position: absolute;
                            right: 20px;
                            top: -5px;
                        }

                        .btn {
                            width: 100px;
                            height: 40px;
                            background: linear-gradient(180deg, #F3C18E 0%, #EDAC89 100%);
                            border-radius: 40px;
                            line-height: 40px;
                            text-align: center;
                            font-weight: 900;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}

.imgBox {
    width: 760px;
    position: relative;
    left: 0%;
    top: 0;

    .img {
        height: auto;
        width: 100%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}
</style>