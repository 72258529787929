

<script setup>
import {defineProps,defineEmits,computed } from 'vue';
const props=defineProps({
    gatherShow:{
        default:false,
        type:Boolean
    },
    text:{
        default:1,
        type:Number
    }
})
const emit = defineEmits(['handleSuccessed','update:gatherShow','handRemove'])//'handleRemove', 
const handleSuccessed=()=>{
    emit('handleSuccessed',false)
}
const handRemove=()=>{
    emit('handRemove',false)
}
const getGatherShow=computed({
    get:()=>props.gatherShow,
    set:(val)=>{
        emit('update:gatherShow',val)
    }
})
</script>
<template>
  <div>
    <el-dialog v-model="getGatherShow" title="" width="30%" center align-center :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
        <span>
            确认要{{ text == 1 ? "删除" : text == 2 ? "收货" : "取消退款"}}吗？
        </span>
        <template #footer>
            <span class="dialog-footer">
                <el-button type="danger" color="#25459C" @click="handleSuccessed">
                    确认
                </el-button>
                <button  @click="handRemove" class="btnClose delBtn">
                        取消
                    </button>
            </span>
        </template>
    </el-dialog>
  </div>
</template>
<style>

</style>