<script setup>
import { ref, reactive, onBeforeMount, onDeactivated, onActivated } from "vue";
import {
  userget_user_info,
  user_order,
  getGuesslike,
  refundList,
  orderdel,
  ordercancel,
  delivery,
  getProductReason,
  refundCancel,
  refundDelete,
} from "@/utils/api";
import { useRouter } from "vue-router";
import MyCoupone from "@/views/userInfo/myCoupone.vue";
import { ElMessage } from "element-plus";
import Regisetr from "../set/regisetr.vue";
import CommenList from "../order/commenList.vue";
import deleteModel from "./components/deleteModel";
import gatherModel from "./components/gatherModel";
import certification from "./certificationQualification";
const activeTop = ref(0);
// const show = ref(false)
const router = useRouter();
const leftactive = ref(null); //leftlis的当前项
const allOrderactive = ref(0); //allOrder的当前项
const centerDialogVisible = ref(false);
const centerDialogVisible1 = ref(false); //取消订单弹窗
const data = reactive({
  list: [], //订单列表
  last_page: 3,
  page: 1,
  likeLast_page: 1, //猜你喜欢
  pageLike: 1, //猜你喜欢
  userInfo: {}, //用户信息
  likeList: [], //猜你喜欢列表
  wuliuList: [], //物流列表
  leftlis: [
    { name: "我的购物车", link: "/cart" },
    { name: "全部订单", link: "" },
    { name: "我的收藏", link: "/collect" },
    { name: "我的优惠券", link: "" },
    { name: "我的评价", link: "" },
    { name: "浏览记录", link: "/browsingHistory" },
    { name: "采购商资质", link: "" },
    { name: "关注门店", link: "/attention" },
    { name: "投诉/建议", link: "/option" },
    {
      name: "商户入驻",
      link: "http://merchant.netsea.aucfan-cn.com/#/merchantSetIn",
      type: 1,
    },
  ],
  allOrder: [
    { name: "所有订单", num: false, id: "All" },
    { name: "待复核", num: 0, id: 5 },
    { name: "待付款", num: 0, id: 0 },
    { name: "待发货", num: 0, id: 1 },
    { name: "待收货", num: 0, id: 2 },
    { name: "待评价", num: 0, id: 3 },
  ], // { name: '退款/售后', num: 0, id: '-3' }
  delAnddeviItem: {}, //要删除 和 确认收货的列表 取消订单
  type: 1, // 1是删除订单  2是确认收货  3取消维权订单
  cancel: 3, //取消订单原因
  reason: [], //取消订单原因列表
});

const loading = ref(true);
// 猜你喜欢触底函数
const loadLike = () => {
  // return
  if (data.pageLike < data.likeLast_page) {
    data.pageLike++;
    getLike();
  }
};
onBeforeMount(() => {
  getUserinfo();
  // 猜你喜欢
  getLike();
  // 取消订单原因
  getProductReason({ type: 0 }).then((res) => {
    if (res.code == 200) {
      data.reason = res.data;
    }
  });
  //主页物流信息列表
  user_order({ status: 3, page: 1, limit: 100, keywords: "" }).then((res) => {
    if (res.code == 200) {
      data.wuliuList = res.data.data;
    }
  });
  let routerIndex = router.currentRoute.value.query;
  console.log(routerIndex.orderIndex,'routerIndex')
  // 认证跳过来
  if(routerIndex.index==6){
    leftactive.value = routerIndex.index;
  }
  // 首页跳转过来
  if(routerIndex.orderIndex){
    allOrderactive.value = routerIndex.orderIndex;
    leftactive.value=1
    getList()
  }
});
const chageAllOrder=(index,num)=>{
  if(num==1){
    leftactive.value=1
  }
  router.replace({ query: {} });
  data.page = 1;
  allOrderactive.value = index;
    getList()
  console.log(allOrderactive.value,' allOrderactive.value',num)
}
const getList = () => {
  loading.value = true;
  if (allOrderactive.value == 6) {
    getRefund();
  } else {
    user_order({
      status: data.allOrder[allOrderactive.value].id,
      page: data.page,
      limit: 10,
      keywords: "",
    }).then((res) => {
      loading.value = false;
      if (res.code == 200) {
        data.last_page = res.data.last_page;
        if (data.page == 1) {
          console.log(data.list,'data.list1')
          data.list = res.data.data;
        } else {
          console.log(data.list,'data.list2')
          data.list = data.list.concat(res.data.data);
        }
      }
    });
  }
};

const getLike = () => {
  getGuesslike({ page: 1, limit: 6 }).then((res) => {
    if (res.code == 200) {
      data.likeList = res.data.data;
      data.allOrder;
    }
  });
};

// 获取退货列表
const getRefund = () => {
  refundList({ limit: 10, page: data.page }).then((res) => {
    if (res.code == 200) {
      data.last_page = res.data.last_page;
      if (data.page == 1) {
        data.list = res.data.data;
      } else {
        data.list = data.list.concat(res.data.data);
      }
    }
  });
};

// 获取用户信息
const getUserinfo = () => {
  userget_user_info().then((res) => {
    if (res.code == 200) {
      data.userInfo = res.data;
      data.allOrder.forEach((item, index) => {
        switch (index) {
          case 1:
            item.num = data.userInfo.order.review;
            break;
          case 2:
            item.num = data.userInfo.order.waiting_pay;
            break;
          case 3:
            item.num = data.userInfo.order.waiting_delivery;
            break;
          case 4:
            item.num = data.userInfo.order.waiting_receipt;
            break;
          case 5:
            item.num = data.userInfo.order.waiting_evaluation;
            break;
          case 6:
            item.num = data.userInfo.order.waiting_refund;
            break;
        }
      });
    }
  });
};

const load = () => {
  if (data.last_page > data.page) {
    data.page++;
    getList();
  }
};
// 个人中心左边
const leftMenuCLick = (index) => {
  console.log('jinru',index)
  if (data.leftlis[index].type == 1) {
    window.open(data.leftlis[index].link);
  } else if (data.leftlis[index].link) {
    router.push({ path: data.leftlis[index].link });
  } else {
    console.log(index,'index')
    leftactive.value = index;
    activeTop.value = null;
    if (leftactive.value == 1) {
      chageAllOrder(0,1)
    }
  }
};
const godetail = (item) => {
  let sub_order_id = [];
  item.store.forEach((item) => {
    sub_order_id.push(item.sub_order_id);
  });
  sub_order_id.length == 0 ? (sub_order_id = [0]) : "";
  router.push({
    path: "/order/orderDetail",
    query: { order_id: item.order_id, sub_order_id: sub_order_id.join(",") },
  });
};
const godetailRefund = (item) => {
  router.push({
    path: "/order/refundOrderDetail",
    query: { refund_id: item.refund_id },
  });
};
// 催发货
const urgeDelivery = () => {
  ElMessage.success("催发货成功");
};
// 立即付款
const payment = (item) => {
  router.push({
    path: "/pay",
    query: item,
  });
};
// 取消订单  (未完成)
const cancalOrder = () => {
  ordercancel({
    cancel: data.cancel,
    order_id: data.delAnddeviItem.order_id,
  }).then((res) => {
    if (res.code == 200) {
      centerDialogVisible1.value = false;
      ElMessage.success("取消成功");
      data.page = 1;
      getList();
      getUserinfo();
    }
  });
};

// 删除订单 // 确认收货  、、取消退款
const sureDelAndsurDelivery = () => {
  console.log(data.delAnddeviItem);
  let sub_order_id = [];
  data.delAnddeviItem.store.forEach((item) => {
    sub_order_id.push(item.sub_order_id);
  });
  if (data.type == 1) {
    //删除订单
    if (allOrderactive.value == 5) {
      // 删除退款售后订单
      refundDelete({ refund_id: data.delAnddeviItem.refund_id }).then((res) => {
        if (res.code == 200) {
          centerDialogVisible.value = false;
          ElMessage.success("删除成功");
          data.page = 1;
          getList();
          getUserinfo();
        }
      });
    } else {
      orderdel({
        order_id: data.delAnddeviItem.order_id,
        sub_order_id: sub_order_id.join(","),
      }).then((res) => {
        if (res.code == 200) {
          centerDialogVisible.value = false;
          ElMessage.success("删除成功");
          data.page = 1;
          getList();
          getUserinfo();
        }
      });
    }
  } else if (data.type == 2) {
    delivery({
      order_id: data.delAnddeviItem.order_id,
      sub_order_id: sub_order_id.join(","),
    }).then((res) => {
      if (res.code == 200) {
        centerDialogVisible.value = false;
        ElMessage.success("收货成功");
        data.page = 1;
        getList();
        getUserinfo();
      }
    });
  } else {
    // 取消退款
    refundCancel({ refund_id: data.delAnddeviItem.refund_id }).then((res) => {
      if (res.code == 200) {
        centerDialogVisible.value = false;
        ElMessage.success("取消成功");
        data.page = 1;
        getList();
        getUserinfo();
      }
    });
  }
};

// 查看物流
// const logisticsClick = (item) => {
//   console.log(item);
//   let sub_order_id = [];
//   item.store.forEach((item) => {
//     sub_order_id.push(item.sub_order_id);
//   });
//   router.push({
//     path: "/logistics",
//     query: {
//       sub_order_id: sub_order_id.join(","),
//       order_id: item.order_id,
//     },
//   });
// };
// 去评价
const goComment = (item) => {
  router.push({
    path: "/comment",
    query: { sub_order_id: item.sub_order_id },
  });
};
// 离开页面值改变
// onDeactivated(()=>{
//   activeTop.value = 0;
// })
</script>

<template>
  <!-- 删除/收货/退款 -->
  <gatherModel
    v-model:gatherShow="centerDialogVisible"
    :text="data.type"
    @handleSuccessed="sureDelAndsurDelivery"
    @handRemove="centerDialogVisible = false"
  ></gatherModel>
  <!-- 取消订单 -->
  <deleteModel
    v-model:deleteShow="centerDialogVisible1"
    v-model:cancel="data.cancel"
    :reason="data.reason"
    @handleSuccessed="cancalOrder"
    @handRemove="centerDialogVisible1 = false"
  ></deleteModel>
  <div class="topHomeBox cof">
    <div class="wrapCenter flex">
      <img
        src="../../assets/logo1.png"
        alt=""
        class="logo"
        style="object-fit: cover"
      />
      <div
        class="homeText fontS20 cur"
        :class="activeTop == 0 ? 'active' : ''"
        @click="
          activeTop = 0;
          leftactive = null;
        "
      >
        个人中心
      </div>
      <div
        class="homeText fontS20 cur"
        :class="activeTop == 1 ? 'active' : ''"
        @click="
          activeTop = 1;
          router.push({ path: '/set' });
        "
        style="line-height: 50px"
      >
        账户设置
      </div>
    </div>
  </div>
  <div
    class="flex wrapCenter"
    style="align-items: flex-start"
    v-if="data.userInfo.id"
  >
    <div class="leftBox">
      <div
        class="lis cur fontS20"
        style="margin-top: 24px"
        :class="leftactive == index ? 'homeRed' : 'co3'"
        v-for="(item, index) in data.leftlis"
        :key="index"
        @click="leftMenuCLick(index)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="rightBox printLogFather">
      <!-- 认证资质 -->
      <div v-if="leftactive == 6">
        <certification></certification>
      </div>
      <!-- 主页开始 -->
      <Regisetr v-if="leftactive == 8" />
      <!-- 评价 -->
      <CommenList v-if="leftactive == 4" />

      <div v-if="leftactive == 3">
        <MyCoupone />
      </div>
      <div v-if="activeTop == 0 && leftactive == null" style="width: 1080px">
        <div class="topBox flexJBC">
          <div class="flex">
            <img
              :src="data.userInfo.avatar"
              alt=""
              class="headimg"
              style="object-fit: cover"
            />
            <div class="co3">
              <span class="fontS24">{{ data.userInfo.nickname }}</span>
              <div class="fontS16" style="margin-top: 10px">
                ID:{{ data.userInfo.id }}
              </div>
            </div>
          </div>
          <!-- 积分和钱包 -->
          <!-- <div class="flex">
                              <div class="flex" style="margin-right:48px">
                                  <img src="../../assets/jifen.png" alt="" class="rightimg">
                                  <div class="co3 fontS20 center">
                                      <span class=" ">￥1100</span>
                                      <div class="" style="margin-top:5px">积分</div>
                                  </div>
                              </div>
                              <div class="flex">
                                  <img src="../../assets/money.png" alt="" class="rightimg">
                                  <div class="co3 fontS20 center">
                                      <span class=" ">￥1100</span>
                                      <div class="" style="margin-top:5px">钱包余额</div>
                                  </div>
                                  <div style="margin-left:20px">
                                      <el-icon color="#999" size="20px">
                                          <Hide @click="show = true" v-if="!show" />
                                          <View @click="show = false" v-else />
                                      </el-icon>
                                  </div>
                              </div>
                          </div> -->
        </div>
        <div class="statusBox flexJBC co3 fontS16">
          <!-- leftMenuCLick(1); -->
          <div
            v-for="(item, index) in data.allOrder"
            @click="chageAllOrder(index,1);"
            :key="index"
            :style="{
              borderRight:
                index == data.allOrder.length - 1 ? '' : '1px solid #ccc',
            }"
          >
            <div v-if="index > 0" class="li cur">
              <span style="margin-right: 10px">{{ item.name }}</span>
              <span class="homeRed" v-if="item.num > 0">{{ item.num }}</span>
            </div>
          </div>
        </div>
        <!-- <div class="wuliuBox marT2">
                  <div class="top flex">
                      <img src="../../assets/wuliu.png" alt="">
                      <span class="fontS24 co6">物流信息</span>
                  </div>
                  <div class="lis flex" v-for="(item, index) in 1" :key="index">
                      <img src="../../assets/Image/5494c0fa4c8d21450ef7357d0929a5d8.jpeg" alt="">
                      <div class="marL2 fontS16">
                          <div class="co3">包裹派送中，请留意电话短信等配送信息···</div>
                          <div style="margin-top:16px">
                              <span style="margin-right:40px" class="co3">2022-11-22 12:12:12</span>
                              <span class="co6 cur" @click.stop="logisticsClick(item)">查看物流详情</span>
                          </div>
                      </div>
                  </div>
          </div> -->
        <div class="youLikeBox marT2">
          <div class="flexJBC">
            <div class="flex">
              <span class="homeRed" style="font-size: 28px">猜你喜欢</span>
            </div>
            <!-- <el-icon size="40px " color="#999" @click="getLike">
                                  <RefreshRight />
                </el-icon> -->
          </div>
          <div
            class="list marT2 printLogFather"
            v-infinite-scroll="loadLike"
            style="max-height: 72vh; overflow-anchor: none; overflow: auto"
            infinite-scroll-distance="30px"
          >
            <el-row :gutter="16">
              <el-col
                :span="8"
                v-for="item in data.likeList"
                :key="item.id"
                @click="
                  router.push({ path: '/goodsDetail', query: { id: item.id } })
                "
              >
                <div class="li cur">
                  <img :src="item.image" alt="" style="object-fit: cover" />
                  <div class="title elipes2 co3 fonrS22">
                    {{ item.product_name }}
                  </div>
                  <div class="flex">
                    <span class="priceColor fontS12">￥</span>
                    <span class="priceColor fontS28">{{ item.price }}</span>
                    <span class="fontS18 co9" style="margin-left: 14px"
                      >{{ item.sales }}人付款</span
                    >
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <!-- 主页结束 -->

      <!-- 全部订单开始 -->
      <div class="allOrder" v-if="leftactive == 1">
        <div class="topTitle flex co6 fontS22">
          <div
            v-for="(item, index) in data.allOrder"
            :key="index"
            class="li cur"
            :class="allOrderactive == index ? 'active' : ''"
            @click="chageAllOrder(index)"
          >
            {{ item.name }}
            <span v-if="item.num > 0">{{ item.num }}</span>
          </div>
        </div>
        <div class="desBox flex fontS18 co6" v-if="allOrderactive != 6">
          <div style="margin-left: 224px">商品</div>
          <div style="margin-left: 280px">单价</div>
          <div style="margin-left: 48px">数量</div>
          <div style="margin-left: 56px">商品操作</div>
          <div style="margin-left: 90px">金额</div>
          <div style="margin-left: 89px">交易状态</div>
          <div style="margin-left: 97px; margin-right: 52px">交易操作</div>
        </div>
        <!-- 退款售后 -->
        <div class="desBox flex fontS18 co6" v-else>
          <div style="margin-left: 224px">商品</div>
          <div style="margin-left: 224px">退款金额</div>
          <div style="margin-left: 100px">申请时间</div>
          <div style="margin-left: 100px">服务类型</div>
          <div style="margin-left: 100px">退款状态</div>
          <div style="margin-left: 100px; margin-right: 52px">交易操作</div>
        </div>

        <div
          class="printLogFather"
          infinite-scroll-distance="30px"
          v-infinite-scroll="load"
          v-loading="loading"
          :infinite-scroll-immediate="false"
          :infinite-scroll-disabled="false"
          style="height: 70vh; overflow: auto"
        >
          <el-empty description="没有订单数据" v-if="data.list.length == 0" />
          <div v-if="allOrderactive != 6">
            <!--   -->
            <div
              class="lisBox"
              v-for="(item, index) in data.list"
              :key="index"
              @click="godetail(item)"
            >
              <div v-for="(item1, index1) in item.store" :key="index1">
                <div class="flexJBC fontS20 co6 top">
                  <div class="flex">
                    <img
                      :src="item1.store_image"
                      alt=""
                      class="headimg"
                      style="object-fit: cover"
                    />
                    <span class="fontS20 co6">{{ item1.store_name }}</span>
                  </div>
                  <div class="flex">
                    <span>订单号:{{ item.sn }}</span>
                    <span style="margin: 0 40px">{{ item.create_time }}</span>
                    <!-- <div
                      @click.stop="
                        data.delAnddeviItem = item;
                        centerDialogVisible = true;
                        data.type = 1;
                      "
                      class="cur"
                    >
                      <img
                        src="../../assets/del.png"
                        alt=""
                        style="width: 40px; height: 40px; object-fit: cover"
                      />
                    </div> -->
                  </div>
                </div>
                <div>
                  <div
                    class="goods co3 fontS16 flex"
                    style="align-items: flex-start"
                  >
                    <div>
                      <div
                        class="left flexJBC"
                        style="align-items: flex-start"
                        v-for="(item2, index2) in item1.goods"
                        :key="index2"
                      >
                        <div class="flex cur" style="align-items: flex-start">
                          <img
                            :src="item2.image"
                            alt=""
                            class="goodsimg"
                            style="object-fit: cover"
                          />
                          <div class="nameBox fontS16">
                            <div class="name elipes2">
                              {{ item2.product_name }}
                            </div>
                            <div
                              class="co9 marT2"
                              v-if="item2.detail"
                              :style="{
                                marginTop: item1.status == 1 ? '7px' : '20px',
                              }"
                            >
                              规格：{{ item2.detail }}
                            </div>
                            <div style="margin-top: 10px" class="co9">
                              {{ item2.warehouse_type_text }}
                            </div>
                            <div
                              style="margin-top: 10px"
                              class="co9"
                              v-if="item1.status == 1"
                            >
                              {{ item2.deliver_status_text }}
                            </div>
                          </div>
                        </div>
                        <div>¥{{ item2.product_price }}</div>
                        <div>{{ item2.product_num }}</div>
                        <div style="width: 80px">
                          <!-- <div class="cur" >修改地址</div> -->
                        </div>
                      </div>
                    </div>
                    <div
                      class="priceBox center"
                      :style="{ height: 203 * item1.goods.length + 'px' }"
                    >
                      <div>¥{{ item1.real_price }}</div>
                      <div style="margin-top: 10px">
                        (含运费：¥{{ item1.real_postage }})
                      </div>
                    </div>
                    <div :style="{ height: 203 * item1.goods.length + 'px' }">
                      <!-- 待支付 -->
                      <div class="Box center" v-if="item1.status == 0">
                        <div>待支付</div>
                        <div style="margin: 10px 0" class="cur">订单详情</div>
                      </div>
                      <!-- 待复核 -->
                      <div class="Box center" v-if="item1.status == 5">
                        <div>待复核</div>
                        <div style="margin: 10px 0" class="cur">订单详情</div>
                      </div>
                      <!-- 待发货 -->
                      <div class="Box center" v-if="item1.status == 1">
                        <div>待发货</div>
                        <div style="margin: 10px 0" class="cur">订单详情</div>
                        <!-- <div style="margin:10px 0" class="cur"
                                                          @click.stop="logisticsClick(item)">查看物流</div> -->
                      </div>
                      <!-- 待收货 -->
                      <div class="Box center" v-if="item1.status == 2">
                        <div>待收货</div>
                        <div style="margin: 10px 0" class="cur">订单详情</div>
                        <!-- <div style="margin:10px 0" class="cur"
                                                          @click.stop="logisticsClick(item)">查看物流</div> -->
                      </div>
                      <!-- 交易完成待评价 -->
                      <div class="Box center" v-if="item1.status == 3">
                        <div>交易完成</div>
                        <div style="margin: 10px 0" class="cur">订单详情</div>
                        <!-- <div style="margin:10px 0" class="cur"
                                                          @click.stop="logisticsClick(item)">查看物流</div> -->
                      </div>
                      <!-- 交易完成已评价 -->
                      <div class="Box center" v-if="item1.status == 4">
                        <div>交易完成</div>
                        <div style="margin: 10px 0" class="cur">订单详情</div>
                        <!-- <div style="margin:10px 0" class="cur"
                                                          @click.stop="logisticsClick(item)">查看物流</div> -->
                        <div style="margin: 10px 0">已评价</div>
                      </div>
                      <!-- （超时关闭） -->
                      <!-- <div class="Box center ">
                                              <div>
                                                  <div>交易关闭</div>
                                                  <div class="co6 fontS14">（超时关闭）</div>
                                              </div>
                                              <div style="margin:10px 0">订单详情</div>
                                          </div> -->
                      <!-- （退款成功） -->
                      <div class="Box center" v-if="item1.status == '-3'">
                        <div>
                          <div>交易关闭</div>
                          <div class="co6 fontS14" style="margin-top: 2px">
                            （退款成功）
                          </div>
                        </div>
                        <div style="margin: 10px 0" class="cur">订单详情</div>
                      </div>
                      <!-- 取消订单 -->
                      <div class="Box center" v-if="item1.status == '-1'">
                        <div>
                          <div>交易关闭</div>
                          <div class="co6 fontS14" style="margin-top: 2px">
                            （取消订单）
                          </div>
                        </div>
                        <div style="margin: 10px 0" class="cur">订单详情</div>
                      </div>
                    </div>

                    <div :style="{ height: 203 * item1.goods.length + 'px' }">
                      <div
                        class="Box center"
                        style="width: 186px; border-right: none"
                        v-if="item1.status == 0 && !item1.disable_show_pay"
                      >
                        <div class="btn cof cur" @click.stop="payment(item)">
                          立即付款
                        </div>
                        <div
                          class="marT2 cur"
                          @click.stop="
                            data.delAnddeviItem = item;
                            centerDialogVisible1 = true;
                          "
                        >
                          取消订单
                        </div>
                      </div>
                      <div
                        class="Box center"
                        style="width: 186px; border-right: none"
                        v-if="item1.status == 1"
                      >
                        <div class="btn cof cur" @click.stop="urgeDelivery">
                          催发货
                        </div>
                      </div>
                      <div
                        class="Box center"
                        style="width: 186px; border-right: none"
                        v-if="item1.status == 2"
                      >
                        <div class="flex">
                          <img
                            src="../../assets/clock.png"
                            alt=""
                            style="width: 24px; height: 24px; object-fit: cover"
                          />
                          <div>还剩{{ item1.auto_deliver_date }}</div>
                        </div>
                        <div
                          class="btn cof marT2 cur"
                          style="background: #ffc047"
                          @click.stop="
                            data.delAnddeviItem = item;
                            centerDialogVisible = true;
                            data.type = 2;
                          "
                        >
                          确认收货
                        </div>
                      </div>
                      <div
                        class="Box center cur"
                        style="width: 186px; border-right: none"
                        v-if="item1.status == 3"
                      >
                        <div class="btn1 fontS16" @click.stop="goComment(item)">
                          去评价
                        </div>
                      </div>
                      <!-- 待复核 -->
                      <div
                        class="Box center cur"
                        style="width: 186px; border-right: none"
                        v-if="item1.status == 5 && !item1.disable_show_pay"
                      >
                        <div
                          class="marT2 cur"
                          @click.stop="
                            data.delAnddeviItem = item;
                            centerDialogVisible1 = true;
                          "
                        >
                          取消订单
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 退款售后 -->
          <div
            class="lisBox"
            v-else
            v-for="(item, index) in data.list"
            :key="index"
            @click="godetailRefund(item)"
          >
            <!-- 退款售后 -->
            <div>
              <div class="flexJBC fontS20 co6 top">
                <div class="flex">
                  <img
                    :src="item.goods.store_image"
                    alt=""
                    style="object-fit: cover"
                    class="headimg"
                  />
                  <span class="fontS20 co6">{{ item.goods.store_name }}</span>
                </div>
                <div class="flex">
                  <span>订单号:{{ item.refund_sn }}</span>
                  <span style="margin: 0 40px">{{ item.create_time }}</span>
                  <img
                    src="../../assets/del.png"
                    alt=""
                    style="width: 40px; height: 40px; object-fit: cover"
                  />
                </div>
              </div>
              <div>
                <div
                  class="goods co3 fontS16 flex"
                  style="align-items: flex-start"
                >
                  <div
                    class="left flexJBC"
                    style="align-items: flex-start; border: none; width: 470px"
                  >
                    <div class="flex" style="align-items: flex-start">
                      <img
                        :src="item.goods.image"
                        alt=""
                        style="object-fit: cover"
                        class="goodsimg"
                      />
                      <div class="nameBox">
                        <div class="name elipes2">
                          {{ item.goods.product_name }}
                        </div>
                        <div class="co9 marT2" v-if="item.goods.detail">
                          规格：{{ item.goods.detail }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="flexJBC co3 fontS16"
                    style="padding-top: 20px; align-items: flex-start"
                  >
                    <div style="width: 100px" class="center">
                      ¥{{ item.total_product_price }}
                    </div>
                    <div style="width: 240px" class="center">
                      {{ item.status_time }}
                    </div>
                    <div style="width: 100px" class="center">
                      {{ item.refund_type_text }}
                    </div>
                    <div style="width: 250px" class="center">
                      {{ item.status_text }}
                    </div>
                    <div style="width: 100px" class="center">
                      <div v-if="item.status == 1">
                        <div style="color: #5c7dcd" class="cur">
                          填写退货信息
                        </div>
                        <!-- <div style="margin-top:16px" class="cur"
                                                          @click.stop="logisticsClick(item)">查看物流</div> -->
                      </div>
                      <div v-if="item.status == -2" class="cur">重新申请</div>
                      <div
                        class="cur"
                        v-if="item.status == 0 || item.status == 4"
                        @click.stop="
                          data.delAnddeviItem = item;
                          centerDialogVisible = true;
                          data.type = 3;
                        "
                      >
                        取消退款
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template> 
<style lang="less" scoped>
//   /* 单选 选中后的字体颜色 */

.rightBox {
  height: 88vh;
  overflow: scroll;

  .lisBox {
    // height: 203px;
    margin-bottom: 20px;

    .goods {
      .priceBox,
      .Box {
        height: 100%;
        display: block;
        padding: 20px;
        box-sizing: border-box;
        border-right: 1px solid #cccccc;

        .btn1 {
          width: 143px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #666666;
        }
      }

      .priceBox {
        width: 185px;
      }

      .Box {
        width: 137px;

        .btn {
          width: 143px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          background: #25459c;
        }
      }

      .left {
        height: 203px;
        width: 816px;
        padding: 20px;
        box-sizing: border-box;
        border-right: 1px solid #cccccc;

        .goodsimg {
          width: 120px;
          height: 120px;
          margin-right: 10px;
        }

        .nameBox {
          width: 312px;

          .name {
            line-height: 25px;
          }
        }
      }

      border: 1px solid #cccccc;
    }

    .top {
      border: 1px solid #cccccc;
      background: rgba(37, 69, 156, 0.2);
      padding: 16px 20px;
      box-sizing: border-box;

      .headimg {
        width: 40px;
        height: 40px;
        background: #ffffff;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
  }

  .allOrder {
    .desBox {
      height: 56px;
      background: #f6f7f9;
      margin: 40px 0;
    }

    .topTitle {
      border-bottom: 1px solid #cccccc;
      padding: 0 27px;
      box-sizing: border-box;

      .li {
        height: 50px;
        line-height: 50px;
        margin-right: 60px;
      }

      .active {
        height: 49px;
        // line-height: 35px;
        color: #25459c;
        border-bottom: 1px solid #25459c;
      }
    }

    margin-top: 40px;
  }

  .youLikeBox {
    .list {
      flex-wrap: wrap;
      // padding: 0 40px;
      box-sizing: border-box;
      justify-content: flex-start;

      .li {
        width: 336px;
        position: relative;

        .title {
          margin: 20px 0 16px;
          line-height: 28px;
        }

        margin-bottom: 40px;
        margin-right: 16px;

        img {
          width: 336px;
          height: 336px;
          display: block;
        }
      }
    }

    background: #ffffff;
    padding: 20px;
    box-sizing: border-box;
    border: 1px solid #cccccc;
  }

  .wuliuBox {
    .lis {
      padding: 20px;
      box-sizing: border-box;
      background: #ffffff;
      border: 1px solid #cccccc;

      img {
        width: 90px;
        height: 90px;
      }
    }

    .top {
      padding: 0 20px;
      box-sizing: border-box;
      height: 70px;
      background: #f6f7f9;
      border: 1px solid #cccccc;

      img {
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }
    }
  }

  .statusBox {
    height: 100px;
    background: #ffffff;
    border: 1px solid #cccccc;

    .li {
      height: 40px;
      line-height: 40px;
      width: 208px;
      text-align: center;
      border-left: 1px solid #cccccc;
    }

    .li:nth-of-type(1) {
      border: none;
    }
  }

  .topBox {
    padding: 20px;
    box-sizing: border-box;
    border: 1px solid #cccccc;

    .rightimg {
      width: 40px;
      height: 40px;
    }

    .headimg {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background: #d6d6d6;
      border: 2px solid #ffffff;
      margin-right: 10px;
    }

    height: 118px;
    background: #f6f7f9;
  }
}

.leftBox {
  width: 150px;
  padding-top: 10px;

  .lis {
    margin-top: 20px;
  }
}

.topHomeBox {
  height: 100px;
  background: #25459c;
  display: flex;
  justify-content: center;
  align-items: center;

  .homeText {
    margin-left: 37px;
    margin-right: 80px;
    margin-top: 32px;
  }

  .active {
    line-height: 50px;
    border-bottom: 4px solid #fff;
  }

  .logo {
    // width: 140px;
    height: 62px;
    // margin-right: 32px;
    // margin: 19px 0;
  }
}
</style>